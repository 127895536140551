import appRouter from '@/router'
import type { LocationQueryRaw, RouteLocationNormalizedLoaded } from 'vue-router'
import { BILL_PATH_CONFIG } from '@/config/path_config'
import fxUitls from '@/js/utils'
import { ref } from 'vue'


const currentRouterFrom = ref<Partial<RouteLocationNormalizedLoaded>>({})
export const useCurrentRouter = () => {
	const setCurrentRouterFrom = (from:RouteLocationNormalizedLoaded) => {
		currentRouterFrom.value = from
	}
	return {
		from: currentRouterFrom,
		setCurrentRouterFrom
	}
}

export function jumpToBill<T extends keyof typeof BILL_PATH_CONFIG, P extends keyof typeof BILL_PATH_CONFIG[keyof typeof BILL_PATH_CONFIG]> (type: string, query: LocationQueryRaw, pathType: P): void {
	const path = BILL_PATH_CONFIG[type as T][pathType]
	appRouter.push({
		path,
		query
	})
}

/**
 * @name routeLoginPage
 * @desc 跳转登录页面
 * @author shw
 * @date 2022/8/26
 */
export function routeLoginPage (): void {
	appRouter.replace('/login')
}

/**
 * @name clearRouterQueryByField
 * @desc 清除当前url指定的query参数
 * @author shw
 * @date 2023/06/25
 */
export function clearRouterQueryByField (route: RouteLocationNormalizedLoaded, field: string | string[]) {
	const { path, query } = route
	const cloneQuery = fxUitls.deepClone(query)
	if (Array.isArray(field)) {
		field.forEach((item: string) => {
			delete cloneQuery[item]
		})
	} else {
		delete cloneQuery[field]
	}
	appRouter.replace({
		path,
		query: cloneQuery
	})
}


