export default {
	'receivable-write-off-order': {
		id: 'receivable-write-off-order',
		label: '应收核销单',
		code: '0007_0005_0008',
		child: {
			'add-receivable-write-off': {
				id: 'add-receivable-write-off',
				label: '新建',
				code: '0007_0005_0008_001_001',
				decisionByAllModule: ['financial-manage_capital-manage_receivable-write-off-order.edit-receivable-write-off']
			},
			'edit-receivable-write-off': {
				id: 'edit-receivable-write-off',
				label: '编辑',
				code: '0007_0005_0008_002_001',
				controlledByAllModule: 'financial-manage_capital-manage_receivable-write-off-order.add-receivable-write-off'
			},
			'delete-receivable-write-off': {
				id: 'delete-receivable-write-off',
				label: '删除',
				code: '0007_0005_0008_004_001'
			},
			'un-receivable-write-off': {
				id: 'un-receivable-write-off',
				label: '反核销',
				code: '0007_0005_0008_251_001'
			},
			'hand-receivable-write-off': {
				id: 'hand-receivable-write-off',
				label: '手动核销',
				code: '0007_0005_0008_250_001'
			},
			'diff-receivable-write-off': {
				id: 'diff-receivable-write-off',
				label: '差异核销',
				code: '0007_0005_0008_250_002'
			},
			'match-receivable-write-off': {
				id: 'match-receivable-write-off',
				label: '匹配核销',
				code: '0007_0005_0008_250_003'
			}
		}
	}
}
