<template>
	<div class="wind-select-tree">
		<w-cell
			v-if="cellType==='cell'"
			:title="cellLabel"
			:value="currentCellText"
			:disabled="disabled"
			:required="required"
			:class="{'empty-cell-text': isEmptyCellText}"
			isLink
			@on-click="showModal"
		></w-cell>
		<div v-else :class="['wind-select-tree__cell', {
			'has-border': border
		}]" @click="showModal">
			<span class="cell-text" :class="{'empty-cell-text': isEmptyCellText}">{{currentCellText}}</span>
			<w-icon-svg v-if="!disabled" type="icon-cell-link-arrow" class="select-arrow-icon"></w-icon-svg>
		</div>
		<a-modal
			v-model:visible="visible"
			:title="title"
			:bodyStyle="{
				width: '45vw',
				height: '35vw',
				'padding': '1vw 1vw 2vw 1vw'
			}"
			centered
			width="45vw"
			class="wind-select-tree__modal"
		>
			<div v-if="optionlist.length > 0" class="wind-select-tree__modal-container">
				<a-tree
					v-model:selectedKeys="currentSelecteds"
					:tree-data="optionlist"
					:fieldNames="{
						children:'children',
						title:'name',
						key: 'id'
					}"
					defaultExpandAll
					blockNode
					class="select-tree"
					@select="onTreeSelect"
				>
					<template #title="{ name, id }">
						<span class="select-tree__node-text">{{ name }}</span>
						<w-icon
							v-show="currentSelecteds.includes(id)"
							type="right-identifier"
							class="right-identifier-icon"
						></w-icon>
					</template>
				</a-tree>
			</div>
			<div v-else class="wind-select-tree__empty-container">
				<w-icon type="empty" class="empty-icon"></w-icon>
			</div>
			<template #footer>
				<a-button key="submit" type="primary" class="confirm-btn" @click="handleOk">确定</a-button>
			</template>
		</a-modal>
	</div>
</template>
<script lang="ts">
/**
 * SelectTree by shang 2022/8/25
 * @desc SelectTree 树形选择器
 */
import { computed, defineComponent, ref } from 'vue'
import type { PropType } from 'vue'
import { getFxInstance } from '@/js/instance'
interface ListItem {
	id: string|number
	name?: string
	visibleFlag?: boolean
	children?:ListItem
}
export default defineComponent({
	name: 'wind-select-tree',
	props: {
		value: [String, Number],
		optionlist: {
			type: Array as PropType<ListItem[]>,
			default: () => {
				return []
			}
		},
		optionProps: {
			type: Object as PropType<{name:string}>,
			default: () => {
				return {
					name: 'name'
				}
			}
		},
		title: {
			type: String,
			default: '选择选项'
		},
		cellType: String,
		cellLabel: {
			type: String,
			default: ''
		},
		cellText: String,
		emptyText: {
			type: String,
			default: '暂无数据'
		},
		disabled: Boolean,
		required: Boolean,
		placeholder: {
			type: String,
			default: '请选择'
		},
		fpSelectShow: Function,
		border: {
			type: Boolean,
			default: true
		}
	},
	setup (props, { emit }) {
		const fxInstance = getFxInstance()
		const visible = ref<boolean>(false)
		const isEmptyCellText = computed(() => {
			return !props.cellText
		})
		const currentCellText = computed(() => {
			if (props.disabled) {
				return props.cellText
			} else if (isEmptyCellText.value) {
				return props.placeholder
			} else {
				return props.cellText
			}
		})
		const showModal = () => {
			if (props.disabled) {
				return
			}
			if (typeof props.fpSelectShow === 'function') {
				props.fpSelectShow().then(() => {
					setActiveItem()
					visible.value = true
				})
			} else {
				setActiveItem()
				visible.value = true
			}
		}
		const handleOk = () => {
			visible.value = false
			if (currentSelectItem.value.id !== props.value) {
				emit('change', currentSelectItem.value)
			}
		}
		const currentSelecteds = ref<(string|number)[]>([])
		const currentSelectItem = ref<ListItem>({
			id: '',
			[props.optionProps.name]: ''
		})
		const setActiveItem = () => {
			currentSelectItem.value = {
				id: '',
				[props.optionProps.name]: ''
			}
			currentSelecteds.value = []
			if (fxInstance.$fxUtils.isDef(props.value) && props.value !== '') {
				currentSelectItem.value = {
					id: props.value!,
					[props.optionProps.name]: props.cellText
				}
				currentSelecteds.value = [props.value!]
			}
		}
		const onTreeSelect = (item:ListItem, { node }:{node:ListItem}) => {
			currentSelectItem.value = {
				id: node.id,
				[props.optionProps.name]: node.name,
				children: node.children
			}
		}
		return {
			visible,
			isEmptyCellText,
			currentCellText,
			showModal,
			handleOk,
			onTreeSelect,
			currentSelecteds,
			currentSelectItem
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.wind-select-tree {
	width: 100%;
    &__cell {
        display: flex;
        align-items: center;
        padding-right: 10px;
        padding-left: 15px;
        width: 100%;
        height: 36px;
        line-height: 36px;
        box-sizing: border-box;
		&.has-border {
			border: 1px solid #cccccc;
			border-radius: 20px;
		}
        .cell-text {
            flex: 1;
            overflow:hidden;
            text-overflow : ellipsis;
            white-space:nowrap;
        }
        .empty-cell-text {
            color: #cccccc;
        }
        .select-arrow-icon {
            width: 12px;
            height: 12px;
            color: #a7a8ab;
            transform: rotate(90deg);
        }
    }
    .empty-cell-text {
        :deep(.wind-cell__value) {
            color: #cccccc;
        }
    }
    &__modal-container {
        overflow: auto;
        height: 100%;
		.right-identifier-icon {
			position:absolute;
			top: 8px;
			right: 2px;
		}
    }
    &__empty-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        .empty-icon {
            width: 200px;
            height: 200px;
        }
    }
}
.wind-select-tree__modal {
    .confirm-btn {
        width: 100%;
    }
}
</style>
<style lang="scss">
@import "$assets/stylus/varsty";
.select-tree {
	&__node-text {
		display: flex;
		align-items: center;
		overflow:hidden;
		max-width: 300px;
		height: 36px;
		text-overflow : ellipsis;
		white-space:nowrap;
		line-height: 36px;
	}
	.ant-tree-node-selected {
		color: $fxDefaultColor !important;
	}
	.ant-tree-switcher {
	top: 6px;
}
}
</style>
