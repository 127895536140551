<template>
	<div
		v-show="visible"
		:style="{ backgroundColor: background || '' }"
		:class="['wind-loading', customClass, { 'is-fullscreen': fullscreen }]">
		<div class="wind-loading__contaienr">
			<div v-if="type==='snake'" class="loading-snake"></div>
			<img
				v-if="type==='dragon'"
				:src="loadingUrl"
				alt=""
				class="loading-img"
				:class="[sizeClass]"
			>
			<div v-if="!!loadingText" class="loading-text">{{loadingText}}</div>
		</div>
	</div>
</template>
<script lang="ts">
/**
 * loading by shang 2022/8/116
 * @desc loading
 */
import { defineComponent, computed, ref, watch } from 'vue'
export default defineComponent({
	name: 'wind-loading',
	props: {
		type: {
			type: String,
			default: 'snake'
		},
		size: String,
		text: {
			type: String,
			default: ''
		}
	},
	setup (props) {
		const visible = ref(true)
		const fullscreen = ref(true)
		const customClass = ref('')
		const background = ref(null)
		const sizeClass = computed(() => props.size ? `is-${props.size}` : '')
		const getImageUrl = (name: string) => {
			const path = `/src/assets/${name.replace(/^\@\/assets\//, '')}`
			const modules = import.meta.globEager('/src/assets/images/icon/wind-ui/*')
			return (modules[path] as { default: string }).default
		}
		const loadingUrl = getImageUrl('@/assets/images/icon/wind-ui/icon-loading-running-man.gif')
		const loadingText = ref('')
		const updateLoadingText = (text: string) => {
			loadingText.value = text
		}
		watch(() => props.text, (val: string) => {
			loadingText.value = val
		}, {
			immediate: true
		})
		return {
			visible,
			fullscreen,
			customClass,
			background,
			sizeClass,
			loadingUrl,
			loadingText,
			updateLoadingText
		}
	}
})
</script>
<style lang="scss" scoped>
@import "../../style/varsty";
.wind-loading {
    position:absolute;
    z-index: 9999;
    margin: 0;
    inset: 0;
    transition: opacity .3s;
    &.is-fullscreen {
        position: fixed
    }
    &__contaienr {
        position: absolute;
        top: 50%;
        left: 50%;
		display: flex;
		align-items: center;
		text-align: center;
		background-color: transparent;
		transform: translate(-50%, -50%);
		flex-direction: column;
        .loading-img {
            display: inline-block;
            width: 70px;
            &.is-small {
                width: 30px;
            }
        }
        .loading-text {
			margin-top: 10px;
            text-align: center;
            color: $wind-loading-text-color;
        }
    }
}
.loading-snake {
	position: relative;
	width: 30px;
	height: 30px;
	border: 2px solid $wind-loading-color;
	border-top-color: rgb(0 0 0 / 20%);
	border-right-color: rgb(0 0 0 / 20%);
	border-bottom-color: rgb(0 0 0 / 20%);
	border-radius: 100%;
	animation: circle infinite 0.75s linear;
}

@keyframes circle {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
<style lang="scss">
.wind-loading-parent--relative {
    position:relative!important;
}
.wind-loading-parent--hidden {
    overflow:hidden!important;
}
</style>
