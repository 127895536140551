export const BILL_PATH_CONFIG = {
	StockPlan: {
		id: 'StockPlan',
		name: '采购计划单',
		path: 'purchasePlanOrder',
		detailPath: 'purchasePlanOrderDetail'
	},
	InStore: {
		id: 'InStore',
		name: '入库单',
		path: 'receiveOrder',
		detailPath: 'receiveOrderReceiving'
	},
	OutStore: {
		id: 'OutStore',
		name: '出库单',
		path: 'sendOrder',
		detailPath: 'sendOrderSending'
	},
	CheckBill: {
		id: 'CheckBill',
		name: '盘点单',
		path: 'stockOrder',
		detailPath: 'stockOrderDetail'
	},
	MachineCompose: {
		id: 'MachineCompose',
		name: '组合加工单',
		path: 'machiningOrder',
		detailPath: 'machiningOrderDetail'
	},
	ProcedureTask: {
		id: 'ProcedureTask',
		name: '工序任务单',
		path: 'processTaskOrder',
		detailPath: 'processTaskOrderDetail'
	}
}
