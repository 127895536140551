import type { State } from './state'

export const getToken = function (state: State): string {
	return state.token!
}

export const getBaseUrl = function (state: State): string {
	return state.baseUrl!
}
export const getTenantId = function (state: State): number {
	return state.tenantId!
}
export const getEmployeeName = function (state: State): string {
	return state.employeeName!
}
export const getHaveCostPower = function (state: State): number {
	return state.haveCostPower!
}
export const entCodeGetter = function (state: State): string {
	return state.entCode!
}

export const usernameGetter = function (state: State): string {
	return state.username
}

export const domainGetter = function (state: State): string {
	return state.domain
}

export const getOrganName = function (state: State): string {
	return state.organName
}

export const getUserId = function (state: State): string {
	return state.userId
}

export const getHostId = function (state: State): string {
	return state.hostId
}
export const getRoles = function (state: State): Array<string> {
	return JSON.parse(state.roles)
}
