// 导入顺序一定要与项目目录顺序一致
import type { App } from 'vue'
import BannerAlter from './fx-banner-alter'
import BillDetailDrawer from './fx-bill-detail-drawer'
import BillError from './fx-bill-error'
import CategoryList from './fx-category-list'
import DatePickerRange from './fx-date-picker-range'
import Header from './fx-header'
import ImagePrintTools from './fx-image-print-tools'
import ItemBlock from './fx-item-block'
import ItemScrollLoadList from './fx-item-scroll-load-list'
import ItemTypeTree from './fx-item-type-tree'
import MistakeCheckConfirm from './fx-mistake-check-confirm'
import OrderDetailContainer from './fx-order-detail-container'
import OrderListBillTypeTabs from './fx-order-list-bill-type-tabs'
import OrderListBlock from './fx-order-list-block'
import OrderListBlockCell from './fx-order-list-block-cell'
import OrderListFooter from './fx-order-list-footer'
import OrderListFooterBtn from './fx-order-list-footer-btn'
import OrderListStateTabs from './fx-order-list-state-tabs'
import OrderListTop from './fx-order-list-top'
import OrderListTopBtn from './fx-order-list-top-btn'
import PrintConnect from './fx-print-connect'
import PrintUsbConnect from './fx-print-usb-connect'
import PrintMulityTools from './fx-print-mulity-tools'
import PrintTools from './fx-print-tools'
import RedBlueBillSpan from './fx-red-blue-bill-span'
import ScalesWeigh from './fx-scales-weigh'
import ScalesWeighConnect from './fx-scales-weigh-connect'
import ScalesWeightPanelTitle from './fx-scales-weight-panel-title'
import ScalesWeightScreen from './fx-scales-weight-screen'
import Setting from './fx-setting'
import StateTabs from './fx-state-tabs'
import UnderStoreError from './fx-under-store-error'
import Upload from './fx-upload'
import User from './fx-user'
import WeighImage from './fx-weigh-image'
import ItemWeighIcon from './fx-item-weigh-icon'
import KeyBoard from './fx-key-board'
export const components = {
	'fx-banner-alter': BannerAlter.component,
	'fx-bill-detail-drawer': BillDetailDrawer.component,
	'fx-bill-error': BillError.component,
	'fx-date-picker-range': DatePickerRange.component,
	'fx-header': Header.component,
	'fx-image-print-tools': ImagePrintTools.component,
	'fx-item-block': ItemBlock.component,
	'fx-category-list': CategoryList.component,
	'fx-item-scroll-load-list': ItemScrollLoadList.component,
	'fx-item-type-tree': ItemTypeTree.component,
	'fx-mistake-check-confirm': MistakeCheckConfirm.component,
	'fx-order-detail-container': OrderDetailContainer.component,
	'fx-order-list-bill-type-tabs': OrderListBillTypeTabs.component,
	'fx-order-list-block': OrderListBlock.component,
	'fx-order-list-block-cell': OrderListBlockCell.component,
	'fx-order-list-footer': OrderListFooter.component,
	'fx-order-list-footer-btn': OrderListFooterBtn.component,
	'fx-order-list-state-tabs': OrderListStateTabs.component,
	'fx-order-list-top': OrderListTop.component,
	'fx-order-list-top-btn': OrderListTopBtn.component,
	'fx-print-connect': PrintConnect.component,
	'fx-print-usb-connect': PrintUsbConnect.component,
	'fx-print-mulity-tools': PrintMulityTools.component,
	'fx-print-tools': PrintTools.component,
	'fx-red-blue-bill-span': RedBlueBillSpan.component,
	'fx-scales-weigh': ScalesWeigh.component,
	'fx-scales-weigh-connect': ScalesWeighConnect.component,
	'fx-scales-weight-panel-title': ScalesWeightPanelTitle.component,
	'fx-scales-weight-screen': ScalesWeightScreen.component,
	'fx-setting': Setting.component,
	'fx-state-tabs': StateTabs.component,
	'fx-under-store-error': UnderStoreError.component,
	'fx-upload': Upload.component,
	'fx-user': User.component,
	'fx-weigh-image': WeighImage.component,
	'fx-item-weigh-icon': ItemWeighIcon.component,
	'fx-key-board': KeyBoard.KeyBoard,
	'fx-virtual-input': KeyBoard.VirtualInput
}

type ComponentKeys = keyof typeof components

const install = (app: App): void => {
	Object.keys(components).forEach(key => {
		app.component(key, components[key as ComponentKeys])
	})
}
export default {
	install,
	...components
}
