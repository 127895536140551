<template>
	<div class="fx-date-picker-range">
		<a-button
			type="primary"
			shape="round"
			ghost
			class="fx-date-picker-range__date-btn"
			@click="onDatePickerShowClick"
		>日期筛选</a-button>
		<a-modal
			v-model:visible="rangePickerVisible"
			title="日期筛选"
			:bodyStyle="{
				width: '100%',
				height: '40vw',
				display: 'flex'
			}"
			:maskClosable="false"
			centered
			width="59vw"
			class="fx-date-picker-range__modal"
		>
			<div class="fx-date-picker-range__modal-container">
				<div class="date-picker">
					<a-range-picker
						v-model:value="rangeDateValue"
						placement="bottomRight"
						:open="isPickerOpen"
						:inputReadOnly="true"
						:ranges="rangePresets"
						:allowClear="false"
						:dropdownClassName="'style-range-picker'"
						@change="onRangeChange"
					/>
				</div>
			</div>
			<template #footer>
				<a-button @click="onCancelClick">清空</a-button>
				<a-button type="primary" @click="onConfirmClick">确定</a-button>
			</template>
		</a-modal>
	</div>
</template>
<script lang="tsx">
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
import { defineComponent, ref, watch, watchEffect } from 'vue'
import type { PropType } from 'vue'
import { getFxInstance } from '@/js/instance'
export default defineComponent({
	name: 'fx-date-picker-range',
	props: {
		value: {
			type: Array as PropType<string[]>,
			default: () => {
				return []
			}
		},
		filterOptions: {
			type: Array as PropType<string[]>,
			default: () => ['昨天', '今天', '明天', '上周', '本周', '下周', '前10天', '上月', '本月', '下月', '本年', '去年']
		}
	},
	emits: ['on-confirm'],
	setup (props, { emit }) {
		const rangePresets = ref({})
		const generateRangePresets = (filterOptions: string[]) => {
			const presets:{[key:string]: Dayjs[]} = {}
			filterOptions.forEach(option => {
				switch (option) {
					case '昨天':
						presets[option] = [dayjs().startOf('day').subtract(1, 'day'), dayjs().endOf('day').subtract(1, 'day')]
						break
					case '今天':
						presets[option] = [dayjs().startOf('day'), dayjs().endOf('day')]
						break
					case '明天':
						presets[option] = [dayjs().add(1, 'day').startOf('day'), dayjs().add(1, 'day').endOf('day')]
						break
					case '上周':
						presets[option] = [dayjs().subtract(1, 'week').startOf('week'), dayjs().subtract(1, 'week').endOf('week')]
						break
					case '本周':
						presets[option] = [dayjs().startOf('week'), dayjs().endOf('week')]
						break
					case '下周':
						presets[option] = [dayjs().add(1, 'week').startOf('week'), dayjs().add(1, 'week').endOf('week')]
						break
					case '前10天':
						presets[option] = [dayjs().startOf('day').subtract(10, 'day'), dayjs().endOf('day')]
						break
					case '上月':
						presets[option] = [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')]
						break
					case '本月':
						presets[option] = [dayjs().startOf('month'), dayjs().endOf('month')]
						break
					case '下月':
						presets[option] = [dayjs().add(1, 'month').startOf('month'), dayjs().add(1, 'month').endOf('month')]
						break
					case '本年':
						presets[option] = [dayjs().startOf('year'), dayjs().endOf('year')]
						break
					case '去年':
						presets[option] = [dayjs().subtract(1, 'year').startOf('year'), dayjs().subtract(1, 'year').endOf('year')]
						break
					default:
						break
				}
			})
			return presets
		}
		watchEffect(() => {
			rangePresets.value = generateRangePresets(props.filterOptions)
		})
		const fxInstance = getFxInstance()
		const rangePickerRef = ref<{handleOpen?:Function}>({})
		const isPickerOpen = ref(false)
		const dateValue = ref({
			start: fxInstance.$fxUtils.newDate(),
			end: fxInstance.$fxUtils.newDate()
		})
		const rangeDateValue = ref([] as Dayjs[])
		const rangePickerVisible = ref(false)
		const initRangeDateValue = () => {
			rangeDateValue.value = [dayjs(dateValue.value.start), dayjs(dateValue.value.end)]
		}
		const onDatePickerShowClick = () => {
			const [start, end] = props.value
			dateValue.value = ({
				start: fxInstance.$fxUtils.newDate(start),
				end: fxInstance.$fxUtils.newDate(end)
			})
			if (start && end) {
				initRangeDateValue()
			}
			rangePickerVisible.value = !rangePickerVisible.value
		}
		const onCancelClick = () => {
			rangeDateValue.value = []
		}
		const onConfirmClick = () => {
			const paramsDateValue = !rangeDateValue.value.length ? ['', ''] : [
				fxInstance.$fxUtils.getDateTime(dateValue.value.start),
				fxInstance.$fxUtils.getDateTime(dateValue.value.end)
			]
			emit('on-confirm', paramsDateValue)
			rangePickerVisible.value = false
		}
		const onRangeChange = (date: Dayjs[]) => {
			const startOf = ref<Dayjs>()
			const endOf = ref<Dayjs>()
			if (date[0] < date[1]) {
				startOf.value = dayjs(date[0]).startOf('day')
				endOf.value = dayjs(date[1]).endOf('day')
			} else {
				startOf.value = dayjs(date[1]).startOf('day')
				endOf.value = dayjs(date[0]).endOf('day')
			}
			dateValue.value.start = fxInstance.$fxUtils.newDate(startOf.value.format('YYYY-MM-DD HH:mm:ss'))
			dateValue.value.end = fxInstance.$fxUtils.newDate(endOf.value.format('YYYY-MM-DD HH:mm:ss'))
			initRangeDateValue()
		}
		watch(() => rangePickerVisible.value, (val) => {
			if (val) {
				setTimeout(() => {
					isPickerOpen.value = val
				}, 200)
			} else {
				isPickerOpen.value = val
			}
		})
		return {
			isPickerOpen,
			rangePresets,
			onRangeChange,
			rangePickerRef,
			dateValue,
			rangeDateValue,
			onDatePickerShowClick,
			rangePickerVisible,
			onCancelClick,
			onConfirmClick
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.fx-date-picker-range {
	position: relative;
	&__date-btn {
		margin-left: 10px;
		width: 100px;
		border-color: $fxGray21 !important;
		background-color: $fxWhite !important;
	}
	&__modal-container {
		display: flex;
		width: 100%;
		flex-direction: column;
	}
}
</style>
<style lang="scss">
@import "$assets/stylus/varsty";
.fx-date-picker-range {
	&__modal {
		.ant-modal-body {
			padding: 5px;
		}
	}
}
.style-range-picker {
	.ant-picker-range-arrow {
		display: none !important;
	}
	.ant-picker-header-view button {
		pointer-events: none;
	}
}
</style>

