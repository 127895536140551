<template>
	<div :class="['fx-item-block', {
		'is-narrow': narrow
	}]" @click="onItemBlockClick">
		<div class="fx-item-block__img-container">
			<w-image :src="getItemSrc(imageUrl)" class="fx-item-block__img"></w-image>
		</div>
		<div v-if="operating" class="fx-item-block__mask is-checking">
			<w-icon type="checking-item" class="checking-item-icon"></w-icon>
			<span>正在操作</span>
		</div>
		<div v-else-if="showMask" class="fx-item-block__mask">
			<slot name="mask"></slot>
		</div>
		<slot></slot>
	</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
	name: 'itemBlock',
	props: {
		narrow: Boolean,
		operating: Boolean,
		showMask: Boolean,
		imageUrl: {
			type: String,
			default: ''
		}
	},
	emits: ['on-item-block-click'],
	setup (props, { emit }) {
		const getImageUrl = (name:string) => {
			const path = `/src/assets/${name.replace(/^\@\/assets\//, '')}`
			const modules = import.meta.globEager('/src/assets/images/img/common/*')
			return (modules[path] as { default: string }).default
		}
		const getItemSrc = (url:string) => {
			return url || getImageUrl('@/assets/images/img/common/no-item-img.png')
		}
		const onItemBlockClick = () => {
			emit('on-item-block-click')
		}
		return {
			getItemSrc,
			onItemBlockClick
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.fx-item-block {
    position: relative;
    overflow: hidden;
    margin-right: 9px;
    margin-bottom: 10px;
	padding-bottom: 5px;
    width: 150px;
    min-height: 120px;
    border-radius: 5px;
    background-color: $fxWhite;
	&.is-narrow {
		width: 148px;
	}
	&__img-container {
		padding: 5px 5px 0;
		box-sizing: border-box;
		width: 100%;
		height: 70px;
	}
    &__img {
        display: inline-block;
		width: 100%;
		height:100%;
    }
    &__mask {
        position: absolute;
        top: 0;
        left: 0;
        padding-top: 2px;
        width: 100%;
        height: 70px;
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        color: $fxWhite;
        background-color: #404751bf;
        line-height: 65px;
        &.is-checking {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            background-color: #3d6548ab;
            .checking-item-icon {
                margin-right: 10px;
            }
        }
    }
}
</style>
