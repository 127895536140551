<template>
	<div class="fx-setting">
		<a-modal
			v-model:visible="visible"
			:style="{
				maxWidth: '100vw',
				top: 0,
				height: '100%'
			}"
			:bodyStyle="{
				height: '100%',
				padding: '0'
			}"
			:closable="false"
			:maskClosable="false"
			:footer="null"
			centered
			width="100vw"
			wrapClassName="wind-dailog is-full-screen"
			transitionName=""
			maskTransitionName=""
		>
			<w-app-page class="setting__container">
				<w-header
					title="设置"
					:customerBackHandler="customerBackHandler"
					centerSlotAlign="center"
				>
				</w-header>
				<div class="setting__content">
					<div class="content-left">
						<div v-for="item in tabItem" :key="item.id" :class="['tab-item', {
							'is-active': item.id === activeKey
						}]" @click="onTabItemClick(item.id)">
							<w-icon-svg :type="item.icon" class="tab-icon"></w-icon-svg>
							{{ item.label }}
						</div>
					</div>
					<div class="content-right">
						<div v-show="activeKey==='basic'">
							<div class="setting-cell">
								<span class="setting-item__lable is-tag-label">称重单位</span>
								<w-select-tag
									v-model:selected="weightUnit"
									:selectList="weightUnitSelectList"
									@change="onWeightUnitChange"
								></w-select-tag>
							</div>
							<div class="tips-text">{{ weightUnitTipsComputed }}</div>
							<div class="setting-cell">
								<span class="setting-item__lable is-tag-label">小数点后保留位</span>
								<w-select-tag
									v-model:selected="weightUnitDecimalPlace"
									:selectList="weightUnitDecimalPlaceSelectListComputed"
									@change="onweightUnitDecimalPlaceChange"
								></w-select-tag>
							</div>
							<div class="setting-cell">
								<span class="setting-item__lable is-tag-label">抹零规则</span>
								<w-select-tag
									v-model:selected="roundDownType"
									:selectList="roundDownListComputed"
								></w-select-tag>
							</div>
							<div class="setting-cell">
								<span class="setting-item__lable is-tag-label">抹零保留小数位数</span>
								<w-select-tag
									v-model:selected="roundDownDecimalPlace"
									:selectList="roundDownDecimalPlaceSelectListComputed"
								></w-select-tag>
							</div>
							<div class="setting-cell">
								<span class="setting-item__lable is-tag-label">品项排序</span>
								<w-select-tag
									v-model:selected="itemSortingType"
									:selectList="itemSortingSelectList"
								></w-select-tag>
							</div>
							<div class="setting-cell">
								<span class="setting-item__lable is-tag-label">字体大小</span>
								<w-select-tag
									v-model:selected="fontSizeType"
									:selectList="fontSizeTypeSelectList"
								></w-select-tag>
							</div>
							<div class="setting-cell">
								<span class="setting-item__lable is-tag-label">合批页明细展示</span>
								<w-select-tag
									v-model:selected="batchDetailShowType"
									:selectList="batchDetailShowSelectList"
								></w-select-tag>
							</div>
						</div>
						<div v-show="activeKey==='tare'">
							<div class="tare-title">
								筐皮设置（单位：KG）
								<span class="icon">
									<w-icon-svg type="icon-about"></w-icon-svg>
									添加筐皮，在称重时可以选用
								</span>
							</div>
							<div class="tare-list">
								<div class="tare-item" v-for="item in basketTareList" :key="item.id">
									<CloseOutlined class="tare-item__delete-icon" @click="onDeleteTare(item.id)" />
									<div>
										<div class="tare-item__weight" >筐皮重量：{{ item.weight }}kg</div>
										<div>筐皮备注：{{ item.memo }}</div>
									</div>
								</div>
								<a-button v-if="basketTareList.length < 10" type="dashed" class="setting-tare__btn" @click="onAddTare">
									<template #icon><PlusOutlined class="add-btn"/></template>
								</a-button>
							</div>
						</div>
						<div v-show="activeKey==='camera'">
							<div class="setting-cell">
								<span class="setting-item__lable is-tag-label">默认打开悬浮窗</span>
								<w-select-tag
									v-model:selected="cameraFloatAuto"
									:selectList="cameraFloatAutoSelectList"
								></w-select-tag>
							</div>
							<div class="setting-cell">
								<span class="setting-item__lable is-tag-label">是否自动拍照</span>
								<w-select-tag
									v-model:selected="automaticPhotograph"
									:selectList="automaticPhotographSelectList"
								></w-select-tag>
							</div>
						</div>
						<div v-show="activeKey==='print'">
							<div class="setting-cell">
								<span class="setting-item__lable is-tag-label">称重后自动打印</span>
								<w-select-tag
									v-model:selected="weightAutoPrint"
									:selectList="weightAutoPrintSelectList"
								></w-select-tag>
							</div>
						</div>
						<div v-show="activeKey==='about'">
							<span class="setting-item__lable">版本号</span>
							<span>{{ appVersion }}</span>
							<a-button
								v-show="isUseAppVersionUpdateFlag"
								class="setting-item__version-validate-btn"
								@click="onCheckUpdateClick"
							>检查更新</a-button>
						</div>
					</div>
				</div>
				<addTare ref="addTareRef" v-model:value="addTareDialogVisible"></addTare>
			</w-app-page>
		</a-modal>
	</div>
</template>
<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { useStore } from '@/store'
import { getFxInstance } from '@/js/instance'
import { weightUnitEnum } from '@/config/app_type/systemType'
import { isUseAppVersionUpdate } from '@/config/env.config'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons-vue'
import addTare from './AddTareDialog.vue'
export default defineComponent({
	name: 'fx-setting',
	components: {
		addTare,
		CloseOutlined,
		PlusOutlined
	},
	setup () {
		const fxInstance = getFxInstance()
		const store = useStore()
		const visible = ref(false)
		const isUseAppVersionUpdateFlag = ref(isUseAppVersionUpdate())
		const weightUnitSelectList = fxInstance.$fxTypeMiddleware.getCommonTypeList('weightUnitList')
		const weightUnit = computed({
			get: () => store.state.system.weightUnit,
			set: (val) => {
				store.commit('SET_SYSTEM', {
					weightUnit: val
				})
			}
		})
		const roundDownList = ref(fxInstance.$fxTypeMiddleware.getCommonTypeList('roundDownList'))
		const roundDownListComputed = computed(() => {
			if (weightUnit.value === weightUnitEnum.jin || weightUnit.value === weightUnitEnum.g) {
				roundDownList.value.forEach((item: {id: string, disabled: boolean}) => {
					if (item.id === '2') {
						item.disabled = true
					}
				})
			} else {
				roundDownList.value.forEach((item: {id: string, disabled: boolean}) => {
					item.disabled = false
				})
			}
			return roundDownList.value
		})
		const roundDownType = computed({
			get: () => store.state.system.roundDown,
			set: (val) => {
				store.commit('SET_SYSTEM', {
					roundDown: val
				})
			}
		})
		const roundDownDecimalPlaceSelectList = ref(fxInstance.$fxTypeMiddleware.getCommonTypeList('roundDownDecimalPlaceList'))
		const roundDownDecimalPlaceSelectListComputed = computed(() => {
			if (weightUnit.value === weightUnitEnum.jin || weightUnit.value === weightUnitEnum.kg) {
				roundDownDecimalPlaceSelectList.value.forEach((item: {id:string, disabled: boolean}) => {
					if (item.id >= weightUnitDecimalPlace.value) {
						item.disabled = true
					} else {
						item.disabled = false
					}
				})
			} else {
				roundDownDecimalPlaceSelectList.value.forEach((item: {id:string, disabled: boolean}) => {
					item.disabled = true
				})
			}
			return roundDownDecimalPlaceSelectList.value
		})
		const roundDownDecimalPlace = computed({
			get: () => store.state.system.roundDownDecimalPlace,
			set: (val) => {
				store.commit('SET_SYSTEM', {
					roundDownDecimalPlace: val
				})
			}
		})
		const itemSortingSelectList = fxInstance.$fxTypeMiddleware.getCommonTypeList('itemSortingSelectList')
		const itemSortingType = computed({
			get: () => store.state.system.itemSortingType,
			set: (val) => {
				store.commit('SET_SYSTEM', {
					itemSortingType: val
				})
			}
		})
		const batchDetailShowSelectList = fxInstance.$fxTypeMiddleware.getCommonTypeList('batchDetailShowSelectList')
		const batchDetailShowType = computed({
			get: () => store.state.system.batchDetailShowType,
			set: (val) => {
				store.commit('SET_SYSTEM', {
					batchDetailShowType: val
				})
			}
		})
		const fontSizeTypeSelectList = fxInstance.$fxTypeMiddleware.getCommonTypeList('fontSizeTypeList')
		const fontSizeType = computed({
			get: () => store.state.stylusDynamic.fontSizeType,
			set: (val) => {
				store.commit('SET_STYLUS_DYNAMIC_FONT_SIZE_TYPE', val)
			}
		})
		const weightUnitDecimalPlaceSelectList = ref(fxInstance.$fxTypeMiddleware.getCommonTypeList('weightUnitDecimalPlaceList'))
		const weightUnitDecimalPlaceSelectListComputed = computed(() => {
			if (weightUnitEnum.jin === weightUnit.value) {
				weightUnitDecimalPlaceSelectList.value.forEach((item: {id:string, disabled: boolean}) => {
					item.disabled = item.id === '3'
				})
			} else if (weightUnitEnum.g === weightUnit.value) {
				weightUnitDecimalPlaceSelectList.value.forEach((item: {disabled: boolean}) => {
					item.disabled = true
				})
			} else {
				weightUnitDecimalPlaceSelectList.value.forEach((item: {disabled: boolean}) => {
					item.disabled = false
				})
			}
			return weightUnitDecimalPlaceSelectList.value
		})
		const weightUnitDecimalPlace = computed({
			get: () => store.state.system.weightUnitDecimalPlace,
			set: (val) => {
				store.commit('SET_SYSTEM', {
					weightUnitDecimalPlace: val
				})
			}
		})
		const onweightUnitDecimalPlaceChange = (value:string):void => {
			if (value <= roundDownDecimalPlace.value) {
				if (value === '2') {
					roundDownDecimalPlace.value = '1'
				}
				if (value === '1' || value === '0') {
					roundDownDecimalPlace.value = '0'
				}
			}
		}
		const cameraFloatAutoSelectList = fxInstance.$fxTypeMiddleware.getCommonTypeList('cameraFloatAutoList')
		const cameraFloatAuto = computed({
			get: () => store.state.system.cameraFloatAuto,
			set: (val) => {
				store.commit('SET_SYSTEM', {
					cameraFloatAuto: val
				})
			}
		})
		const automaticPhotographSelectList = fxInstance.$fxTypeMiddleware.getCommonTypeList('automaticPhotographList')
		const automaticPhotograph = computed({
			get: () => store.state.system.automaticPhotograph,
			set: (val) => {
				store.commit('SET_SYSTEM', {
					automaticPhotograph: val
				})
			}
		})
		const weightAutoPrintSelectList = fxInstance.$fxTypeMiddleware.getCommonTypeList('weightPrintAuto')
		const weightAutoPrint = computed({
			get: () => store.state.system.weightAutoPrint,
			set: (val) => {
				store.commit('SET_SYSTEM', {
					weightAutoPrint: val
				})
			}
		})
		const appVersion = computed(() => store.state.system.appVersion)
		const onSettingClick = () => {
			visible.value = true
		}
		const customerBackHandler = () => {
			visible.value = false
		}
		const basketTareList = computed(() => store.getters.getBasketTareList)
		const addTareDialogVisible = ref(false)
		const onAddTare = () => {
			addTareDialogVisible.value = true
		}
		const onDeleteTare = (id: string) => {
			store.commit('DEL_TARE_ITEM', id)
		}
		const activeKey = ref('basic')
		const tabItem = ref([{
			id: 'basic',
			label: '常用设置',
			icon: 'icon-setting-basic'
		},
		{
			id: 'tare',
			label: '筐皮设置',
			icon: 'icon-basket-tare-setting'
		},
		{
			id: 'camera',
			label: '拍照设置',
			icon: 'icon-camera-setting'
		}, {
			id: 'print',
			label: '打印设置',
			icon: 'icon-print-setting'
		}, {
			id: 'about',
			label: '关于',
			icon: 'icon-about'
		}])
		const onTabItemClick = (id: string) => {
			activeKey.value = id
		}
		const weightUnitTipsComputed = computed(() => {
			if (weightUnitEnum.jin === weightUnit.value) {
				return '市斤小数点后最多保留2位'
			}
			if (weightUnitEnum.g === weightUnit.value) {
				return '只能选择整数，即小数点只能是0位'
			}
			return ''
		})
		const onWeightUnitChange = (value:string):void => {
			switch (value) {
				case weightUnitEnum.kg:
					weightUnitDecimalPlace.value = '3'
					roundDownDecimalPlace.value = '2'
					break
				case weightUnitEnum.jin:
					weightUnitDecimalPlace.value = '2'
					roundDownDecimalPlace.value = '1'
					roundDownType.value = '1'
					break
				case weightUnitEnum.g:
					weightUnitDecimalPlace.value = '0'
					roundDownDecimalPlace.value = '0'
					roundDownType.value = '1'
					break
				default:
					break
			}
		}
		const onCheckUpdateClick = () => {
			fxInstance.$fxCordova.appVersion.checkAppVersion()
		}
		return {
			visible,
			isUseAppVersionUpdateFlag,
			onSettingClick,
			customerBackHandler,
			activeKey,
			tabItem,
			onTabItemClick,
			weightUnitSelectList,
			roundDownList,
			roundDownListComputed,
			roundDownType,
			roundDownDecimalPlace,
			roundDownDecimalPlaceSelectListComputed,
			itemSortingSelectList,
			fontSizeTypeSelectList,
			fontSizeType,
			weightAutoPrintSelectList,
			batchDetailShowType,
			batchDetailShowSelectList,
			weightUnitDecimalPlaceSelectListComputed,
			weightUnitDecimalPlace,
			onweightUnitDecimalPlaceChange,
			cameraFloatAutoSelectList,
			cameraFloatAuto,
			automaticPhotographSelectList,
			automaticPhotograph,
			weightUnit,
			itemSortingType,
			weightAutoPrint,
			appVersion,
			weightUnitTipsComputed,
			onWeightUnitChange,
			onCheckUpdateClick,
			onAddTare,
			onDeleteTare,
			basketTareList,
			addTareDialogVisible
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.fx-setting {
    display: flex;
	.fx-setting-icon {
		width: 26px;
		height: 26px;
	}
	.content-left {
		width: 400px;
	}
}
</style>
<style lang="scss">
@import "$assets/stylus/varsty";
.wind-dailog {
	&.is-full-screen {
		.ant-modal-content {
			height: 100%;
		}
	}
	.setting__content {
		display: flex;
		height: 100%;
		flex-direction: row;
		.content-left {
			width: 150px;
			border-right: 1px solid $fxBorder;
			.tab-item {
				position: relative;
				display: flex;
				align-items: center;
				padding-right: 10px;
				padding-left: 20px;
				width: 100%;
				height: 50px;
				line-height: 50px;
				color: $fxBlack5;
				.tab-icon {
					margin-right: 10px
				}
				&.is-active {
					color: $fxDefaultColor;
					&::before {
						content: '';
						position: absolute;
						top: 0;
						right: 0;
						width: 3px;
						height: 100%;
						background-color: $fxDefaultColor;
					}
				}

			}
		}
		.content-right {
			flex: 1;
			padding-top: 20px;
			padding-left: 50px;
			.setting-cell {
				display: flex;
			}
			.setting-item__lable {
				margin-right: 20px;
				width: 100px;
				&.is-tag-label {
					position: relative;
					top: 5px;
				}

			}
			.setting-item__version-validate-btn {
				margin-left: 10px;
			}
			.tips-text {
				margin-bottom: 10px;
				margin-left: 120px;
				height: 30px;
				font-size: 12px;
				line-height: 30px;
			}
			.tare-title {
				display: flex;
				margin-bottom: 20px;
				font-size: 16px;
				.icon {
					display: flex;
					align-items: center;
					margin-left: 15px;
					font-size: 14px;
					color: $fxGray4;
				}
			}
			.tare-list {
				display: flex;
				flex-wrap: wrap;
				.tare-item {
					position: relative;
					display: flex;
					align-items: center;
					margin: 0 10px 10px 0;
					padding-left: 12px;
					width: 180px;
					height: 80px;
					font-size: 14px;
					border: 1px solid $fxBorder;
					border-radius: 5px;
					color: $fxBlack1;
					&__weight {
						font-size: 16px;
						font-weight: bold;
					}
					&__delete-icon {
						position: absolute;
						top: 5px;
						right: 5px;
					}
				}
				.setting-tare__btn {
					display: flex;
					justify-content: center;
					align-items: center;
					margin: 0 10px 10px 0;
					width: 180px;
					height: 80px;
					border-color: $fxBlue20;
					border-radius: 5px;
					color: $fxBlue20;
					.add-btn {
						font-size: 30px;
					}
				}
			}
		}
	}
}
</style>

