export default {
	'order-mistake-check-setting': {
		id: 'order-mistake-check-setting',
		label: '单据异常警告设置',
		code: '0002_0008',
		child: {
			'edit-order-mistake-check': {
				id: 'edit-order-mistake-check',
				label: '编辑单据异常警告设置',
				code: '0002_0008_002_001'
			}
		}
	}
}
