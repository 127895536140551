import API_COMMON from './src/api_common'
import API_LABEL_PRINT from './src/api_label_print'
import API_ORDER_REQUIRED from './src/api_order-required'
import API_ORDER_MISTAKE_CHECK from './src/api_order-mistake-check'
import API_PARAM_CONFIG from './src/api_param_config'
import API_TAKE_STOCK_ORDER from './src/api_take_stock_order'
import API_RECEIVE_ORDER from './src/api_receive_order'
import API_SEND_ORDER from './src/api_send_order'
import API_PURCHASE_PLAN_ORDER from './src/api_purchase_plan_order'
import API_SORTATION_TASK_ORDER from './src/api_sortation-task-order'
import API_MACHINING_ORDER from './src/api_machining_order'
import API_PROCESS_TASK_ORDER from './src/api_process-task-order'
export default {
	...API_COMMON,
	...API_LABEL_PRINT,
	...API_ORDER_REQUIRED,
	...API_ORDER_MISTAKE_CHECK,
	...API_PARAM_CONFIG,
	...API_TAKE_STOCK_ORDER,
	...API_RECEIVE_ORDER,
	...API_SEND_ORDER,
	...API_PURCHASE_PLAN_ORDER,
	...API_SORTATION_TASK_ORDER,
	...API_MACHINING_ORDER,
	...API_PROCESS_TASK_ORDER
}
