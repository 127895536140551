<template>
	<a-modal
		v-model:visible="visible"
		:title="title"
		:bodyStyle="{
			width: '60vw',
			height: '41vw',
			'padding': '1vw 1vw 1vw 1vw',
			display: 'flex',
			'align-items': 'center',
			'justify-content': 'center'
		}"
		centered
		width="60vw"
		class="wind-image-preview__modal"
	>
		<w-image :src="previewUrl" class="wind-image-preview__modal-image"></w-image>
		<template #footer>
			<a-button key="submit" type="primary" class="confirm-btn" @click="handleOk">确定</a-button>
		</template>
	</a-modal>
</template>
<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
export default defineComponent({
	name: 'w-image-preview',
	props: {
		previewUrl: String,
		previewVisible: Boolean,
		title: {
			type: String,
			default: '预览'
		}
	},
	setup (props, { emit }) {
		const visible = ref(false)
		const handleOk = () => {
			visible.value = false
		}
		watch(() => props.previewVisible, val => {
			visible.value = val
		}, {
			immediate: true
		})
		watch(() => visible.value, val => {
			emit('update:previewVisible', val)
		})
		return {
			visible,
			handleOk
		}
	}
})
</script>
<style lang="scss" scoped>
.wind-image-preview__modal-image {
    overflow: hidden;
    height: 100%;
}
</style>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.wind-image-preview__modal {
    .confirm-btn {
        width: 100%;
    }
}
</style>
