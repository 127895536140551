// 合加工单
export default {
	// 数据来源
	machiningType: [{
		type: '手工新建-组合加工',
		id: 9200,
		label: '手工新建-组合加工'
	}, {
		type: '生产计划下发',
		id: 9201,
		label: '生产计划下发'
	}, {
		type: '工序任务生成',
		id: 9203,
		label: '工序任务生成 '
	}, {
		type: '生产计划分批',
		id: 9204,
		label: '生产计划分批 '
	}],
	// 录入方式
	dataType: [{
		type: 'nanual',
		id: 'MANUAL',
		label: '手工新建'
	}, {
		type: 'autoImport',
		id: 'AUTO_IMPORT',
		label: '自动录入'
	}],
	// 是否为附成品
	additionType: [{
		type: 'addition',
		id: 0,
		label: '否'
	}, {
		type: 'unaddition',
		id: 1,
		label: '是'
	}]
}
