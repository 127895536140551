import utils from '@/js/utils'
import type { WeightItem } from '@/models/weight'
import type { BillDetail, ItemDetail } from '@/views/bill_process-task-order/OrderDetail.vue'
import type { BillMainDetail } from '@/models/process-task'
import fxTypeMiddleware from '@/js/typeMiddleware'
import { getAmount, formatterWeightRecord } from '../../utils'
export default function getLabelPrintSourceData<T extends Partial<BillDetail>, U extends Partial<BillMainDetail>, K extends Partial<ItemDetail>> (bill: T, billMain: U, item: K, dosingNames: string, weighRecord: Partial<WeightItem>, printUnitCount?: number) {
	const actualTotal = getAmount(printUnitCount!, item, weighRecord, item.actualTotal!)
	const accurateWeighRecord = formatterWeightRecord(weighRecord)
	const stationNames = billMain.stationNameList && billMain.stationNameList.join(',')
	const timeUnitText = fxTypeMiddleware.getCommonTypeObjectById('timeUnitList')(`${billMain.timeUnit}`).label ?? ''
	const workTimeCount = `${billMain.workTimeCount}${timeUnitText}`
	const printData = {
		baseInfo: [
			{
				printTime: utils.setDate(),
				sysBillCode: billMain.code,
				productionPlanCode: billMain.productionPlanCode,
				procedureName: billMain.procedureName,
				stationNames,
				workTimeCount,
				organName: bill.organ?.name,
				createUserName: billMain.createUserName,
				claimDate: billMain.claimDate,
				responsibleUserName: billMain.responsibleUserName,
				actualStartDate: billMain.actualStartDate,
				actualEndDate: billMain.actualEndDate,
				consumingTimeDay: billMain.consumingTimeDay,
				remark: billMain.remark
			}
		],
		detailInfo: [
			{
				storeCode: item.house?.code,
				storeName: item.house?.name,
				itemCode: item.item?.code,
				itemName: item.item?.name,
				dosingNames,
				actualTotal,
				itemUnitName: billMain.materialUnitName,
				weightValue: accurateWeighRecord.netWeight,
				peerValue: accurateWeighRecord.tareWeight,
				roughWeight: accurateWeighRecord.grossWeight,
				weightUnit: accurateWeighRecord.unitName,
				addCount: weighRecord.num
			}
		],
		itemCode: [{
			itemBarcode: item!.mitemUnit!.barcode || ''
		}]
	}
	return printData
}
