// cordova-plugin-camera
import windUi from '@/components/wind-ui'
import utils from '@/js/utils'

export interface CameraInstance {
	getPicture: () => Promise<File>
	getPictureByLibary: () => Promise<File>
}
const log = (msg: string) => {
	return utils.fxDebug('cordova-api')(msg)
}
const base642File = (dataurl: string, fileType = 'jpg') => {
	const arr = dataurl.split(',')
	const mime = (arr[0].match(/:(.*?);/) as string[])[1] || 'image/jpeg'
	const bstr = atob(arr[1])
	let n = bstr.length
	const u8arr = new Uint8Array(n)
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n)
	}
	const fileName = `${new Date().getTime()}.${fileType}`
	return new File([u8arr], fileName, { type: mime })
}
const getPicture = function (): Promise<File> {
	return new Promise(resolve => {
		try {
			window.navigator.camera.getPicture(
				function (imageData: string) {
					resolve(base642File(`data:image/jpeg;base64,${imageData}`))
				},
				function () {
					windUi.$fxMessage.error('调用摄像头失败')
				},
				{
					quality: 50,
					destinationType: window.Camera.DestinationType.DATA_URL
				}
			)
		} catch (error) {
			log('浏览器环境下无法使用camera.getPicture功能')
			windUi.$fxMessage.error('调用摄像头失败')
		}
	})
}
const getPictureByLibary = (): Promise<File> => {
	return new Promise(resolve => {
		try {
			window.navigator.camera.getPicture(
				function (imageData: string) {
					resolve(base642File(`data:image/jpeg;base64,${imageData}`))
				},
				function () {
					windUi.$fxMessage.error('调用相册失败')
				},
				{
					quality: 50,
					destinationType: window.Camera.DestinationType.DATA_URL,
					sourceType: window.Camera.PictureSourceType.PHOTOLIBRARY
				}
			)
		} catch (error) {
			log('浏览器环境下无法使用camera.getPictureByLibary功能')
			windUi.$fxMessage.error('调用相册失败')
		}
	})
}
export default {
	getPicture,
	getPictureByLibary
}
