import utils from '@/js/utils'
import type { ItemDetail } from '@/models/in-store'
import type { WeightItem } from '@/models/weight'
import { getAmount, formatterWeightRecord } from '../../utils'
export default function getLabelPrintSourceData<K extends Partial<ItemDetail>> (organName: string, otherOrganName: string, item: K, itemTypeName: string, weighRecord: Partial<WeightItem>, printUnitCount?: number) {
	const amount = getAmount(printUnitCount!, item, weighRecord, item.amount!)
	const accurateWeighRecord = formatterWeightRecord(weighRecord)
	return {
		baseInfo: [
			{
				printTime: utils.setDate(),
				organName,
				otherOrganName,
				itemCode: item!.item!.code,
				itemName: item!.item!.name,
				itemTypeRefName: itemTypeName,
				itemSpec: item!.item!.spec,
				itemUnitName: item!.itemUnit!.name,
				amount,
				productDate: utils.setDate(item.productDate),
				shelfLife: item!.item!.shelfLife,
				inTaxPrice: item.inTaxPrice,
				inTaxMoney: item.inTaxMoney,
				weightValue: accurateWeighRecord.netWeight,
				peerValue: accurateWeighRecord.tareWeight,
				roughWeight: accurateWeighRecord.grossWeight,
				weightUnit: accurateWeighRecord.unitName,
				addCount: weighRecord.num
			}
		],
		itemCode: [{
			itemBarcode: item!.itemUnit!.barcode || '',
			batchQrCode: item!.batchCode || ''
		}]
	}
}
