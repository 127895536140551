<template>
	<div class="wind-clock">{{time}}</div>
</template>

<script lang="ts">
/**
 * Clock by shang 2022/8/11
 * @desc Clock 时钟插件
 */
import { defineComponent, ref } from 'vue'
export default defineComponent({
	name: 'w-clock',
	setup () {
		const getTime = function (date = new Date()):string {
			const pushZero = (val:number) => {
				return val < 10 ? '0' + val : val
			}
			const myH = pushZero(date.getHours())
			const myM = pushZero(date.getMinutes())
			return `${myH}:${myM}`
		}
		const time = ref(getTime())
		setInterval(() => {
			time.value = getTime()
		}, 1000)
		return {
			time
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.wind-clock {
    font-size: 18px;
    font-weight: bold;
}
</style>
