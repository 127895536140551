<template>
	<div class="wind-select-tab-mulity">
		<w-cell
			v-if="cellType==='cell'"
			:title="cellLabel"
			:value="currentCellText"
			:disabled="disabled"
			:required="required"
			:class="{'empty-cell-text': isEmptyCellText}"
			isLink
			@on-click="showModal"
		></w-cell>
		<w-cell
			v-else-if="cellType==='filter'"
			:title="cellLabel"
			:value="customCellText || currentCellText"
			:disabled="disabled"
			:required="required"
			class="wind-select-tab-mulity__filter"
			isLink
			@on-click="showModal"
		></w-cell>
		<div v-else class="wind-select-tab-mulity__cell" @click="showModal">
			<span class="cell-text" :class="{'empty-cell-text': isEmptyCellText}">{{currentCellText}}</span>
			<w-icon-svg type="icon-cell-link-arrow" class="select-arrow-icon"></w-icon-svg>
		</div>
		<a-modal
			v-model:visible="visible"
			:title="title"
			:bodyStyle="{
				width: '45vw',
				height: '35vw',
				'padding': '1vw 1vw 2vw 1vw'
			}"
			centered
			width="45vw"
			class="wind-select-tab-mulity__modal"
		>
			<div v-show="filterable" class="list-item-seachbar-container">
				<w-search-bar
					v-model:searchKey="searchKey"
					placeholder="请输入搜索关键词"
					class="list-item-seachbar"
				></w-search-bar>
			</div>
			<template #footer>
			<div class="btn-box">
				<a-button key="reset" type="plain" class="confirm-btn" @click="handleReset">重置</a-button>
				<a-button key="submit" type="primary" class="confirm-btn" @click="handleOk">确定</a-button>
			</div>
			</template>
			<div v-if="allVisibleOptionList.length > 0" class="wind-select-tab-mulity__modal-container">
				<van-tabs v-model:active="tabActive">
					<van-tab v-for="item in allVisibleOptionList" :key="item.id" :name="item.id" :title="item.name">
						<div class="list-item-container">
							<div
								v-for="listItem in item.listData"
								:key="listItem.id"
								class="list-item"
								:class="{'is-select': currentSelectIds.includes(listItem.id)}"
								@click="onListItemClick(listItem)"
							>
								<w-icon class="right-identifier-icon"
									:type="currentSelectIds.includes(listItem.id) ? 'right-identifier-fillblue' : 'right-identifier-fillgray'">
								</w-icon>
								<span class="list-item__text">{{(listItem as unknown as Record<string, string>)[optionProps.name]}}</span>
							</div>
						</div>
					</van-tab>
				</van-tabs>
			</div>
			<div v-else class="wind-select-tab-mulity__empty-container">
				<w-icon type="empty" class="empty-icon"></w-icon>
			</div>
		</a-modal>
	</div>
</template>
<script lang="ts">
/**
 * SelectTabMulity by shang 2022/8/25
 * @desc SelectTabMulity 分页选择器
 */
import { defineComponent, ref, computed } from 'vue'
import type { PropType } from 'vue'
import { getFxInstance } from '@/js/instance'
interface ListItem {
	id: string
	visibleFlag?: boolean
}
interface TabItem {
	id: string
	name: string
	listData: ListItem[]
}
export default defineComponent({
	name: 'wind-select-tab-mulity',
	props: {
		value: {
			type: Array as PropType<string[]>,
			default: () => {
				return []
			}
		},
		optionlist: {
			type: Array as PropType<TabItem[]>,
			default: () => {
				return []
			}
		},
		optionProps: {
			type: Object as PropType<{name:string}>,
			default: () => {
				return {
					name: 'name'
				}
			}
		},
		customCellText: String,
		title: {
			type: String,
			default: '选择选项'
		},
		cellType: String,
		cellLabel: {
			type: String,
			default: ''
		},
		cellText: String,
		emptyText: {
			type: String,
			default: '暂无数据'
		},
		disabled: Boolean,
		required: Boolean,
		placeholder: {
			type: String,
			default: '请选择'
		},
		filterable: {
			type: Boolean,
			default: false
		}
	},
	setup (props, { emit }) {
		const fxInstance = getFxInstance()
		const visible = ref<boolean>(false)
		const isEmptyCellText = computed(() => {
			return !fxInstance.$fxUtils.isDef(props.cellText) || props.cellText === ''
		})
		const currentCellText = computed(() => {
			if (props.disabled) {
				return props.cellText
			} else if (isEmptyCellText.value) {
				return props.placeholder
			} else {
				return props.cellText
			}
		})
		const showModal = () => {
			setActiveTab()
			searchKey.value = ''
			visible.value = true
		}
		const handleOk = () => {
			visible.value = false
			emit('update:value', currentSelectIds.value)
			emit('change', currentSelectIds.value)
		}
		const handleReset = () => {
			currentSelectIds.value = []
		}
		const tabActive = ref('')
		const currentSelectItem = ref<ListItem>({
			id: '',
			[props.optionProps.name]: ''
		})
		const currentSelectIds = ref<string[]>([])
		const setActiveTab = () => {
			currentSelectItem.value = {
				id: '',
				[props.optionProps.name]: ''
			}
			currentSelectIds.value = props.value
			tabActive.value = allVisibleOptionList.value[0].id
			if (props.value.length > 0) {
				let listItemIndex = -1
				const _value = props.value[0]
				const tab = allVisibleOptionList.value.find(item => {
					const index = item.listData.findIndex(listItem => {
						return listItem.id === _value
					})
					if (~index) {
						listItemIndex = index
					}
					return ~index
				})
				if (tab) {
					tabActive.value = tab.id
					currentSelectItem.value = tab.listData[listItemIndex]
				} else {
					console.warn(`wind-select-tab-mulity组件未找到匹配id:${props.value}`)
				}
			} else {
				tabActive.value = allVisibleOptionList.value[0].id
			}
		}
		const onListItemClick = (item:ListItem) => {
			if (currentSelectIds.value.includes(item.id)) {
				currentSelectIds.value = currentSelectIds.value.filter(currentItem => {
					return currentItem !== item.id
				})
			} else {
				currentSelectIds.value.push(item.id)
			}
		}
		// {{{ searchItem
		const searchKey = ref('')
		const searchProps = ['name', 'code', 'pinYin']
		const allVisibleOptionList = computed(() => {
			const listData = props.optionlist.map((item:TabItem) => {
				const listData = item.listData.filter(_item => {
					return _item.visibleFlag !== false && fxInstance.$fxUtils.fuzzyQueryObj(_item, searchKey.value, searchProps)
				})
				return {
					...item,
					listData
				}
			})
			return listData
		})
		return {
			visible,
			isEmptyCellText,
			currentCellText,
			searchKey,
			showModal,
			handleOk,
			handleReset,
			tabActive,
			allVisibleOptionList,
			currentSelectItem,
			currentSelectIds,
			onListItemClick
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.wind-select-tab-mulity {
	&__cell {
		display: flex;
		align-items: center;
		padding-right: 10px;
		padding-left: 15px;
		width: 280px;
		height: 36px;
		border: 1px solid #cccccc;
		border-radius: 20px;
		line-height: 36px;
		box-sizing: border-box;
		.cell-text {
			flex: 1;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		.empty-cell-text {
			color: #cccccc;
		}
		.select-arrow-icon {
			width: 12px;
			height: 12px;
			color: #a7a8ab;
			transform: rotate(90deg);
		}
	}
	&__filter {
		padding: 0;
		border: none;
		:deep(.link-arrow-container) {
			transform: rotate(90deg);
			margin-left: 20px
		}
		:deep(.wind-cell__value) {
			overflow: visible;
		}
	}
	.empty-cell-text {
		:deep(.wind-cell__value) {
			color: #cccccc;
		}
	}
	&__modal-container {
		overflow: auto;
		height: 96%;
		.van-tabs {
			display: flex;
			overflow: auto;
			height: 100%;
			flex-direction: column;
			:deep(.van-tabs__wrap) {
				margin-bottom: 5px;
				height: 40px;
			}
			:deep(.van-tabs__content) {
				flex: 1;
				overflow-y: scroll;
			}
			.list-item {
				display: flex;
				align-items: center;
				padding: 0 10px;
				height: 40px;
				line-height: 40px;
				box-sizing: border-box;
				&.is-select {
					color: $fxDefaultColor;
					background-color: #5c92fe14;
				}
				.list-item__text {
					flex: 1;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				.right-identifier-icon {
					margin-right: 8px;
				}
			}
		}
	}
	&__empty-container {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		.empty-icon {
			width: 200px;
			height: 200px;
		}
	}
}
.wind-select-tab-mulity__modal {
	.btn-box {
		display: flex;
		justify-content: center;
		width: 100%;
		.confirm-btn {
			width: 50%;
		}
	}
	.list-item-seachbar {
		margin: 0;
		width: 100%;
	}
}
</style>
<style lang="scss">
.wind-select-tab-mulity {
	&__modal {
		.ant-modal-body {
			border-bottom: 1px solid#f0f0f0;
		}
	}
}
</style>
