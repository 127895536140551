<template>
	<div class="scales-panel__title" v-if="!showInStoreOrOutStoreTitle">
		<w-icon-svg
			class="scales-btn__prev"
			:class="{
				'is-visible':itemPrevBtnVisible
			}"
			type="icon-left-arrow"
			@click="onItemPrevClick"
		></w-icon-svg>
		<div class="title-text">{{title}}</div>
		<w-link
			v-show="detailLinkVisible"
			afterIconSvg="icon-cell-link-arrow"
			@click="onDetailLinkClick"
		>详情</w-link>
		<w-icon-svg
			class="scales-btn__next"
			:class="{
				'is-visible':itemNextBtnVisible
			}"
			type="icon-right-arrow"
			@click="onItemNextClick"
		></w-icon-svg>
		<w-icon
			v-if="showSimpleSwitch"
			class="fold-btn"
			type="fold"
			@click="onSwitchScalesSimpleClick"
		></w-icon>
	</div>
	<div class="scales-panel__title-simple" v-else>
		<div class="title-text" v-if="!currentData.id">{{title}}</div>
		<div class="scales-panel__title-simple-left">
			<div class="scales-item__name">{{ currentData.item?.name }}</div>
		</div>
		<div class="scales-panel__title-simple-right">
			<div class="scales-item__info" v-if="showScalesItemInfo">
				<div class="scales-item__info-top">
					<div class="scales-item__info-top-name">订货:</div>
					<div class="scales-item__info-top-amount">{{ stockAmountToWeight }}{{ weightUnitLabel }}</div>
				</div>
				<div class="scales-item__info-bottom">
					<div class="scales-item__info-bottom-name">差异:</div>
					<div :class="['scales-item__info-bottom-amount', diffAmountClass]">{{ diffAmountToWeight }}{{ weightUnitLabel }}</div>
				</div>
			</div>
			<a-button
				v-if="detailLinkVisible"
				class="scales-item__btn"
				@click="onDetailLinkClick"
			>详情</a-button>
		</div>
	</div>
</template>
<script lang="ts">
/**
 * fxScalesWeightPanelTitle by shang 2023/7/13
 * @desc fxScalesWeightPanelTitle 称重面板title
 */
import { defineComponent, ref, watch } from 'vue'
import { useStore } from '@/store'
import type { ItemDetail } from '@/models/in-store'
export default defineComponent({
	name: 'fx-scales-weight-panel-title',
	props: {
		detailLinkVisible: {
			type: Boolean,
			default: true
		},
		itemPrevBtnVisible: Boolean,
		itemNextBtnVisible: Boolean,
		title: String,
		showSimpleSwitch: {
			type: Boolean,
			default: true
		},
		showInStoreOrOutStoreTitle: {
			type: Boolean,
			default: false
		},
		data: {
			type: Object,
			default: () => {
				return {}
			}
		},
		stockAmountToWeight: {
			type: Number,
			default: 0
		},
		diffAmountToWeight: {
			type: Number,
			default: 0
		},
		showScalesItemInfo: {
			type: Boolean,
			default: false
		},
		diffAmountClass: {
			type: String,
			default: ''
		},
		isCurrentItemCanWeigh: {
			type: Boolean,
			default: false
		}
	},
	setup (props, { emit }) {
		const currentData = ref<Partial<ItemDetail>>({})
		const store = useStore()
		const weightUnitLabel = ref()
		watch(() => props.data as ItemDetail, (value) => {
			currentData.value = value
			weightUnitLabel.value = props.isCurrentItemCanWeigh ? store.getters.getWeightUnitLabel : currentData.value.itemUnit?.name
		})
		const onItemPrevClick = () => {
			emit('on-prev-click')
		}
		const onItemNextClick = () => {
			emit('on-next-click')
		}
		const onSwitchScalesSimpleClick = () => {
			emit('on-switch-scales-simple')
		}
		const onDetailLinkClick = () => {
			emit('on-detail-link-click')
		}
		return {
			currentData,
			weightUnitLabel,
			onItemPrevClick,
			onItemNextClick,
			onSwitchScalesSimpleClick,
			onDetailLinkClick
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.scales-panel__title {
	display: flex;
	align-items: center;
	margin: 6px;
	height: 50px;
	border-radius: 5px;
	background-color: $fxGray17;
	.title-text {
		display:-webkit-box; // 将对象作为弹性伸缩盒子模型显示。
		overflow: hidden;
		text-align: center;
		text-overflow: ellipsis;
		flex: 1;
		-webkit-box-orient: vertical;  // 从上到下垂直排列子元素
		-webkit-line-clamp: 2; // 显示的行数
	}
	.scales-btn__prev, .scales-btn__next {
		margin-right: 10px;
		margin-left: 10px;
		width: 23px;
		height: 23px;
		color: $fxGray4;
		&.is-visible {
			color: $fxDefaultColor;
		}
	}
	.fold-btn {
		margin-right: 10px;
		margin-left: 10px;
		width: 23px;
		height: 23px;
	}
}
.scales-panel__title-simple {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 6px;
	height: 50px;
	border-radius: 5px;
	color: #333333;
	background-color: $fxWhite;
	.title-text {
		font-size: 18px;
		color: #999999;
	}
	&-left {
		display: flex;
		flex: 1;
		.scales-item__name {
			display:-webkit-box;
			overflow: hidden;
			font-size: 18px;
			font-weight: 600;
			text-align: left;
			text-overflow: ellipsis;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
		}
	}
	&-right {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		.scales-item__info {
			display: flex;
			flex-direction: column;
			font-size: 12px;
			&-top, &-bottom {
				display: flex;
				flex-direction: row;
				&-name {
					margin-right: 3px;
				}
				.diff-amount-red {
					color: $fxRed;
				}
				.diff-amount-green {
					color: $fxGreen;
				}
			}
		}
		.scales-item__btn {
			margin-left: 10px;
			width: 51px;
			height: 37px;
			border: 1px solid #B4B4B4;
			border-radius: 2px;
		}
	}
}
</style>

