<template>
	<a-drawer
		:visible="visible"
		:title="title"
		:width="drawerWidth"
		placement="left"
		class="bill-detail-drawer"
		@close="onClose"
	>
		<slot></slot>
	</a-drawer>
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue'

export default defineComponent({
	name: 'billDetailDrawer',
	props: {
		value: Boolean,
		title: String,
		drawerWidth: {
			type: String,
			default: '31.25vw'
		}
	},
	emits: ['update:value'],
	setup (props, { emit }) {
		const visible = computed({
			get () {
				return props.value
			},
			set (val) {
				emit('update:value', val)
			}
		})
		const onClose = () => {
			visible.value = false
		}
		return {
			visible,
			onClose
		}
	}
})
</script>
<style lang="scss">
@import "$assets/stylus/varsty";
.bill-detail-drawer {
	.ant-drawer-header-title {
		flex-direction: row-reverse;
	}
	.ant-drawer-body {
		padding: 0;
	}
}
</style>
