import type { RequestConfig } from '@/js/api/dataSource'
import { post, get } from '@/js/api/dataSource'
import utils from '@/js/utils'
export default {
	// 标签打印
	labelPrint: {
		// 获取默认打印模板
		getPrintTemplateDefault (): RequestConfig {
			return post({ url: 'bill/pad/tag_print_template/v1/default' })
		},
		// 获取打印设置
		getBillPrintItemCode (): RequestConfig {
			return get({
				url: 'bill/pad/item_code/setting/v1/getBillPrintItemCode',
				success (res = {}) {
					return utils.JSONparse((res || {}).setConditions, 'object')
				}
			})
		}
	}
}
