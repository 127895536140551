import type { MistakeCheckItemType, BillType } from '@/config/order_mistake_check_config'
import { MISTAKE_CHECK_ITEM_CONFIG } from '@/config/order_mistake_check_config'
import fxUtils from '@/js/utils'

function getOrderMistakeCheckConfig (state: Record<string, Record<string, object>>, type: BillType): MistakeCheckItemType {
	const appOrderMistakeCheckConfig = fxUtils.deepClone(MISTAKE_CHECK_ITEM_CONFIG[type]) as MistakeCheckItemType
	if (!fxUtils.isEmptyObj(state.appOrderMistakeCheckConfig) && state.appOrderMistakeCheckConfig[type]) {
		const selectedList: string[] = appOrderMistakeCheckConfig.mistakeCheckList.filter(item => item.mistakeType === 'error').map(item => item.id)
		selectedList.push(...(state.appOrderMistakeCheckConfig[type] as string[]))
		appOrderMistakeCheckConfig.selectedList = fxUtils.arrDedup(selectedList)
	} else {
		appOrderMistakeCheckConfig.selectedList = appOrderMistakeCheckConfig.mistakeCheckList.map(item => item.id)
	}
	return appOrderMistakeCheckConfig
}
export default {
	getOrderMistakeCheckList: (state: Record<string, Record<string, object>>) => (type: BillType, mistakeCheckList: string[] = []): string[] => {
		return getOrderMistakeCheckConfig(state, type).selectedList.filter(item => {
			return !mistakeCheckList.length || mistakeCheckList.includes(item)
		})
	}
}
