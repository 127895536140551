// 单据列表状态枚举
export enum OrderListStateEnum {
	// 全部
	all = 'all',
	// 未提交
	unSubmit = 'unSubmit',
	// 未审核
	unChecked = 'unChecked',
	// 已审核
	checked = 'checked',
	// 待分拣
	waitSort = 'waitSort',
	// 已完成(已分拣)
	sorted = 'sorted',
	// 全部(工序任务)
	processAll = 'processAll',
	// 待开工(工序任务)
	waitStart = 'waitStart',
	// 待认领(工序任务)
	waitClaim = 'waitClaim',
	// 已完成(工序任务)
	finished = 'finished'
}

