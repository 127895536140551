import type { App } from 'vue'
import API_CONFIG from '@/config/api_config'
import { dataSource } from './dataSource'
import type { DataSourceReturnFunc } from './dataSource'
import utils from '@/js/utils'

const log = utils.fxDebug('fxApi')
const API: { [key: string]: { [key: string]: Function } } = { ...API_CONFIG }
export const fxApi = function (apiName: string, ...arg: unknown[]): DataSourceReturnFunc {
	const [apiModule, apiFunc] = apiName.split('.')
	if (!API[apiModule][apiFunc]) {
		log(`api接口命名有误${apiName}`)
	}
	const options = API[apiModule][apiFunc](...arg)
	return dataSource(options)
}

const install = (app: App): void => {
	app.config.globalProperties.$fxApi = fxApi
}
export default {
	install
}

