export default {
	connectBlueToothPrinter (arg0 = '', arg1 = '', success: Function, error: Function): void {
		window.cordova.exec(success, error, 'PrinterPlugin', 'connectBlueToothPrinter', [arg0, arg1])
	},
	printLabel (alabelStyleXml = '', labelData = '', labelWidth = '', labelHeight = '', printNum = '', success: Function, error: Function): void {
		window.cordova.exec(success, error, 'PrinterPlugin', 'printLabel', [alabelStyleXml, labelData, labelWidth, labelHeight, printNum])
	},
	connectUsbPrinter (arg0 = {}, success: Function, error: Function): void {
		window.cordova.exec(success, error, 'PrinterPlugin', 'connectBlueToothPrinter', [arg0])
	}
}
