
import type { App } from 'vue'
import KeyBoard from './KeyBoard.vue'
import VirtualInput from './VirtualInput.vue'
const install = (app: App): void => {
	app.component(KeyBoard.name, KeyBoard)
	app.component(VirtualInput.name, VirtualInput)
}

export default {
	install,
	KeyBoard,
	VirtualInput
}
