<template>
	<div v-if="disabled" class="wind-select-dropdown__cell">
		<div class="cell-label fx-ellipsis" @click.prevent>{{cellLabel}}</div>
	</div>
	<a-dropdown v-else>
		<div class="wind-select-dropdown__cell">
			<div class="cell-label fx-ellipsis" @click.prevent>{{cellLabel}}</div>
			<div class="select-arrow"></div>
		</div>
		<template #overlay>
			<a-menu>
				<a-menu-item v-for="item in optionlist" :key="item.id" @click="onItemClick(item)">{{ item.name }}</a-menu-item>
			</a-menu>
		</template>
	</a-dropdown>
</template>
<script lang="ts">
/**
 * Select-dropdown by shang 2023/05/26
 * @desc Select-dropdown 下拉选择器
 */
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
interface ListItem {
	id: string|number
	name?: string
}
export default defineComponent({
	name: 'wind-select-dropdown',
	props: {
		cellLabel: {
			type: String,
			default: ''
		},
		optionlist: {
			type: Array as PropType<ListItem[]>,
			default: () => {
				return []
			}
		},
		disabled: Boolean
	},
	emits: ['change'],
	setup (props, { emit }) {
		const onItemClick = (item:ListItem) => {
			emit('change', item)
		}
		return {
			onItemClick
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.wind-select-dropdown__cell {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 10px;
	width: 100%;
	height: 24px;
	line-height: 24px;
	box-sizing: border-box;
	.cell-label {
		font-size: 12px;
		flex: 1;
	}
	.select-arrow {
		width: 0;
		height: 0;
		border: 5px solid #86A0C7;
		border-right-color:	transparent;
		border-bottom: 0 solid transparent;
		border-left-color: transparent;
	}
}
</style>
