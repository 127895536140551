// 入库单生成类型
import { normalizationBillGenerateType } from './type'
import type { BillGenerateField } from './type'
/**
 * @name inStoreBillGenerateMainType
 * @desc 入库单主类型, 不能随意添加, 必须与后端一致, 属于单一类型
 * @author shw
 * @date 2023/2/6
 */
const inStoreBillGenerateMainType = {
	// 仅可手工新建可选 - 期初入库
	beginPeriod: {
		label: '期初入库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.billType}` === '1001'
		}
	},
	// 可手工新建选择，也可采购入库分派自动生
	purchase: {
		label: '采购入库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.billType}` === '1002'
		}
	},
	// 仅可手工新建可选 -  其他入库
	other: {
		label: '其他入库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.billType}` === '1003'
		}
	},
	// 仅可盘点自动生成 -  盘盈入库
	stockProfit: {
		label: '盘盈入库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.billType}` === '1004'
		}
	},
	diff: {
		label: '差异入库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.billType}` === '1005'
		}
	},
	move: {
		label: '调拨入库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.billType}` === '1006'
		}
	},
	return: {
		label: '退货出库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.billType}` === '1007'
		}
	},
	offset: {
		label: '冲减返库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.billType}` === '1008'
		}
	},
	salesReturn: {
		label: '销售返库',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.billType}` === '1009'
		}
	}
}
/**
 * @name inStoreBillGenerateSubType
 * @desc 入库单子类型, 属于单一类型
 * @author shw
 * @date 2023/2/6
 */
export const inStoreBillGenerateSubType = {
	// 采购订单子类 {{{
	'stockOrder-sub-hand': {
		label: '手工新建采购入库单',
		rule (organDetails: BillGenerateField): boolean {
			return inStoreBillGenerateCollectionType['normal-sub'].rule(organDetails) && inStoreBillGenerateMainType.purchase.rule(organDetails)
		}
	},
	'assignStraight-sub': {
		label: '直发分派入库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.subBillType}` === '1002-2'
		}
	},
	// 越库分派入库单（卖方有仓库）
	'assignCross-sub': {
		label: '越库分派入库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.subBillType}` === '1002-3'
		}
	},
	// 越库分派入库单（卖方无仓库）
	'assignCross-sub-noHouse': {
		label: '越库分派入库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.subBillType}` === '1002-3-1'
		}
	},
	'outStore-sub': {
		label: '出库单审核生成',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.subBillType}` === '1002-4'
		}
	},
	'saleOrder-sub-outOrder': {
		label: '销售订单分派的出库单直接生成',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.subBillType}` === '1002-5'
		}
	},
	'stockOrder-sub-outOrder': {
		label: '采购订单的出库单直接生成',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.subBillType}` === '1002-6'
		}
	},
	'delSaleReturnOutStoreOrder-sub-blueOrder': {
		label: '删除销售返库红色出库单生成的蓝色入库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.subBillType}` === '1002-7'
		}
	},
	// }}}
	// {{{ 其他入库类子单据
	'other-sub-hand': {
		label: '手工新建其他入库单',
		rule (organDetails: BillGenerateField): boolean {
			return organDetails.autoType === 0 && inStoreBillGenerateMainType.other.rule(organDetails)
		}
	},
	// }}}
	// {{{ 差异入库类子单据
	'amount-sub': {
		label: '数量出库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.subBillType}` === '1005-4'
		}
	},
	'money-sub': {
		label: '金额出库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.subBillType}` === '1005-5'
		}
	},
	// }}}
	// {{{ 销售返库类子单据
	'handReturn-sub-blueOrder': {
		label: '手工新建的入库单退货生成的蓝色入库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.subBillType}` === '1009-1'
		}
	},
	'outStoreOrderChildInStoreOrder-sub-blueOrder': {
		label: '出库单的下级入库单退货生成的蓝色入库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.subBillType}` === '1009-2'
		}
	},
	'assigneSaleOrderInStoreOrderReturn-sub-blueOrder': {
		label: '销售订单入库分派的入库单退货生成的蓝色入库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.subBillType}` === '1009-3'
		}
	}
	// }}}
}
/**
 * @name inStoreBillGenerateCollectionType
 * @desc 入库单集合类型, 是多个主类型或者子类型的集合, 方便逻辑判断, 注意不要与主/子类型冲突
 * @author shw
 * @date 2023/2/6
 */
const inStoreBillGenerateCollectionType = {
	// 手工新建的入库单
	'normal-sub': {
		label: '手工新建入库单',
		rule (organDetails: BillGenerateField): boolean {
			return organDetails.autoType === 0
		}
	},
	'srm-sub-inStore': {
		label: 'srm生成入库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.sourceBillType}` === 'SRM_Bill'
		}
	},
	'red-inStore': {
		label: '红色入库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.billSign}` === '2'
		}
	},
	'jsd-return-sub-sale-back-inStore': {
		label: '极速订退单生成销售返库入库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.sourceType}` === '1002'
		}
	},
	'pull-nc-sub': {
		label: '拉取NC订单数据生成对应入库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.sourceType}` === '1004'
		}
	},
	'push-to-nc-inStore': {
		label: '推送给nc成功的入库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.sendTtjNcFlag}` === '1'
		}
	},
	'sales-order-dispatch-inStore': {
		label: '销售订单入库分派生成的入库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.billType}` === '1002' && `${organDetails.subBillType}` === '1002-2'
		}
	}
}
export default {
	/**
	 * @name inStoreBillGenerateType
	 * @desc 入库单生成类型
	 * @author shw
	 * @date 2021/9/10
	 */
	inStoreBillGenerateType: [
		...normalizationBillGenerateType(inStoreBillGenerateMainType),
		...normalizationBillGenerateType(inStoreBillGenerateSubType),
		...normalizationBillGenerateType(inStoreBillGenerateCollectionType)
	],
	inStoreBillGenerateSubType: [...normalizationBillGenerateType(inStoreBillGenerateSubType)]
}
