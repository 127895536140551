<template>
	<div class="fx-scales-weight-screen">
		<w-icon type="scale-weight"></w-icon>
		<div class="fx-scales-weight-screen__content">{{ weightValueComputed }}{{ weightUnitLabel }}</div>
	</div>
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useWeight } from '@/js/hooks'
import { useStore } from '@/store'
export default defineComponent({
	name: 'fx-scales-weigh-connect',
	setup () {
		const store = useStore()
		const { weight } = useWeight()
		const weightUnitLabel = computed(() => store.getters.getWeightUnitLabel)
		const weightValueComputed = computed(() => store.getters.getWeightValue(weight.value))
		return {
			weightValueComputed,
			weightUnitLabel
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.fx-scales-weight-screen {
    display: flex;
    align-items: center;
    margin-right: 20px;
    padding: 0 2px;
	width: 120px;
    height: 26px;
    font-weight: bold;
    border: 2px solid white;
    border-radius: 5px;
    box-sizing: border-box;
	&__content {
		flex: 1;
		display: flex;
		justify-content: flex-end;
		font-size: 16px;
		font-weight: bold;
	}
}
</style>
