// cordova-plugin-scale
import windUi from '@/components/wind-ui'
import utils from '@/js/utils'
import { registerCordovaApi } from './register'
import scaleBridge from './scale_bridge'
export interface ScaleInstance {
	connect: (address: string, name: string) => Promise<string>
	onStateChange: (fn: Function) => void
}
export interface ScaleInfo {
	scaleType: number
	scaleName: string
}
const log = (msg: string) => {
	return utils.fxDebug('cordova-api')(msg)
}

const connectBluetoothScale = function (address: string, name: string, silent?: boolean): Promise<string> {
	return new Promise(resolve => {
		registerCordovaApi('ScalePlugin', scaleBridge, () => {
			window.ScalePlugin.connectBluetoothScale(
				address,
				name,
				function () {
					resolve(name)
				},
				function () {
					if (!silent) {
						windUi.$fxMessage.error('连接失败')
					}
				}
			)
		}, () => {
			log('浏览器环境下无法使用scale.connectBluetoothScale功能')
			if (!silent) {
				windUi.$fxMessage.error('连接失败')
			}
		})
	})
}
const connectIntegratedScale = function (address: string, name: string, silent?: boolean): Promise<string> {
	return new Promise(resolve => {
		registerCordovaApi('ScalePlugin', scaleBridge, () => {
			window.ScalePlugin.connectIntegratedScale(
				name,
				function () {
					resolve(name)
				},
				function () {
					if (!silent) {
						windUi.$fxMessage.error('连接失败')
					}
				}
			)
		}, () => {
			log('浏览器环境下无法使用scale.connectIntegratedScale功能')
			if (!silent) {
				windUi.$fxMessage.error('连接失败')
			}
		})
	})
}
const connectUsbScale = function (address: string, name: string, silent?: boolean): Promise<string> {
	return new Promise(resolve => {
		registerCordovaApi('ScalePlugin', scaleBridge, () => {
			window.ScalePlugin.connectUsbScale(
				name,
				function () {
					resolve(name)
				},
				function () {
					if (!silent) {
						windUi.$fxMessage.error('连接失败')
					}
				}
			)
		}, () => {
			log('浏览器环境下无法使用scale.connectUsbScale功能')
			if (!silent) {
				windUi.$fxMessage.error('连接失败')
			}
		})
	})
}
const onStateChange = function (cd: Function): void {
	registerCordovaApi('ScalePlugin', scaleBridge, () => {
		window.ScalePlugin.addScaleChangeListener(
			'',
			function (json: string) {
				const state = JSON.parse(json)
				cd(state)
			},
			function () {
				log('监听状态失败')
			}
		)
	}, () => {
		log('浏览器环境下无法使用scale.onStateChange功能')
	})
}
const onBatteryChange = function (cd: Function): void {
	registerCordovaApi('ScalePlugin', scaleBridge, () => {
		window.ScalePlugin.addScaleBatteryListener(
			'',
			function (json: string) {
				const state = JSON.parse(json)
				cd(state)
			},
			function () {
				log('监听电池状态失败')
			}
		)
	}, () => {
		log('浏览器环境下无法使用scale.onBatteryChange功能')
	})
}
const setTare = function (): void {
	registerCordovaApi('ScalePlugin', scaleBridge, () => {
		window.ScalePlugin.setTare(
			'',
			function () {
				log('去皮成功')
			},
			function () {
				log('去皮失败')
			}
		)
	}, () => {
		log('浏览器环境下无法使用scale.setTare功能')
	})
}
const setBoxTare = function (boxTare: number): void {
	registerCordovaApi('ScalePlugin', scaleBridge, () => {
		window.ScalePlugin.setBoxTare(
			boxTare,
			function () {
				log('去皮成功')
			},
			function () {
				log('去皮失败')
			}
		)
	}, () => {
		log('浏览器环境下无法使用scale.setBoxTare功能')
	})
}
const setZero = function (): void {
	registerCordovaApi('ScalePlugin', scaleBridge, () => {
		window.ScalePlugin.setZero(
			'',
			function () {
				log('置零成功')
			},
			function () {
				log('置零失败')
			}
		)
	}, () => {
		log('浏览器环境下无法使用scale.setZero功能')
	})
}
const getDeviceType = function (): Promise<ScaleInfo> {
	return new Promise(resolve => {
		registerCordovaApi('ScalePlugin', scaleBridge, () => {
			window.ScalePlugin.getDeviceType(
				'',
				function (json: string) {
					const scaleInfo: ScaleInfo = JSON.parse(json)
					resolve(scaleInfo)
				},
				function () {
					log('获取设备类型失败')
				}
			)
		}, () => {
			log('浏览器环境下无法使用scale.getDeviceType功能')
			const scaleInfo: ScaleInfo = {
				scaleType: 0,
				scaleName: ''
			}
			resolve(scaleInfo)
		})
	})
}
const disConnectScale = function (): Promise<boolean> {
	return new Promise(resolve => {
		registerCordovaApi('ScalePlugin', scaleBridge, () => {
			window.ScalePlugin.disConnectScale(
				'',
				function () {
					resolve(true)
				},
				function () {
					windUi.$fxMessage.error('电子秤断开失败')
				}
			)
		}, () => {
			windUi.$fxMessage.error('电子秤断开失败')
			log('浏览器环境下无法使用scale.disConnectScale功能')
		})
	})
}
export default {
	connectBluetoothScale,
	connectIntegratedScale,
	connectUsbScale,
	onStateChange,
	onBatteryChange,
	setTare,
	setBoxTare,
	setZero,
	getDeviceType,
	disConnectScale
}
