import { getStorage, getStorageNumber } from '../utils'

const state = {
	// 服务地址
	baseUrl: getStorage('baseUrl', ''),
	// token
	token: getStorage('token', ''),
	// hostId
	hostId: getStorage('hostId', ''),
	// 用户名
	username: getStorage('username', ''),
	// 密码
	password: getStorage('password', ''),
	// 用户输入的地址
	domain: getStorage('domain', ''),
	// 租户id
	tenantId: getStorageNumber('tenantId', 0),
	// 集团码
	entCode: getStorage('entCode', ''),
	// 员工名称
	employeeName: getStorage('employeeName', ''),
	// 管理员标记
	systemFlag: getStorageNumber('systemFlag', 0),
	// 可见销售金额
	haveMoneyPower: getStorageNumber('haveMoneyPower', 0),
	// 可见成本金额
	haveCostPower: getStorageNumber('haveCostPower', 0),
	// 自动登录标记标记
	loginSilentFlag: getStorageNumber('loginSilentFlag', 0),
	// 机构名称
	organName: getStorage('organName', ''),
	// userId
	userId: getStorage('userId', ''),
	// 角色名
	roles: getStorage('roles', '[]')
}
export default state
export type State = typeof state
