<template>
	<div class="fx-weigh-image" :class="{
		'is-disabled': disabled
	}">
		<!-- <div v-show="!disabled" class="watch-image" @click="showModal">查看照片</div> -->
		<div v-if="!disabled" class="tools-space">
			<w-icon type="image-icon" class="tools-space-btn" @click="showModal"></w-icon>
		</div>
		<div v-if="showCamera" class="tools-space" :class="{
			'tools-space-camera-disabled': disabledCamera,
			'tools-space-camera-disabled-no-print': !showPrint
			}" >
			<w-icon type="camara-icon" class="tools-space-btn" @click="onCameraClick"></w-icon>
		</div>
		<div v-if="showPrint" class="tools-space">
			<w-icon type="print-btn-icon" class="tools-space-btn" @click="onPrintClick"></w-icon>
		</div>
		<a-modal
			v-model:visible="visible"
			:title="title"
			:bodyStyle="{
				width: '60vw',
				height: '41vw',
				'padding': '1vw 1vw 1vw 1vw',
				display: 'flex'
			}"
			centered
			width="60vw"
			class="fx-weigh-image__modal"
		>
			<div v-if="value.length>0" class="fx-weigh-image__images-ul">
				<div v-for="(item, index) in value" :key="index" class="file-list__block">
					<w-image :src="item.imageUrl" class="block-image" @click="onPreviewClick(item.imageUrl)"></w-image>
					<a-popconfirm
						title="确认后，称重图片将被删除，确认是否删除？"
						@confirm="onFileDelClick(index)"
					>
						<w-icon
							v-if="!disabled && !disabledCamera"
							type="delete-icon"
							size="mini"
							pointer
							class="delete-btn"
						></w-icon>
					</a-popconfirm>
				</div>
			</div>
			<w-empty v-else :message="emptyText"></w-empty>
			<template #footer>
				<!-- <a-button key="submit" type="primary" class="confirm-btn" @click="handleOk">确定</a-button> -->
			</template>
		</a-modal>
		<w-image-preview v-model:previewVisible="previewVisible" :previewUrl="previewUrl"></w-image-preview>
	</div>
</template>
<script lang="ts">
/**
 * fxImagePrint by shang 2022/8/25
 * @desc fxImagePrint 拍照,打印工具按钮
 */
import { defineComponent, ref, computed } from 'vue'
import type { PropType } from 'vue'
import { getFxInstance } from '@/js/instance'
import { useStore } from '@/store'
interface WeighImageItem {
	imageUrl: string
}
export default defineComponent({
	name: 'fx-image-print-tools',
	props: {
		value: {
			type: Array as PropType<WeighImageItem[]>,
			default: () => {
				return []
			}
		},
		title: {
			type: String,
			default: '称重图片'
		},
		maxCount: {
			type: Number,
			default: 6
		},
		emptyText: {
			type: String,
			default: '暂无图片'
		},
		disabled: Boolean,
		showCamera: {
			type: Boolean,
			default: true
		},
		showPrint: {
			type: Boolean,
			default: true
		},
		toastTakePhoteMessage: {
			type: Boolean,
			default: false
		},
		disabledCamera: {
			type: Boolean,
			default: false
		}
	},
	setup (props, { emit }) {
		const store = useStore()
		const fxInstance = getFxInstance()
		const visible = ref(false)
		const previewVisible = ref(false)
		const previewUrl = ref('')
		const automaticPhotograph = computed(() => {
			return store.state.system.automaticPhotograph
		})
		const cameraFloatAuto = computed(() => {
			return store.state.system.cameraFloatAuto
		})
		const makePhotoHandler = (file:File) => {
			if (props.disabled || !props.showCamera) {
				return false
			}
			if (props.value.length >= props.maxCount) {
				fxInstance.$fxMessage.warning('已达到最大照片数量')
				return false
			}
			uploadFn(file).then(res => {
				const imageList = props.value
				imageList.push({
					imageUrl: res as unknown as string
				})
				if (props.toastTakePhoteMessage) {
					fxInstance.$fxMessage.success('操作成功')
				}
				emit('change', imageList)
			})
		}
		const onCameraClick = () => {
			if (props.disabled || props.disabledCamera) {
				return false
			}
			fxInstance.$fxCameraFloat.open((file:File):void => {
				makePhotoHandler(file)
			})
		}
		const doOpenCameraFloatAuto = () => {
			if (cameraFloatAuto.value) {
				fxInstance.$fxCameraFloat.open((file:File):void => {
					makePhotoHandler(file)
				})
			}
		}
		const doTakePhotoAuto = async (params: {amount: string, money: string, billCode?: string, inOrganName?: string, inHouseName?:string, itemName?:string, unitName?:string} = { amount: '', money: '', billCode: '', inOrganName: '', inHouseName: '', itemName: '', unitName: '' }) => {
			if (!automaticPhotograph.value) {
				return true
			}
			if (props.disabled) {
				return false
			}
			if (props.value.length >= props.maxCount) {
				fxInstance.$fxMessage.warning('已达到最大照片数量')
				return false
			}
			const file = await fxInstance.$fxCameraFloat.takePhoto(params)
			if (file) {
				const imageUrl = await uploadFn(file)
				const imageList = props.value
				imageList.push({
					imageUrl: imageUrl as unknown as string
				})
			}
			return true
		}
		const showModal = () => {
			if (props.disabled) {
				return false
			}
			visible.value = true
		}
		const handleOk = () => {
			visible.value = false
		}
		const uploadFn = (file:File) => {
			const params = {
				file
			}
			return fxInstance.$fxApi('common.upload')({ data: params })
		}
		const onPreviewClick = (url:string) => {
			previewUrl.value = url
			previewVisible.value = true
		}
		const onFileDelClick = (index:number) => {
			const imageList = props.value
			imageList.splice(index, 1)
			emit('change', imageList)
		}
		const onPrintClick = () => {
			if (props.disabled) {
				return false
			}
			emit('on-print-click')
		}
		return {
			visible,
			previewVisible,
			previewUrl,
			onCameraClick,
			showModal,
			handleOk,
			uploadFn,
			onFileDelClick,
			onPreviewClick,
			onPrintClick,
			doTakePhotoAuto,
			doOpenCameraFloatAuto
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.fx-weigh-image {
	position: relative;
    display: flex;
    margin-right: 10px;
    margin-left: 6px;
    width: 166px;
	min-width:70px;
    height: 36px;
    border: 1px solid #B4B4B4;
    text-align:center;
    color: $fxBlack1;
    line-height: 36px;
	.watch-image {
		position: absolute;
		top: -33px;
		left: 5px;
		width: 60px;
		height: 25px;
		font-size: 12px;
		border-radius: 30px;
		color: $fxWhite;
		background-color: $fxDefaultColor;
		opacity: 0.8;
		line-height: 25px;
	}
	.tools-space {
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		&-camera-disabled {
			border-color: #d9d9d9;
      color: rgb(0 0 0 / 25%);
      background-color: #f5f5f5;
			.tools-space-btn {
				color: #d9d9d9;
			}
		}
		&-camera-disabled-no-print {
			border-radius: 30px;
		}
	}
    .tools-space-btn {
        width: 20px;
        height: 20px;
        color: $fxBlack1;
    }
    &.is-disabled {
        border-color: #d9d9d9;
        color: rgb(0 0 0 / 25%);
        background-color: #f5f5f5;
        .tools-space-btn {
            color: #d9d9d9;
        }
        .tools-split-border {
            &::before {
                background-color: #d9d9d9;
            }
        }
    }
}
.fx-weigh-image__modal {
    .fx-weigh-image__images-ul {
        display: flex;
        overflow: auto;
        padding-top:10px;
        padding-left: 20px;
        flex:1;
        flex-flow: row wrap;
        box-sizing:border-box;
        .file-list__block {
            position: relative;
            display: flex;
            align-items: center;
            margin-right: 20px;
            margin-bottom: 20px;
            width: 150px;
            height: 150px;
            border-radius: 6px;
            background-color: #f2f3f5;
            flex-direction: row;
            box-sizing: border-box;
            .block-image {
                width: 100%;
                height: 100%;
                border-radius: 6px;
            }
            .delete-btn {
                position: absolute;
                top:-10px;
                right: -10px;
                width: 20px;
                height: 20px;
            }
        }
    }
    .confirm-btn {
        width: 100%;
    }
}
</style>
