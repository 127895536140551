<template>
	<div class="fx-keyboard" @mousedown.prevent>
    <div class="fx-keyboard-column">
      <div class="fx-keyboard-column-item" @click="addNumber('1')">1</div>
      <div class="fx-keyboard-column-item" @click="addNumber('4')">4</div>
      <div class="fx-keyboard-column-item" @click="addNumber('7')">7</div>
      <div class="fx-keyboard-column-item" @click="clearInput('clear')">C</div>
    </div>
    <div class="fx-keyboard-column">
			<div class="fx-keyboard-column-item" @click="addNumber('2')">2</div>
      <div class="fx-keyboard-column-item" @click="addNumber('5')">5</div>
      <div class="fx-keyboard-column-item" @click="addNumber('8')">8</div>
      <div class="fx-keyboard-column-item" @click="addNumber('0')">0</div>

    </div>
    <div class="fx-keyboard-column">
			<div class="fx-keyboard-column-item" @click="addNumber('3')">3</div>
      <div class="fx-keyboard-column-item" @click="addNumber('6')">6</div>
      <div class="fx-keyboard-column-item" @click="addNumber('9')">9</div>
      <div class="fx-keyboard-column-item" @click="addDecimalPoint('.')">.</div>
    </div>
    <div class="fx-keyboard-column">
			<div class="fx-keyboard-column-item" @click="backSpace('back')">
				<w-icon type="back-icon"></w-icon>
			</div>
			<div class="fx-keyboard-column-item" @click="addOperator('+')">+</div>
			<div class="fx-keyboard-column-item calculation" @click="calculation">=</div>
    </div>
  </div>
</template>
<script lang="ts">
import { fxEventBus } from '@/js/eventBus'
import { defineComponent, ref } from 'vue'
import { fxEventBusKey } from '@/config/app_eventbus_config'
import utils from './utils'
export default defineComponent({
	name: 'keyBoard',
	setup () {
		const inputNumber = ref<string>('')
		const inputNumberArray = ref<string[]>([])
		const keyBoardState = ref('')
		const addNumber = (num: string) => {
			fxEventBus.emit(fxEventBusKey.VIRTUAL_INPUT_EMIT, {
				key: utils.getFocusKey(),
				value: num,
				type: 'number'
			})
		}

		const addDecimalPoint = (val: string) => {
			fxEventBus.emit(fxEventBusKey.VIRTUAL_INPUT_EMIT, {
				key: utils.getFocusKey(),
				value: val,
				type: 'decimal'
			})
		}

		const clearInput = (val: string) => {
			fxEventBus.emit(fxEventBusKey.VIRTUAL_INPUT_EMIT, {
				key: utils.getFocusKey(),
				value: val,
				type: 'clear'
			})
		}

		const addOperator = (val: string) => {
			fxEventBus.emit(fxEventBusKey.VIRTUAL_INPUT_EMIT, {
				key: utils.getFocusKey(),
				value: val,
				type: 'operate'
			})
		}

		const backSpace = (val: string) => {
			fxEventBus.emit(fxEventBusKey.VIRTUAL_INPUT_EMIT, {
				key: utils.getFocusKey(),
				value: val,
				type: 'back'
			})
		}

		const calculation = () => {
			fxEventBus.emit(fxEventBusKey.VIRTUAL_INPUT_EMIT, {
				key: utils.getFocusKey(),
				value: '=',
				type: 'acc'
			})
		}
		return {
			inputNumber,
			inputNumberArray,
			keyBoardState,
			addNumber,
			addDecimalPoint,
			clearInput,
			addOperator,
			backSpace,
			calculation
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.fx-keyboard {
	display: flex;
	flex-direction: row;
	border-top: 1px solid #cccccc;
	border-left: 1px solid #cccccc;
	&-column {
		display: flex;
		flex-direction: column;
		flex: 1;
		&-item {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 45px;
			font-size: 18px;
			font-weight: 600;
			border: 1px solid #cccccc;
			border-top: none;
			border-left: none;
			color: #333333;
		}
		.calculation {
			flex: 1;
		}
	}
}
</style>
