// 项目通用状态设置
export interface stateItem {
	type?: String
	id: Number | String
	label: String
	stateIconColor?: String
}
export interface FxCommonState {
	[key: string]: stateItem[]
}
export type StateType = keyof FxCommonState
const STATE_MODULE: FxCommonState = {
	// 单据通用状态
	billState: [{
		id: 0,
		label: '待提交',
		type: 'unSubmit',
		stateIconColor: 'orange'
	}, {
		id: 1,
		label: '待审核',
		type: 'unChecked',
		stateIconColor: 'orange'
	}, {
		id: 2,
		label: '已审核',
		type: 'checked',
		stateIconColor: 'green'
	}, {
		id: 3,
		label: '已删除',
		type: 'deleted',
		stateIconColor: 'gray'
	}, {
		id: 4,
		label: '已复审',
		type: 'rechecked',
		stateIconColor: 'green'
	}],
	// 单据通用复审状态
	recheckState: [{
		id: 1,
		label: '已复审',
		type: 'rechecked',
		stateIconColor: 'green'
	}],
	// 加急状态
	urgentState: [{
		id: 0,
		label: '不加急',
		type: 'unurgent',
		stateIconColor: 'green'
	}, {
		id: 1,
		label: '加急',
		type: 'urgented',
		stateIconColor: 'red'
	}],
	// 分派状态
	assignState: [{
		id: 0,
		label: '未分派',
		type: 'unassign',
		stateIconColor: 'gray'
	}, {
		id: 1,
		label: '分派中',
		type: 'assigning',
		stateIconColor: 'orange'
	}, {
		id: 2,
		label: '已分派',
		type: 'assigned',
		stateIconColor: 'green'
	}, {
		id: 3,
		label: '已放弃',
		type: 'abandoned',
		stateIconColor: 'red'
	}],
	// 分拣状态
	sortingType: [{
		id: 'waitClaim',
		type: 'waitClaim',
		label: '待认领'
	}, {
		id: 'waitSort',
		type: 'waitSort',
		label: '待分拣'
	}, {
		id: 'sorting',
		type: 'sorting',
		label: '分拣中'
	}, {
		id: 'sorted',
		type: 'sorted',
		label: '已分拣'
	}],
	// 工序任务单状态
	processTaskState: [{
		id: 2,
		type: 'waitClaim',
		label: '待认领'
	}, {
		id: 3,
		type: 'waitStart',
		label: '待开工'
	}, {
		id: 4,
		type: 'underway',
		label: '进行中'
	}, {
		id: 5,
		type: 'finished',
		label: '已完成'
	}],
	// 单据验收状态
	checkAcceptStatus: [{
		id: '0',
		type: 'waitClaim',
		label: '未验收',
		stateIconColor: 'gray'
	}, {
		id: '1',
		type: 'waitStart',
		label: '验收中',
		stateIconColor: 'orange'
	}, {
		id: '2',
		type: 'underway',
		label: '已验收',
		stateIconColor: 'green'
	}]
}
export default STATE_MODULE
