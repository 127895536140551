// 单据生成类型设置
import COMMON_TYPE_CONFIG from '../common_type_config/index'
import type { BillGenerateTypeItem } from '../common_type_config/type'
// 项目通用类型设置
import itemProp from './itemProp'
import machiningOrder from './machiningOrder'
import organType from './organType'
import receiveOrder from './receiveOrder'
import stockOrder from './stockOrder'
import systemType from './systemType'
import sortingType from './sortingType'
import processTask from './processTask'
import type { BILL_PATH_CONFIG } from '@/config/path_config'
export interface TypeItem extends BillGenerateTypeItem {
	type?: string
	id?: number | string
	label: string
	pinYin?: string
}
const TYPE_MODULE = {
	...COMMON_TYPE_CONFIG,
	...itemProp,
	...machiningOrder,
	...organType,
	...receiveOrder,
	...stockOrder,
	...systemType,
	...sortingType,
	...processTask
}
export type FxCommonType = typeof TYPE_MODULE
export type CommonType = keyof FxCommonType
export default TYPE_MODULE

type GetBillType<T> = keyof T extends string ? Capitalize<keyof T> : T
export type BillType = GetBillType<typeof BILL_PATH_CONFIG>

export * from './orderList'
export * from './receiveOrder'

