export default {
	'settlement-way': {
		id: 'settlement-way',
		label: '结算方式',
		code: '0007_0006_0001',
		child: {
			'add-way': {
				id: 'add-way',
				label: '新增',
				code: '0007_0006_0001_001_001',
				decisionByAllModule: ['financial-manage_financial-archives_settlement-way.edit-way']
			},
			'edit-way': {
				id: 'edit-way',
				label: '编辑',
				code: '0007_0006_0001_002_001',
				controlledByAllModule: 'financial-manage_financial-archives_settlement-way.add-way'
			},
			'enable-way': {
				id: 'enable-way',
				label: '启用',
				code: '0007_0006_0001_006_001'
			},
			'disable-way': {
				id: 'disable-way',
				label: '停用',
				code: '0007_0006_0001_008_001'
			},
			'export-way': {
				id: 'export-way',
				label: '导出',
				code: '0007_0006_0001_040_001'
			}
		}
	}
}
