<template>
	<div ref="refModel">
		<a-modal
			v-model:visible="visible"
			title="常用筐皮设置"
			centered
			width="50vw"
			:getContainer="() => $refs.refModel"
			wrapClassName="item-detail__dialog"
		>
			<template #footer>
				<div class="footer-btn">
					<a-button
						type="primary"
						ghost
						@click="onCancelClick"
					>
						取消
					</a-button>
					<a-button
						type="primary"
						@click="onConfirmClick"
					>
						确定
					</a-button>
				</div>
			</template>
			<div class="form-box">
				<div class="form-item form-item-weight">
					<div class="form-item-title fx-common-require-icon">筐皮重量（KG）:</div>
					<w-input-number
						v-model:value="weight"
						:min="0.01"
						:max="999.99"
						:pointSize="2"
						:intLength="3"
						placeholder="0.01～999.99"
						class="form-input form-input-weight"
					/>
				</div>
				<div class="form-item">
					<div class="form-item-title">筐皮备注:</div>
					<a-input
						v-model:value="memo"
						allowClear
						:showCount="true"
						:maxlength="5"
						placeholder="请填写(0/5)"
						class="form-input"
					/>
				</div>
			</div>
		</a-modal>
	</div>
</template>
<script lang="ts" setup>
import { ref, watch } from 'vue'
import { useStore } from '@/store'
import { getFxInstance } from '@/js/instance'
const props = defineProps({
	value: {
		type: Boolean,
		default: false
	}
})
const emit = defineEmits<{(e: 'update:value', value: boolean): void}>()
const store = useStore()
const fxInstance = getFxInstance()
const visible = ref(false)
const weight = ref<string | number>('')
const memo = ref('')
watch(() => props.value, (val:boolean) => {
	visible.value = val
	if (val) {
		clear()
	}
}, {
	immediate: true
})
watch(() => visible.value, (val:boolean) => {
	emit('update:value', val)
})
const clear = () => {
	weight.value = ''
	memo.value = ''
}
const onCancelClick = () => {
	visible.value = false
}
const onConfirmClick = () => {
	if (!weight.value) {
		return fxInstance.$fxMessage.warning('请输入筐皮重量!')
	}
	store.commit('SET_TARE', {
		id: fxInstance.$fxUtils.createUUID(),
		weight: (weight.value as number),
		memo: memo.value
	})
	fxInstance.$fxMessage.success('操作成功')
	visible.value = false
}
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
:deep(.ant-modal-header) {
	border-bottom: 1px solid $fxGray25!important;
}
:deep(.ant-modal-footer) {
	border-top: 1px solid $fxGray25!important;
}
.item-detail__dialog {
	.footer-btn {
		display: flex;
		justify-content: center;
		width: 100%;
		.ant-btn-background-ghost.ant-btn-primary {
			border-color: #979797;
			color: $fxBlack1;
		}
	}
	.form-box {
		.form-item-weight {
			margin-bottom: 20px;
		}
		.form-item {
			display: flex;
			&-title {
				width: 150px;
				height: 40px;
				line-height: 40px;
				font-size: 16px;
			}
			.form-input-weight {
				padding: 0 10px;
				width: 270px;
				height: 40px;
				border: 1px solid #B4B4B4;
				border-radius: 2px;
			}
			.form-input {
				width: 275px;
				height: 40px;
				border: 1px solid #B4B4B4;
				border-radius: 2px;
			}
			:deep(.ant-input-affix-wrapper-focused) {
				box-shadow: none;
			}
			:deep(.ant-input-number-input) {
				height: 38px;
			}
		}
	}
}
</style>
