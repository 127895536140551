import { fxApi } from '@/js/api'
import fxUtils from '@/js/utils'
import cordova from '@/js/cordova'

const normalizationTaxList = function (list: number[]) {
	return list.map(item => {
		return {
			id: item,
			label: `${fxUtils.toFixedParseNumber(item * 100, 2)}%`
		}
	})
}

export function syncSystemTaxes ({ commit }: { commit: Function }): Promise<number[]> {
	return new Promise(resolve => {
		return fxApi('common.getTax').then((res: number[]) => {
			commit('SET_SYSTEM', {
				taxesList: normalizationTaxList(res)
			})
			resolve(res)
		})
	})
}


export function syncSystemAppVersion ({ commit }: { commit: Function }): Promise<string> {
	return new Promise(resolve => {
		return cordova.appVersion.getVersionNumber().then((res: string) => {
			commit('SET_SYSTEM', {
				appVersion: res
			})
			resolve(res)
		})
	})
}

export function syncQrcodeSetting ({ commit }: { commit: Function }): Promise<string> {
	return new Promise(resolve => {
		return fxApi('labelPrint.getBillPrintItemCode').then((res) => {
			commit('SET_QRCODE_SETTING', res)
			resolve(res)
		})
	})
}
