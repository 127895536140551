export default {
	'financial-customer-manage': {
		id: 'financial-customer-manage',
		label: '客户列表',
		code: '0007_0006_0004',
		child: {
			'edit-customer': {
				id: 'edit-customer',
				label: '编辑',
				code: '0007_0006_0004_002_001'
			},
			'enable-customer': {
				id: 'enable-customer',
				label: '启用',
				code: '0007_0006_0004_006_001'
			},
			'disable-customer': {
				id: 'disable-customer',
				label: '停用',
				code: '0007_0006_0004_008_001'
			},
			'export-customer': {
				id: 'export-customer',
				label: '导出',
				code: '0007_0006_0004_040_001'
			},
			'import-customer': {
				id: 'import-customer',
				label: '批量导入',
				code: '0007_0006_0004_030_006'
			}
		}
	}
}
