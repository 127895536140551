import type { App } from 'vue'
import initAppPlugin from './src/appPlugin'
import initUi from './src/ui'
import initUitls from './src/utils.js'
import initVueCommon from './src/vueCommon.js'

const install = (app: App): void => {
	app.use(initAppPlugin)
		.use(initUi)
		.use(initUitls)
		.use(initVueCommon)
}
export default {
	install
}
