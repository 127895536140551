import { computed, ref } from 'vue'
import type { WeightState } from '@/js/weigh'
import fxUitls from '@/js/utils'
import vuex from '@/store'

const connected = ref(false)
const isIntegratedScale = ref(false)
const isUsbScale = ref(false)
const rawWeight = ref(0)
const rawPeelWeight = ref(0)
const rawWeightLock = ref(false)
const lockWeight = ref(0)
const lockPeel = ref(0)
const rawRoundDown = ref(false)
const rawRoundUp = ref(false)
const rawTared = ref(false)
const rawStable = ref(false)
export const useWeight = () => {
	const resetWeightState = () => {
		rawWeight.value = 0
		rawPeelWeight.value = 0
		rawWeightLock.value = false
		lockWeight.value = 0
		lockPeel.value = 0
	}
	const setWeightState = (state: WeightState) => {
		rawWeight.value = state.weight
		rawPeelWeight.value = state.peelData
		rawTared.value = state.isTared
		rawStable.value = state.isStable
	}
	const weight = computed(() => {
		if (isWeightLock.value) {
			return lockWeight.value
		}
		if (rawRoundDown.value) {
			return roundDownHandler(rawWeight.value)
		}
		if (rawRoundUp.value) {
			return roundUpHandler(rawWeight.value)
		}
		if (vuex.getters.getWeightUnitLabel === 'g') {
			return rawWeight.value
		} else {
			return fxUitls.toFixedParseNumber(rawWeight.value, vuex.getters.getWieghtUnitDecimalPlaceValue)
		}
	})
	const roundDownWeight = computed(() => {
		if (isWeightLock.value) {
			return lockWeight.value
		}
		return roundDownHandler(rawWeight.value)
	})
	const roundUpWeight = computed(() => {
		if (isWeightLock.value) {
			return lockWeight.value
		}
		return roundUpHandler(rawWeight.value)
	})
	const weightUnitRatio = computed(() => vuex.getters.getWeightUnitRatio)
	const roundDownHandler = (weight: number) => {
		const roundDownType = vuex.getters.getRoundDownType
		const roundDownDecimalPlace = vuex.getters.getRoundDownDecimalPlace
		const unitWeight = ref<number>(0)
		if (roundDownType === '1') {
			unitWeight.value = fxUitls.toNumber(fxUitls.formatNumByPoint(getWeightByWeightUnit(weight), roundDownDecimalPlace))
		} else if (roundDownType === '2') {
			unitWeight.value = fxUitls.toFixedParseNumber(getWeightByWeightUnit(weight), roundDownDecimalPlace)
		} else {
			unitWeight.value = getWeightByWeightUnit(weight)
		}
		return fxUitls.toNumber(unitWeight.value / weightUnitRatio.value)
	}
	const roundUpHandler = (weight: number) => {
		return fxUitls.toFixedParseNumber(getWeightByWeightUnit(weight) / weightUnitRatio.value)
	}
	const peelWeight = computed(() => {
		if (isWeightLock.value) {
			return lockPeel.value
		}
		return rawPeelWeight.value
	})
	const grossWeight = computed(() => {
		return fxUitls.toFixedParseNumber(weight.value + peelWeight.value, vuex.getters.getWieghtUnitDecimalPlaceValue)
	})
	const isWeightLock = computed(() => rawWeightLock.value)
	const weightLockToggle = () => {
		lockWeight.value = weight.value
		lockPeel.value = peelWeight.value
		rawWeightLock.value = !rawWeightLock.value
	}
	const isTared = computed(() => rawTared.value)
	const isStable = computed(() => rawStable.value)
	const isRoundDown = computed(() => rawRoundDown.value)
	const roundDownToggle = () => {
		rawRoundDown.value = !rawRoundDown.value
		rawRoundUp.value = false
	}
	const isRoundUp = computed(() => rawRoundUp.value)
	const roundUpToggle = () => {
		rawRoundUp.value = !rawRoundUp.value
		rawRoundDown.value = false
	}
	const isIntegratedOrUsbScale = computed(() => {
		return isIntegratedScale.value || isUsbScale.value
	})
	const getWeightByWeightUnit = (weight: number): number => {
		return vuex.getters.getWeightValue(weight)
	}
	const weightUnitWeight = computed(() => {
		return getWeightByWeightUnit(weight.value)
	})
	const weightUnitPeelWeight = computed(() => {
		return getWeightByWeightUnit(peelWeight.value)
	})
	const weightUnitGrossWeight = computed(() => {
		return getWeightByWeightUnit(grossWeight.value)
	})
	return {
		connected,
		isIntegratedScale,
		isUsbScale,
		resetWeightState,
		setWeightState,
		weight,
		roundDownWeight,
		roundUpWeight,
		peelWeight,
		grossWeight,
		isWeightLock,
		weightLockToggle,
		isTared,
		isStable,
		isRoundDown,
		roundDownToggle,
		isRoundUp,
		roundUpToggle,
		isIntegratedOrUsbScale,
		getWeightByWeightUnit,
		weightUnitWeight,
		weightUnitPeelWeight,
		weightUnitGrossWeight
	}
}
