// 单据生成类型
import IN_STORE_FIELD, { InStoreFieldEnum } from './in_store'

export type BillFieldTypeKey = 'inStore'

export interface BillTypeField {
	[key: string]: {
		[key: string]: boolean
	}
}
type BillFieldEditConfig = {
	[k in BillFieldTypeKey]: {
		billField: BillTypeField
		billTypeKey: string
		billFieldEnum: unknown
	}
}
const billFieldEditConfig:BillFieldEditConfig = {
	inStore: {
		billField: IN_STORE_FIELD,
		billTypeKey: 'inStoreBillGenerateSubType',
		billFieldEnum: InStoreFieldEnum
	}
}
export default billFieldEditConfig
