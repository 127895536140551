import type { RequestConfig } from '@/js/api/dataSource'
import { get } from '@/js/api/dataSource'
export default {
	// 单据异常警告设置
	orderMistakeCheck: {
		// 查询单据异常警告设置
		getOrderMistakeCheck (type: string): RequestConfig {
			return get({ url: `archive/mistake_proofing_set/v1/${type}` })
		}
	}
}
