import { setStorage } from '../utils'
import type { State } from './state'
import fxUtils from '@/js/utils'
import QRCODE_SETTING from '@/config/qrcode_setting'
import type { BasketTareItem } from '@/models/weight'

export type QrcodeSetting = typeof QRCODE_SETTING

export function SET_SYSTEM<T extends Partial<State>> (state: T, loginer: T): void {
	for (const key in loginer) {
		state[key] = loginer[key]
		setStorage(key, loginer[key])
	}
}

export function SET_QRCODE_SETTING<T extends PartialPlus<State>> (state: T, qrcodeSetting: PartialPlus<QrcodeSetting>): void {
	const _qrcodeSetting: PartialPlus<QrcodeSetting> = fxUtils.deepClone(QRCODE_SETTING)
	Object.keys(qrcodeSetting).forEach((key: string) => {
		const settingItem = qrcodeSetting[key as keyof QrcodeSetting]
		Object.keys(settingItem!).forEach((detailKey: string) => {
			if (!_qrcodeSetting![key as keyof QrcodeSetting]) {
				_qrcodeSetting![key as keyof QrcodeSetting] = {}
			}
			_qrcodeSetting![key as keyof QrcodeSetting]![detailKey as keyof typeof settingItem] = settingItem![detailKey as keyof typeof settingItem]
		})
	})
	state.qrCodeSetting = _qrcodeSetting
	setStorage('qrCodeSetting', _qrcodeSetting)
}
export function SET_TARE<T extends Partial<State>> (state: T, tareItem: BasketTareItem): void {
	(state.basketTareList as BasketTareItem[]).push(tareItem)
	setStorage('basketTareList', state.basketTareList)
}
export function DEL_TARE_ITEM<T extends Partial<State>> (state: T, id: string): void {
	(state.basketTareList as BasketTareItem[]) = (state.basketTareList as BasketTareItem[]).filter(item => item.id !== id)
	setStorage('basketTareList', state.basketTareList)
}
