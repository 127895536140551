<template>
	<div class="fx-upload">
		<w-cell
			v-if="cellType==='cell'"
			:title="cellLabel"
			:value="currentCellText"
			:required="required"
			isLink
			@on-click="showModal"
		></w-cell>
		<div v-else class="wind-select__cell" @click="showModal">
			<span class="cell-text">{{currentCellText}}</span>
			<w-icon type="select-arrow" class="select-arrow-icon"></w-icon>
		</div>
		<a-modal
			v-model:visible="visible"
			:title="title"
			:bodyStyle="{
				width: '60vw',
				height: '41vw',
				'padding': '1vw 1vw 1vw 1vw',
				display: 'flex'
			}"
			centered
			width="60vw"
			class="fx-upload__modal"
		>
			<template #footer>
				<a-button key="submit" type="primary" class="confirm-btn" @click="handleOk">确定</a-button>
			</template>
			<w-upload
				:fileList="fileList"
				:maxCount="maxCount"
				:maxSize="maxSize"
				:uploadApi="uploadFn"
				:disabled="disabled"
				:uploadType="uploadType"
				@on-image-preview-click="onPreviewClick"
			></w-upload>
		</a-modal>
		<w-image-preview v-model:previewVisible="previewVisible" :previewUrl="previewUrl"></w-image-preview>
	</div>
</template>
<script lang="ts">
/**
 * Select by shang 2022/8/25
 * @desc Select 上传组件
 */
import { computed, defineComponent, ref } from 'vue'
import type { PropType } from 'vue'
import { getFxInstance } from '@/js/instance'
import { isCordova } from '@/config/env.config'
export default defineComponent({
	name: 'fx-upload',
	props: {
		value: {
			type: Array as PropType<{appendixUrl:string}[]>,
			default: () => {
				return []
			}
		},
		title: {
			type: String,
			default: '上传附件'
		},
		cellType: String,
		cellLabel: {
			type: String,
			default: ''
		},
		maxCount: {
			type: Number,
			default: 9
		},
		maxSize: {
			type: Number,
			default: 5
		},
		emptyText: {
			type: String,
			default: '暂无数据'
		},
		disabled: Boolean,
		required: Boolean
	},
	setup (props, { emit }) {
		const fxInstance = getFxInstance()
		const visible = ref(false)
		const previewVisible = ref(false)
		const previewUrl = ref('')
		const fileList = ref<{url:string}[]>([])
		let fileString = ''
		const currentCellText = computed(() => {
			return props.value.length === 0 ? '无' : props.value.length
		})
		const showModal = () => {
			fileList.value = props.value.map(item => {
				return { url: item.appendixUrl }
			})
			fileString = fileList.value.map(item => item.url).join(',')
			visible.value = true
		}
		const handleOk = () => {
			visible.value = false
			const currentFileString = fileList.value.map(item => item.url).join(',')
			if (currentFileString !== fileString) {
				const list = fileList.value.map(item => {
					return {
						appendixUrl: item.url
					}
				})
				emit('update:value', list)
				emit('change', list)
			}
		}
		const uploadType = isCordova() ? 'native' : 'web'
		const uploadFn = (file:File) => {
			const params = {
				file
			}
			return fxInstance.$fxApi('common.upload')({ data: params })
		}
		const onPreviewClick = function (url:string) {
			previewUrl.value = url
			previewVisible.value = true
		}
		return {
			visible,
			fileList,
			currentCellText,
			showModal,
			handleOk,
			uploadFn,
			onPreviewClick,
			previewVisible,
			previewUrl,
			uploadType
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.fx-upload__modal {
    .confirm-btn {
        width: 100%
    }
}
</style>
