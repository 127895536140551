import type { App } from 'vue'
import utilsArray from './src/array'
import utilsDate from './src/date'
import { fxDebug } from './src/debug'
import fxDebounce from './src/debounce'
import utilsType from './src/type'
import utilsFunc from './src/utils'
import utilsNumber from './src/number'
import utilsReg from './src/reg'
import utilsScrollTop from './src/scrollTop'
import utilsString from './src/string'
import { fxDataVerification } from './src/verification'
export const utils = {
	fxDebug,
	...fxDebounce,
	...utilsArray,
	...utilsDate,
	...utilsType,
	...utilsFunc,
	...utilsNumber,
	...utilsReg,
	...utilsScrollTop,
	...utilsString,
	fxDataVerification
}

const install = (app: App): void => {
	app.config.globalProperties.$fxUtils = utils
}

export default {
	install,
	...utils
}
