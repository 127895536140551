import { setStorage } from '../utils'
import fxUtils from '@/js/utils'

export function SET_ORDER_MISTAKE_CHECK_SETTING (state:Record<string, Record<string, object>>, res: Record<string, string> | Record<string, string>[]): void {
	if (fxUtils.isArray(res)) {
		state.appOrderMistakeCheckConfig = {}
		res.forEach(item => {
			state.appOrderMistakeCheckConfig[item.type] = fxUtils.JSONparse(item.data) || []
		})
	} else {
		state.appOrderMistakeCheckConfig[res.type] = fxUtils.JSONparse(res.data) || []
	}
	setStorage('appOrderMistakeCheckConfig', state.appOrderMistakeCheckConfig)
}
