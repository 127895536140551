
import type { App } from 'vue'
import $Message from './package/message'
import $MessageBox from './package/message-box'
import $Loading from './package/loading'
import AlphaIndex from './package/alpha-index'
import AppContainer from './package/app-container'
import AppPage from './package/app-page'
import Calendar from './package/calendar'
import CalendarDialog from './package/calendar-dialog'
import Cell from './package/cell'
import Clock from './package/clock'
import Empty from './package/empty'
import Header from './package/header'
import Icon from './package/icon'
import IconSvg from './package/icon-svg'
import Image from './package/image'
import ImagePreview from './package/image-preview'
import InputNumber from './package/input-number'
import Link from './package/link'
import RenderDom from './package/render-dom/index'
import ScrollLoadList from './package/scroll-load-list'
import SearchBar from './package/search-bar'
import Select from './package/select'
import SelectDropdown from './package/select-dropdown'
import SelectMulity from './package/select-mulity'
import SelectTab from './package/select-tab'
import SelectTabMulity from './package/select-tab-mulity'
import SelectTabCascade from './package/select-tab-cascade'
import SelectTag from './package/select-tag'
import SelectTree from './package/select-tree'
import Stepper from './package/stepper'
import Tag from './package/tag'
import TextareaDialog from './package/textarea-dialog'
import Upload from './package/upload'
import VirtualList from './package/virtual-list'

export type FxMessage = typeof $Message
export type FxMessageBox = typeof $MessageBox
export type FxLoading = typeof $Loading

const components = {
	'w-alpha-index': AlphaIndex,
	'w-app-container': AppContainer,
	'w-app-page': AppPage,
	'w-calendar': Calendar,
	'w-calendar-dialog': CalendarDialog,
	'w-cell': Cell,
	'w-clock': Clock,
	'w-empty': Empty,
	'w-header': Header,
	'w-icon': Icon,
	'w-icon-svg': IconSvg,
	'w-image': Image,
	'w-image-preview': ImagePreview,
	'w-input-number': InputNumber,
	'w-link': Link,
	'w-render-dom': RenderDom,
	'w-scroll-load-list': ScrollLoadList,
	'w-search-bar': SearchBar,
	'w-select': Select,
	'w-select-dropdown': SelectDropdown,
	'w-select-mulity': SelectMulity,
	'w-select-tab': SelectTab,
	'w-select-tab-mulity': SelectTabMulity,
	'w-select-tab-cascade': SelectTabCascade,
	'w-select-tag': SelectTag,
	'w-select-tree': SelectTree,
	'w-stepper': Stepper,
	'w-tag': Tag,
	'w-textarea-dialog': TextareaDialog,
	'w-upload': Upload,
	'w-virtual-list': VirtualList
}


// const initSvgIcon = () => {
// 	const req = import.meta.globEager('@/assets/images/icon/svg/**/*.svg')
// 	Object.keys(req).map(item => {
// 		return require(`@/assets/images/icon/svg${item.slice(1)}`)
// 	})
// }
type ComponentKeys = keyof typeof components
const install = (app: App): void => {
	Object.keys(components).forEach(key => {
		app.component(key, components[key as ComponentKeys])
	})
	// initSvgIcon()
	app.config.globalProperties.$fxMessage = $Message
	app.config.globalProperties.$fxLoading = $Loading
	app.config.globalProperties.$fxMessageBox = $MessageBox
}
export default {
	install,
	$fxMessage: $Message,
	$fxLoading: $Loading,
	$fxMessageBox: $MessageBox,
	...components
}
