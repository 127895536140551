<template>
	<div class="wind-alpha-index">
		<div
			v-for="item in alphaArr"
			:key="item"
			:class="{
				'is-active': formatterLetter(item)===activeLetter
			}"
			class="wind-alpha-index__letter-list-item"
			@click="onLetterClick(item)"
		>
			{{item}}
		</div>
	</div>
</template>
<script lang="ts">
/**
 * AlphaIndex by shang 2023/6/25
 * @desc AlphaIndex 字母索引
 */
import { defineComponent, ref, watch } from 'vue'
export default defineComponent({
	name: 'wind-alpha-index',
	props: {
		value: String
	},
	emits: ['update:value'],
	setup (props, { emit }) {
		const activeLetter = ref<string|undefined>('')
		watch(() => activeLetter.value, value => {
			emit('update:value', value)
		})
		watch(() => props.value, value => {
			activeLetter.value = value
		}, {
			immediate: true
		})
		const alphaArr = ref(['#', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'])
		const onLetterClick = (letter:string) => {
			activeLetter.value = formatterLetter(letter)
		}
		const formatterLetter = (letter:string) => {
			if (letter === '#') {
				return ''
			}
			return letter
		}
		return {
			activeLetter,
			alphaArr,
			onLetterClick,
			formatterLetter
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.wind-alpha-index {
	position: relative;
	overflow: auto;
	width: 26px;
	height: 100%;
	font-size: 14px;
	scrollbar-width: none;
	border-left: 1px solid #f2f3f5;
	&::-webkit-scrollbar {
		width: 0 !important;
	}
	&__letter-list-item {
		height: 30px;
		border-bottom: 1px solid #f2f3f5;
		text-indent: 4px;
		line-height: 30px;
		&.is-active {
			color: $fxDefaultColor;
		}
	}
}
</style>
