import { reactive } from 'vue'
import type { UnwrapNestedRefs } from 'vue'
import APP_ICON_CONFIG from '@/config/app_icon_config'

export const useAppIcon = (type: string | string[]): { [key: string]: UnwrapNestedRefs<string> } => {
	if (Array.isArray(type)) {
		const iconObj: Record<string, string> = {}
		type.forEach(item => {
			iconObj[item] = APP_ICON_CONFIG[item] || ''
		})
		return reactive(iconObj)
	} else {
		return reactive({
			[type]: APP_ICON_CONFIG[type] || ''
		})
	}
}
