import type { App } from 'vue'
import { fxApi } from '@/js/api'
import { fxCordova } from '@/js/cordova'
import type { VersionUpdate } from '@/models/version'
import vuex from '@/store'
import windUi from '@/components/wind-ui'
import { APP_UPDATE_URL } from '@/config/app_version_config'
class FxVersionVerification {
	getNewVersionData (): Promise<VersionUpdate> {
		return fxApi('common.getVersionUpdate').then((res: VersionUpdate) => {
			return Promise.resolve(res)
		})
	}

	versionSync (versionData: VersionUpdate): Promise<[string, VersionUpdate]> {
		return new Promise(resolve => {
			fxCordova.appVersion.checkVersion(versionData.hardwareVersion).then(res => {
				vuex.commit({
					type: 'SET_SYSTEM',
					hasNewVersion: res !== '0'
				})
				resolve([res, versionData])
			})
		})
	}

	versionUpdate ([updateType, versionData]: [string, VersionUpdate], forceFlag?: boolean) {
		return new Promise(resolve => {
			if (updateType === '0') {
				resolve(true)
			} else {
				if (versionData.forceUpdateFlag && forceFlag) {
					const options = {
						title: '发现新版本,请下载安装!',
						message: versionData.hardwareUpdateMsg || ''
					}
					windUi.$fxMessageBox.alert(options, () => {
						fxCordova.inappbrowser.open(APP_UPDATE_URL, '_system')
					})
				} else {
					const options = {
						title: '发现新版本,是否下载?',
						message: versionData.hardwareUpdateMsg || ''
					}
					windUi.$fxMessageBox.confirm(options).then(() => {
						fxCordova.inappbrowser.open(APP_UPDATE_URL, '_system')
					}).catch(() => {
						vuex.commit({
							type: 'SET_SYSTEM',
							ignoreVersionUpdate: true
						})
					})
				}
			}
		})
	}

	verCheck () {
		return this.getNewVersionData()
			.then(this.versionSync)
			.then(this.versionUpdate)
	}

	verCheckForce () {
		return this.getNewVersionData().then(this.versionSync).then(res => {
			return this.versionUpdate(res, true)
		})
	}
}

export const fxVersionVerification = new FxVersionVerification()
const install = (app: App): void => {
	app.config.globalProperties.$fxVersionVerification = fxVersionVerification
}

export default {
	install,
	fxVersionVerificationInstance: fxVersionVerification
}
