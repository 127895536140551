import type { App } from 'vue'

const postMessageKey = {
	exitApp: 'exitApp'
}
const app = 'scm8-pad'
const postMessageParent = (params: object) => {
	window.parent.postMessage(params, '*')
}

const exitApp = () => {
	postMessageParent({
		app,
		action: postMessageKey.exitApp
	})
}

export const iframePostMessage = {
	exitApp
}

const install = (app: App): void => {
	app.config.globalProperties.$fxIframePostMessage = iframePostMessage
}

export default {
	install,
	...iframePostMessage
}
