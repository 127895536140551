import type { App } from 'vue'
import scanBluetooth from './src/scanBluetooth'
import scale from './src/scale'
import print from './src/print'
import camera from './src/camera'
import appVersion from './src/app_version'
import cameraFloat from './src/cameraFloat'
import scanUsb from './src/scanUsb'
import inappbrowser from './src/inappbrowser'

export type CordovaInstance = {
	scanBluetooth: typeof scanBluetooth
	scale: typeof scale
	print: typeof print
	camera: typeof camera
	appVersion: typeof appVersion
	cameraFloat: typeof cameraFloat
	scanUsb: typeof scanUsb
	inappbrowser: typeof inappbrowser
}

export const fxCordova = {
	scanBluetooth,
	scale,
	print,
	camera,
	appVersion,
	cameraFloat,
	scanUsb,
	inappbrowser
}
const install = (app: App): void => {
	app.config.globalProperties.$fxCordova = fxCordova
}

export default {
	install,
	...fxCordova
}
