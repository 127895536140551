<template>
	<div class="wind-empty">
		<img class="wind-empty__icon" src="@/assets/images/icon/wind-ui/icon-order-empty.png">
		<div class="wind-empty__text">{{message}}</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'fx-empty',
	props: {
		message: {
			type: String,
			default: ''
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.wind-empty {
	flex: 1;
	&__icon {
		margin: 0 auto;
		margin-top: 100px;
		width: 190px;
		height: 135px;
	}
	&__text {
		text-align: center;
		color: $fxBlack1;
	}
}
</style>
