// 单据列表状态枚举
export enum weightUnitEnum {
	kg = '1',
	jin = '2',
	g = '3',
}

export default {
	// 称重器可选单位
	weightUnitList: [{
		type: 'kg',
		id: '1',
		label: 'KG',
		ratio: 1
	}, {
		type: 'jin',
		id: '2',
		label: '斤',
		ratio: 2
	}, {
		type: 'g',
		id: '3',
		label: 'g',
		ratio: 1000
	}],
	// 称重单位可选择的小数位数
	weightUnitDecimalPlaceList: [{
		id: '0',
		label: '0',
		value: 0
	}, {
		id: '1',
		label: '1',
		value: 1
	}, {
		id: '2',
		label: '2',
		value: 2
	}, {
		id: '3',
		label: '3',
		value: 3
	}],
	// 品项排序
	itemSortingSelectList: [{
		id: '1',
		label: '默认排序'
	}, {
		id: '2',
		label: '重量适配法排序'
	}],
	// 合批页明细展示
	batchDetailShowSelectList: [{
		id: '1',
		label: '展开明细'
	}, {
		id: '2',
		label: '收起明细'
	}],
	// 默认打开拍照悬浮窗
	cameraFloatAutoList: [{
		id: 1,
		label: '打开'
	}, {
		id: 0,
		label: '关闭'
	}],
	// 是否自动拍照
	automaticPhotographList: [{
		id: 1,
		label: '是'
	}, {
		id: 0,
		label: '否'
	}],
	// 称重器自动打印标记
	weightPrintAuto: [{
		id: 1,
		label: '是'
	}, {
		id: 0,
		label: '否'
	}],
	// 系统字体大小
	fontSizeTypeList: [{
		id: 'normal',
		label: '标准'
	}, {
		id: 'big',
		label: '大'
	}],
	// 称重品项抹零规则
	roundDownList: [{
		id: '1',
		label: '直接抹零'
	}, {
		id: '2',
		label: '四舍五入'
	}],
	// 称重抹零保留小数位数
	roundDownDecimalPlaceList: [{
		id: '0',
		label: '0',
		value: 0
	}, {
		id: '1',
		label: '1',
		value: 1
	}, {
		id: '2',
		label: '2',
		value: 2
	}]
}
