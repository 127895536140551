export const registerCordovaApi = (apiName: string, api: object, cb: Function, fail: Function): void => {
	try {
		if (!window[apiName]) {
			window[apiName] = api
		}
		cb()
	} catch (error: unknown) {
		fail()
	}
}
