export type FileType = 'file' | 'image' | 'word' | 'excel' | 'video'
const fileTypeMap:Record<FileType, string[]> = {
	file: ['.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.key', '.pages', '.numbers', '.pdf', '.jpg', '.jpeg', '.png', '.gif'],
	image: ['.jpg', '.jpeg', '.png', '.gif'],
	word: ['.doc', '.docx'],
	excel: ['.xls', '.xlsx'],
	video: ['.mp4', '.ogg', '.webm']
}

export default fileTypeMap
