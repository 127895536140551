<template>
	<div class="wind-cell" :class="{'wind-cell-required':required}" @click="onCellClick">
		<div class="wind-cell__title" :class="{'require-icon':required}">{{title}}</div>
		<slot name="value">
			<div class="wind-cell__value">{{value}}</div>
		</slot>
		<div class="link-arrow-container">
			<w-icon-svg v-if="isLink&&!disabled" class="cell-link-arrow" type="icon-cell-link-arrow"></w-icon-svg>
		</div>
	</div>
</template>
<script lang="ts">
/**
 * Cell by shang 2022/9/20
 * @desc 筛选条件-单元格 主要用于用户筛选条件时,提供条件单元格
 *
 * @param {title} [str] - 标题
 * @param {value}  [str]
 * @param {isLink}  [boolean]
 * @param {disabled}  [boolean]
 * @param {required}  [boolean]
 *
 * @event {on-click} 选择时触发
 * @event {on-clear} 点击clear按钮时触发
 */
import { defineComponent } from 'vue'
export default defineComponent({
	name: 'wind-cell',
	props: {
		value: [String, Number],
		title: String,
		isLink: Boolean,
		disabled: Boolean,
		required: Boolean
	},
	setup (props, { emit }) {
		const onCellClick = () => {
			if (props.disabled) {
				return false
			}
			emit('on-click')
		}
		return {
			onCellClick
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.wind-cell {
    display: flex;
    align-items: center;
    padding: 0 10px 0 18px;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #eeeeee;
    box-sizing: border-box;
    &-required {
        padding-left: 0;
    }
    &__title {
        flex: 5;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #595959;
    }
    .require-icon {
        &::before {
            position: relative;
            top: 4px;
            margin-right: 5px;
            margin-left: 5px;
            width: 3px;
            height: 16px;
            font-size: 20px;
            color: #ff5331;
            content: "*";
        }
    }
    &__value {
        overflow:hidden;
        text-align: right;
        text-overflow : ellipsis;
        white-space:nowrap;
        flex: 7;
    }
    .link-arrow-container {
        display: flex;
        margin-left: 8px;
        width: 12px;
        height: 20px;
    }
    .cell-link-arrow {
        width: 12px;
        color: #a7a8ab;
    }
}
</style>
