import PRODUCTION_MANAGE from './production-manage'
import STORE_MANAGE from './store-manage'
import SORTATION_MANAGE from './sortation-manage'
const labelPrintConfig = {
	'production-manage': PRODUCTION_MANAGE,
	'store-manage': STORE_MANAGE,
	'sortation-manage': SORTATION_MANAGE
}
type CreateLabelPrintConfigMap<T> = keyof T extends string ? {
	[P in keyof T as keyof T[P] extends string ? `${P}_${keyof T[P]}` : '']: T[P]
} : never
type CreateLabelPrintConfig<T> = keyof T extends string ? {
	[P in keyof T]: P extends `${infer Main}_${infer Sub}` ? Main extends string ? Sub extends keyof T[P] ? T[P][Sub] : never : never : never
} : never
export type LabelPrintConfig = CreateLabelPrintConfig<CreateLabelPrintConfigMap<typeof labelPrintConfig>>
export type GetLabelPrintConfig<T extends keyof LabelPrintConfig> = LabelPrintConfig[T]

export default labelPrintConfig
