<template>
	<div class="container__top">
		<slot></slot>
		<div class="list-btn-tools">
			<slot name="btns"></slot>
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
	name: 'fx-order-list-top'
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.container__top {
	display: flex;
	align-items: center;
	margin-left: 14px;
	height: 50px;
	background-color: $fxWhite;
	box-shadow: $fx-box-shadow-top;
	.list-btn-tools {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		flex: 1;
	}
}
</style>
