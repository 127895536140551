let focusKey: string | null = null

export default {
	setFocusKey (key: string | null) {
		focusKey = key
	},
	getFocusKey () {
		return focusKey
	}
}
