<template>
	<a-alert
		:message="message"
		:type="type"
		closable
		class="alert-message"
		@close="onClose"
	/>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'bannerAlter',
	props: {
		message: String,
		type: String
	},
	emits: ['on-close'],
	setup (props, { emit }) {
		const onClose = () => {
			emit('on-close')
		}
		return {
			onClose
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.alert-message {
	&.ant-alert-error {
		:deep(.ant-alert-message) {
			color: $fxRed;
		}
	}
	&.ant-alert-warning {
		:deep(.ant-alert-message) {
			color: $fxOrange1;
		}
	}
}
</style>
