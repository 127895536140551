export default {
	'order-required-setting': {
		id: 'order-required-setting',
		label: '单据必填设置',
		code: '0002_0007',
		child: {
			'edit-order-required': {
				id: 'edit-order-required',
				label: '编辑单据必填设置',
				code: '0002_0007_002_001'
			}
		}
	}
}
