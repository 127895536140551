/**
 * @name orderMistakeCheckConfig
 * @desc 单据异常警告设置——基础配置
 * @author cc
 * @date 2022/10/26
 */

import IN_STORE_ORDER from './src/inStoreOrder'
import OUT_STORE_ORDER from './src/outStoreOrder'
import TAKE_STOCK_ORDER from './src/takeStockOrder'
import STOCK_PLAN_ORDER from './src/stockPlanOrder'
import STOCK_ORDER from './src/stockOrder'
import MOVE_ORDER from './src/moveOrder'

export interface MistakeCheckItemType {
	id: string
	label: string
	selectedList: string[]
	mistakeCheckList: {
		id: string
		title: string
		mistakeType: string
	}[]
}

export type BillType = 'InStore' | 'OutStore' | 'MoveOrder' | 'TakeStockOrder' | 'StockOrder' | 'StockPlanOrder'

export const MISTAKE_CHECK_ITEM_CONFIG = {
	...IN_STORE_ORDER,
	...OUT_STORE_ORDER,
	...TAKE_STOCK_ORDER,
	...STOCK_PLAN_ORDER,
	...STOCK_ORDER,
	...MOVE_ORDER
}
