export default {
	'data-initialization': {
		id: 'data-initialization',
		label: '数据初始化',
		code: '0002_0005',
		connectDetails: ['system-setting_data-initialization.initialize-bill', 'system-setting_data-initialization.initialize-bill-archives', 'system-setting_data-initialization.initialize-business-bill'],
		child: {
			'initialize-bill': {
				id: 'initialize-bill',
				label: '初始化业务单据+方案',
				code: '0002_0005_080_001',
				controlledByAllModule: 'system-setting_data-initialization.initialize-bill-archives',
				decisionByAllModule: ['system-setting_data-initialization.initialize-business-bill']
			},
			'initialize-bill-archives': {
				id: 'initialize-bill-archives',
				label: '初始化系统数据',
				code: '0002_0005_080_002',
				decisionByAllModule: ['system-setting_data-initialization.initialize-bill', 'system-setting_data-initialization.initialize-business-bill']
			},
			'initialize-business-bill': {
				id: 'initialize-business-bill',
				label: '初始化业务单据',
				code: '0002_0005_080_003',
				connectModule: ['system-setting_data-initialization'],
				controlledByAllModule: 'system-setting_data-initialization.initialize-bill'
			}
		}
	}
}
