
import type { App } from 'vue'
import component from './OrderListTop.vue'
const install = (app: App): void => {
	app.component(component.name, component)
}

export default {
	install,
	component
}
