import SYSTEM_PARAMS_RESOLVER from '@/config/system_params_resolver'
import type systemState from './state'
import type { State } from './state'
import type { BasketTareItem } from '@/models/weight'
import fxUtils from '@/js/utils'
import fxTypeMiddleware from '@/js/typeMiddleware'


type SystemParamItem = {
	code: string
	disable: boolean
	id: string
	nickName: string
	paramCode: string
	paramName: string
	paramTypeCode: string
	paramTypeValue: string
	paramValue: string
	pointSize: number
	schema: string
	tenantId: number
	type: string
	value: string
}

type SystemResolver = typeof SYSTEM_PARAMS_RESOLVER
type SystemResolverValue = {
	[P in keyof SystemResolver]: ReturnType<SystemResolver[P]>
}
export const getSysPointSize = function (state: State): number {
	return state.sysPointSize
}
export const getSysMoneyPointSize = function (state: State): number {
	return state.sysMoneyPointSize
}
export const getSysPricePointSize = function (state: State): number {
	return state.sysPricePointSize
}
export const getTaxesList = function <T extends State> (state: State): T['taxesList'] {
	return state.taxesList
}
export const getSysParams = (state: typeof systemState) => {
	function getMessage<T extends keyof SystemResolverValue> (key: T): SystemParamItem
	function getMessage<T extends keyof SystemResolverValue, P extends keyof SystemParamItem> (key: T, prop: P): P extends 'value' ? SystemResolverValue[T] : SystemParamItem[P]
	function getMessage<T extends keyof SystemResolverValue, P extends keyof SystemParamItem> (key: T, prop?: P): SystemResolverValue[T] | SystemParamItem | SystemParamItem[P] {
		const sysParam: SystemParamItem = (state.sysParamsConfig as { [P in T]: SystemParamItem } || {})[key] || {}
		if (prop === 'value') {
			return (sysParam[prop] && SYSTEM_PARAMS_RESOLVER[key]((sysParam[prop] as string)!) as SystemResolverValue[T])
		}
		if (prop) {
			return sysParam[prop] as SystemParamItem[P]
		}
		return sysParam
	}
	return getMessage
}

const weightUnitList = fxTypeMiddleware.getCommonTypeList('weightUnitList')
const weightUnitDecimalPlaceList = fxTypeMiddleware.getCommonTypeList('weightUnitDecimalPlaceList')
const roundDownDecimalPlaceList = fxTypeMiddleware.getCommonTypeList('roundDownDecimalPlaceList')

export const getWeightUnitLabel = (state: typeof systemState) => {
	const weightUnit = weightUnitList.find(item => item.id === state.weightUnit)
	return weightUnit!.label
}
export const getWeightValue = (state: typeof systemState) => (weightValue: number) => {
	const weightUnit = weightUnitList.find(item => item.id === state.weightUnit)
	const weightUnitDecimalPlace = weightUnitDecimalPlaceList.find(item => item.id === state.weightUnitDecimalPlace)
	return fxUtils.toFixedParseNumber(weightUnit!.ratio * weightValue, weightUnitDecimalPlace!.value)
}
export const getWieghtUnitDecimalPlaceValue = (state: typeof systemState) => {
	const weightUnitDecimalPlace = weightUnitDecimalPlaceList.find(item => item.id === state.weightUnitDecimalPlace)
	return weightUnitDecimalPlace?.value
}
export const getWeightUnitRatio = (state: typeof systemState) => {
	const weightUnit = weightUnitList.find(item => item.id === state.weightUnit)
	return weightUnit!.ratio
}

export const getItemSortingTypeIsSortingByWeight = (state: typeof systemState) => {
	return state.itemSortingType === '2'
}
export const getBatchDetailShowType = (state: typeof systemState) => {
	return state.batchDetailShowType === '1'
}
export const getWeightPrintAuto = (state: typeof systemState) => {
	return !!state.weightAutoPrint
}

export const getCameraFloatAuto = (state: typeof systemState) => {
	return !!state.cameraFloatAuto
}
export const getIgnoreVersionUpdate = (state: typeof systemState) => {
	return state.ignoreVersionUpdate
}
export const getDeviceScaleName = (state: typeof systemState) => {
	return state.deviceScaleName
}
export const getDeviceScaleAddress = (state: typeof systemState) => {
	return state.deviceScaleAddress
}
export const getQrcodeSetting = (state: typeof systemState) => {
	return state.qrCodeSetting
}
export const getRoundDownType = (state: typeof systemState) => {
	return state.roundDown
}
export const getRoundDownDecimalPlace = (state: typeof systemState) => {
	const roundDownDecimalPlace = roundDownDecimalPlaceList.find(item => item.id === state.roundDownDecimalPlace)
	return roundDownDecimalPlace?.value
}
export const getBasketTareList = function (state: State): BasketTareItem[] {
	return state.basketTareList
}
