<template>
	<a-config-provider :locale="zhCN">
		<!-- <Transition :name="transitionName" mode="out-in"> -->
		<listenForCard></listenForCard>
		<router-view v-slot="{ Component }">
			<keep-alive :include="keepAlives">
				<component :is="Component"/>
			</keep-alive>
		</router-view>
		<!-- </Transition> -->
	</a-config-provider>
</template>
<script lang="ts">
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import { defineComponent, ref, watch, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { getFxInstance } from '@/js/instance'
import { useStore } from '@/store'
import { useWeight } from '@/js/hooks'
import keepAlives from '@/config/keep_alive_config.js'
import listenForCard from './AppListenForCard.vue'
import 'tdesign-vue-next/es/style/index.css'

dayjs.locale('zh-cn')
export default defineComponent({
	components: {
		listenForCard
	},
	setup () {
		onMounted(() => {
			syncAppVersion()
			setDeviceInfo()
			fxInstance.$fxStyleDynamic.setFontSizeType()
		})
		const fxInstance = getFxInstance()
		const route = useRoute()
		const store = useStore()
		const { setWeightState } = useWeight()
		const transitionName = ref('page-next')
		const pathHistory: string[] = []
		watch(() => route.path, (to, from) => {
			const len = pathHistory.length - 1
			if (len >= 0 && pathHistory[len] === to) {
				pathHistory.pop()
				transitionName.value = 'page-fade-in-left'
			} else {
				pathHistory.push(from)
				transitionName.value = 'page-fade-in-right'
			}
		})
		const show = ref(true)
		fxInstance.$fxWeigh.onListenerState(setWeightState)
		fxInstance.$fxPrint.zoneLabelPrinter()
		fxInstance.$fxCameraFloat.init()
		const setDeviceInfo = () => {
			return fxInstance.$fxWeigh.initWeighDevice()
		}
		const syncAppVersion = () => {
			return store.dispatch('syncSystemAppVersion')
		}
		return {
			dayjs,
			zhCN,
			transitionName,
			show,
			keepAlives
		}
	}
})
</script>
<style lang="scss">
.container {
	height: 100%;
}

#app {
	position: fixed;
	right: 0;
	left: 0;
	height: 100%;
}

.page-fade-in-right-enter-active {
	animation: fadein-right .5s;
}

.page-fade-in-left-enter-active {
	animation: fadein-left .5s;
}

@keyframes fadein-right {
	0% {
		opacity: 0;
		transform: translateX(100%);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes fadein-left {
	0% {
		opacity: 0;
		transform: translateX(-100%);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

.t-table {
	color: #fff !important;
}

.t-dialog__footer {
	text-align: center !important;
}

.t-loading__overlay {
	background: #3A3A3B69 !important;
}

</style>
