<template>
	<div class="fx-print-tools" :class="{
		'is-disabled': disabled
	}">
		<div class="tools-space">
			<w-icon-svg type="icon-print-btn" class="tools-space-btn" @click="onPrintClick"></w-icon-svg>
		</div>
	</div>
</template>
<script lang="ts">
/**
 * fxPrintTools by shang 2022/8/25
 * @desc fxPrintTools 打印工具栏
 */
import { defineComponent } from 'vue'
export default defineComponent({
	name: 'fx-print-tools',
	props: {
		disabled: Boolean
	},
	setup (props, { emit }) {
		const onPrintClick = () => {
			emit('on-print-click')
		}
		return {
			onPrintClick
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.fx-print-tools {
    display: flex;
	min-width:70px;
    height: 36px;
    border: 1px solid $fxDefaultColor;
    border-radius: 20px;
    text-align:center;
    color: $fxDefaultColor;
    line-height: 36px;
	.tools-space {
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: center;
	}
    .tools-space-btn {
        width: 20px;
        height: 20px;
        color: $fxDefaultColor;
    }
    &.is-disabled {
        border-color: #d9d9d9;
        color: rgb(0 0 0 / 25%);
        background-color: #f5f5f5;
        .tools-space-btn {
            color: #d9d9d9;
        }
    }
}
</style>
