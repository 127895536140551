import type { App } from 'vue'
import vuex from '@/store'
const stylusMapping = {
	fontSizeType: 'data-font-size-type'
}

const setStyle = function (type: keyof typeof stylusMapping, value: string) {
	const typeName = stylusMapping[type]
	const typeValue = window.document.documentElement.getAttribute(typeName)
	if (typeValue === value) {
		return false
	}
	window.document.documentElement.setAttribute(typeName, value)
}

const getStyle = function (type: keyof typeof stylusMapping) {
	const typeName = stylusMapping[type]
	return window.document.documentElement.getAttribute(typeName)
}

const setFontSizeType = function (type = vuex.getters.getFontSizeType) {
	setStyle('fontSizeType', type)
}

export const styleDynamic = {
	setStyle,
	getStyle,
	setFontSizeType
}
export type StyleDynamic = typeof styleDynamic

const install = (app: App): void => {
	app.config.globalProperties.$fxStyleDynamic = styleDynamic
}
export default {
	install,
	...styleDynamic
}
