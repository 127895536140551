// cordova-plugin-scale
import windUi from '@/components/wind-ui'
import utils from '@/js/utils'
import { registerCordovaApi } from './register'
import printBridge from './print_bridge'
import type { UsbDevice } from './scanUsb'
export interface PrintInstance {
	connectBlueToothPrinter: (address: string, name: string) => Promise<string>
	printLabel: (labelStyleXml: string, labelData: string, labelWidth: number, labelHeight: number, printNum: number) => Promise<boolean>
}

const log = (msg: string) => {
	return utils.fxDebug('cordova-api')(msg)
}

const connectBlueToothPrinter = function (address: string, name: string): Promise<string> {
	return new Promise(resolve => {
		registerCordovaApi('PrinterPlugin', printBridge, () => {
			window.PrinterPlugin.connectBluetoothPrinter(
				address,
				name,
				function () {
					windUi.$fxMessage.success('打印机连接成功')
					resolve(name)
				},
				function () {
					windUi.$fxMessage.error('连接失败')
				}
			)
		}, () => {
			log('浏览器环境下无法使用print.connectBlueToothPrinter功能')
			windUi.$fxMessage.error('连接失败')
		})
	})
}
const printLabel = function (labelStyleXml: string, labelData: string, labelWidth: number, labelHeight: number, printNum: number): Promise<boolean> {
	return new Promise(resolve => {
		registerCordovaApi('PrinterPlugin', printBridge, () => {
			window.PrinterPlugin.printLabel(
				labelStyleXml,
				labelData,
				labelWidth,
				labelHeight,
				printNum,
				function () {
					windUi.$fxMessage.success('打印成功')
					resolve(true)
				},
				function () {
					windUi.$fxMessage.error('打印失败')
				}
			)
			resolve(true)
		}, () => {
			log('浏览器环境下无法使用print.printLabel功能')
			windUi.$fxMessage.error('打印失败')
		})
	})
}
const connectUsbPrinter = function (deviceInfo: UsbDevice): Promise<UsbDevice> {
	return new Promise(resolve => {
		registerCordovaApi('PrinterPlugin', printBridge, () => {
			window.PrinterPlugin.connectUsbPrinter(
				deviceInfo,
				function () {
					windUi.$fxMessage.success('打印机连接成功')
					resolve(deviceInfo)
				},
				function () {
					windUi.$fxMessage.error('连接失败')
				}
			)
		}, () => {
			log('浏览器环境下无法使用print.connectUsbPrinter功能')
			windUi.$fxMessage.error('连接失败')
		})
	})
}

const connectZoneLabelPrinter = function (): Promise<boolean> {
	return new Promise(resolve => {
		registerCordovaApi('PrinterPlugin', printBridge, () => {
			window.PrinterPlugin.connectZoneLabelPrinter(
				function () {
					windUi.$fxMessage.success('打印机连接成功')
					resolve(true)
				},
				function () {}
			)
		}, () => {
			log('浏览器环境下无法使用print.connectZoneLabelPrinter')
		})
	})
}
export default {
	connectBlueToothPrinter,
	printLabel,
	connectUsbPrinter,
	connectZoneLabelPrinter
}
