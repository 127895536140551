import PRODUCTION_PROCESSING from './production-processing'
import PRODUCTION_PLAN from './production-plan'
import PICKING_LIST from './picking-list'
import PROCESS_TASK from './process-task/index'
import MACHINING_ORDER from './machining-order'
import DEVICE_INFORMATION from './device-information'
import PRODUCTION_BOARD from './production-board'
import LOG_WATCH from './log-watch'
import DOSING_DEFAULT_HOUSE from './dosing-default-house'

export default {
	'production-manage': {
		id: 'production-manage',
		label: '生产管理',
		code: '0010',
		child: {
			...PRODUCTION_PROCESSING,
			...PRODUCTION_PLAN,
			...PICKING_LIST,
			...PROCESS_TASK,
			...MACHINING_ORDER,
			...DEVICE_INFORMATION,
			...PRODUCTION_BOARD,
			...LOG_WATCH,
			...DOSING_DEFAULT_HOUSE
		}
	}
}
