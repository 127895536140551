import { defineComponent } from 'vue'
export default defineComponent({
	name: 'w-render-dom',
	props: {
		render: Function,
		backValue: Object
	},
	setup (props) {
		return () => props.render!(props.backValue)
	}
})
