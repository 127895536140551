import type { RequestConfig } from '@/js/api/dataSource'
import { get, post, put, del } from '@/js/api/dataSource'
import fxCommon from '@/js/common'
import { EMPTY_TAB_DATA } from '@/config/api_config/common'
const API_MODULE = 'outStore'
export default {
	// 出库单
	outStore: {
		// {{{ list页面筛选条件
		// 获取出库方tab下拉选择数据
		getOrganSelectTabList (): RequestConfig {
			// 出库方——‘机构’、‘配送中心’
			const params = { organTypeList: ['2', '1'] }
			return post({ url: 'archive/organ/v1/manage/simple/tab', data: params })
		},
		// 获取入库方tab下拉选择数据
		getOtherOrganSelectTabList (): RequestConfig {
			// 入库方——‘机构’、‘配送中心’、‘供货商’、‘客户’
			const params = { organTypeList: ['2', '1', '5', '6'] }
			return post({
				url: 'archive/organ/v1/manage/simple/tab',
				data: params,
				success: res => {
					res.push(EMPTY_TAB_DATA)
					return res
				}
			})
		},
		// 分页查询-获取出库仓库下拉列表(tab分页数据结构)
		getOutHouseSelect (organIdList: string[]): RequestConfig {
			const params = {
				organTypeList: [2, 1],
				organIdList
			}
			return post({ url: 'archive/house/v1/manage/simple/tab', data: params })
		},
		// }}}
		// {{{ 出库单主表
		// 获取出库单主表
		getBillList (): RequestConfig {
			return post({ url: 'bill/pad/out_store/v1/page' })
		},
		// 批量删除
		delMulity (): RequestConfig {
			return put({ url: 'bill/pad/out_store/v1/delete', successToast: true })
		},
		// 批量提交
		submitMulity (): RequestConfig {
			return put({ url: 'bill/pad/out_store/v1/submit', successToast: true })
		},
		// 批量退回
		returnMulity (): RequestConfig {
			return put({ url: 'bill/pad/out_store/v1/back', successToast: true })
		},
		// 批量审核
		checkMulity (): RequestConfig {
			return put({ url: 'bill/pad/out_store/v1/check', successToast: true, errorToast: false })
		},
		// 批量反审
		uncheckMulity (): RequestConfig {
			return put({ url: 'bill/pad/out_store/v1/uncheck', successToast: true, errorToast: false })
		},
		// 批量分批并审核
		splitCheckMulity (): RequestConfig {
			return put({ url: 'bill/pad/out_store/v1/split_check', successToast: true, errorToast: false })
		},
		// 批量复审
		recheckMulity (): RequestConfig {
			return put({ url: 'bill/pad/out_store/v1/recheck', successToast: true })
		},
		// 批量取消复审
		unrecheckMulity (): RequestConfig {
			return put({ url: 'bill/pad/out_store/v1/un_recheck', successToast: true })
		},
		// }}}
		// {{{ 新增出库单
		// 获取出库单类型列表--过滤掉报损出库
		getBillTypeSelectList (): RequestConfig {
			return get({
				url: 'bill/out_store/v1/types'
			})
		},
		// 获取机构列表
		getOrganList (): RequestConfig {
			return post({
				url: 'archive/mobile/organ/v1/in_store/organ_list',
				data: {
					flag: 'pad'
				}
			})
		},
		// 获取入库机构列表
		getOtherOrganList (): RequestConfig {
			return post({
				url: 'archive/mobile/organ/v1/in_store/organ_list'
			})
		},
		// 获取仓库列表
		getStoreList (organId: string): RequestConfig {
			return post({ url: `archive/bill/house/v1/organ/${organId}/houseList` })
		},
		// 获取经办人列表
		getAgentList (): RequestConfig {
			return post({ url: 'archive/bill/employee/v1/operator_list' })
		},
		// 根据单据种类获取可使用单据业务类型
		getBillDetailBizTypeSelect (type: string): RequestConfig {
			return post({ url: `archive/bill/bill_biz_type/v1/bill_type/101/${type}`, loading: false })
		},
		// 新增出库单
		addBill (): RequestConfig {
			return post({
				url: 'bill/pad/out_store/v1',
				success: res => fxCommon.normalizationBillUpdateVersion(API_MODULE, res)
			})
		},
		// }}}
		// {{{ 称重出库中
		// 继续验收-查询品项列表--分未添加、已添加
		getItemList (billId: string, houseId: string): RequestConfig {
			return get({
				url: `bill/pad/out_store/v1/${billId}/weigh/${houseId}`,
				success: res => fxCommon.normalizationBillUpdateVersion(API_MODULE, res)
			})
		},
		// 继续验收-查询品项列表——分未验收、已验收
		getDetailList (billId: string): RequestConfig {
			return get({
				url: `bill/pad/out_store/v1/${billId}/weigh`,
				success: res => fxCommon.normalizationBillUpdateVersion(API_MODULE, res)
			})
		},
		// 新增出库单明细
		addDetail (billId: string): RequestConfig {
			return post({
				url: `bill/pad/out_store/${billId}/detail/v1`,
				successToast: true,
				data: { ...fxCommon.getBillDetailUpdateVersion(API_MODULE, billId), checkAccept: 1 },
				success: res => fxCommon.normalizationBillDetailUpdateVersion(API_MODULE, res, billId)
			})
		},
		// 保存出库单明细
		saveDetail (billId: string, detailId: string): RequestConfig {
			return put({
				url: `bill/pad/out_store/${billId}/detail/v1/${detailId}`,
				successToast: true,
				data: { ...fxCommon.getBillDetailUpdateVersion(API_MODULE, billId), checkAccept: 1 },
				success: res => fxCommon.normalizationBillDetailUpdateVersion(API_MODULE, res, billId)
			})
		},
		// 删除出库明细
		deleteDetails (billId: string, id: string): RequestConfig {
			return del({
				url: `bill/pad/out_store/${billId}/detail/v1/${id}`,
				successToast: true
			})
		},
		// 明细清空
		clearDetails (billId: string): RequestConfig {
			return del({ url: `bill/out_store/${billId}/detail/v1/clear` })
		},
		// 根据品项查询单位列表
		getUnitList (itemId: string, billId: string): RequestConfig {
			return post({ url: `bill/out_store/${billId}/detail/v1/unitList/${itemId}` })
		},
		// }}}
		// {{{ 出库单详情
		// 获取业务类型
		getBusinessTypeList (billType: string): RequestConfig {
			return post({ url: `archive/bill/bill_biz_type/v1/bill_type/102/${billType}` })
		},
		// 单据详情
		getBillDetail (billId: string): RequestConfig {
			return get({
				url: `bill/pad/out_store/v1/${billId}`,
				success: res => fxCommon.normalizationBillUpdateVersion(API_MODULE, res)
			})
		},
		// 批量设置
		setMulity (billId: string): RequestConfig {
			return put({
				url: `bill/pad/out_store/${billId}/detail/v1/set`,
				loading: false,
				successToast: true,
				data: {
					...fxCommon.getBillDetailUpdateVersion(API_MODULE, billId)
				}
			})
		},
		// 明细查询价格
		priceList (billId: string): RequestConfig {
			return post({ url: `bill/pad/out_store/${billId}/detail/v1/item/price`, loading: false })
		},
		// 明细查询库存
		stockAmount (billId: string, organId: string): RequestConfig {
			return post({ url: `bill/out_store/${billId}/detail/v1/${organId}/stock/amount`, loading: false })
		},
		// 品项查询库存
		itemStockAmount (organId: string): RequestConfig {
			return post({ url: `bill/pad/out_store/v1/${organId}/stock/amount`, loading: false })
		},
		// 获取出库原因列表
		getOutReasonList (id: string): RequestConfig {
			return get({ url: `archive/out_reason/v1/list/${id}`, loading: false })
		},
		// 保存单据
		saveBill (billId: string): RequestConfig {
			return put({
				url: `bill/pad/out_store/v1/${billId}`,
				successToast: true,
				data: {
					...fxCommon.getBillUpdateVersion(API_MODULE, billId),
					...fxCommon.getTenantIdParam()
				},
				success: res => fxCommon.normalizationBillUpdateVersion(API_MODULE, res)
			})
		},
		// 删除单据
		deleteBill (billId: string): RequestConfig {
			return put({ url: `bill/pad/out_store/v1/${billId}/delete`, successToast: true })
		},
		// 提交审核校验验收明细
		verifyBillDetails (billId: string): RequestConfig {
			return get({ url: `bill/pad/out_store/v1/${billId}/verify` })
		},
		// 提交单据
		submitBill (billId: string): RequestConfig {
			return put({ url: `bill/pad/out_store/v1/${billId}/submit`, successToast: true })
		},
		// 退回单据
		returnBill (billId: string): RequestConfig {
			return put({ url: `bill/pad/out_store/v1/${billId}/back`, successToast: true })
		},
		// 审核单据
		checkBill (billId: string): RequestConfig {
			return put({ url: `bill/pad/out_store/v1/${billId}/check`, successToast: true, errorToast: false })
		},
		// 反审单据
		uncheckBill (billId: string): RequestConfig {
			return put({ url: `bill/pad/out_store/v1/${billId}/uncheck`, successToast: true, errorToast: false })
		},
		// 分批并审核单据
		splitCheckBill (billId: string): RequestConfig {
			return put({ url: `bill/pad/out_store/v1/${billId}/split_check`, successToast: true, errorToast: false })
		},
		// 复审单据
		recheckBill (): RequestConfig {
			return put({ url: 'bill/pad/out_store/v1/recheckOne', successToast: true })
		},
		// 取消复审单据
		unrecheckBill (billId: string): RequestConfig {
			return put({ url: `bill/pad/out_store/v1/${billId}/un_recheck`, successToast: true })
		},
		// }}}
		// {{{ 防错校验
		// 总体查询
		checkMistake (billId: string, flag = false): RequestConfig {
			return post({
				url: `bill/pad/out_store/v1/mistake_proofing/${billId}?error_check=${flag}`,
				success: (res) => {
					fxCommon.syncBillVersionByMistakeDetails(API_MODULE, billId, res)
					return res
				}
			})
		},
		// 单价差异
		getDiffPrice (billId: string): RequestConfig {
			return post({
				url: `bill/pad/out_store/v1/mistake_proofing/${billId}?error_check=false`,
				data: ['DiffPrice'],
				loading: false,
				success: (res) => {
					fxCommon.syncBillVersionByMistakeDetails(API_MODULE, billId, res)
					return res
				}
			})
		},
		// 出库数量为0查询
		getZeroAmount (billId: string): RequestConfig {
			return post({
				url: `bill/pad/out_store/v1/mistake_proofing/${billId}?error_check=false`,
				data: ['ZeroAmount'],
				loading: false,
				success: (res) => {
					fxCommon.syncBillVersionByMistakeDetails(API_MODULE, billId, res)
					return res
				}
			})
		},
		// 查询重复品项的明细
		getRepeat (billId: string): RequestConfig {
			return post({
				url: `bill/pad/out_store/v1/mistake_proofing/${billId}?error_check=false`,
				data: ['Repeat'],
				loading: false,
				success: (res) => {
					fxCommon.syncBillVersionByMistakeDetails(API_MODULE, billId, res)
					return res
				}
			})
		},
		// 出库仓库未关联品项查询
		getRel (billId: string): RequestConfig {
			return post({
				url: `bill/pad/out_store/v1/mistake_proofing/${billId}?error_check=false`,
				data: ['RelAllGoods'],
				loading: false,
				success: (res) => {
					fxCommon.syncBillVersionByMistakeDetails(API_MODULE, billId, res)
					return res
				}
			})
		},
		// 出库数量为负库存
		getStockFkc (billId: string): RequestConfig {
			return post({
				url: `bill/pad/out_store/v1/mistake_proofing/${billId}?error_check=false`,
				data: ['StockFkc'],
				loading: false,
				success: (res) => {
					fxCommon.syncBillVersionByMistakeDetails(API_MODULE, billId, res)
					return res
				}
			})
		},
		// 查询配货标记
		getDistributionMarkNull (billId: string): RequestConfig {
			return post({
				url: `bill/pad/out_store/v1/mistake_proofing/${billId}?error_check=false`,
				data: ['DistributionMarkNull'],
				loading: false,
				success: (res) => {
					fxCommon.syncBillVersionByMistakeDetails(API_MODULE, billId, res)
					return res
				}
			})
		},
		// 与最新价格一致
		agreement (billId: string): RequestConfig {
			return put({ url: `bill/out_store/${billId}/detail/v1/sync_latest_price`, successToast: true })
		},
		// }}}
		getInDepartmentList (organId: string, itemId: string[]): RequestConfig {
			const data = {
				organId,
				itemIds: itemId
			}
			return post({ url: 'archive/bill/house/v1/out/bill/inDepartmentList', data })
		},
		// 修改单据类型为部门领出时候 将明细 部分数据置成0
		clearDetailMoney (id: string): RequestConfig {
			return post({ url: `/bill/out_store/${id}/detail/v1/batchUpdateOutStoreDetail`, loading: false })
		},
		// 明细清空入库仓库
		clearDetailInHouse (id: string): RequestConfig {
			return put({ url: `/bill/out_store/v1/${id}/clear/in/house`, loading: false })
		}
	}
}
