// 出库单生成类型
import type { BillGenerateField } from './type'
export default {
	/**
	 * @name outStoreBillGenerateType
	 * @desc 出库单生成类型
	 * @author shw
	 * @date 2021/9/10
	 */
	outStoreBillGenerateType: [{
		id: 0,
		type: 'sale',
		label: '销售出库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.billType}` === '2001'
		}
	}, {
		id: 1,
		type: 'other',
		label: '其他出库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.billType}` === '2002'
		}
	}, {
		id: 2,
		type: 'break',
		label: '报损出库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.billType}` === '2003'
		}
	}, {
		id: 3,
		type: 'stockLoss',
		label: '盘亏出库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.billType}` === '2004'
		}
	}, {
		id: 4,
		type: 'diff',
		label: '差异出库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.billType}` === '2005'
		}
	}, {
		id: 5,
		type: 'move',
		label: '调拨出库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.billType}` === '2006'
		}
	}, {
		id: 6,
		type: 'stockProfit',
		label: '盘盈出库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.billType}` === '2007'
		}
	}, {
		id: 7,
		type: 'normal-sub',
		label: '普通出库单',
		rule (organDetails: BillGenerateField): boolean {
			return organDetails.autoType === 0
		}
	}, {
		id: 8,
		type: 'assignStraight-sub',
		label: '直发分派出库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.subBillType}` === '2001-2'
		}
	}, {
		id: 9,
		type: 'assignCross-sub',
		label: '越库分派出库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.subBillType}` === '2001-3'
		}
	}, {
		id: 10,
		type: 'amount-sub',
		label: '数量出库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.subBillType}` === '2005-4'
		}
	}, {
		id: 11,
		type: 'money-sub',
		label: '金额出库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.subBillType}` === '2005-5'
		}
	}, {
		id: 12,
		type: 'offset',
		label: '冲减出库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.billType}` === '2009'
		}
	}, {
		id: 13,
		type: 'saleReturnStoreAmount',
		label: '销售返库数量出库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.subBillType}` === '2008-1'
		}
	}, {
		id: 14,
		type: 'overcharge-sub',
		label: '超收生成的出库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.subBillType}` === '2009-1'
		}
	}, {
		id: 15,
		type: 'push-to-nc-outStore',
		label: '推送给nc成功的出库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.sendTtjNcFlag}` === '1'
		}
	}, {
		id: 16,
		type: 'departmentPick',
		label: '部门领出单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.billType}` === '2101'
		}
	}, {
		id: 17,
		type: 'los-sub',
		label: '一键报损生成的出库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.subBillType}` === '2010-1'
		}
	}, {
		id: 18,
		type: 'assignCross-sub-inStore-sub',
		label: '越库入库后生成的卖方的出库单',
		rule (organDetails: BillGenerateField): boolean {
			return `${organDetails.sourceBillType}` === 'InStore'
		}
	}]
}
