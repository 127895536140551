<template>
	<svg
		:class="['wind-icon-svg', ...classes]"
		:style="iconStyle"
		@click="onIconClick"
	>
		<use :xlink:href="iconType"></use>
	</svg>
</template>
<script lang="ts">
/**
 * IconSvg by shw
 * @desc IconSvg 图标
 * @params [String] type 内置图标类型
 * @params [String] imgUrl 自定义图标url
 * @params [String] width 图标宽
 * @params [String] height 图标高
 * @params [String] size 图标尺寸(normal, mini)
 * @params [Boolean] stop 阻止点击冒泡
 * @params [Boolean] stop 阻止冒泡
 * @params [Boolean] pointer 鼠标样式
 * @params [Boolean] verticalAlign 垂直居中
 * @params [Boolean] svg 使用svg的icon
 * @params [Boolean] disabled 禁用
 */
import { defineComponent, computed } from 'vue'
export default defineComponent({
	name: 'w-icon-svg',
	props: {
		type: String,
		width: String,
		height: String,
		size: {
			type: String,
			default: 'normal'
		},
		stop: Boolean,
		pointer: Boolean,
		verticalAlign: Boolean,
		disabled: Boolean
	},
	emits: ['click'],
	setup (props, { emit }) {
		const onIconClick = function (event: Event) {
			if (props.stop) {
				event.stopPropagation()
			}
			if (props.disabled) {
				return false
			}
			if ((event as Event).type === 'click') {
				emit('click', event)
			}
		}
		const iconStyle = computed(() => {
			return {
				width: props.width,
				height: props.height
			}
		})
		const classes = computed(() => {
			return [`wind-icon__size-${props.size}`, {
				'is-pointer': props.pointer,
				'is-vertical-align': props.verticalAlign,
				'is-disabled': props.disabled
			}]
		})
		const iconType = computed(() => {
			return `#fx-${props.type}`
		})
		return {
			onIconClick,
			classes,
			iconStyle,
			iconType
		}
	}
})
</script>
<style lang="scss" scoped>
.wind-icon-svg {
    display:inline-block;
    overflow: hidden;
    width: 20px;
    height: 20px;
    fill: currentcolor;
    &__size-mini {
        width: 12px;
        height: 12px;
    }
    &.is-pointer {
        cursor: pointer;
    }
    &.is-vertical-align {
        vertical-align: middle;
    }
    &.is-disabled {
        filter: grayscale(100%);
        cursor: not-allowed;
    }
}
</style>
