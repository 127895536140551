export default {
	// 分拣方法
	sortingMethod: [{
		id: 'seddings',
		type: 'seddings',
		label: '播种法分拣'
	}, {
		id: 'directSeeding',
		type: 'directSeeding',
		label: '直接播种法分拣'
	}],
	// 分拣方式
	sortingMode: [{
		id: 'flat',
		type: 'flat',
		label: '平板端分拣'
	}],
	// 分拣策略
	sortingStrategy: [{
		id: 'inOrganType',
		type: 'inOrganType',
		label: '按入库方分拣'
	}, {
		id: 'inHouseType',
		type: 'inHouseType',
		label: '按入库仓库分拣'
	}],
	// 任务分派方式
	allocationType: [{
		id: 'assign',
		type: 'assign',
		label: '指派'
	}, {
		id: 'claim',
		type: 'claim',
		label: '认领'
	}]
}
