import { createApp, nextTick } from 'vue'
import type { App, ComponentPublicInstance } from 'vue'
import Loading from './Loading.vue'
let app: App | null = null
let vm: ComponentPublicInstance | null = null
let divEl: HTMLDivElement | null = null
const createLoading = (text?: string) => {
	if (!divEl) {
		divEl = document.createElement('div')
		document.body.appendChild(divEl)
	}
	if (!vm) {
		app = createApp(Loading, { text })
		vm = app.mount(divEl)
	} else {
		(vm as unknown as { updateLoadingText: Function }).updateLoadingText(text)
	}
}
const show = (text?: string): void => {
	createLoading(text)
}
const close = (): void => {
	nextTick(() => {
		app?.unmount()
		vm = null
	})
}
export default {
	show,
	close
}
