// 应用icon图标配置
interface IconMap { [key: string]: string }
const getImageUrl = (name: string) => {
	const path = `/src/assets/${name.replace(/^\@\/assets\//, '')}`
	const modules = import.meta.globEager('/src/assets/images/icon/common/*')
	return (modules[path] as { default: string }).default
}
const iconList: IconMap = {
	stateAll: getImageUrl('@/assets/images/icon/common/icon-state-all.png'),
	stateAllActive: getImageUrl('@/assets/images/icon/common/icon-state-all-active.png'),
	stateUnsubmit: getImageUrl('@/assets/images/icon/common/icon-state-unsubmit.png'),
	stateUnsubmitActive: getImageUrl('@/assets/images/icon/common/icon-state-unsubmit-active.png'),
	stateUncheck: getImageUrl('@/assets/images/icon/common/icon-state-uncheck.png'),
	stateUncheckActive: getImageUrl('@/assets/images/icon/common/icon-state-uncheck-active.png'),
	stateChecked: getImageUrl('@/assets/images/icon/common/icon-state-checked.png'),
	stateCheckedActive: getImageUrl('@/assets/images/icon/common/icon-state-checked-active.png'),
	stateWaitStart: getImageUrl('@/assets/images/icon/common/icon-state-waitstart.png'),
	stateWaitStartActive: getImageUrl('@/assets/images/icon/common/icon-state-waitstart-active.png'),
	stateWaitClaim: getImageUrl('@/assets/images/icon/common/icon-state-waitclaim.png'),
	stateWaitClaimActive: getImageUrl('@/assets/images/icon/common/icon-state-waitclaim-active.png'),
	scaleActionZero: getImageUrl('@/assets/images/icon/common/icon-scale-action-zero.png'),
	scaleActionManually: getImageUrl('@/assets/images/icon/common/icon-scale-action-manually.png'),
	scaleActionLock: getImageUrl('@/assets/images/icon/common/icon-scale-action-lock.png'),
	scaleActionClear: getImageUrl('@/assets/images/icon/common/icon-scale-action-clear.png'),
	scaleActionTare: getImageUrl('@/assets/images/icon/common/icon-scale-action-tare.png'),
	scaleActionRoundDown: getImageUrl('@/assets/images/icon/common/icon-scale-action-round-down.png'),
	scaleActionRoundUp: getImageUrl('@/assets/images/icon/common/icon-scale-action-round-up.png'),
	scaleActionMemo: getImageUrl('@/assets/images/icon/common/icon-scale-action-memo.png'),
	homeModuleEnter: getImageUrl('@/assets/images/icon/common/icon-home-module-enter.png'),
	itemBlockSortedState: getImageUrl('@/assets/images/icon/common/icon-sorted-state.png'),
	itemBlockStockoutState: getImageUrl('@/assets/images/icon/common/icon-stockout-state.png'),
	itemBlockSubmitState: getImageUrl('@/assets/images/icon/common/icon-submited-state.png'),
	itemBlockDelayedDispatchState: getImageUrl('@/assets/images/icon/common/icon-delayed-dispatch-state.png')
}
export default iconList
