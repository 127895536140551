import type { RequestConfig } from '@/js/api/dataSource'
import { post, put, get, del } from '@/js/api/dataSource'
import fxCommon from '@/js/common'
const API_MODULE = 'machiningOrder'
export default {
	// 组合加工单
	machiningOrder: {
		// 获取 机构列表
		getOrganSelectTab (typeList = []): RequestConfig {
			const params = { organTypeList: ['2', '1', '5', '6'] }
			if (typeList.length > 0) {
				params.organTypeList = typeList
			}
			return post({
				url: 'archive/organ/v1/manage/simple/tab',
				data: params,
				success: res => {
					return res
				}
			})
		},
		// 分页查询-获取仓库下拉列表(tab分页数据结构)
		getHouseSelect (typeList = []): RequestConfig {
			const params = {
				organTypeList: [2, 1, 5, 6]
			}
			if (typeList.length > 0) {
				params.organTypeList = typeList
			}
			return post({
				url: 'archive/house/v1/manage/simple/tab',
				data: params,
				success: res => {
					res.forEach((item: { listData: [] }) => {
						if (!item.listData) {
							item.listData = []
						}
					})
					return res
				}
			})
		},
		// 分页查询品项下拉列表tab
		getItemSelectTab (): RequestConfig {
			return get({ url: 'archive/item/v1/list/tab' })
		},
		// 获取经办人接口
		getAgentList (): RequestConfig {
			return get({ url: 'archive/bill/employee/v1/all' })
		},
		// 获取经办人启用列表
		getAgentEnableList (): RequestConfig {
			return post({ url: 'archive/bill/employee/v1/operator_list' })
		},
		// 获取成品列表
		getProductItemList (houseId: string): RequestConfig {
			return get({ url: `archive/pad/compose_bom/v1/production_plan/itemUnitList/0/house/${houseId}` })
		},
		// 获取配料列表
		getDosingItemList (itemId: string, houseId: string): RequestConfig {
			return get({ url: `archive/pad/compose_bom/v1/production_plan/itemUnitList/${itemId}/house/${houseId}` })
		},
		// 获取附成品列表
		getAdditionItemList (itemId: string, houseId: string): RequestConfig {
			return get({ url: `archive/pad/compose_bom/v1/production_plan/itemUnitList/${itemId}/house/${houseId}` })
		},
		// 分页查询
		getPage (): RequestConfig {
			return post({ url: 'bill/pad/machine_compose/v1/page' })
		},
		// 分页查询(回收站)
		getDelPage (): RequestConfig {
			return post({ url: 'bill/pad/machine_compose/v1/page', query: { del_flag: true } })
		},
		// 新增主表
		add (): RequestConfig {
			return post({
				url: 'bill/pad/machine_compose/v1',
				successToast: true,
				success: res => fxCommon.normalizationBillUpdateVersion(API_MODULE, res)
			})
		},
		// 编辑主表
		edit (id: string): RequestConfig {
			return put({
				url: `bill/pad/machine_compose/v1/${id}`,
				successToast: true,
				data: {
					...fxCommon.getBillUpdateVersion(API_MODULE, id),
					...fxCommon.getTenantIdParam()
				},
				success: res => fxCommon.normalizationBillUpdateVersion(API_MODULE, res)
			})
		},
		// 获取当前登录人及所属机构及机构的默认收货地址
		getCurrentUser (): RequestConfig {
			return get({ url: 'archive/employee/v1/currentUser' })
		},
		// 获取详情加工机构
		getStoreOrganList (): RequestConfig {
			return post({
				url: 'archive/organ/v1/subProduct/organ_list',
				data: {
					scmPadFlag: 'pad'
				}
			})
		},
		// 根据C号获取详情加工机构
		getStoreOrganListByCcode (cCode: string): RequestConfig {
			return get({
				url: `archive/organ/v1/get/c_code/${cCode}`
			})
		},
		// 获取品项所有组合BOM信息
		getBomList (itemId: string, organId: string): RequestConfig {
			return get({ url: `archive/compose_bom/v1/listBom/${itemId}/${organId}`, loading: false })
		},
		//  获取加工单详情
		getDetails (id: string): RequestConfig {
			return get({
				url: `bill/pad/machine_compose/v1/${id}`,
				success: res => {
					return fxCommon.normalizationBillUpdateVersion(API_MODULE, res)
				}
			})
		},
		//  获取计算组合BOM制造费用的换算系数
		getCountBomMachineChargeConversion (bomId: string, itemUnitId: string): RequestConfig {
			return get({ url: `archive/compose_bom/v1/getCountBomMachineChargeConversion/${bomId}/itemUnit/${itemUnitId}`, loading: false })
		},
		//  获取品项以及单位
		getItemUnitList (itemId = 0, houseId: string): RequestConfig {
			return get({ url: `archive/bill/item/v1/production_plan/itemUnitList/${itemId}/house/${houseId} `, loading: false })
		},
		// 根据品项查询单位列表
		getUnitList (itemId: string, billId: string): RequestConfig {
			return post({ url: `bill/pad/machine_compose/v1/${billId}/unitList/${itemId}` })
		},
		// 删除空单据
		delEmptyBill (id: string): RequestConfig {
			return del({ url: `bill/pad/machine_compose/v1/clear/empty/${id}`, loading: false })
		},
		// 新增成品/附成品
		addDetailProduct (billId: string): RequestConfig {
			return post({
				url: `bill/pad/machine_compose/${billId}/detail/v1/product`,
				data: { ...fxCommon.getBillDetailUpdateVersion(API_MODULE, billId) },
				success: res => fxCommon.normalizationBillDetailUpdateVersion(API_MODULE, res, billId),
				successToast: true
			})
		},
		// 新增配料
		addDetailDosing (billId: string): RequestConfig {
			return post({
				url: `bill/pad/machine_compose/${billId}/detail/v1/dosing`,
				data: { ...fxCommon.getBillDetailUpdateVersion(API_MODULE, billId) },
				success: res => fxCommon.normalizationBillDetailUpdateVersion(API_MODULE, res, billId),
				successToast: true
			})
		},
		// 批量新增配料
		addDetailDosingMulity (billId: string, doingList: []): RequestConfig {
			doingList.forEach((item: { billVersion: number }) => {
				item.billVersion = fxCommon.getBillDetailUpdateVersion(API_MODULE, billId).billVersion
			})
			return post({ url: `bill/pad/machine_compose/${billId}/detail/v1/dosing/batch/add`, data: doingList })
		},
		// 修改成品/附成品
		editDetailProduct (billId: string, id: string): RequestConfig {
			return put({
				url: `bill/pad/machine_compose/${billId}/detail/v1/product/${id}`,
				data: { ...fxCommon.getBillDetailUpdateVersion(API_MODULE, billId) },
				success: res => fxCommon.normalizationBillDetailUpdateVersion(API_MODULE, res, billId),
				successToast: true
			})
		},
		// 修改配料
		editDetailDosing (billId: string, id: string): RequestConfig {
			return put({
				url: `bill/pad/machine_compose/${billId}/detail/v1/dosing/${id}`,
				data: { ...fxCommon.getBillDetailUpdateVersion(API_MODULE, billId) },
				success: res => fxCommon.normalizationBillDetailUpdateVersion(API_MODULE, res, billId),
				successToast: true
			})
		},
		// 批量修改配料
		editDetailDosingMulity (billId: string, doingList: []): RequestConfig {
			doingList.forEach((item: { billVersion: number }) => {
				item.billVersion = fxCommon.getBillDetailUpdateVersion(API_MODULE, billId).billVersion
			})
			return put({ url: `bill/pad/machine_compose/${billId}/detail/v1/dosing/batch/update`, data: doingList, successToast: true })
		},
		// 根据bom获取原料
		getLastDosing (organId: string, bomId: string, planTotal: string, itemUnitId: string): RequestConfig {
			return get({ url: `archive/compose_bom/v1/organ/${organId}/${bomId}/lastDosing/${planTotal}/itemUnit/${itemUnitId}` })
		},
		// 清空成品关联bom
		relationProduct (billId: string): RequestConfig {
			return del({ url: `bill/pad/machine_compose/${billId}/detail/v1/delete/relation_product` })
		},
		// 清空明细
		clear (billId: string): RequestConfig {
			return del({ url: `bill/pad/machine_compose/${billId}/detail/v1/clear` })
		},
		// 提交
		submit (id: string): RequestConfig {
			return put({ url: `bill/pad/machine_compose/v1/submit/${id}`, successToast: true })
		},
		// 退回
		return (id: string): RequestConfig {
			return put({ url: `bill/pad/machine_compose/v1/back/${id}`, successToast: true })
		},
		// 审核
		check (id: string): RequestConfig {
			return put({ url: `bill/pad/machine_compose/v1/check/${id}`, successToast: true, errorToast: false })
		},
		// 反审核
		uncheck (id: string): RequestConfig {
			return put({ url: `bill/pad/machine_compose/v1/uncheck/${id}`, successToast: true, errorToast: false })
		},
		// 复审
		recheck (): RequestConfig {
			return put({ url: '/bill/pad/machine_compose/v1/recheckOne', successToast: true })
		},
		// 取消复审
		unrecheck (id: string): RequestConfig {
			return put({ url: `/bill/pad/machine_compose/v1/${id}/un_recheck`, successToast: true })
		},
		// 删除
		delete (id: string): RequestConfig {
			return put({ url: `bill/pad/machine_compose/v1/delete/${id}`, successToast: true })
		},
		// 批量删除明细
		delDetailMulity (billId: string): RequestConfig {
			return del({ url: `bill/pad/machine_compose/${billId}/detail/v1/delete`, successToast: true })
		},
		// 批量提交
		submitMulity (): RequestConfig {
			return post({ url: 'bill/pad/machine_compose/v1/batch/submit', successToast: true })
		},
		// 批量退回
		returnMulity (): RequestConfig {
			return post({ url: 'bill/pad/machine_compose/v1/batch/back', successToast: true })
		},
		// 批量审核
		checkMulity (): RequestConfig {
			return post({ url: 'bill/pad/machine_compose/v1/batch/check', successToast: true })
		},
		// 批量反审
		uncheckMulity (): RequestConfig {
			return post({ url: 'bill/pad/machine_compose/v1/batch/uncheck', successToast: true })
		},
		// 批量复审
		recheckMulity (): RequestConfig {
			return put({ url: '/bill/pad/machine_compose/v1/recheck', successToast: true })
		},
		// 批量取消复审
		unrecheckMulity (): RequestConfig {
			return put({ url: '/bill/pad/machine_compose/v1/un_recheck', successToast: true })
		},
		// 批量删除
		deleteMulity (): RequestConfig {
			return post({ url: 'bill/pad/machine_compose/v1/batch/delete', successToast: true })
		},
		// 退回生产计划
		backToPlan (): RequestConfig {
			return post({ url: 'bill/pad/machine_compose/v1/batch/backToPlan', successToast: true })
		},
		// 回收站批量恢复
		recoverMulity (): RequestConfig {
			return post({ url: 'bill/pad/machine_compose/v1/batch/restore', successToast: true })
		},
		// 回收站删除
		recyclingDelete (id: string): RequestConfig {
			return put({ url: `bill/pad/machine_compose/v1/remove/${id}`, successToast: true })
		},
		// 回收站批量删除
		recyclingDeleteMulity (): RequestConfig {
			return post({ url: 'bill/pad/machine_compose/v1/batch/remove', successToast: true })
		},
		// 复制单据
		copyOrder (id: string): RequestConfig {
			return put({ url: `bill/pad/machine_compose/v1/copy/${id}` })
		},
		// 单据业务链
		getBillChain (id: string): RequestConfig {
			return get({ url: `bill/pad/machine_compose/v1/${id}/chain` })
		},
		// 加工费用类型
		other_cost_type (id = 0): RequestConfig {
			return get({ url: `archive/other_cost_type/v1/list/${id}/costType/2` })
		},
		// 全部费用类型
		allOther_cost_type (costType: string): RequestConfig {
			return get({ url: `archive/other_cost_type/v1/list/costType/${costType}` })
		},
		// 费用类型回显
		getOtherCostType (otherChargeTypeList: string[]): RequestConfig {
			return post({ url: 'archive/other_cost_type/v1/getList', data: { appendIds: otherChargeTypeList, costTypes: ['2'] } })
		},
		// 防错校验
		checkMistake (billId: string): RequestConfig {
			return post({ url: `bill/pad/machine_compose/v1/mistake_proofing/${billId}` })
		},
		// 查询未关联品项
		getNotRelGodds (billId: string): RequestConfig {
			return post({ url: `bill/pad/in_store/v1/mistake_proofing/${billId}`, query: { error_check: false }, data: ['RelAllGoods'], loading: false })
		},
		// 查成品下的配料
		getDosing (billId: string, id: string): RequestConfig {
			return get({ url: `bill/pad/machine_compose/${billId}/detail/v1/product/${id}` })
		},
		// 异常警告-查询实际产量为0
		actualZeroAmount (billId: string): RequestConfig {
			return post({ url: `bill/pad/machine_compose/v1/mistake_proofing/${billId}`, query: { error_check: false }, data: ['ActualZeroAmount'], loading: false })
		},
		// 异常警告-查询重复品项
		repeatItem (billId: string): RequestConfig {
			return post({ url: `bill/pad/machine_compose/v1/mistake_proofing/${billId}`, query: { error_check: false }, data: ['RepeatItem'], loading: false })
		},
		// // 异常警告-警告-费用为0
		// costZero (billId) {
		// 	return post({ url: `bill/pad/machine_compose/v1/mistake_proofing/${billId}`, query: { error_check: false }, data: ['CostZero'], loading: false })
		// }
		// 异常警告-成品未添加配料
		productNotHaveDosing (billId: string): RequestConfig {
			return post({ url: `bill/pad/machine_compose/v1/mistake_proofing/${billId}`, query: { error_check: false }, data: ['ProductNotHaveDosing'], loading: false })
		},
		getStockAmount (billId: string): RequestConfig {
			return post({ url: `bill/machine_compose/v1/${billId}/stock/detail/amount` })
		}
	}
}
