<template>
	<w-app-page v-show="visiableFlag" class="under-store-error">
		<fx-header :title="billTitle" :customerBackHandler="customerBackHandler"></fx-header>
		<div class="under-store-error__content">
			<div class="under-store-error__content-left">
				<slot name="bill-form"></slot>
			</div>
			<div class="under-store-error__content-right">
				<div class="under-store-error__detail-container-title">
					<w-search-bar
						v-model:searchKey="searchItemKey"
						placeholder="搜索品项"
						class="detail-container-title-seachbar"
						@search="onSearchKeyChange"
					></w-search-bar>
				</div>
				<a-alert
					v-if="alertVisiableFlag"
					:message="alertErrorMsg"
					type="warning"
					closable
					class="alert-message"
					@close="onAlertMessageClose"
				/>
				<div class="under-store-error__detail-container-context">
					<w-scroll-load-list
						v-model:loading="loading"
						:finish="finish"
						:immediateCheck="false"
						class="detail-item-list"
						@load="onListLoad"
					>
						<underStoreItemBlock
							v-for="item in viewItemDetailsList"
							:itemData="item"
							:key="item.id"
						></underStoreItemBlock>
					</w-scroll-load-list>
				</div>
			</div>
		</div>
	</w-app-page>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import { getFxInstance } from '@/js/instance'
import underStoreItemBlock from './UnderStoreItemBlock.vue'
const FUZZY_SEARCH_ITEM_PROPS = ['item.name', 'item.pinYin', 'item.code']
export default defineComponent({
	name: 'under-store-error-page',
	components: {
		underStoreItemBlock
	},
	props: {
		billTitle: String,
		errorMsg: String,
		useBackClose: Boolean
	},
	setup (props) {
		const fxInstance = getFxInstance()
		const visiableFlag = ref(false)
		const alertVisiableFlag = ref(false)
		const alertErrorMsg = computed(() => {
			return props.errorMsg || `以下明细中，品项库存不足，无法按照本次出库数量出库，已为您筛选出${itemDetailsList.value.length}条明细`
		})
		const customerBackHandler = computed(() => {
			if (props.useBackClose) {
				return 	() => {
					visiableFlag.value = false
				}
			} else {
				return null
			}
		})
		// {{{ itemDetails
		const itemDetailsList = ref<{id: string}[]>([])
		// }}}
		// {{{ searchItem
		const searchItemKey = ref('')
		// }}}
		// {{{ list-scroll-load
		const searchItemList = ref<{id: string}[]>([])
		const setSearchItemList = () => {
			searchItemList.value = itemDetailsList.value.filter(fuzzySearchItem)
		}
		const fuzzySearchItem = (item: {id: string}) => {
			return fxInstance.$fxUtils.fuzzyQueryObj(item, searchItemKey.value, FUZZY_SEARCH_ITEM_PROPS)
		}
		const onSearchKeyChange = () => {
			setSearchItemList()
			refreshList()
		}
		const viewItemDetailsList = ref<{id: string}[]>([])
		const start = 0
		const pageCount = 40
		let end = start + pageCount
		const loading = ref(false)
		const finish = ref(false)
		const onListLoad = () => {
			viewItemDetailsList.value = searchItemList.value.slice(start, end)
			loading.value = false
			if (viewItemDetailsList.value.length >= searchItemList.value.length) {
				finish.value = true
			}
			end += pageCount
		}
		const refreshList = () => {
			end = start + pageCount
			loading.value = true
			finish.value = false
			onListLoad()
		}
		watch(() => itemDetailsList.value, () => {
			setSearchItemList()
			refreshList()
		})
		/// }}}
		const open = (list: {id: string}[]) => {
			itemDetailsList.value = list
			visiableFlag.value = true
			alertVisiableFlag.value = true
			fxInstance.$fxMessage.error('品项库存不足')
		}
		const onAlertMessageClose = () => {
			alertVisiableFlag.value = false
			visiableFlag.value = false
		}
		return {
			visiableFlag,
			alertVisiableFlag,
			alertErrorMsg,
			customerBackHandler,
			searchItemKey,
			onSearchKeyChange,
			loading,
			finish,
			onListLoad,
			viewItemDetailsList,
			open,
			onAlertMessageClose
		}
	}
})
</script>

<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.under-store-error {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: auto;
        width: 100%;
        background-color: $fxGray14;
        flex: 1;
        &-left {
            display: flex;
            overflow: auto;
            width: 300px;
            height: 100%;
            font-size: 15px;
            background-color: $fxWhite;
        }
        &-right {
            display: flex;
            height: 100%;
            flex: 1;
            flex-direction: column;
            .under-store-error__detail-container-title {
                display: flex;
                align-items: center;
                margin-left: 10px;
                height: 60px;
                font-size: 15px;
                background-color: $fxWhite;
                box-shadow: $fx-box-shadow-top;
                .detail-container-title-seachbar {
                    margin-right: 10px;
                    width: 250px;
                    height: 39px;
                }
            }
            .alert-message {
                z-index: 999;
                margin-left: 10px;
                &.ant-alert-error {
                    :deep(.ant-alert-message) {
                        color: $fxRed;
                    }
                }
                &.ant-alert-warning {
                    :deep(.ant-alert-message) {
                        color: $fxOrange1;
                    }
                }
            }
            .under-store-error__detail-container-context {
                display: flex;
                overflow: auto;
                height: 100%;
                flex: 1;
                flex-direction: row;
                .detail-item-list {
                    padding-top: 10px;
                    padding-left: 10px;
                    box-sizing: border-box;
                }
            }
        }
    }
    .blue-bill {
        :deep(.wind-cell__value) {
            color: $fxDefaultColor;
        }
    }
    .red-bill {
        :deep(.wind-cell__value) {
            color: $fxRed;
        }
    }
}
</style>
