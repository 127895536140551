import utilsType from './type'
import windUi from '@/components/wind-ui'
/*
 * 数据校验类 by shw
 * @method resetData 重置数据
 * @method checkData 校验数据
 * @method checkComfirm 校验数据,且自动提示 返回promise , params[data] 需要校验的数据 params[message] 需要提示的语句
 */
type CheckData = object | string | number | boolean
class Verification<T = CheckData> {
	origin: T
	constructor (data: T) {
		this.origin = data
		this.resetData(data)
	}

	resetData (data: T) {
		this.origin = typeof data === 'object' ? utilsType.deepClone(data) as T : data
	}

	checkData (data: T, log = false) {
		return utilsType.compare(this.origin as { [key: string]: {}; }, data as { [key: string]: {}; }, log)
	}

	checkComfirm (data: T, message = '当前页面存在未保存数据，是否确定取消并退出操作？') {
		return new Promise(resolve => {
			if (this.checkData(data)) {
				resolve(message)
			} else {
				windUi.$fxMessageBox.confirm(message).then(() => {
					resolve(message)
				})
			}
		})
	}

	getOriginData () {
		return utilsType.deepClone(this.origin)
	}
}

export const fxDataVerification = function <T = CheckData> (origin: T): Verification<T> {
	return new Verification(origin)
}

