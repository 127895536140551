import utilsType from './type'
// 解决ios识别问题 by shw
const newDate = function (date?: string | number | undefined): Date {
	if (typeof date === 'string' && ~date.indexOf('-')) {
		date = date.replace(/-/g, '/')
	}
	return date ? new Date(date) : new Date()
}
// 格式化日期 by shw
const setDate = function (date: Date | string | number = new Date(), formatter?: string | Function): string {
	if (!utilsType.isDate(date)) {
		date = newDate(date)
	}
	const y = date.getFullYear()
	let m: string | number = date.getMonth() + 1
	let d: string | number = date.getDate()
	m = m < 10 ? '0' + m : m
	d = d < 10 ? '0' + d : d
	switch (formatter) {
		case '年月日':
			return `${y}年${m}月${d}日`
		case 'ios':
			return `${y}/${m}/${d}`
		case 'YYYY-MM':
			return `${y}-${m}`
		case 'MM-DD':
			return `${m}-${d}`
		default:
			return `${y}-${m}-${d}`
	}
}
// 获取年月日小时分钟秒  by shw
const getDateTime = function (date: Date | string | number = new Date(), formatter?: string | Function): string {
	if (!utilsType.isDate(date)) {
		date = newDate(date)
	}
	const pushZero = (val: number) => {
		return val < 10 ? '0' + val : val
	}
	const y = (date as Date).getFullYear()
	const m = pushZero((date as Date).getMonth() + 1)
	const d = pushZero((date as Date).getDate())
	const myH = pushZero((date as Date).getHours())
	const myM = pushZero((date as Date).getMinutes())
	const myS = pushZero((date as Date).getSeconds())
	if (typeof formatter === 'function') {
		return formatter(y, m, d, myH, myM, myS)
	}
	switch (formatter) {
		case '年月日':
			return `${y}年${m}月${d}日 ${myH}:${myM}:${myS}`
		case '年月日时分':
			return `${y}年${m}月${d}日 ${myH}:${myM}`
		case 'YYYY-MM-DD HH:MM':
			return `${y}-${m}-${d} ${myH}:${myM}`
		case 'MM-DD HH:MM':
			return `${m}-${d} ${myH}:${myM}`
		case 'HH:MM':
			return `${myH}:${myM}`
		default:
			return `${y}-${m}-${d} ${myH}:${myM}:${myS}`
	}
}
// 转换日期格式, 只接受string类型的date by shw
const formatterDateTime = function (date: string, formatter = 'YYYY:MM:DD HH:MM'): string {
	if (!date) {
		return date
	}
	if (typeof date !== 'string') {
		return date
	}
	switch (formatter) {
		case 'YYYY:MM:DD HH:MM':
			if (date.length === 16) {
				return date
			} else {
				return date.slice(0, -3)
			}
		case 'YYYY:MM:DD':
			if (date.length === 16) {
				return date.slice(0, -6)
			} else {
				return date.slice(0, -9)
			}
		default:
			return date
	}
}

// 获取年月日, 自定义小时分钟秒 by shw
const getDateTimeCustomer = function (myH: string, myM: string, myS: string): Function {
	return function (date = new Date(), formatter?: string | Function): string {
		return getDateTime(date, function (y: string, m: string, d: string) {
			if (typeof formatter === 'function') {
				return formatter(y, m, d, myH, myM, myS)
			}
			switch (formatter) {
				case '年月日':
					return `${y}年${m}月${d}日 ${myH}:${myM}:${myS}`
				case '年月日时分':
					return `${y}年${m}月${d}日 ${myH}:${myM}`
				case 'YYYY-MM-DD HH:MM':
					return `${y}-${m}-${d} ${myH}:${myM}`
				default:
					return `${y}-${m}-${d} ${myH}:${myM}:${myS}`
			}
		})
	}
}

// 获取某天+00:00:00
const getDateFirstTime = function (date = new Date(), formatter?: string | Function): string {
	return getDateTimeCustomer('00', '00', '00')(date, formatter)
}

// 获取某天+23:59:59
const getDateLastTime = function (date = new Date(), formatter?: string | Function): string {
	return getDateTimeCustomer('23', '59', '59')(date, formatter)
}

// 比较日期大小
const compareDate = function (firstDate: string, secondDate?: string): boolean {
	return newDate(firstDate) >= newDate(secondDate)
}
// 获取当前天的指定前/后天数 by shw
const getCustomDate = (days: number): Date => {
	const date = new Date()
	date.setDate(date.getDate() + days)
	return date
}
// 获取指定天的指定前/后天数  by shw
const getCustomAssignDate = function (assignDate: string, days: number): Date {
	const date = newDate(assignDate)
	date.setDate(date.getDate() + days)
	return date
}
// 获取本周第一天/当传参时则为当前周的指定前/后周的第一天 by cc
const getCurrentWeekFirst = function (week = 0): string {
	const firstDay = new Date()
	const currentWeek = firstDay.getDay()
	const customWeek = week * 7
	const diffWeek = currentWeek === 0 ? -6 : 1 - currentWeek
	firstDay.setDate(firstDay.getDate() + diffWeek + customWeek)
	return setDate(firstDay)
}
// 获取本周最后一天/当传参时则为当前周的指定前/后周的最后一天 by cc
const getCurrentWeekLast = function (week = 0): string {
	const lastDay = new Date()
	const currentWeek = lastDay.getDay()
	const customWeek = week * 7
	const diffWeek = currentWeek === 0 ? 0 : 7 - currentWeek
	lastDay.setDate(lastDay.getDate() + diffWeek + customWeek)
	return setDate(lastDay)
}
// 获取当月第一天/当传参时则为当前月的指定前/后月的第一天 by shw
const getCurrentMonthFirst = function (month = 0): string {
	const firstDay = new Date()
	firstDay.setMonth(firstDay.getMonth() + month, 1)
	return setDate(firstDay)
}
// 获取当月最后一天/当传参时则为当前月的指定前/后月的最后一天 by shw
const getCurrentMonthLast = function (month = 0): string {
	const lastDay = new Date()
	lastDay.setMonth(lastDay.getMonth() + 1 + month, 0)
	return setDate(lastDay)
}
// 获取本年第一天/当传参时则为当前年的指定前/后年的第一天 by cc
const getCurrentYearFirst = function (year = 0): string {
	const firstDay = new Date()
	firstDay.setFullYear(firstDay.getFullYear() + year, 0, 1)
	return setDate(firstDay)
}
// 获取本年最后一天/当传参时则为当前年的指定前/后年的最后一天 by cc
const getCurrentYearLast = function (year = 0): string {
	const lastDay = new Date()
	lastDay.setFullYear(lastDay.getFullYear() + year, 11, 31)
	return setDate(lastDay)
}
// 比较两个日期,是否满足 firstDate + range < secondDate by shw
const compareDateRange = (firstDate: string, secondDate: string, range = 0): boolean => {
	return (newDate(secondDate) >= newDate(firstDate)) && getCustomAssignDate(firstDate, range) >= newDate(secondDate)
}
// 判断是否过期
function isTimeExpired (time: string, range?: number): boolean {
	if (range) {
		return compareDateRange(time, '', range)
	}
	return compareDate('', time)
}

// 毫秒转化分钟
const ms2m = function (ms: number): number {
	return Math.floor(ms / 1000 / 60)
}
// 毫秒转天
const ms2d = function (ms: number): number {
	return ms / 1000 / 60 / 60 / 24
}
// 小时转毫秒
const h2ms = function (h: number | string): number {
	return Math.floor(Number(h) * 60 * 60 * 1000)
}
// 天转毫秒
const d2ms = function (d: number): number {
	return Math.floor(d * 24 * 60 * 60 * 1000)
}

const countDay = function (d: string): number {
	const now = new Date().getTime()
	const expiration = new Date(d).getTime()
	const count = expiration - now
	return Math.floor(ms2d(count))
}

const getDiffTime = function (d: string): string {
	const now = new Date().getTime()
	const expiration = newDate(d).getTime()
	let diffTime = now - expiration
	const days = Math.floor(diffTime / 1000 / 60 / 60 / 24)
	diffTime -= days * 1000 * 60 * 60 * 24
	const hours = Math.floor(diffTime / 1000 / 60 / 60)
	diffTime -= hours * 1000 * 60 * 60
	const minutes = Math.floor(diffTime / 1000 / 60)
	diffTime -= minutes * 1000 * 60
	const seconds = Math.floor(diffTime / 1000)
	const pushZero = (val: number) => {
		return val < 10 ? '0' + val : val
	}
	return `${days}天${pushZero(hours)}小时${pushZero(minutes)}分${pushZero(seconds)}秒`
}
export default {
	newDate,
	setDate,
	getCustomDate,
	getDateTime,
	formatterDateTime,
	getDateFirstTime,
	getDateLastTime,
	getCurrentMonthFirst,
	getCurrentMonthLast,
	getCurrentWeekFirst,
	getCurrentWeekLast,
	getCurrentYearFirst,
	getCurrentYearLast,
	compareDate,
	compareDateRange,
	isTimeExpired,
	getCustomAssignDate,
	countDay,
	ms2m,
	ms2d,
	h2ms,
	d2ms,
	getDiffTime
}
