<template>
	<span :class="billColor"><slot></slot></span>
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue'

export default defineComponent({
	name: 'redBlueBillSpan',
	props: {
		state: Number
	},
	setup (props) {
		const billColor = computed(() => {
			switch (props.state) {
				case 1:
					return 'blue-bill'
				case 2:
					return 'red-bill'
				default:
					return ''
			}
		})
		return {
			billColor
		}
	}
})
</script>
<style lang="scss">
@import "$assets/stylus/varsty";
.blue-bill {
	color: $fxDefaultColor;
}
.red-bill {
	color: $fxRed;
}
</style>
