/*
 * @ 防抖函数 SHW
 * @ wait 延迟毫秒数
 * @ immediate 立即执行
 * @ final 最终执行
 */
const debounce = function ():Function {
	let timeout:ReturnType<typeof setTimeout> | null = null
	return function (wait: number, immediate = true, final = false) {
		return new Promise(resolve => {
			if (timeout) {
				clearTimeout(timeout)
			}
			if (immediate) {
				const immediateAction = !timeout
				timeout = setTimeout(() => {
					if (final && !immediateAction) {
						resolve(timeout)
					}
					timeout = null
				}, wait)
				if (immediateAction) {
					resolve(timeout)
				}
			} else {
				timeout = setTimeout(() => {
					resolve(timeout)
					timeout = null
				}, wait)
			}
		})
	}
}
const fxDebounce = debounce()
export default {
	fxDebounce
}
