<template>
	<w-header
		:title="title"
		:showBackBtn="showBackBtn"
		:customerBackHandler="customerBackHandler"
		:centerSlotAlign="centerSlotAlign"
	>
		<template v-slot:header-center>
			<slot name="header-center"></slot>
		</template>
		<template v-slot:header-right>
			<slot name="header-right">
				<template v-if="!customeHeaderRight">
					<div class="right-fun">
						<slot name="right-fun"></slot>
					</div>
					<fx-user class="header-icon"></fx-user>
				</template>
			</slot>
		</template>
	</w-header>
</template>
<script lang="ts">

import { defineComponent } from 'vue'

export default defineComponent({
	name: 'fx-header',
	props: {
		title: String,
		showBackBtn: {
			type: Boolean,
			default: true
		},
		customerBackHandler: Function,
		centerSlotAlign: String,
		customeHeaderRight: Boolean,
		showScalesWeightScreen: Boolean
	},
	setup () {
		const handleQrcodeScan = () => {
			alert(1)
		}
		return { handleQrcodeScan }
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";

.header-icon {
	margin-right: 20px;
}

.header-right {
	height: 44px;
}
.right-fun{
	margin-right: 20px;
}
</style>
