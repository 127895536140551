<template>
	<div :class="['fx-order-list-block-cell', {
		'border-bottom': borderBottom
	}]">
		<div :class="['fx-order-list-block-cell__left', leftCellClass]"><slot name="left"></slot></div>
		<div :class="['fx-order-list-block-cell__right', rightCellClass]"><slot name="right"></slot></div>
	</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
	name: 'fx-order-list-block-cell',
	props: {
		leftCellClass: {
			type: String,
			default: ''
		},
		rightCellClass: {
			type: String,
			default: ''
		},
		borderBottom: Boolean
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.fx-order-list-block-cell {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 10px;
	width: 100%;
	height: 30px;
	&.border-bottom {
		&::before {
			content: "";
			position:absolute;
			top: 0;
			width: 370px;
			height: 1px;
			background-color: $fxGray13;
		}
	}
	&__left {
		display: flex;
		text-align: left;
	}
	&__right {
		display: flex;
		text-align: right;
	}
}
</style>
