<template>
	<img ref="imgRef" :src="imgSrc" :onerror="imgErrorHandler" @click="onImageClick">
</template>
<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
const QING_URL_1 = 'http://fx-ygyl.pek3a.qingstor.com'
const QING_URL_2 = 'https://fx-ygyl.pek3a.qingstor.com'
const ALIYUN_URL = 'https://fxscms.oss-cn-beijing.aliyuncs.com'
export default defineComponent({
	name: 'w-image',
	props: {
		src: [String],
		type: {
			type: String,
			default: ''
		}
	},
	setup (props, { emit }) {
		const imgRef = ref({})
		const getImageUrl = (name: string) => {
			const path = `/src/assets/${name.replace(/^\@\/assets\//, '')}`
			const modules = import.meta.globEager('/src/assets/images/img/common/*')
			return (modules[path] as { default: string }).default
		}
		const getDefaultImg = () => {
			switch (props.type) {
				case 'preview':
					return getImageUrl('@/assets/images/img/common/default-img-preview.png')
				default:
					return getImageUrl('@/assets/images/img/common/default-img.png')
			}
		}
		let imgErrorHandler = ''
		const setImgErrorHandler = () => {
			imgErrorHandler = createImgErrorHandler()
		}
		const createImgErrorHandler = () => {
			let tryUrl = props.src!
			if (tryUrl.indexOf(ALIYUN_URL) === 0) {
				tryUrl = QING_URL_2 + tryUrl.substr(ALIYUN_URL.length)
			} else if (tryUrl.indexOf(QING_URL_1) === 0) {
				tryUrl = ALIYUN_URL + tryUrl.substr(QING_URL_1.length)
			} else if (tryUrl.indexOf(QING_URL_2) === 0) {
				tryUrl = ALIYUN_URL + tryUrl.substr(QING_URL_2.length)
			}
			return `
				if (this.$fxImgErrorFlag) {
					this.onerror=null;
					this.src='${getDefaultImg()}'
				} else {
					this.$fxImgErrorFlag=true;
					this.src="${tryUrl}"
				}
			`
		}
		const imgSrc = computed(() => props.src || getDefaultImg())
		const onImageClick = () => {
			emit('click')
		}
		watch(() => props.src, () => {
			(imgRef.value as { $fxImgErrorFlag: boolean }).$fxImgErrorFlag = false
			setImgErrorHandler()
		})
		setImgErrorHandler()
		return {
			imgRef,
			imgSrc,
			imgErrorHandler,
			onImageClick
		}
	}
})
</script>
<style lang="scss" scoped>
</style>
