import type { App, defineComponent } from 'vue'
import AppContainer from './AppContainer.vue'

AppContainer.install = (app: App): void => {
	app.component(AppContainer.name, AppContainer)
}

type IWithInstall = ReturnType<typeof defineComponent> & { install(app: App): void }

const fxComponent: IWithInstall = AppContainer
export default fxComponent
