<template>
	<div class="wind-tag">
		<div :class="classes" :style="tagStyles">{{label}}</div>
	</div>
</template>
<script lang="ts">
/**
 * Tag
 * @desc Tag 标签组件
 * @param {String} color 颜色
 * @param {String} label 文本
 * @param {String} size 尺寸 large / default /small
 */
import { defineComponent, computed } from 'vue'
export default defineComponent({
	name: 'w-tag',
	props: {
		color: String,
		label: String,
		size: {
			type: String,
			default: 'default'
		},
		plain: {
			type: Boolean,
			default: false
		},
		width: null
	},
	setup (props) {
		const classes = computed(() => {
			const classList = ['wind-tag__style']
			if (props.color) {
				classList.push(`color-${props.color}`)
			}
			if (props.size) {
				classList.push(`style-${props.size}`)
			}
			if (props.plain) {
				classList.push('is-plain')
			}
			return classList
		})
		const tagStyles = computed(() => {
			const style:{[key:string]:string} = {}
			if (props.width) {
				style.width = `${props.width}px`
				style.minWidth = 'auto'
			}
			return style
		})
		return {
			classes,
			tagStyles
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.wind-tag {
    display: inline-block;
    margin-left: 4px;
    &__style {
        margin-right: 10px;
        padding: 0 5px;
        min-width: 48px;
        height: 24px;
        font-size: 14px;
        border-radius: 4px;
        text-align: center;
        line-height: 24px;
        &.color-orange {
            color: $fxOrange7;
            background-color: $fxBDOrange7;
        }
        &.color-green {
            color: $fxGreen6;
            background-color: $fxBDGreen6;
        }
        &.color-gray {
            color: $fxGray24;
            background-color: $fxBDGray24;
        }
        &.color-red {
            color: $fxRed7;
            background-color: $fxBDRed7;
        }
        &.style-small {
            height: 18px;
            line-height: 18px;
            font-size: 12px;
        }
        &.style-default {
            height: 24px;
            line-height: 24px;
        }
        &.style-large {
            height: 36px;
            line-height: 36px;
            font-size: 16px;
        }
        &.is-plain {
            background-color: transparent !important;
        }
    }
}
</style>
