<template>
	<div v-show="showScale" class="weigh-connect">
		<w-icon type="print" @click="onScaleScanClick"></w-icon>
		<w-icon v-show="connected" type="check-circle-filled" class="conected-icon" @click="onScaleScanClick"></w-icon>
	</div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import { getFxInstance } from '@/js/instance'
import { usePrint, useWeight } from '@/js/hooks'
import { isCordova } from '@/config/env.config'
export default defineComponent({
	name: 'fx-print-usb-connect',
	setup () {
		const fxInstance = getFxInstance()
		const { connected } = usePrint()
		const showScale = ref(isCordova())
		const onScaleScanClick = () => {
			fxInstance.$fxPrint.usbScan().then((device:{address:string, name:string, deviceType: number}) => {
				if (device.deviceType === 3) {
					fxInstance.$fxPrint.usbConnect(device)
				}
			})
		}
		const { isIntegratedScale } = useWeight()
		return {
			onScaleScanClick,
			connected,
			showScale,
			isIntegratedScale
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.weigh-connect {
	position: relative;
    display: flex;
}
.conected-icon {
    position: absolute;
    top: 12px;
    right: -5px;
	overflow: hidden;
    width: 10px;
    height: 10px;
	border-radius: 5px;
	background-color: $fxWhite;
}
</style>
