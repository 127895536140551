export default {
	'collection-order': {
		id: 'collection-order',
		label: '预收单',
		code: '0007_0005_0007',
		child: {
			'add-collection': {
				id: 'add-collection',
				label: '新建',
				code: '0007_0005_0007_001_001',
				decisionByAllModule: ['financial-manage_capital-manage_collection-order.edit-collection']
			},
			'edit-collection': {
				id: 'edit-collection',
				label: '编辑',
				code: '0007_0005_0007_002_001',
				controlledByAllModule: 'financial-manage_capital-manage_collection-order.add-collection'
			},
			'submit-collection': {
				id: 'submit-collection',
				label: '提交',
				code: '0007_0005_0007_009_001'
			},
			'back-collection': {
				id: 'back-collection',
				label: '退回',
				code: '0007_0005_0007_010_001'
			},
			'check-collection': {
				id: 'check-collection',
				label: '审核',
				code: '0007_0005_0007_011_001'
			},
			'uncheck-collection': {
				id: 'uncheck-collection',
				label: '反审',
				code: '0007_0005_0007_012_001'
			},
			'recheck-collection': {
				id: 'recheck-collection',
				label: '复审',
				code: '0007_0005_0007_013_001'
			},
			'unrecheck-collection': {
				id: 'unrecheck-collection',
				label: '取消复审',
				code: '0007_0005_0007_014_001'
			},
			'delete-collection': {
				id: 'delete-collection',
				label: '删除',
				code: '0007_0005_0007_004_001'
			}
		}
	}
}
