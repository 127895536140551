<template>
	<div class="fx-order-list-bill-type-tabs">
		<van-tabs v-model:active="billTypeActive" shrink line-width="6vw" :before-change="beforeChange">
			<van-tab
				v-for="item in billTypeTabsList"
				:key="item.id"
				:name="item.id"
				:title="item.label"
			>
			</van-tab>
		</van-tabs>
	</div>
</template>
<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent, computed } from 'vue'
import type { TabsProps } from 'vant'
interface TypeTabsListItem {
	id: string
	label: string
}
export default defineComponent({
	name: 'fx-order-list-bill-type-tabs',
	props: {
		value: Number,
		billTypeTabsList: {
			type: Array as PropType<TypeTabsListItem[]>,
			default: () => {
				return []
			}
		},
		beforeChange: Function as PropType<TabsProps['beforeChange']>
	},
	setup (props, { emit }) {
		const billTypeActive = computed({
			get () {
				return props.value
			},
			set (val) {
				emit('update:value', val)
			}
		})
		return {
			billTypeActive
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.fx-order-list-bill-type-tabs {
	margin-left: 14px;
	padding: 0 10px;
	background-color: $fxWhite;
	:deep(.van-tabs__wrap) {
		border-bottom: 1px solid $fxBorder;
		.van-tab__text {
			font-size: 16px;
		}
	}
}
</style>
