import { OrderListStateEnum } from '@/config/app_type/orderList'
import { ref, computed } from 'vue'
type StateTabType = `${OrderListStateEnum}`
const STATE_TAB_CONFIG = {
	[OrderListStateEnum.all]: {
		id: OrderListStateEnum.all,
		type: OrderListStateEnum.all,
		label: '全部',
		icon: 'stateAll',
		iconActive: 'stateAllActive',
		billState: []
	},
	[OrderListStateEnum.unSubmit]: {
		id: OrderListStateEnum.unSubmit,
		type: OrderListStateEnum.unSubmit,
		label: '待提交',
		icon: 'stateUnsubmit',
		iconActive: 'stateUnsubmitActive',
		billState: [0]
	},
	[OrderListStateEnum.unChecked]: {
		id: OrderListStateEnum.unChecked,
		type: OrderListStateEnum.unChecked,
		label: '待审核',
		icon: 'stateUncheck',
		iconActive: 'stateUncheckActive',
		billState: [1]
	},
	[OrderListStateEnum.checked]: {
		id: OrderListStateEnum.checked,
		type: OrderListStateEnum.checked,
		label: '已审核',
		icon: 'stateChecked',
		iconActive: 'stateCheckedActive',
		billState: [2, 4]
	},
	[OrderListStateEnum.waitSort]: {
		id: OrderListStateEnum.waitSort,
		type: OrderListStateEnum.waitSort,
		label: '待分拣',
		icon: 'stateUncheck',
		iconActive: 'stateUncheckActive',
		billState: ['waitSort', 'sorting']
	},
	[OrderListStateEnum.sorted]: {
		id: OrderListStateEnum.sorted,
		type: OrderListStateEnum.sorted,
		label: '已完成',
		icon: 'stateChecked',
		iconActive: 'stateCheckedActive',
		billState: ['sorted']
	},
	[OrderListStateEnum.waitStart]: {
		id: OrderListStateEnum.waitStart,
		type: OrderListStateEnum.waitStart,
		label: '待开工',
		icon: 'stateWaitStart',
		iconActive: 'stateWaitStartActive',
		billState: [1]
	},
	[OrderListStateEnum.waitClaim]: {
		id: OrderListStateEnum.waitClaim,
		type: OrderListStateEnum.waitClaim,
		label: '待认领',
		icon: 'stateWaitClaim',
		iconActive: 'stateWaitClaimActive',
		billState: [2]
	},
	[OrderListStateEnum.finished]: {
		id: OrderListStateEnum.finished,
		type: OrderListStateEnum.finished,
		label: '已完成',
		icon: 'stateChecked',
		iconActive: 'stateCheckedActive',
		billState: [3]
	},
	[OrderListStateEnum.processAll]: {
		id: OrderListStateEnum.processAll,
		type: OrderListStateEnum.processAll,
		label: '全部',
		icon: 'stateAll',
		iconActive: 'stateAllActive',
		billState: [4]
	}
}
export const useBillStateTab = ({
	tabActive,
	tabList
}: { tabActive: StateTabType, tabList: StateTabType[] }) => {
	const stateTabActive = ref<StateTabType>(tabActive)
	const stateTabList = ref(tabList.map(item => STATE_TAB_CONFIG[item]))
	const listTitle = computed(() => {
		return STATE_TAB_CONFIG[stateTabActive.value as StateTabType].label
	})
	const tabActiveState = computed(() => {
		return STATE_TAB_CONFIG[stateTabActive.value as StateTabType].billState as (string | number)[]
	})
	const isUnSubmitTab = computed(() => {
		return stateTabActive.value === OrderListStateEnum.unSubmit
	})
	const isUnCheckedTab = computed(() => {
		return stateTabActive.value === OrderListStateEnum.unChecked
	})
	const isCheckedTab = computed(() => {
		return stateTabActive.value === OrderListStateEnum.checked
	})
	const isWaitSortTab = computed(() => {
		return stateTabActive.value === OrderListStateEnum.waitSort
	})
	const isSortedTab = computed(() => {
		return stateTabActive.value === OrderListStateEnum.sorted
	})
	const isTabState = (stateTabType: StateTabType) => {
		return computed(() => {
			return stateTabActive.value === stateTabType
		})
	}
	return {
		stateTabActive,
		stateTabList,
		tabActiveState,
		listTitle,
		isUnSubmitTab,
		isUnCheckedTab,
		isCheckedTab,
		isWaitSortTab,
		isSortedTab,
		isTabState
	}
}
