<template>
	<div class="item-block">
		<w-image :src="getItemSrc(itemData.item.imageUrl)" class="item-block__img"></w-image>
		<div class="item-block__mask">
			<div class="item-mask__cell fx-ellipsis">
				<span>本次出库:{{itemData.outAmount}}{{itemData.itemUnit.name}}</span>
			</div>
			<div class="item-mask__cell fx-ellipsis">
				<span>现有库存:{{itemData.storeAmount}}{{itemData.itemUnit.name}}</span>
			</div>
		</div>
		<div class="item-info__cell item-info__cell-item-name">
			<div class="item-name fx-ellipsis">{{itemData.item.name}}</div>
		</div>
		<div class="item-info__cell item-info__cell-item-spec">
			<div class="item-spec fx-ellipsis">{{itemData.item.spec}}</div>
		</div>
		<div class="item-info__cell item-info__cell-item-store">
			<div class="item-store fx-ellipsis">{{itemData.house.name}}</div>
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
	name: 'erroritemBlock',
	props: {
		itemData: {
			type: Object,
			default: () => {
				return {}
			}
		}
	},
	setup () {
		const getImageUrl = (name:string) => {
			const path = `/src/assets/${name.replace(/^\@\/assets\//, '')}`
			const modules = import.meta.globEager('/src/assets/images/img/common/*')
			return (modules[path] as { default: string }).default
		}
		const getItemSrc = (url:string) => {
			return url || getImageUrl('@/assets/images/img/common/no-item-img.png')
		}
		return {
			getItemSrc
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.item-block {
    position: relative;
    overflow: hidden;
    margin-right: 10px;
    margin-bottom: 10px;
    width: 150px;
    height: 147px;
    border-radius: 5px;
    background-color: $fxWhite;
    &__img {
        display: inline-block;
        margin: 5px 5px 0;
        width: 140px;
        height: 65px;
    }
    &__mask {
        position: absolute;
        top: 0;
        left: 0;
        padding-top: 2px;
        width: 100%;
        height: 70px;
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        color: $fxWhite;
        background-color: #404751bf;
        line-height: 65px;
        .item-mask__cell {
            padding: 0 10px;
            width: 100%;
            height: 22px;
            font-size: 14px;
            text-align: right;
            line-height: 22px;
            box-sizing: border-box;
        }
    }
    .item-info__cell {
        padding: 0 10px;
        width: 100%;
        height: 24px;
        line-height: 24px;
        box-sizing: border-box;
        &-item-spec {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &-item-name {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        &-item-store {
            display: flex;
            align-items: center;
        }
    }
}
</style>
