import type { RequestConfig } from '@/js/api/dataSource'
import { post, get, put } from '@/js/api/dataSource'
export default {
	// 工序任务单
	processTask: {
		// 分页查询
		getPage (): RequestConfig {
			return post({ url: 'mes/schedule/procedureTask/flat/pageQueryFlat', responseComplete: true })
		},
		// 认领
		claimOrder (): RequestConfig {
			return post({ url: 'mes/schedule/procedureTask/flat/update/operation/claim', successToast: true })
		},
		// 开工
		startOrder (): RequestConfig {
			return post({ url: 'mes/schedule/procedureTask/flat/update/operation/start', successToast: true })
		},
		// 报工获取详情
		getReportOrderDetails (orderId: string): RequestConfig {
			return get({ url: `mes/schedule/procedureTask/flat/getList/operation/${orderId}` })
		},
		// 没有BOM的工序任务单直接报工
		reportOrderNoBom (orderId: string): RequestConfig {
			return put({ url: `mes/schedule/procedureTask/flat/update/operation/notHaveBomCompletion/${orderId}`, successToast: true })
		},
		// 获取仓库列表
		getStoreList (factoryDockId: string): RequestConfig {
			return get({
				url: `mes/archive/external/organ/list/${factoryDockId}/centre`,
				success: (res) => {
					res.forEach((item: {id: string, dockId: string}) => {
						item.id = item.dockId
					})
					return res.filter((item: {organDataType: string}) => {
						return item.organDataType === 'store'
					})
				}
			})
		},
		// 提交
		submitOrder (procedureTaskId: string): RequestConfig {
			return put({ url: `mes/schedule/procedureTask/flat/submit/${procedureTaskId}`, successToast: true, errorToast: false })
		},
		// 获取工序任务单主表详情
		getOrderMainDetail (id: string): RequestConfig {
			return get({ url: `mes/schedule/procedureTask/flat/get/scheduleProcedureTask/${id}` })
		},
		// 更新工序任务单备注
		updateOrderMemo (id: string): RequestConfig {
			return put({ url: `mes/schedule/procedureTask/flat/update/procedureTask/${id}`, successToast: true })
		},
		// 更新工序任务单和关联消耗品信息,通过dataType区分id对应的值
		updateItemDetial (dataType: number, id: string): RequestConfig {
			return put({ url: `mes/schedule/procedureTask/flat/update/${dataType}/procedureTaskAndRaw/${id}`, successToast: true })
		},
		// 获取工位列表
		getListStationByParams (): RequestConfig {
			const params = {
				enableFlag: 1
			}
			return post({
				url: 'mes/dock/scm8/archive/getListStationByParams',
				data: params,
				success: res => {
					if (!res) {
						return []
					}
					res.forEach((item: {productionWorkshopName: string, name: string, stationSelectText: string}) => {
						item.stationSelectText = `${item.productionWorkshopName}-${item.name}`
					})
					return res
				}
			})
		},
		// 获取当前工序任务关联的工位人员
		getStationUser (billId: string): RequestConfig {
			return get({ url: `mes/schedule/procedureTask/flat/station/getListUser/${billId}` })
		},
		// 抢单模式认领工序任务
		grabbingOrder (orderId: string, userId: string): RequestConfig {
			return put({ url: `mes/schedule/procedureTask/flat/order/grabbing/${orderId}/${userId}`, successToast: true })
		},
		// 工序任务单汇总分页查询
		getSummaryPage (): RequestConfig {
			return post({
				url: 'mes/schedule/procedureTask/flat/query/sum/grabbing',
				responseComplete: true
			})
		},
		// 报工获取汇总详情
		getSummaryOrderDetails (): RequestConfig {
			return post({ url: 'mes/schedule/procedureTask/flat/getList/operation/sum' })
		},
		// 报工获取详情
		getSummarySingleOrderDetails (orderId: string): RequestConfig {
			return get({ url: `mes/schedule/procedureTask/flat/getList/operation/${orderId}?stockFlag=true` })
		},
		// 开工
		startMulityOrder (): RequestConfig {
			return post({ url: 'mes/schedule/procedureTask/flat/update/grabbing/operation/start', successToast: true })
		},
		// 批量提交工序任务单
		submitMulityOrder (): RequestConfig {
			return post({ url: 'mes/schedule/procedureTask/flat/batch/submit', responseComplete: true })
		},
		// 批量更新产出品和消耗品信息--点保存
		updateMulityItemDetial (): RequestConfig {
			return put({ url: 'mes/schedule/procedureTask/flat/batch/update/procedureTaskAndRaw', successToast: true })
		},
		// 抢单模式查询汇总数据
		getSumGrabbing (): RequestConfig {
			return post({ url: 'mes/schedule/procedureTask/flat/query/sum/grabbing', responseComplete: true })
		},
		// 批量抢单认领工序任务
		grabbingOrders (userId: string, orderIds: string[]): RequestConfig {
			return post({ url: `mes/schedule/procedureTask/flat/order/grabbing/${userId}`, data: orderIds, successToast: true })
		}
	}
}
