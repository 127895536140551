export default {
	'verification-order': {
		id: 'verification-order',
		label: '应付核销单',
		code: '0007_0005_0004',
		child: {
			'add-verification': {
				id: 'add-verification',
				label: '新建',
				code: '0007_0005_0004_001_001',
				decisionByAllModule: ['financial-manage_capital-manage_verification-order.edit-verification']
			},
			'edit-verification': {
				id: 'edit-verification',
				label: '编辑',
				code: '0007_0005_0004_002_001',
				controlledByAllModule: 'financial-manage_capital-manage_verification-order.add-verification'
			},
			'delete-verification': {
				id: 'delete-verification',
				label: '删除',
				code: '0007_0005_0004_004_001'
			},
			'un-verification': {
				id: 'un-verification',
				label: '反核销',
				code: '0007_0005_0004_251_001'
			},
			'hand-verification': {
				id: 'hand-verification',
				label: '手动核销',
				code: '0007_0005_0004_250_001'
			},
			'diff-verification': {
				id: 'diff-verification',
				label: '差异核销',
				code: '0007_0005_0004_250_002'
			}
		}
	}
}
