import { createRouter, createWebHistory } from 'vue-router'


// @ts-ignore
const routes = [
	{
		path: '',
		redirect: '/index',
		component: () => import(/* webpackChunkName: "tableBar" */ '@/views/index.vue')
	},
	{
		path: '/',
		redirect: '/index',
		component: () => import(/* webpackChunkName: "tableBar" */ '@/views/index.vue')
	},
	{
		path: '/index',
		name: 'index',
		component: () => import(/* webpackChunkName: "tableBar" */ '@/views/index.vue')
	}, {
		// 登录
		path: '/login',
		name: 'login',
		component: () => import(/* webpackChunkName: "login" */ '@/views/login/index.vue')
	}, {
		// 点单
		path: '/order',
		name: 'order',
		component: () => import(/* webpackChunkName: "home" */ '@/views/order/order.vue')
	}, {
		// 吧台
		path: '/tableBar',
		name: 'tableBar',
		component: () => import(/* webpackChunkName: "tableBar" */ '@/views/order/tableBar.vue')
	}, {
		// 点单详情
		path: '/orderDetail',
		name: 'orderDetail',
		component: () => import(/* webpackChunkName: "tableBar" */ '@/views/order/orderDetail.vue')
	}, {
		// 点单历史
		path: '/historyOrder',
		name: 'historyOrder',
		component: () => import(/* webpackChunkName: "tableBar" */ '@/views/order/historyOrder.vue')
	},
	{
		// 取酒
		path: '/getWine',
		name: 'getWine',
		component: () => import(/* webpackChunkName: "tableBar" */ '@/views/order/getWine.vue')
	},
	{
		// 取酒
		path: '/setWine',
		name: 'setWine',
		component: () => import(/* webpackChunkName: "tableBar" */ '@/views/order/setWine.vue')
	},
	{
		// 扫描二维码
		path: '/qrcodeScan',
		name: 'qrcodeScan',
		component: () => import(/* webpackChunkName: "tableBar" */ '@/views/order/qrcodeScan.vue')
	},
	{
		// 展示付款二维码
		path: '/paymentQrcode',
		name: 'paymentQrcode',
		component: () => import(/* webpackChunkName: "tableBar" */ '@/views/order/paymentQrcode.vue')
	}
]

const router = createRouter({
	history: createWebHistory(),
	routes
})

export default router
