import type { App } from 'vue'
import cordova from '@/js/cordova'
import vuex from '@/store'

let openCbCache: null | ((file: File) => void) = null
let resumeFlag = false
class FxCameraFloat {
	public opened = false

	init () {
		cordova.cameraFloat.onCameraStateListener((state: number) => {
			this.opened = !!state
		})
		document.addEventListener('pause', () => {
			if (this.opened) {
				resumeFlag = true
				this.close()
			}
		})
		document.addEventListener('resume', () => {
			if (resumeFlag && openCbCache) {
				setTimeout(() => {
					this.open(openCbCache!)
				}, 1500)
			}
			resumeFlag = false
		})
	}

	open (fn: (file: File) => void) {
		openCbCache = fn
		if (this.opened) {
			cordova.cameraFloat.onTakePictureListener(fn)
			return Promise.resolve()
		}
		return cordova.cameraFloat.open().then(() => {
			cordova.cameraFloat.onTakePictureListener(fn)
			return Promise.resolve()
		})
	}

	openAuto (fn: (file: File) => void) {
		if (vuex.getters.getCameraFloatAuto) {
			return this.open(fn)
		} else {
			return Promise.resolve()
		}
	}

	close () {
		if (!this.opened) {
			return Promise.resolve()
		}
		return cordova.cameraFloat.close().then(() => {
			return Promise.resolve()
		})
	}

	takePhoto (params: { amount?: string, money?: string }) {
		if (!this.opened) {
			return Promise.resolve()
		}
		return cordova.cameraFloat.takePicture(params)
	}
}

export const fxCameraFloat = new FxCameraFloat()

const install = (app: App): void => {
	app.config.globalProperties.$fxCameraFloat = fxCameraFloat
}

export default {
	install,
	fxCameraFloatInstance: fxCameraFloat
}
