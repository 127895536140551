import type { RequiredBillTypes, BillType } from '@/models/order-required'
import fxUtils from '@/js/utils'

function getOrderRequiredConfig (state: Record<string, Record<string, object>>, type: BillType, billType: number | string): RequiredBillTypes {
	const appOrderRequiredConfig = state.appOrderRequiredConfig
	if (appOrderRequiredConfig[type]) {
		return <RequiredBillTypes>(appOrderRequiredConfig[type] as RequiredBillTypes[]).find(item => {
			return fxUtils.logicJudgmentStr(item.id + '', (_billType: string) => {
				return _billType === billType + ''
			}) || false
		})
	} else {
		return <RequiredBillTypes>({})
	}
}
export const getOrderRequiredList = (state: Record<string, Record<string, object>>) => (type: BillType, billType: number, relevanceProp = '') => {
	const orderRequiredList = Object.values(getOrderRequiredConfig(state, type, billType).requiredItems || {})
	return orderRequiredList.filter(item => {
		if (item.relevance && item.required) {
			return item.relevance.relevanceArr.includes(relevanceProp)
		} else {
			return item.required
		}
	}).map(item => item.id)
}
export const getOrderDataIsRequired = (state: Record<string, Record<string, object>>) => (type: BillType, billType: number, requiredProp: string, relevanceProp = '') => {
	const orderRequiredList = getOrderRequiredConfig(state, type, billType)?.requiredItems || {}
	if (orderRequiredList[requiredProp] && orderRequiredList[requiredProp].relevance && orderRequiredList[requiredProp].required) {
		return orderRequiredList[requiredProp]?.relevance?.relevanceArr.includes(relevanceProp)
	} else {
		return orderRequiredList[requiredProp] && orderRequiredList[requiredProp].required
	}
}
export const getOrderDefaultValue = (state: Record<string, Record<string, object>>) => (type: BillType, billType: number, defaultProp = '', defaultValue = 0) => {
	const orderRequiredList = getOrderRequiredConfig(state, type, billType).requiredItems || {}
	if (orderRequiredList[defaultProp] && orderRequiredList[defaultProp].default) {
		return orderRequiredList[defaultProp]?.default?.defaultValue
	} else {
		return defaultValue
	}
}

