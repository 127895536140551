<template>
	<div class="wind-app-page">
		<slot></slot>
	</div>
</template>

<script lang="ts">
/**
 * AppPage by shang 2022/8/11
 * @desc AppPage app页面内容容器
 */
import { defineComponent } from 'vue'
export default defineComponent({
	name: 'w-app-page'
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.wind-app-page {
    display: flex;
    overflow-x: hidden;
    height:100%;
    background:$fxWhite;
    flex-direction: column;
}
</style>
