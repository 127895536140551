import { fxApi } from '@/js/api'
import type { BillType } from '@/config/order_mistake_check_config'

export function getOrderMistakeCheckConfig ({ commit }: { commit: Function }, { type }: { type: BillType }): Promise<Record<string, string> | Record<string, string>[]> {
	return new Promise(resolve => {
		fxApi('orderMistakeCheck.getOrderMistakeCheck', type).then(res => {
			commit('SET_ORDER_MISTAKE_CHECK_SETTING', res)
			resolve(res)
		})
	})
}

