<template>
	<div class="state-tabs">
		<div
			v-for="item in tabsList"
			:key="item.id"
			class="state-tabs__item"
			:class="{
				'is-active': item.id===tabActive
			}"
			@click="onTabClick(item)"
		>
			<w-image :src="item.id===tabActive ? stateIcons[item.iconActive]:stateIcons[item.icon]" class="state-icon"></w-image>
			<div>{{item.label}}</div>
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import { useAppIcon } from '@/js/hooks'
interface StateTab {
	id: string,
	type: string
	label: string
	icon: string
	iconActive: string
	billState: number[]
}
export default defineComponent({
	name: 'fx-state-tabs',
	props: {
		tabActive: {
			type: String,
			default: ''
		},
		tabsList: {
			type: Array as PropType<StateTab[]>,
			default: () => {
				return []
			}
		}
	},
	emits: ['click', 'update:tabActive'],
	setup (props, { emit }) {
		const iconList = props.tabsList.reduce((prev:string[], cur) => {
			prev.push(cur.icon, cur.iconActive)
			return prev
		}, [])
		const stateIcons = useAppIcon(iconList)
		const onTabClick = function (item: { id: string }) {
			if (item.id === props.tabActive) {
				return false
			}
			emit('update:tabActive', item.id)
			emit('click', item)
		}
		return {
			stateIcons,
			onTabClick
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.state-tabs {
    display: flex;
		overflow: hidden auto;
    width: 134px;
    height: 100%;
    flex-direction: column;
		background-color: $fxWhite;
    &__item {
        position:relative;
        display: flex;
        justify-content: center;
        align-items: center;
				min-height: 90px;
        color: #666666;
        flex: 1;
        flex-direction: column;
        &.is-active {
            font-weight:bolder;
            color: $fxDefaultColor;
            &::before {
                position:absolute;
                left: 0;
                width: 5px;
                height: 90px;
                background-color: $fxDefaultColor;
                content: "";
            }
        }
        .state-icon {
            margin-bottom: 8px;
            height: 35px;
        }
    }
}
</style>
