export default {
	'delivery-order': {
		id: 'delivery-order',
		label: '送货单',
		code: '0003_0006',
		child: {
			'edit-delivery': {
				id: 'edit-delivery',
				label: '查看送货单',
				code: '0003_0006_002_001'
			},
			'delete-delivery': {
				id: 'delete-delivery',
				label: '删除送货单',
				code: '0003_0006_004_001'
			},
			'print-delivery': {
				id: 'print-delivery',
				label: '打印送货单',
				code: '0003_0006_015_001'
			},
			'export-delivery': {
				id: 'export-delivery',
				label: '导出送货单明细数据',
				code: '0003_0006_040_003'
			}
		}
	}
}
