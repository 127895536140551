export default {
	'financial-supplier-manage': {
		id: 'financial-supplier-manage',
		label: '供货商列表',
		code: '0007_0006_0003',
		child: {
			'edit-supplier': {
				id: 'edit-supplier',
				label: '编辑',
				code: '0007_0006_0003_002_001'
			},
			'enable-supplier': {
				id: 'enable-supplier',
				label: '启用',
				code: '0007_0006_0003_006_001'
			},
			'disable-supplier': {
				id: 'disable-supplier',
				label: '停用',
				code: '0007_0006_0003_008_001'
			},
			'export-supplier': {
				id: 'export-supplier',
				label: '导出',
				code: '0007_0006_0003_040_001'
			},
			'import-supplier': {
				id: 'import-supplier',
				label: '批量导入',
				code: '0007_0006_0003_030_006'
			}
		}
	}
}
