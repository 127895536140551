import type { RequestConfig } from '@/js/api/dataSource'
import { post, put, get } from '@/js/api/dataSource'
export default {
	// 分拣任务单
	sortationTaskOrder: {
		// {{{ list列表
		// 筛选条件入库方列表
		getOrganList (): RequestConfig {
			return post({
				url: 'archive/mobile/organ/v1/in_store/organ_list'
			})
		},
		// 获取入库单list数据
		getBillList (): RequestConfig {
			return post({
				url: 'sorting/scm8/flat/task/v1/page',
				success: res => {
					res || (res = { result: [], total: 0 })
					return res
				}
			})
		},
		// 批量提交
		submitMulity (): RequestConfig {
			return post({ url: 'sorting/scm8/flat/task/v1/batch/submit', successToast: true })
		},
		// 查询单据详情
		getBillDetail (billId: string): RequestConfig {
			return get({ url: `sorting/scm8/flat/task/v1/${billId}` })
		},
		// }}}
		// {{{ 线路投箱
		// 查询分拣记录统计
		getSortedCount (billId: string): RequestConfig {
			return get({ url: `sorting/scm8/flat/task/v1/count/record/${billId}` })
		},
		// 查询可投放品项类型及品项
		getItemTypeList (billId: string): RequestConfig {
			return get({
				url: `sorting/scm8/flat/task/record/v1/${billId}/line_box/getItemTypeList`,
				success: res => {
					res.typeTreeVoList = [{
						id: 'root',
						code: '00000',
						name: '全部',
						children: res.typeTreeVoList
					}]
					return res
				}
			})
		},
		// 查询可选择线路
		getSortingLineList (billId: string, itemId: string, outHouseId: string): RequestConfig {
			return get({ url: `sorting/scm8/flat/task/record/v1/${billId}/line_box/getList/${itemId}/${outHouseId}` })
		},
		// 全部确认
		confirmAll (billId: string): RequestConfig {
			return put({ url: `sorting/scm8/flat/task/record/v1/${billId}/line_box/confirm`, successToast: true })
		},
		// 确认筛选出来的品项
		confirmByItemId (billId: string): RequestConfig {
			return put({ url: `sorting/scm8/flat/task/record/v1/${billId}/line_box/item/filter/confirm `, successToast: true })
		},
		// 指定品项全部确认
		confirmItem (billId: string, itemId: string, outHouseId: string): RequestConfig {
			return put({ url: `sorting/scm8/flat/task/record/v1/${billId}/line_box/item/${itemId}/${outHouseId}/confirm`, successToast: true })
		},
		// 提交
		submit (billId: string): RequestConfig {
			return put({ url: `sorting/scm8/flat/task/record/v1/${billId}/line_box/submit`, successToast: true })
		},
		// 提交前校验
		submitValidate (billId: string): RequestConfig {
			return put({ url: `sorting/scm8/flat/task/record/v1/${billId}/line_box/check/submit`, successToast: false })
		},
		// 保存明细
		saveDetail (billId: string, detailId: string): RequestConfig {
			return put({ url: `sorting/scm8/flat/task/record/v1/${billId}/line_box/update/${detailId}`, successToast: true })
		},
		// 缺货
		lineStockout (billId: string): RequestConfig {
			return put({ url: `sorting/scm8/flat/task/record/v1/${billId}/line_box/batch/out/stock`, successToast: true })
		},
		// 延迟发货
		delayedDispatch (billId: string): RequestConfig {
			return put({ url: `sorting/scm8/flat/task/record/v1/${billId}/line_box/batch/delayed/shipment`, successToast: true })
		},
		// 提交并审核
		check (billId: string): RequestConfig {
			return put({ url: `sorting/scm8/flat/task/record/v1/${billId}/line_box/execute/submit/checkOutStore`, successToast: true })
		},
		// }}}
		// {{{ 整体分拣
		// 获取仓库list
		getHouseList (billId: string): RequestConfig {
			return get({ url: `sorting/scm8/flat/task/record/v1/${billId}/whole/getList/house` })
		},
		// 获取分拣list
		getintegralDetailList (billId: string): RequestConfig {
			return get({ url: `sorting/scm8/flat/task/record/v1/${billId}/whole/getList` })
		},
		// 保存明细
		saveIntegraDetail (billId: string, detailId: string): RequestConfig {
			return put({ url: `sorting/scm8/flat/task/record/v1/${billId}/whole/update/${detailId}`, successToast: true })
		},
		// 重置明细
		integralReset (billId: string): RequestConfig {
			return put({ url: `sorting/scm8/flat/task/record/v1/${billId}/whole/batch/reset`, successToast: true })
		},
		// 缺货
		integralStockout (billId: string): RequestConfig {
			return put({ url: `sorting/scm8/flat/task/record/v1/${billId}/whole/batch/out/stock`, successToast: true })
		},
		// 撤回
		integralCancel (billId: string): RequestConfig {
			return put({ url: `sorting/scm8/flat/task/record/v1/${billId}/whole/batch/withdraw`, successToast: true })
		},
		// 提交
		integralSubmit (billId: string): RequestConfig {
			return put({ url: `sorting/scm8/flat/task/record/v1/${billId}/whole/submit`, successToast: true })
		},
		// 全部确认
		integralConfirmAll (billId: string): RequestConfig {
			return put({ url: `sorting/scm8/flat/task/record/v1/${billId}/whole/confirm`, successToast: true })
		}
		// }}}
	}
}
