import PINYIN_LIBRARY from './pinyin_library'
import regUtils from './reg'
// 字符串切割,处理空字符串与类型错误的问题 by shw
const split = function (value: string | number | null, separator: string | RegExp, howmany?: number | undefined): string[] {
	if (typeof value !== 'string' || value === '') {
		return []
	}
	return String.prototype.split.apply<string | number, [string | RegExp, number | undefined], string[]>(value, [separator, howmany])
}
// 字符串反转 by shw
const strReverse = (str: string): string => {
	return str.split('').reverse().join('')
}
// 汉字转拼音(全拼/简拼)
const chinese2Pinyin = function (val: string, simple = false): string {
	return split(val, '').reduce((pre, cur) => {
		if (regUtils.regInitByType(['english', 'number']).test(cur)) {
			pre += cur
		} else if (regUtils.regInitByType('chinese').test(cur)) {
			const name = getFirstLetter(cur, simple)
			pre += name
		}
		return pre
	}, '')
}
const getFirstLetter = function (val: string, simple: boolean) {
	const [first] = [...val]
	for (const key in PINYIN_LIBRARY) {
		if (~PINYIN_LIBRARY[key].indexOf(first)) {
			return ucfirst(key, simple)
		}
	}
	return ''
}
const ucfirst = function (l1: string, simple: boolean): string {
	if (l1.length > 0) {
		if (simple) {
			return l1.substr(0, 1)
		}
		const first = l1.substr(0, 1).toUpperCase()
		const spare = l1.substr(1, l1.length)
		return first + spare
	}
	return ''
}
// 获取文本宽度 by shw
const getTextWidth = function (str: string): number {
	const html = document.createElement('span')
	html.innerText = str
	html.className = 'fx-getTextWidth-span'
	document.querySelector('body')?.appendChild(html)
	const width = (document.querySelector('.fx-getTextWidth-span') as HTMLElement)?.offsetWidth
	document.querySelector('.fx-getTextWidth-span')?.remove()
	return width
}
// 字符串逻辑判断
const logicJudgmentStr = function (str = '', fn: Function): boolean {
	if (/\|/.test(str)) {
		const strList = str.split('|')
		return strList.some(item => logicJudgmentStr(item, fn))
	} else if (/\&/.test(str)) {
		const strList = str.split('&')
		return strList.every(item => logicJudgmentStr(item, fn))
	} else {
		return fn(str.trim())
	}
}
// 获取字符串首位
const getStringFirst = (str: string | undefined) => {
	if (!str) {
		return undefined
	}
	return str[0]
}
export default {
	split,
	strReverse,
	chinese2Pinyin,
	getTextWidth,
	logicJudgmentStr,
	getStringFirst
}
