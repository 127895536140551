import type { RequestConfig } from '@/js/api/dataSource'
import { post, get, del, file } from '@/js/api/dataSource'
export default {
	// 通用
	common: {
		// 登录
		login (): RequestConfig {
			return post({ url: 'auth/v1/login/pad' })
		},
		// 登录页员工卡登陆
		loginDomainEmployeeCard (): RequestConfig {
			return post({ url: 'auth/v1/login/domain/employeeCard' })
		},
		// 员工卡登陆
		loginEmployeeCard (): RequestConfig {
			return post({ url: '/auth/v1/login/employeeCard' })
		},
		// 获取当前登录人
		getCurrentUser (): RequestConfig {
			return get({ url: 'archive/employee/v1/currentUser' })
		},
		// 退出登录
		logout (): RequestConfig {
			return del({ url: 'auth/v1/logout', successToast: true })
		},
		// 上传图片
		upload (): RequestConfig {
			return file({ url: 'archive/fileupload/v1/upload' })
		},
		// 获取税率
		getTax (): RequestConfig {
			return get({ url: 'archive/common/v1/tax' })
		},
		// 获取当前登陆人权限
		getCurrentAuth (fatherCodeList = '', loading = true) {
			return get({
				url: 'auth/privilege_employee/v1/mes/privilege/employee/tree',
				query: { fatherCodeList, systemSign: 0 },
				success: (res) => {
					return {
						treeModularsData: res.treeModularsData || [],
						haveModulars: res.haveModulars || [],
						havePrivileges: res.havePrivileges || [],
						treeHavePrivilegesData: res.treeHavePrivilegesData || []
					}
				},
				loading
			})
		},
		// 获取分页查询日期区间
		getAccountingPeriod (): RequestConfig {
			return get({ url: 'archive/accounting_period/v1/managed_organ/begin_end' })
		},
		// 版本升级
		getVersionUpdate (): RequestConfig {
			return get({ url: 'auth/pad/v1/getVersionAndForceFlag', errorToast: false })
		},
		busisnessShareId (): RequestConfig {
			return post({ url: 'business/share/id' })
		}
	}
}
