import { setStorage } from '../utils'
import type { State } from './state'
import { removeToken } from '@/utils/auth'

const DEFAULT_LOGIN_STATE = {
	token: '',
	employeeName: '',
	loginSilentFlag: 0
}

export function SET_LOGINER<T extends Partial<State>> (state: T, loginer: T): void {
	for (const key in loginer) {
		state[key] = loginer[key]
		console.log(loginer[key])
		setStorage(key, loginer[key])
	}
}

export function LOGIN_OUT (state: State): void {
	removeToken()
	SET_LOGINER(state, DEFAULT_LOGIN_STATE)
}
