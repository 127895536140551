import type { RequestConfig } from '@/js/api/dataSource'
import { post, put, get, del } from '@/js/api/dataSource'
import fxCommon from '@/js/common'
import { EMPTY_TAB_DATA } from '../common'
const API_MODULE = 'receiveOrder'

export default {
	// 入库单
	receiveOrder: {
		// {{{ list页面筛选条件
		// 获取入库方tab下拉选择数据
		getOrganSelectTabList (): RequestConfig {
			// 入库方——‘机构’、‘配送中心’
			const params = { organTypeList: ['2', '1'] }
			return post({ url: 'archive/organ/v1/manage/simple/tab', data: params })
		},
		// 获取出库方tab下拉选择数据
		getOtherOrganSelectTabList (): RequestConfig {
			// 出库方——‘机构’、‘配送中心’、‘供货商’、‘客户’
			const params = { organTypeList: ['2', '1', '5', '6'] }
			return post({
				url: 'archive/organ/v1/manage/simple/tab',
				data: params,
				success: res => {
					res.push(EMPTY_TAB_DATA)
					return res
				}
			})
		},
		// 获取单据类型下拉选择数据
		getBillTypeSelectList (): RequestConfig {
			return get({ url: 'bill/pad/in_store/v1/billtype' })
		},
		// }}}
		// {{{ 入库单list页
		// 获取入库单list数据
		getBillList (): RequestConfig {
			return post({
				url: 'bill/pad/in_store/v1/page',
				success: res => {
					res || (res = { result: [], total: '0' })
					return res
				}
			})
		},
		// 批量删除
		delMulity (): RequestConfig {
			return put({ url: 'bill/pad/in_store/v1/delete', successToast: true })
		},
		// 批量提交
		submitMulity (): RequestConfig {
			return put({ url: 'bill/pad/in_store/v1/submit', successToast: true })
		},
		// 批量退回
		returnMulity (): RequestConfig {
			return put({ url: 'bill/pad/in_store/v1/back', successToast: true })
		},
		// 批量审核
		checkMulity (): RequestConfig {
			return put({ url: 'bill/pad/in_store/v1/check', successToast: true, errorToast: false })
		},
		// 批量反审
		uncheckMulity (): RequestConfig {
			return put({ url: 'bill/pad/in_store/v1/uncheck', successToast: true, errorToast: false })
		},
		// }}}
		// {{{ 新增入库单
		// 获取单据类型列表--过滤掉期初入库
		getBillTypeListCanAdd (): RequestConfig {
			return get({
				url: 'bill/pad/in_store/v1/billtype',
				success: res => {
					return res.filter((item: { id: number }) => {
						return item.id !== 1001
					})
				}
			})
		},
		// 获取机构列表
		getOrganList (): RequestConfig {
			return post({
				url: 'archive/mobile/organ/v1/in_store/organ_list',
				data: {
					flag: 'pad'
				}
			})
		},
		// 获取出库机构列表
		getOtherOrganList (): RequestConfig {
			return post({
				url: 'archive/mobile/organ/v1/in_store/organ_list'
			})
		},
		// 获取仓库列表
		getStoreList (organId: string): RequestConfig {
			return post({ url: `archive/bill/house/v1/organ/${organId}/houseList` })
		},
		// 获取经办人列表
		getAgentList (): RequestConfig {
			return post({ url: 'archive/bill/employee/v1/operator_list' })
		},
		// 详情-根据单据种类获取可使用单据业务类型
		getBillDetailBizTypeSelect (type: string): RequestConfig {
			return post({ url: `archive/bill/bill_biz_type/v1/bill_type/100/${type}`, loading: false })
		},
		// 新增入库单接口
		addBill (): RequestConfig {
			return post({
				url: 'bill/pad/in_store/v1',
				successToast: true,
				success: res => fxCommon.normalizationBillUpdateVersion(API_MODULE, res)
			})
		},
		// }}}
		// 仓库查询品项
		getStoreItemList (itemId: number|string, houseId: string): RequestConfig {
			return get({
				url: `archive/bill/item/v1/itemList/${itemId}/house/${houseId}`,
				success: res => fxCommon.normalizationBillUpdateVersion(API_MODULE, res)
			})
		},
		// 供应商 + 仓库 查询品项
		getSupplierStoreItemList (itemId: number|string, supplierId: string, houseId: string): RequestConfig {
			return get({
				url: `archive/bill/item/v1/itemList/${itemId}/supplier/${supplierId}/house/${houseId}`,
				success: res => fxCommon.normalizationBillUpdateVersion(API_MODULE, res)
			})
		},
		// {{{ 验收入库中
		// 继续验收-查询品项列表--分未添加、已添加
		getItemList (billId: string, houseId: string): RequestConfig {
			return get({
				url: `bill/pad/in_store/v1/${billId}/weigh/${houseId}`,
				success: res => fxCommon.normalizationBillUpdateVersion(API_MODULE, res)
			})
		},
		// 继续验收-查询品项列表——分未验收、已验收
		getDetailList (billId: string): RequestConfig {
			return get({
				url: `bill/pad/in_store/v1/${billId}/weigh`,
				success: res => fxCommon.normalizationBillUpdateVersion(API_MODULE, res)
			})
		},
		// 新增验收品项明细
		addDetail (billId: string): RequestConfig {
			return post({
				url: `bill/pad/in_store/${billId}/detail/v1`,
				data: { ...fxCommon.getBillDetailUpdateVersion(API_MODULE, billId), checkAccept: 1 },
				success: res => fxCommon.normalizationBillDetailUpdateVersion(API_MODULE, res, billId),
				successToast: true
			})
		},
		// 保存修改验收品项明细
		saveDetail (billId: string, id: string): RequestConfig {
			return put({
				url: `bill/pad/in_store/${billId}/detail/v1/${id}`,
				data: { ...fxCommon.getBillDetailUpdateVersion(API_MODULE, billId), checkAccept: 1 },
				success: res => fxCommon.normalizationBillDetailUpdateVersion(API_MODULE, res, billId),
				successToast: true
			})
		},
		// 删除单条明细
		delDetail (billId: string, id: string): RequestConfig {
			return del({ url: `bill/pad/in_store/${billId}/detail/v1/${id}`, successToast: true })
		},
		// 根据品项查询单位列表
		getUnitList (itemId: string, billId: string): RequestConfig {
			return post({ url: `bill/in_store/${billId}/detail/v1/unitList/${itemId}` })
		},
		// }}}
		// {{{ 入库单单据详情
		// 获取单据详情
		getBillDetail (billId: string): RequestConfig {
			return get({
				url: `bill/pad/in_store/v1/${billId}`,
				success: res => {
					if (!res.billBizType) {
						res.billBizType = {
							id: '',
							name: ''
						}
						res.billBizTypeId = ''
					}
					return fxCommon.normalizationBillUpdateVersion(API_MODULE, res)
				}
			})
		},
		// 批量设置
		setMulity (billId: string): RequestConfig {
			return put({
				url: `bill/pad/in_store/${billId}/detail/v1/set`,
				loading: false,
				successToast: true,
				data: {
					...fxCommon.getBillDetailUpdateVersion(API_MODULE, billId)
				}
			})
		},
		// 明细查询价格
		priceList (billId: string): RequestConfig {
			return post({ url: `bill/in_store/${billId}/detail/v1/priceList`, loading: false })
		},
		// 明细清空
		clearDetails (billId: string): RequestConfig {
			return del({ url: `bill/pad/in_store/${billId}/detail/v1/clear` })
		},
		// 明细查询库存
		stockAmount (billId: string, organId: string): RequestConfig {
			return post({ url: `bill/in_store/${billId}/detail/v1/${organId}/stock/amount`, loading: false })
		},
		// 保存单据
		saveBill (billId: string): RequestConfig {
			return put({
				url: `bill/pad/in_store/v1/${billId}`,
				successToast: true,
				data: {
					...fxCommon.getBillUpdateVersion(API_MODULE, billId),
					...fxCommon.getTenantIdParam()
				},
				success: res => fxCommon.normalizationBillUpdateVersion(API_MODULE, res)
			})
		},
		// 提交审核校验验收明细
		verifyBillDetails (billId: string): RequestConfig {
			return get({ url: `bill/pad/in_store/v1/${billId}/verify` })
		},
		// 提交单据
		submitBill (billId: string): RequestConfig {
			return put({ url: `bill/pad/in_store/v1/submit/${billId}`, successToast: true })
		},
		// 退回单据
		returnBill (billId: string): RequestConfig {
			return put({ url: `bill/pad/in_store/v1/back/${billId}`, successToast: true })
		},
		// 审核单据
		checkBill (billId: string): RequestConfig {
			return put({ url: `bill/pad/in_store/v1/${billId}/check`, successToast: true, errorToast: false })
		},
		// 结束并审核
		endCheck (billId: string): RequestConfig {
			return put({ url: `bill/pad/in_store/v1/${billId}/end_check`, successToast: true, errorToast: false })
		},
		// 拆单并审核
		splitCheck (billId: string): RequestConfig {
			return put({ url: `bill/pad/in_store/v1/${billId}/split_check`, successToast: true, errorToast: false })
		},
		// 反审单据
		uncheckBill (billId: string): RequestConfig {
			return put({ url: `bill/pad/in_store/v1/${billId}/uncheck`, successToast: true, errorToast: false })
		},
		// 删除单据
		deleteBill (billId: string): RequestConfig {
			return put({ url: `bill/pad/in_store/v1/${billId}/delete`, successToast: true })
		},
		// 明细按照订货量收货
		receiveByOrder (billId: string): RequestConfig {
			return put({ url: `bill/pad/in_store/${billId}/detail/v1/receivingAccordingToOrderedQuantity`, successToast: true })
		},
		// 明细缺货
		receiveByOOS (billId: string, detailIds: string[], reasonType: string, reason: string): RequestConfig {
			return post({
				url: `bill/pad/in_store/${billId}/detail/v1/batch/out/stock`,
				data: {
					detailIds,
					reasonType,
					reason
				},
				successToast: true
			})
		},
		// 明细重置
		reset (billId: string, changedList: string[]): RequestConfig {
			return post({
				url: `bill/pad/in_store/${billId}/detail/v1/batch/reset`,
				data: changedList,
				successToast: true
			})
		},
		// }}}
		// {{{ 防错校验
		// 总体查询
		checkMistake (billId: string, flag = true): RequestConfig {
			return post({
				url: `bill/pad/in_store/v1/mistake_proofing/${billId}?error_check=${flag}`,
				success: (res) => {
					fxCommon.syncBillVersionByMistakeDetails(API_MODULE, billId, res)
					return res
				}
			})
		},
		// 数量为0
		getZeroAmount (billId: string): RequestConfig {
			return post({
				url: `bill/pad/in_store/v1/mistake_proofing/${billId}?error_check=false`,
				data: ['ZeroAmount'],
				loading: false,
				success: (res) => {
					fxCommon.syncBillVersionByMistakeDetails(API_MODULE, billId, res)
					return res
				}
			})
		},
		// 查询重复品项的明细
		getRepeat (billId: string): RequestConfig {
			return post({
				url: `bill/pad/in_store/v1/mistake_proofing/${billId}?error_check=false`,
				data: ['RepeatItem'],
				loading: false,
				success: (res) => {
					fxCommon.syncBillVersionByMistakeDetails(API_MODULE, billId, res)
					return res
				}
			})
		},
		// 单价差异
		getDiffPrice (billId: string): RequestConfig {
			return post({
				url: `bill/pad/in_store/v1/mistake_proofing/${billId}?error_check=false`,
				data: ['DiffPrice'],
				loading: false,
				success: (res) => {
					fxCommon.syncBillVersionByMistakeDetails(API_MODULE, billId, res)
					return res
				}
			})
		},
		// 入库价格浮动提醒
		getDiffLastPrice (billId: string): RequestConfig {
			return post({
				url: `bill/pad/in_store/v1/mistake_proofing/${billId}?error_check=false`,
				data: ['DiffLastPrice'],
				loading: false,
				success: (res) => {
					fxCommon.syncBillVersionByMistakeDetails(API_MODULE, billId, res)
					return res
				}
			})
		},
		// 与最新价格一致
		agreement (billId: string): RequestConfig {
			return put({ url: `bill/in_store/${billId}/detail/v1/agreement/price`, successToast: true })
		},
		// 查询未关联品项
		getNotRelGodds (billId: string): RequestConfig {
			return post({
				url: `bill/pad/in_store/v1/mistake_proofing/${billId}?error_check=false`,
				data: ['RelAllGoods'],
				loading: false,
				success: (res) => {
					fxCommon.syncBillVersionByMistakeDetails(API_MODULE, billId, res)
					return res
				}
			})
		},
		// 查询出库方为供货商时未关联品项
		getSupplierRefItem (billId: string): RequestConfig {
			return post({
				url: `bill/pad/in_store/v1/mistake_proofing/${billId}?error_check=false`,
				data: ['SupplierItem'],
				loading: false
			})
		},
		// 查询入库价上浮比例控制品项
		getInPriceUp (billId: string): RequestConfig {
			return post({
				url: `bill/pad/in_store/v1/mistake_proofing/${billId}?error_check=false`,
				data: ['InPriceUp'],
				loading: false,
				success: (res) => {
					fxCommon.syncBillVersionByMistakeDetails(API_MODULE, billId, res)
					return res
				}
			})
		},
		// }}}
		// {{{ 按出库方收货
		// 查询收货信息
		// 查询出库方信息、查询品项信息、查询明细信息
		getReceiveInBatchInfo (): RequestConfig {
			return post({ url: 'bill/pad/in_store/v1/batchReceiving/getOutOganInfoByInBill' })
		},
		// 按品项合批、查询品项信息、查询明细信息
		getReceiveGoodInBatchInfo (): RequestConfig {
			return post({ url: 'bill/pad/in_store/v1/batchReceiving/item/getOutOganInfoByInBill' })
		},
		// 批量保存明细
		saveDetailMulity (): RequestConfig {
			return put({
				url: 'bill/pad/in_store/v1/detail/batch/mod',
				successToast: true
			})
		},
		// 批量提交、审核前的校验
		checkBillReceive (): RequestConfig {
			return post({ url: 'bill/pad/in_store/v1/batchReceiving/errorCheck' })
		},
		// 入库单明细缺货原因类型显示
		getOutStockReason (): RequestConfig {
			return get({ url: 'bill/pad/in_store/v1/get/out_stock/reason' })
		},
		// 供货商评分
		supplierRate (): RequestConfig {
			return post({
				url: 'bill/supplier_evaluate/v1',
				successToast: true,
				data: {
					...fxCommon.getTenantIdParam()
				}
			})
		}
	}
}
