<template>
	<div class="wind-select">
		<w-cell
			v-if="cellType==='cell'"
			:title="cellLabel"
			:value="currentCellText"
			:disabled="disabled"
			:required="required"
			:class="[{'empty-cell-text': isEmptyCellText}, cellClass]"
			isLink
			@on-click="showModal"
		></w-cell>
		<div v-else :class="['wind-select__cell', cellClass, {
			'has-border': border
		}]" @click="showModal">
			<span class="cell-text" :class="{'empty-cell-text': isEmptyCellText}">{{currentCellText}}</span>
			<w-icon-svg v-if="showClear" type="icon-circle-close" class="select-clear-icon" @click="onClearClick"></w-icon-svg>
			<w-icon-svg v-if="!disabled" type="icon-cell-link-arrow" class="select-arrow-icon"></w-icon-svg>
		</div>
		<a-modal
			v-model:visible="visible"
			:title="title"
			:bodyStyle="{
				width: '45vw',
				height: '35vw',
				'padding': '1vw 1vw 2vw 1vw'
			}"
			centered
			width="45vw"
			class="wind-select__modal"
		>
			<template #footer> <!-- <a-button key="submit" type="primary" class="confirm-btn" @click="handleOk">确定</a-button> --></template>
			<div v-if="allVisibleOptionList.length > 0" class="wind-select__modal-container">
				<div class="list-item-container">
					<div
						v-for="listItem in allVisibleOptionList"
						:key="listItem.id"
						class="list-item"
						:class="{'is-select': currentSelectItem.id === listItem.id}"
						@click="onListItemClick(listItem)"
					>
						<w-icon class="right-identifier-icon"
							:type="currentSelectItem.id === listItem.id ? 'right-identifier-fillblue' : 'right-identifier-fillgray'">
						</w-icon>
						<span class="list-item__text">{{(listItem as unknown as Record<string, string>)[optionProps.name]}}</span>
					</div>
				</div>
			</div>
			<div v-else class="wind-select__empty-container">
				<w-icon type="empty" class="empty-icon"></w-icon>
			</div>
		</a-modal>
	</div>
</template>
<script lang="ts">
/**
 * Select by shang 2022/8/25
 * @desc Select 单选选择器
 */
import { computed, defineComponent, ref, watch } from 'vue'
import type { PropType } from 'vue'
import { getFxInstance } from '@/js/instance'
interface ListItem {
	id: string|number
	visibleFlag?: boolean
}
export default defineComponent({
	name: 'wind-select',
	props: {
		value: [String, Number],
		optionlist: {
			type: Array as PropType<ListItem[]>,
			default: () => {
				return []
			}
		},
		optionProps: {
			type: Object as PropType<{name:string}>,
			default: () => {
				return {
					name: 'name'
				}
			}
		},
		title: {
			type: String,
			default: '选择选项'
		},
		cellType: String,
		cellLabel: {
			type: String,
			default: ''
		},
		cellText: String,
		emptyText: {
			type: String,
			default: '暂无数据'
		},
		disabled: Boolean,
		required: Boolean,
		placeholder: {
			type: String,
			default: '请选择'
		},
		fpSelectShow: Function,
		cellClass: String,
		border: {
			type: Boolean,
			default: true
		},
		clearable: {
			type: Boolean,
			default: false
		}
	},
	setup (props, { emit }) {
		const fxInstance = getFxInstance()
		const visible = ref<boolean>(false)
		const isEmptyCellText = computed(() => {
			return !props.cellText
		})
		const currentCellText = computed(() => {
			if (props.disabled) {
				return props.cellText
			} else if (isEmptyCellText.value) {
				return props.placeholder
			} else {
				return props.cellText
			}
		})
		const showModal = () => {
			if (props.disabled) {
				return
			}
			if (typeof props.fpSelectShow === 'function') {
				props.fpSelectShow().then(() => {
					setActiveItem()
					visible.value = true
				})
			} else {
				setActiveItem()
				visible.value = true
			}
		}
		const handleOk = () => {
			visible.value = false
			if (currentSelectItem.value.id !== props.value) {
				emit('change', currentSelectItem.value)
			}
		}
		const currentSelectItem = ref<ListItem>({
			id: '',
			[props.optionProps.name]: ''
		})
		const setActiveItem = () => {
			currentSelectItem.value = {
				id: '',
				[props.optionProps.name]: ''
			}
			if (fxInstance.$fxUtils.isDef(props.value) && props.value !== '') {
				const select = allVisibleOptionList.value.find(item => {
					return item.id === props.value
				})
				if (select) {
					currentSelectItem.value = select
				} else {
					console.warn(`wind-select组件未找到匹配id:${props.value}`)
				}
			}
		}
		const onListItemClick = (item:ListItem) => {
			currentSelectItem.value = item
			handleOk()
		}
		const allVisibleOptionList = ref<ListItem[]>([])
		watch(() => props.optionlist, (val:ListItem[]) => {
			allVisibleOptionList.value = val.filter((item:ListItem) => {
				return item.visibleFlag !== false
			})
		}, {
			immediate: true
		})
		const showClear = computed(() => {
			return props.clearable && !props.disabled && !isEmptyCellText.value
		})
		const onClearClick = (e: Event) => {
			e.stopPropagation()
			currentSelectItem.value = {
				id: '',
				[props.optionProps.name]: ''
			}
			emit('change', currentSelectItem.value)
		}
		return {
			visible,
			isEmptyCellText,
			currentCellText,
			showModal,
			handleOk,
			allVisibleOptionList,
			currentSelectItem,
			onListItemClick,
			showClear,
			onClearClick
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.wind-select {
	&__cell {
		display: flex;
		align-items: center;
		padding-right: 10px;
		padding-left: 15px;
		width: 280px;
		height: 36px;
		line-height: 36px;
		box-sizing: border-box;
		&.has-border {
			border: 1px solid #cccccc;
			border-radius: 20px;
		}
		.cell-text {
			flex: 1;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		.empty-cell-text {
			color: #cccccc;
		}
		.select-arrow-icon {
			width: 12px;
			height: 12px;
			color: #a7a8ab;
			transform: rotate(90deg);
		}
		.select-clear-icon {
			margin-right: 4px;
			width: 14px;
			height: 14px;
			color: #a7a8ab;
		}
	}
	.empty-cell-text {
		:deep(.wind-cell__value) {
			color: #cccccc;
		}
	}
	&__modal-container {
		overflow: auto;
		height: 96%;
		.list-item-container {
			display: flex;
			overflow: auto;
			height: 100%;
			flex-direction: column;
			.list-item {
				display: flex;
				align-items: center;
				padding: 0 10px;
				height: 40px;
				line-height: 40px;
				box-sizing: border-box;
				&.is-select {
					color: $fxDefaultColor;
					background-color: #5c92fe14;
				}
				.list-item__text {
					flex: 1;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				.right-identifier-icon {
					margin-right: 8px;
				}
			}
		}
	}
	&__empty-container {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		.empty-icon {
			width: 200px;
			height: 200px;
		}
	}
}
.wind-select__modal {
	.confirm-btn {
		width: 100%;
	}
}
</style>
