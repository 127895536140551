import { createApp } from 'vue'
import appInit from '@/js/appInit'
import { useVconsole } from '@/config/env.config'
import App from './App.vue'
import VConsole from 'vconsole'

const app = createApp(App).use(appInit)
if (useVconsole()) {
	const vConsole = new VConsole()
	console.log(vConsole)
}
// if (!isWeb()) {
// 	window.document.addEventListener('deviceready', function () {
// 		app.mount('#app')
// 	}, false)
// } else {
app.mount('#app')
// }

export default app
