// cordova-plugin-app-version
import utils from '@/js/utils'
const log = (msg: string) => {
	return utils.fxDebug('cordova-api')(msg)
}
export interface AppVersion {
	getPackageName: () => Promise<string>
	getVersionNumber: () => Promise<string>
	getAppName: () => Promise<string>
	checkVersion: () => Promise<string>
	checkAppVersion: () => Promise<void>
}
// 获取APP的包名
const getPackageName = function (): Promise<string> {
	return new Promise(resolve => {
		try {
			window.cordova.getAppVersion.getPackageName().then((packageName: string) => {
				resolve(packageName)
			})
		} catch (error) {
			resolve('com.fengxing.scm8pad')
		}
	})
}
// 获取APP的发布版本号
const getVersionNumber = function (): Promise<string> {
	return new Promise(resolve => {
		try {
			window.cordova.getAppVersion.getVersionNumber().then((versionNumber: string) => {
				resolve(versionNumber)
			})
		} catch (error) {
			resolve('1.0.0')
		}
	})
}
// 获取APP的名字
const getAppName = function (): Promise<string> {
	return new Promise(resolve => {
		try {
			window.cordova.getAppVersion.getAppName().then((appName: string) => {
				resolve(appName)
			})
		} catch (error) {
			resolve('@SCM8-PAD')
		}
	})
}
// app版本校验
const checkVersion = function (newVersion: string): Promise<string> {
	return getVersionNumber().then((currentVersion: string) => {
		let type = '0'
		const currVerArr = currentVersion.split('.')
		const newVerArr = newVersion.split('.')
		if (currVerArr.length !== 3 || newVerArr.length !== 3) {
			type = '1'
		} else {
			for (let i = 0; i < newVerArr.length; i++) {
				if (~~currVerArr[i] > ~~newVerArr[i]) {
					break
				} else if (~~newVerArr[i] > ~~currVerArr[i]) {
					type = '1'
					break
				}
			}
		}
		return Promise.resolve(type)
	})
}
// 版本校验
const checkAppVersion = function (): Promise<void> {
	return new Promise(resolve => {
		try {
			window.AppUpgrade.checkAppVersion()
			resolve()
		} catch (error) {
			log('浏览器环境下无法使用appupgrade.checkAppVersion功能')
		}
	})
}

export default {
	getPackageName,
	getVersionNumber,
	getAppName,
	checkVersion,
	checkAppVersion
}
