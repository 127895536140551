export default {
	'maintenance-record': {
		id: 'maintenance-record',
		label: '维修点检任务',
		code: '0010_0006_0002',
		// connectDetails: ['stock-sale-manage_sale-order.assign-in-sale'],
		child: {
		}
	}
}
