import { fxApi } from '@/js/api'
import type { BillType } from '@/models/order-required'

export function getOrderRequiredConfig ({ commit }: { commit: Function }, { type }: { type: BillType }) {
	return new Promise(resolve => {
		fxApi('orderRequired.getOrderRequired', type).then(res => {
			commit('SET_ORDER_REQUIRED_SETTING', res)
			resolve(res)
		})
	})
}
