// 重量通用方法
import vuex from '@/store'
import utils from '@/js/utils'
import type { WeightItem } from '@/models/weight'
const WEIGHT_MAX = 60
// 获取最大称重记录数量
function getMaxWeightCount () {
	return WEIGHT_MAX
}
// 根据重量获取数量
function getAmountByWeight (weight: number, kgToMainUnit: number, unitRatio: number): number {
	return utils.toFixedParseNumber(weight * kgToMainUnit / unitRatio, vuex.getters.getSysPointSize)
}
// 是否为称重品项
function isDetailCanWeigh (item: { item?: { canWeigh?: number } }): boolean {
	return !!(item.item && item.item.canWeigh)
}
// 根据数量获取重量
function getWeightByAmount (amount: number, kgToMainUnit: number, unitRatio: number): number {
	const itemWeightKg = amount * unitRatio / kgToMainUnit
	return vuex.getters.getWeightValue(itemWeightKg)
}
// 根据数量获取重量(KG)
function getKgWeightByAmount (amount: number, kgToMainUnit: number, unitRatio: number): number {
	return utils.toFixedParseNumber(amount * unitRatio / kgToMainUnit, vuex.getters.getSysPointSize)
}
// 根据最小单位数量获取重量
function getWeightByMainUnitAmount (mainUnitAmount: number, kgToMainUnit: number): number {
	const itemWeightKg = mainUnitAmount / kgToMainUnit
	return vuex.getters.getWeightValue(itemWeightKg)
}
// 根据数量为品项增加一条称重记录
function addWeightRecordByAmount (weighRecords: WeightItem[], amount: number, kgToMainUnit: number, unitRatio: number) {
	const weight = getKgWeightByAmount(amount, kgToMainUnit, unitRatio)
	weighRecords.push({
		id: utils.createUUID(),
		unitName: 'Kg',
		netWeight: weight,
		tareWeight: 0,
		grossWeight: weight,
		money: 0,
		num: 0
	})
	weighRecords.forEach((item: { num: number }, index: number) => {
		item.num = index + 1
	})
	return weighRecords
}
// 转换到当前系统设置的称重单位和数量
function getSysWeightUnitAmount (itemAmount: number, itemRatio: number, itemkgToMainUnit: number): [number, string] {
	const curWeightUnitLabel = vuex.getters.getWeightUnitLabel
	const itemWeightKg = itemAmount! * itemRatio! / itemkgToMainUnit!
	const itemWeightValue = vuex.getters.getWeightValue(itemWeightKg)
	return [itemWeightValue, curWeightUnitLabel]
}
export default {
	getMaxWeightCount,
	getAmountByWeight,
	isDetailCanWeigh,
	getWeightByAmount,
	getKgWeightByAmount,
	addWeightRecordByAmount,
	getWeightByMainUnitAmount,
	getSysWeightUnitAmount
}
