export const isDevelopment = function (): boolean {
	return import.meta.env.DEV
}
export const isProducton = function (): boolean {
	return import.meta.env.PROD
}
let FX_IS_CORDOVA = false

if (import.meta.env.VITE_APP_IS_CORDOVA === 'true' || isDevelopment() || window.$fxUseCordova) {
	FX_IS_CORDOVA = true
}
export const isCordova = function (): boolean {
	return !!FX_IS_CORDOVA
}

let FX_IS_WEB = false

if (import.meta.env.VITE_APP_IS_WEB === 'true' || isDevelopment()) {
	FX_IS_WEB = true
}
export const isWeb = function (): boolean {
	return !!FX_IS_WEB
}


let FX_USE_VCONSOLE = false
if (import.meta.env.VITE_APP_USE_VCONSOLE === 'true') {
	FX_USE_VCONSOLE = true
}
export const useVconsole = function (): boolean {
	return !!FX_USE_VCONSOLE
}

let FX_USE_DEFAULT_DOMAIN = false
if (import.meta.env.VITE_APP_USE_DEFAULT_DOMAIN === 'true') {
	FX_USE_DEFAULT_DOMAIN = true
}
export const useDefaultDomain = function (): boolean {
	return !!FX_USE_DEFAULT_DOMAIN
}
let FX_USE_GRAY_DEFAULT_DOMAIN = false
if (import.meta.env.VITE_APP_USE_GRAY_DEFAULT_DOMAIN === 'true') {
	FX_USE_GRAY_DEFAULT_DOMAIN = true
}
export const useGrayDefaultDomain = function (): boolean {
	return !!FX_USE_GRAY_DEFAULT_DOMAIN
}
let FX_USE_APP_VERSION_UPDATE = false
if (import.meta.env.VITE_APP_USE_VERSION_UPDATE === 'true') {
	FX_USE_APP_VERSION_UPDATE = true
}
export const isUseAppVersionUpdate = function (): boolean {
	return !!FX_USE_APP_VERSION_UPDATE
}

type LoggerRemoteType = 'development' | 'release'
export const getFxLoggerRemote = function (): LoggerRemoteType {
	return import.meta.env.VITE_APP_LOGGER_REMOTE_TYPE
}

export const getFxSoureQrCodeRemote = function (): 'development' | 'release' {
	return import.meta.env.VITE_APP_SOURCEQRCODE_REMOTE_TYPE
}

