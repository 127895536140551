import { getStorageNumber, getStorageBoolean, getStorageJson, getStorage } from '../utils'
import QRCODE_SETTING from '@/config/qrcode_setting'

const state = {
	// 整数最大位数
	numberLength: 8,
	// 数量小数点保留位数(4位)
	sysPointSize: getStorageNumber('sysPointSize', 4),
	// 金额小数点保留位数(2位)
	sysMoneyPointSize: getStorageNumber('sysMoneyPointSize', 2),
	// 单价小数点保留位数(4位)
	sysPricePointSize: getStorageNumber('sysPricePointSize', 4),
	// 可输入最大数量
	maxAmount: 99999999,
	// 可选税率
	taxesList: [{
		id: 0,
		name: '0%'
	}, {
		id: 0.01,
		name: '1%'
	}, {
		id: 0.03,
		name: '3%'
	}, {
		id: 0.05,
		name: '5%'
	}, {
		id: 0.06,
		name: '6%'
	}, {
		id: 0.09,
		name: '9%'
	}, {
		id: 0.13,
		name: '13%'
	}, {
		id: 0.21,
		name: '21%'
	}],
	// 通过iframe嵌入
	isIframe: getStorageBoolean('isIframe', false),
	// 通过redirectPage进入程序
	isByRedirectPage: getStorageBoolean('isByRedirectPage', false),
	// 系统设置
	sysParamsConfig: getStorageJson('sysParamsConfig', {}),
	// 应用版本号
	appVersion: getStorage('appVersion', '1.0.0'),
	// 新版本标记
	hasNewVersion: false,
	// 版本更新提示忽略标记
	ignoreVersionUpdate: false,
	// 电子秤称重单位 1:KG 2:斤 3:g
	weightUnit: getStorage('weightUnit', '1'),
	// 电子秤称重单位保留小数
	weightUnitDecimalPlace: getStorage('weightUnitDecimalPlace', '2'),
	// 品项排序 1:默认排序 2:重量适配法排序
	itemSortingType: getStorage('itemSortingType', '1'),
	// 合批页面明细展示  1 展开明细  2 收起明细
	batchDetailShowType: getStorage('batchDetailShowType', '2'),
	// 称重后自动打印
	weightAutoPrint: getStorageNumber('weightAutoPrint', 0),
	// 自动开启浮动拍照窗
	cameraFloatAuto: getStorageNumber('cameraFloatAuto', 0),
	// 自动拍照
	automaticPhotograph: getStorageNumber('automaticPhotograph', 0),
	// 静默连接秤设备名称
	deviceScaleName: getStorage('deviceScaleName', ''),
	// 静默连接秤设备地址
	deviceScaleAddress: getStorage('deviceScaleAddress', ''),
	// 品项二维码打印设置
	qrCodeSetting: getStorageJson('qrCodeSetting', QRCODE_SETTING),
	// 抹零规则 1:直接抹零 2:四舍五入
	roundDown: getStorage('roundDown', '1'),
	// 抹零保留小数位数
	roundDownDecimalPlace: getStorage('roundDownDecimalPlace', '1'),
	// 筐皮列表
	basketTareList: getStorageJson('basketTareList', [])
}
export default state

export type State = typeof state
