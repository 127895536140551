export const EMPTY_TAB_DATA = {
	id: 'empty',
	name: '空数据',
	listData: [{
		id: 'empty',
		name: '空数据',
		nickName: '空数据',
		pinYin: 'ksj'
	}]
}
