import utils from '@/js/utils'

type storegeType = 'localStorage' | 'sessionStorage'

const log = utils.fxDebug('fxVuex')
const getStorageHandler = function (key: string, type: storegeType = 'localStorage'): string {
	return window[type][key]
}
const setStorageHandler = function (key: string, val: string, type: storegeType = 'localStorage'): void {
	window[type].setItem(key, val)
}

const normalizationKey = function (key: string): string {
	return `${window.location.host}__${key}`
}
const getStorage = function <T> (lname: string, defaultValue: T, type: storegeType = 'localStorage', validity = 0): T | string {
	lname = normalizationKey(lname)
	if (validity) {
		return getStorageTimeStamp(lname, type, validity) || defaultValue
	} else {
		return getStorageHandler(lname, type) || defaultValue
	}
}
const getStorageNumber = function <T extends number> (lname: string, defaultValue: T, type: storegeType = 'localStorage'): T | number {
	lname = normalizationKey(lname)
	return parseFloat(getStorageHandler(lname, type)) || defaultValue
}
const getStorageBoolean = function <T extends boolean> (lname: string, defaultValue: T, type: storegeType = 'localStorage'): T | boolean {
	lname = normalizationKey(lname)
	const val = getStorageHandler(lname, type)
	if (val === '' || val === undefined) {
		return defaultValue
	} else {
		return val === 'true'
	}
}
const getStorageJson = function <T extends object> (lname: string, defaultValue: T, type: storegeType = 'localStorage'): T {
	lname = normalizationKey(lname)
	const value = utils.JSONparse(getStorageHandler(lname, type)) as T
	if (!value) {
		return defaultValue
	}
	return value
}
const setStorage = function <T> (key: string, val: T, type: storegeType = 'localStorage'): void {
	key = normalizationKey(key)
	if (utils.toRawType(val) === 'Object' || utils.toRawType(val) === 'Array') {
		setStorageHandler(key, JSON.stringify(val), type)
	} else {
		const _val = `${val}`
		setStorageHandler(key, _val, type)
	}
}
const setStorageTimeStamp = function <T> (key: string, val: T, type: storegeType = 'localStorage'): void {
	key = normalizationKey(key)
	const time = new Date().getTime()
	let _val = ''
	if (utils.toRawType(val) === 'Object' || utils.toRawType(val) === 'Array') {
		_val = JSON.stringify(val)
	}
	setStorageHandler(key, `fxVal=${_val}&fxTimeStamp=${time}`, type)
}
const getStorageTimeStamp = function (key: string, type: storegeType = 'localStorage', validity = 0): string {
	key = normalizationKey(key)
	const currentTime = new Date().getTime()
	const valObj = parseTimeStampValue(getStorageHandler(key, type))
	const time = valObj.fxTimeStamp || 0
	const interval = utils.ms2d(currentTime - time)
	const _val = valObj.fxVal || ''
	if (!validity || validity > interval) {
		return _val
	} else {
		return ''
	}
}
const parseTimeStampValue = function (val: string) {
	if (!val || !/^fxVal=/.test(val)) {
		return {}
	}
	try {
		const [valPair, timeStampPair] = val.split('&')
		const fxVal = valPair.split('=')[1]
		const fxTimeStamp = timeStampPair.split('=')[1]
		return {
			fxVal,
			fxTimeStamp: Number(fxTimeStamp)
		}
	} catch (error) {
		log('时间戳解析失败, 返回空值')
		return {}
	}
}
export {
	getStorage,
	getStorageNumber,
	getStorageBoolean,
	getStorageJson,
	setStorage,
	setStorageTimeStamp,
	getStorageTimeStamp
}
