import type { App, defineComponent } from 'vue'
import component from './ImagePreview.vue'

component.install = (app: App): void => {
	app.component(component.name, component)
}

type IWithInstall = ReturnType<typeof defineComponent> & { install(app: App): void }

const fxComponent: IWithInstall = component
export default fxComponent
