<template>
	<a-modal
		v-model:visible="visible"
		:title="title"
		:bodyStyle="bodyStyle"
		:width="modalWidth"
		:footer="showFooter"
		centered
		class="wind-textarea-dialog__modal"
	>
		<template #footer>
			<a-button key="submit" type="primary" class="confirm-btn" @click="handleOk">确定</a-button>
		</template>
		<div class="wind-textarea-dialog__container">
			<template v-if="inputType === 'textarea'">
				<a-textarea
					v-model:value="message"
					:placeholder="placeholder"
					:auto-size="{ minRows: 6, maxRows: 6 }"
					:maxlength="maxlength"
					class="textarea-input"
				/>
				<div class="textarea-input-length">{{`${message}`.length}}/{{maxlength}}</div>
			</template>
			<w-input-number
				ref="inputRef"
				v-if="inputType === 'number'"
				v-model:value="message"
				:max="max"
				:min="min"
				:pointSize="pointSize"
				:intLength="intLength"
				:integer="integer"
				class="amount-input"
				@pressEnter="onInputPressEnter"
			></w-input-number>
			<a-input
				ref="inputRef"
				v-if="inputType === 'input'"
				v-model:value="message"
				:placeholder="placeholder"
				:maxlength="maxlength"
				:allowClear="allowClear"
				@pressEnter="onInputPressEnter"
			/>
		</div>
	</a-modal>
</template>
<script lang="ts">
/**
 * TextareaDialog by shang 2022/10/14
 * @desc 文本域模态框
 *
 * @param {title} [String] - 标题
 * @param {value}  [String]
 * @param {placeholder}  [String]
 * @param {inputValue}  [String, Number] - 输入框内容
 * @param {maxlength}  [Number] - 最大输入字符数量
 * @param {inputType}  [String] - 输入框类型 textarea / input / number
 * @param {min}  [String, Number] - 最小值
 * @param {max}  [String, Number] - 最大值
 * @param {pointSize}  [String, Number] - 小数位
 * @param {intLength}  [String, Number] - 整数位
 * @param {integer}  [Boolean] - 是否int
 * @param {allowClear}  [Boolean] - 可以点击清除图标删除内容
 *
 * @event {on-confirm} 确定输入内容
 * @event {pressEnter} 按下回车确定
 */
import { defineComponent, ref, watch, computed } from 'vue'
export default defineComponent({
	name: 'wind-textarea-dialog',
	props: {
		value: Boolean,
		title: {
			type: String,
			default: '提示'
		},
		placeholder: {
			type: String,
			default: '请输入'
		},
		inputValue: {
			type: [String, Number],
			default: ''
		},
		maxlength: {
			type: Number,
			default: 50
		},
		inputType: {
			type: String,
			default: 'textarea'
		},
		min: [String, Number],
		max: [String, Number],
		pointSize: [String, Number],
		intLength: [String, Number],
		integer: Boolean,
		allowClear: Boolean
	},
	emits: ['update:value', 'on-confirm', 'pressEnter'],
	setup (props, { emit }) {
		const inputRef = ref()
		const visible = ref(false)
		watch(() => props.value, (val:boolean) => {
			visible.value = val
		}, {
			immediate: true
		})
		watch(() => visible.value, (val:boolean) => {
			emit('update:value', val)
			if (val) {
				message.value = props.inputValue
			}
		})
		const bodyStyle = computed(() => {
			switch (props.inputType) {
				case 'textarea':
					return {
						width: '45vw',
						height: '20vw',
						padding: '1vw 1vw 2vw 1vw'
					}
				case 'input':
				case 'number':
					return {
						width: '100vw',
						height: '6vw',
						padding: '1vw'
					}
				default:
					return {}
			}
		})
		const modalWidth = computed(() => {
			switch (props.inputType) {
				case 'textarea':
					return '45vw'
				case 'input':
				case 'number':
					return '100vw'
				default:
					return '45vw'
			}
		})
		const showFooter = computed(() => {
			return props.inputType === 'textarea' ? undefined : null
		})
		const handleOk = () => {
			visible.value = false
			emit('update:value', false)
			emit('on-confirm', message.value)
		}
		const message = ref<string|number>('')
		watch(() => props.inputValue, (val:string|number) => {
			message.value = val
		}, {
			immediate: true
		})
		const onInputPressEnter = () => {
			inputRef.value?.blur()
			visible.value = false
			emit('update:value', false)
			emit('pressEnter', message.value)
		}
		return {
			inputRef,
			visible,
			bodyStyle,
			modalWidth,
			showFooter,
			handleOk,
			message,
			onInputPressEnter
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.wind-textarea-dialog__modal {
    .confirm-btn {
        width: 100%;
    }
}
.wind-textarea-dialog__container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .textarea-input {
        margin-right: 10px;
        margin-left: 10px;
    }
    .textarea-input-length {
        position: absolute;
        right: 20px;
        bottom: 20px;
        color: #cccccc;
    }
    .amount-input {
        margin: auto;
        padding: 0 11px;
        width: 100%;
        height: 30px;
        border-radius: 2px;
        background-color: #f3f4f5;
        align-self: flex-start;
        line-height: 30px;
        &:focus {
            box-shadow: 0 0 0 0.208vw rgb(93 145 251 / 20%);
        }
    }
    :deep(.ant-input-affix-wrapper) {
        border: none;
        background-color: #f3f4f5;
        .ant-input {
            background-color: #f3f4f5;
        }
    }
}
</style>
<style lang="scss">
.ant-modal-wrap {
    .ant-modal.wind-textarea-dialog__modal {
        max-width: 100vw;
    }
}
</style>

