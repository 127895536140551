
export default {
	// 全部机构类型(包括企业, 机构, 供货商, 客户)
	allOrganType: [{
		type: 'group',
		id: '0',
		label: '企业',
		icon: 'organ-type-group',
		organLevel: '0'
	}, {
		type: 'shop',
		id: '2',
		label: '门店',
		icon: 'organ-shop',
		organLevel: '1'
	}, {
		type: 'rdc',
		id: '1',
		label: '配送中心',
		icon: 'organ-rdc',
		organLevel: '1'
	}, {
		type: 'store',
		id: '3',
		label: '仓库',
		icon: 'organ-store',
		organLevel: '2'
	}, {
		type: 'section',
		id: '4',
		label: '部门',
		icon: 'organ-section',
		organLevel: '2'
	}, {
		type: 'supplier',
		id: '5',
		label: '供货商主体',
		icon: 'organ-supplier',
		organLevel: '1'
	}, {
		type: 'customer',
		id: '6',
		label: '客户主体',
		icon: 'organ-customer',
		organLevel: '1'
	}]
}
