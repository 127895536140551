<template>
	<a-modal
		v-model:visible="confirmVisible"
		centered
		width="35vw"
		wrapClassName="mistake-confirm-dialog"
		:closable="false"
		:maskClosable="false"
	>
		<template #title>
			<div class="confirm-title">
				<exclamation-circle-filled class="warn-icon"/>
				{{confirmTitleText}}
			</div>
		</template>
		<template #footer>
			<div class="confirm-footer-btn">
				<a-button
					class="confirm-footer-btn-cancel"
					@click="onCancelClick"
				>
					取消
				</a-button>
				<a-button
					v-if="showIgnoreBtn"
					ghost
					type="primary"
					class="confirm-footer-btn-ignore"
					@click="onIgnoreClick"
				>
					忽略
				</a-button>
				<a-button
					v-if="showSyncPriceBtn"
					type="primary"
					class="confirm-footer-btn-handle"
					@click="onSyncPriceClick"
				>
					同步价格
				</a-button>
				<a-button
					v-else
					type="primary"
					class="confirm-footer-btn-handle"
					@click="onHandleClick"
				>
					去处理
				</a-button>
			</div>
		</template>
		{{confirmMessage}}
	</a-modal>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import { ExclamationCircleFilled } from '@ant-design/icons-vue'
export default defineComponent({
	name: 'fx-mistake-check-confirm',
	components: {
		ExclamationCircleFilled
	},
	props: {
		visible: Boolean,
		confirmTitleText: String,
		confirmMessage: String,
		showSyncPriceBtn: Boolean,
		showIgnoreBtn: {
			type: Boolean,
			default: true
		}
	},
	emits: ['update:visible', 'on-cancel-click', 'on-ignore-click', 'on-handle-click', 'on-sync-price-click'],
	setup (props, { emit }) {
		const confirmVisible = ref(false)
		watch(() => props.visible, (newValue) => {
			confirmVisible.value = newValue
		})
		watch(() => confirmVisible.value, (newValue) => {
			emit('update:visible', newValue)
		})
		const onCancelClick = () => {
			confirmVisible.value = false
			emit('on-cancel-click')
		}
		const onIgnoreClick = () => {
			confirmVisible.value = false
			emit('on-ignore-click')
		}
		const onHandleClick = () => {
			confirmVisible.value = false
			emit('on-handle-click')
		}
		const onSyncPriceClick = () => {
			confirmVisible.value = false
			emit('on-sync-price-click')
		}
		return {
			confirmVisible,
			onCancelClick,
			onIgnoreClick,
			onHandleClick,
			onSyncPriceClick
		}
	}
})
</script>

<style lang="scss">
@import "$assets/stylus/varsty";
.mistake-confirm-dialog {
    .ant-modal-header {
        padding-bottom: 5px;
        border-bottom: none;
        .ant-modal-title::before {
            display: none;
        }
    }
    .ant-modal-footer {
        border-top: none;
    }
    .ant-modal-body {
        padding: 12px 24px;
        padding-left: 50px;
    }
    .confirm-title {
        display: flex;
        align-items: center;
        .warn-icon {
            margin-right: 12px;
            font-size: 22px;
            color: $fxYellow3;
        }
    }
}
</style>
