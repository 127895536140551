import { createVNode } from 'vue'
import { Modal } from 'ant-design-vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'

const MESSAGE_BOX_WIDTH = '40vw'
let confirmVisible = false
let alterVisible = false
let promptVisible = false
const confirm = (options: string | { title: string, message: string }): Promise<string> => {
	return new Promise((resolve, reject) => {
		if (!confirmVisible) {
			confirmVisible = true
			let message = ''
			let title = '提示'
			if (typeof options === 'string') {
				message = options
			} else {
				title = options.title
				message = options.message
			}
			const messageList = message.split('<br>')
			Modal.confirm({
				title,
				icon: createVNode(ExclamationCircleOutlined),
				content: (
					messageList.map(item => {
						return <div>{item}</div>
					})
				),
				centered: true,
				width: MESSAGE_BOX_WIDTH,
				onOk () {
					confirmVisible = false
					resolve('ok')
				},
				onCancel () {
					const error = 'error'
					confirmVisible = false
					reject(error)
				},
				autoFocusButton: null
			})
		}
	})
}
const prompt = (options: string | { title: string, message: string, placeholder?: string, maxLength?: number, marginTop?:string }): Promise<string> => {
	return new Promise((resolve, reject) => {
		if (!promptVisible) {
			promptVisible = true
			let message = ''
			let title = '提示'
			let placeholder = '请输入'
			let maxLength = 300
			let marginTop = '10px'
			if (typeof options === 'string') {
				message = options
			} else {
				title = options.title
				message = options.message
				if (options.placeholder) {
					placeholder = options.placeholder
				}
				if (options.maxLength) {
					maxLength = options.maxLength
				}
				if (options.marginTop) {
					marginTop = options.marginTop
				}
			}
			Modal.confirm({
				title,
				icon: createVNode(ExclamationCircleOutlined),
				content: (
					<div>
						<span>{message}</span>
						<input type="text" id="prompt-input" class="ant-input" placeholder={placeholder} maxlength={maxLength} style={{ 'margin-top': marginTop }} />
					</div>
				),
				centered: true,
				width: MESSAGE_BOX_WIDTH,
				onOk () {
					promptVisible = false
					const userInput = (document.getElementById('prompt-input') as HTMLInputElement).value
					resolve(userInput)
				},
				onCancel () {
					const error = 'error'
					promptVisible = false
					reject(error)
				},
				autoFocusButton: null
			})
		}
	})
}
const alert = (options: string | { title: string, message: string, bodyStyle?: {}}, beforeClose?: (fn: Function) => void): Promise<string> => {
	return new Promise(resolve => {
		if (!alterVisible) {
			alterVisible = true
			let message = ''
			let title = '提示'
			let bodyStyle = {}
			if (typeof options === 'string') {
				message = options
			} else {
				title = options.title
				message = options.message
				bodyStyle = options.bodyStyle ? options.bodyStyle : {}
			}
			const messageList = message.split('<br>')
			Modal.info({
				title,
				icon: createVNode(ExclamationCircleOutlined),
				content: (
					messageList.map(item => {
						const regStr = /style="([^"]*)"/
						const style = item.match(regStr)
						if (style && style[1]) {
							return <div style={style[1]}>{item.replace(regStr, '')}</div>
						} else {
							return <div>{item}</div>
						}
					})
				),
				centered: true,
				width: MESSAGE_BOX_WIDTH,
				bodyStyle,
				maskClosable: false,
				keyboard: false,
				okText: '确定',
				onOk (close) {
					if (!beforeClose) {
						close()
						alterVisible = false
						resolve('ok')
					} else {
						const closeHandler = () => {
							close()
							alterVisible = false
							resolve('ok')
						}
						beforeClose(closeHandler)
					}
				}
			})
		}
	})
}
export default {
	confirm,
	alert,
	prompt
}
