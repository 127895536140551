import utilsType from './type'
import utilsDate from './date'
// 生成uuid by shw
const createUUID = function (): string {
	const random = Math.round(Math.random() * 1000000)
	return `${new Date().getTime()}${random}`
}
// 模糊查询string shw
const fuzzyQueryString = function (val: string[] | string, key: string): boolean {
	if (Array.isArray(val)) {
		return !!(~(val.findIndex(item => {
			return fuzzyQueryString(item, key)
		})))
	} else {
		return (val || '').toLowerCase().includes((key || '').toLowerCase())
	}
}
// 模糊查询obj shw
const fuzzyQueryObj = function <T extends object> (val: T, key: string, props: string[]): boolean {
	return !!props.find((item: string) => {
		if (~item.indexOf('.') && typeof val === 'object') {
			return fuzzyQuery(val, key, item)
		}
		return fuzzyQueryString(`${val[item as keyof T]}`, key)
	})
}
const fuzzyQuery = function <T extends object, K extends keyof T & string> (val: T, key: string, searchKey: K | string): boolean {
	if (~searchKey.indexOf('.') && utilsType.toRawType(val) === 'Object') {
		const searchKeys = searchKey.split('.')
		const firstKey = searchKeys.shift()
		const otherKey = searchKeys.join('.')
		const _val = val[firstKey as K] || ''
		if (Array.isArray(_val)) {
			return _val.some(item => {
				return fuzzyQuery(item, key, otherKey)
			})
		} else if (utilsType.toRawType(_val) === 'Object') {
			return fuzzyQuery(_val as Object, key, otherKey)
		} else {
			return fuzzyQueryString(`${_val}`, key)
		}
	} else {
		return fuzzyQueryString(`${val[searchKey as K]}`, key)
	}
}

// url参数转json by shw
const parameters2json = function (str: string): { [key: string]: string } {
	const params: { [key: string]: string } = {}
	str.replace(/([^?&]+)=([^?&]+)/g, function (s, v, k) {
		params[v] = decodeURIComponent(k)
		return `${k}=${v}`
	})
	return params
}

// 获取手工单号类型的年月日时分 by shw
const createBillCode = function (type = ''): string {
	return utilsDate.getDateTime(new Date(), (y: string | number, m: string | number, d: string | number, myH: string | number, myM: string | number) => {
		y = `${y}`.substr(-2)
		let random = ''
		for (let i = 0; i < 3; i++) {
			random += Math.floor(Math.random() * 10)
		}
		return `${type}${y}${m}${d}${myH}${myM}${random}`
	})
}

interface FileData {
	headers: {
		'content-disposition': string
	}
	data: string
}
// 后端返回文件流下载文件
const downloadFile = function (fileData: FileData | string, fileName = `excel-${new Date().getTime()}`): void {
	const Temp = document.createElement('a')
	let blobData: BlobPart = ''
	if (typeof fileData === 'object' && fileData.headers) {
		const disposition = fileData.headers['content-disposition']
		fileName = decodeURIComponent(disposition.match(/filename=(.*\.xlsx)/)![1] || `excel-${new Date().getTime()}`)
		blobData = fileData.data
	} else {
		blobData = fileData as BlobPart
	}
	const blob = new Blob([blobData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
	Temp.href = window.URL.createObjectURL(blob)
	Temp.download = fileName
	Temp.click()
	Temp.remove()
}


// 通过url下载文件
const downloadFileByUrl = function (url: string): void {
	const domA = document.createElement('a')
	domA.href = url
	domA.target = '_blank'
	domA.style.display = 'none'
	document.body.appendChild(domA)
	domA.click()
	document.body.removeChild(domA)
}

// selectTag通用查询方法
interface Item {
	id: string
	name: string
}
interface Select {
	name: string
}
const getSelectTagById = function (id: string, list: { [key: string]: Item[] }[], listData = 'listData') {
	const select: Select = { name: '' }
	for (let i = 0; i < list.length; i++) {
		const index = list[i][listData].findIndex(item => {
			return (item as Item).id === id
		})
		if (~index) {
			return list[i][listData][index]
		}
	}
	return select
}

export default {
	createUUID,
	fuzzyQueryString,
	fuzzyQueryObj,
	parameters2json,
	createBillCode,
	downloadFile,
	downloadFileByUrl,
	getSelectTagById
}
