import type { App } from 'vue'
import type { stateItem, FxCommonState } from '@/config/app_state_config'
import STATE_MODULE from '@/config/app_state_config'
import utils from '@/js/utils'
type PartialStateItem = Partial<stateItem>
const log = (msg: string) => {
	return utils.fxDebug('fxStateMiddleware')(msg)
}
const getCommonStateConfig = function <K extends keyof FxCommonState> (type: K) {
	return STATE_MODULE[type]
}
const getStateObjById = function <K extends keyof FxCommonState> (type: K) {
	return (val: string): PartialStateItem => {
		const states = getCommonStateConfig(type)
		return states.find(item => {
			return `${item.id}` === `${val}`
		}) || {}
	}
}

// 获取单据类型对象,单据状态包括审核状态或复审状态
const getOrderStateById = function (billState: number, recheckState: number) {
	if (Number(recheckState)) {
		return getCommonStateConfig('recheckState').find(item => {
			return `${item.id}` === `${recheckState}`
		}) || {}
	}
	return getCommonStateConfig('billState').find(item => {
		return `${item.id}` === `${billState}`
	}) || {}
}

const isState = function <K extends keyof FxCommonState> (type: K) {
	return (stateName: string[] | string) => {
		return (stateId: string) => {
			let states: string[] = []
			if (Array.isArray(stateName)) {
				states = stateName
			} else {
				states.push(stateName)
			}
			const values = getStateObjById(type)(stateId).type || ''
			if (!values) {
				log('无法匹配state, 没有配置state-type')
			}
			return states.includes(values as string)
		}
	}
}
/**
 * @name getCommonStateList
 * @desc 获取通用状态的select选项list
 * @author shw
 * @date 2022/05/11
 * @param {String} type 状态类型名称
 * @return Array
 */
const getCommonStateSelectList = function <K extends keyof FxCommonState> (type: K) {
	return STATE_MODULE[type].map(item => {
		return {
			id: item.id,
			label: item.label,
			stateIconColor: item.stateIconColor
		}
	})
}
export type StateMiddleware = Record<string, Function>
const stateMiddleware = {
	getCommonStateSelectList,
	getStateObjById,
	getOrderStateById,
	isState
}
const install = (app: App): void => {
	app.config.globalProperties.$fxStateMiddleware = stateMiddleware
}
export default {
	install,
	...stateMiddleware
}
