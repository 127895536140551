import utils from '@/js/utils'
import vuex from '@/store'
import type { ItemDetail as MachiningOrderItemDetail } from '@/views/bill_machining-order/OrderDetail.vue'
interface CheckBillRowItem {
	realCheckAmount1: number
	realCheckAmount2: number
	mainUnitAmount: number
	stockAmount1: number
	stockAmount2: number
	realCheckMoney: number
	stockMoney: number
	mainUnitStockAmount: number
	mainUnitPrice: number
	assistUnitAmount: number
	checkUnit1: {
		ratio: number
	}
	checkUnit2: {
		ratio: number
	}
	mainUnit: {
		ratio: number
	}
	item: {
		assistUnitRatio: number
	},
	assistUnitStockAmount: number
}
const moneyFixed = function (n: number): number {
	return utils.toFixedParseNumber(n, vuex.getters.getSysMoneyPointSize)
}
const priceFixed = function (n: number): number {
	return utils.toFixedParseNumber(n, vuex.getters.getSysPricePointSize)
}
const amountFixed = function (n: number): number {
	return utils.toFixedParseNumber(n, vuex.getters.getSysPointSize)
}
/*
 * 项目通用计算函数
 * 1.init为配置单据对应字段名, 此字段名为最终提交保存参数
 * 2.init以外属性为 追加函数
 */
const COMMON_PARAMS = {
	// 数量
	amount: 'amount',
	// 非税单价
	price: 'price',
	// 非税金额
	sumPrice: 'money',
	// 含税单价
	priceWithTax: 'taxPrice',
	// 含税金额
	sumPriceWithTax: 'taxMoney',
	// 税率
	taxes: 'taxRate',
	// 税额
	taxMoney: 'taxRateMoney',
	// 辅助数量
	assistUnitAmount: 'assistUnitAmount'
}
interface CalculationConfig {
	[key: string]: Record<string, unknown>
}
const calculationConfig: CalculationConfig = {
	// 通用计算函数
	common: {
		init: COMMON_PARAMS
	},
	// 入库单计算函数
	inStore: {
		init: {
			// 数量
			amount: 'amount',
			// 非税单价
			price: 'inPrice',
			// 非税金额
			sumPrice: 'inMoney',
			// 含税单价
			priceWithTax: 'inTaxPrice',
			// 税率
			taxes: 'taxRate',
			// 含税金额
			sumPriceWithTax: 'inTaxMoney',
			// 税额
			taxMoney: 'inTaxRateMoney',
			// 辅助数量
			assistUnitAmount: 'assistUnitAmount',
			// 合批最小单位数量
			mergeBatchPadMinItemUnitAmount: 'mergeBatchPadMinItemUnitAmount'
		}
	},
	// 出库单计算函数
	outStore: {
		init: {
			// 数量
			amount: 'amount',
			// 非税单价
			price: 'salesPrice',
			// 非税金额
			sumPrice: 'salesMoney',
			// 含税单价
			priceWithTax: 'salesTaxPrice',
			// 税率
			taxes: 'salesTaxRate',
			// 含税金额
			sumPriceWithTax: 'salesTaxMoney',
			// 税额
			taxMoney: 'salesTaxRateMoney',
			// 辅助数量
			assistUnitAmount: 'assistUnitAmount'
		}
	},
	// 盘点单计算函数
	takeStock: {
		init: {
			// 数量[实盘数量1]
			amount: 'realCheckAmount1',
			// 辅助数量
			assistUnitAmount: 'assistUnitAmount'
		},
		// 计算实盘金额
		computeRealCheckMoney (row: CheckBillRowItem): void {
			// 最小单位实盘数量=实盘数量1*盘点单位1的ratio + 实盘数量2*盘点单位2的ratio
			const amount1 = row.realCheckAmount1 * row.checkUnit1.ratio
			const amount2 = row.checkUnit2 ? (row.realCheckAmount2 * row.checkUnit2.ratio) : 0
			row.mainUnitAmount = amountFixed(amount1 + amount2)
			// 计算实盘金额
			if ((row.realCheckAmount1 - 0) === row.stockAmount1 && (row.realCheckAmount2 - 0) === row.stockAmount2) {
				// 实盘数量 等于 账存数量时，实盘金额 = 账存金额
				row.realCheckMoney = row.stockMoney
				// 消除小数计算误差，使最小单位实盘数量 = 最小单位账存数量
				row.mainUnitAmount = row.mainUnitStockAmount
			} else {
				// 实盘金额 = 最小单位单价 × 实盘数量（最小单位）
				row.realCheckMoney = moneyFixed(row.mainUnitPrice * row.mainUnitAmount)
			}
		},
		// 计算差异数量
		// 差异数量 = 最小单位实盘数量 -  最小单位账存数量
		computeDiffAmount (row: CheckBillRowItem): number {
			return amountFixed(row.mainUnitAmount - row.mainUnitStockAmount)
		},
		// 计算差异辅助数量
		// 差异辅助数量 = 实盘辅助数量 - 账存辅助数量
		computedDiffAssistAmount (row: CheckBillRowItem) {
			return amountFixed(row.assistUnitAmount - row.assistUnitStockAmount)
		},
		// 根据最小单位数量换算盘点单位1的数量
		computedCheckUnit1Amount (amount: number, row: CheckBillRowItem): number {
			return amountFixed(amount * row.mainUnit.ratio / row.checkUnit1.ratio)
		},
		// 计算辅助数量
		computeAssistAmount (row: CheckBillRowItem): void {
			row.assistUnitAmount = amountFixed(row.mainUnitAmount / row.item.assistUnitRatio)
		},
		// 计算差异单价
		// 差异单价 = 差异金额 / 差异数量
		computedDiffPrice (row: CheckBillRowItem) {
			return row.mainUnitAmount === row.mainUnitStockAmount ? 0 : priceFixed((row.realCheckMoney - row.stockMoney) / (row.mainUnitAmount - row.mainUnitStockAmount))
		},
		// 计算差异金额
		// 差异金额 = 实盘金额 -  账存金额
		computedDiffMoney (row: CheckBillRowItem): number {
			return moneyFixed(row.realCheckMoney - row.stockMoney)
		},
		// 修改实盘金额
		changeRealCheckMoney (row: CheckBillRowItem): void {
			row.mainUnitPrice = moneyFixed(row.realCheckMoney / row.mainUnitAmount)
		}
	},
	// 组合加工单
	machiningOrder: {
		init: COMMON_PARAMS,
		// 修改计划产量
		changePlanTotal (row: MachiningOrderItemDetail): void {
			row.planMakeCharge = moneyFixed(row.machineChargeConversion * row.planTotal)
			row.totalChange = true
			if (!row.id) {
				row.planTotalChange = true
			}
		},
		// 修改实际产量
		changeActualTotal (row: MachiningOrderItemDetail): void {
			if (!row.additionFlag) {
				row.makeCharge = moneyFixed(row.machineChargeConversion * row.actualTotal)
				row.totalChange = true
				row.productionCost = moneyFixed(row.actualTotal * row.productionCostPrice)
				if (!row.id && !row.planTotalChange) {
					row.planTotal = row.actualTotal
				}
			}
		},
		// 附成品修改生产成本单价
		changeProductionCostPrice (row: MachiningOrderItemDetail): void {
			row.productionCost = moneyFixed(row.productionCostPrice * row.actualTotal)
		},
		// 附成品修改生产成本
		changeProductionCost (row: MachiningOrderItemDetail): void {
			row.productionCostPrice = priceFixed(row.productionCost / row.actualTotal)
		}
	}
}
export default calculationConfig
