export default {
	'system-params': {
		id: 'system-params',
		label: '系统参数',
		code: '0002_0002',
		child: {
			'edit-params': {
				id: 'edit-params',
				label: '编辑系统参数',
				code: '0002_0002_002_001'
			}
		}
	}
}
