export default {
	'payment-order': {
		id: 'payment-order',
		label: '付款单',
		code: '0007_0005_0002',
		child: {
			'add-payment': {
				id: 'add-payment',
				label: '新建',
				code: '0007_0005_0002_001_001',
				decisionByAllModule: ['financial-manage_capital-manage_payment-order.edit-payment']
			},
			'edit-payment': {
				id: 'edit-payment',
				label: '编辑',
				code: '0007_0005_0002_002_001',
				controlledByAllModule: 'financial-manage_capital-manage_payment-order.add-payment'
			},
			'submit-payment': {
				id: 'submit-payment',
				label: '提交',
				code: '0007_0005_0002_009_001'
			},
			'back-payment': {
				id: 'back-payment',
				label: '退回',
				code: '0007_0005_0002_010_001'
			},
			'check-payment': {
				id: 'check-payment',
				label: '审核',
				code: '0007_0005_0002_011_001'
			},
			'uncheck-payment': {
				id: 'uncheck-payment',
				label: '反审',
				code: '0007_0005_0002_012_001'
			},
			'recheck-payment': {
				id: 'recheck-payment',
				label: '复审',
				code: '0007_0005_0002_013_001'
			},
			'unrecheck-payment': {
				id: 'unrecheck-payment',
				label: '取消复审',
				code: '0007_0005_0002_014_001'
			},
			'delete-payment': {
				id: 'delete-payment',
				label: '删除',
				code: '0007_0005_0002_004_001'
			},
			'print-payment': {
				id: 'print-payment',
				label: '打印',
				code: '0007_0005_0002_015_001'
			}
		}
	}
}
