import CUSTOMER_MANAGE from './customer-manage'
import EMPLOYEE_MANAGE from './employee-manage'
import ITEM_MANAGE from './item-manage'
import GOODS_MANAGE from './goods-manage'
import ORGAN_MANAGE from './organ-manage'
import PRICE_PLAN from './price-plan'
import ROLE_MANAGE from './role-manage'
import SUPPLIER_MANAGE from './supplier-manage'
import SUPPORT_ARCHIVES from './support-archives'
import UNIT_SETTING from './unit-setting'
import STORAGE_MANAGE from './storage-manage'
import BOM_SET from './bom-set'
import QUICK_OUT_PLAN from './quick-out-plan'
export default {
	'basic-archives': {
		id: 'basic-archives',
		label: '基础档案',
		code: '0001',
		child: {
			...CUSTOMER_MANAGE,
			...EMPLOYEE_MANAGE,
			...ITEM_MANAGE,
			...GOODS_MANAGE,
			...ORGAN_MANAGE,
			...PRICE_PLAN,
			...ROLE_MANAGE,
			...SUPPLIER_MANAGE,
			...SUPPORT_ARCHIVES,
			...UNIT_SETTING,
			...STORAGE_MANAGE,
			...BOM_SET,
			...QUICK_OUT_PLAN
		}
	}
}
