<template>
	<a-modal
		v-model:visible="visible"
		title="手工录入"
		:bodyStyle="{
			padding: '1vw 0'
		}"
		width="35vw"
		centered
		class="manually-weight__modal"
	>
		<template #footer>
			<div class="footer-container">
				<a-button type="primary" class="confirm-btn" @click="addWeight">累加</a-button>
				<a-button key="submit" type="primary" class="confirm-btn" @click="handleOk">确定</a-button>
			</div>
		</template>
		<div class="manually-weight__container">
			<fx-virtual-input
				ref="inputRef"
				v-model:value="weightValue"
				:max="max"
				:min="min"
				:pointSize="pointSize"
				:intLength="intLength"
				:inputWidth="260"
				class="amount-input"
			></fx-virtual-input>
			<div class="cell-end">当前单位：{{ weightUnitLabel }}，整数位数：3位，小数位数：{{ pointSize }}位</div>
		</div>
		<div class="scales-panel__keyboard">
			<fx-key-board></fx-key-board>
		</div>
	</a-modal>
</template>
<script lang="ts">
/**
 * TextareaDialog by shang 2022/10/14
 * @desc 文本域模态框
 *
 * @param {value}  [String]
 * @param {inputValue}  [String, Number] - 输入框内容
 * @param {maxlength}  [Number] - 最大输入字符数量
 * @param {min}  [String, Number] - 最小值
 * @param {max}  [String, Number] - 最大值
 * @param {pointSize}  [String, Number] - 小数位
 * @param {intLength}  [String, Number] - 整数位
 *
 * @event {on-confirm} 确定输入内容
 */
import { defineComponent, ref, watch, computed, nextTick } from 'vue'
import { useStore } from '@/store'
import { getFxInstance } from '@/js/instance'
export default defineComponent({
	name: 'manually-weight-dialog',
	props: {
		value: Boolean,
		maxlength: {
			type: Number,
			default: 50
		},
		min: [String, Number],
		max: [String, Number],
		pointSize: {
			type: [String, Number],
			default: 2
		},
		intLength: {
			type: [String, Number],
			default: 3
		}
	},
	emits: ['update:value', 'on-confirm', 'pressEnter'],
	setup (props, { emit }) {
		const store = useStore()
		const fxInstance = getFxInstance()
		const weightUnitLabel = computed(() => store.getters.getWeightUnitLabel)
		const weightUnitRatio = computed(() => store.getters.getWeightUnitRatio)
		const inputRef = ref()
		const visible = ref(false)
		watch(() => props.value, (val:boolean) => {
			visible.value = val
		}, {
			immediate: true
		})
		watch(() => visible.value, (val:boolean) => {
			emit('update:value', val)
			if (val) {
				weightValue.value = 0
				nextTick(() => {
					inputRef.value?.onFocus()
				})
			}
		})
		const addWeight = () => {
			visible.value = true
			const weight = fxInstance.$fxUtils.toFixedParseNumber(Number(weightValue.value) / weightUnitRatio.value, store.getters.getSysPointSize)
			emit('update:value', true)
			emit('on-confirm', {
				weight
			})
			weightValue.value = 0
		}
		const handleOk = () => {
			visible.value = false
			const weight = fxInstance.$fxUtils.toFixedParseNumber(Number(weightValue.value) / weightUnitRatio.value, store.getters.getSysPointSize)
			emit('update:value', false)
			emit('on-confirm', {
				weight
			})
		}
		const weightValue = ref<string|number>('')
		return {
			inputRef,
			visible,
			addWeight,
			handleOk,
			weightValue,
			weightUnitLabel
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.manually-weight__modal {
	.confirm-btn {
		width: 40%;
	}
	.footer-container {
		display: flex;
		justify-content: space-around;
		align-items: center;
	}
}
.manually-weight__container {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	height: 100%;
	.amount-input {
		padding: 0 10px;
		width: 280px;
		height: 40px;
		border: 1px solid #B4B4B4;
		border-radius: 2px;
		line-height: 40px;
	}
	.cell-end{
		margin: 10px 0;
		width: 280px;
		height: 12px;
		line-height: 12px;
		font-size: 12px;
		color: $fxGray4;
	}
}
</style>

