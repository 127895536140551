import IN_STORE from './in-store'
import OUT_STORE from './out-store'
import MOVE_ORDER from './move-order'
import TAKE_STOCK from './take-stock'
import PUTAWAY_TASK from './putaway-task'
import DELIVERY_ORDER from './delivery-order'
export default {
	'store-manage': {
		id: 'store-manage',
		label: '库存管理',
		code: '0003',
		child: {
			...IN_STORE,
			...OUT_STORE,
			...MOVE_ORDER,
			...TAKE_STOCK,
			...PUTAWAY_TASK,
			...DELIVERY_ORDER
		}
	}
}
