import SETTLEMENT_WAY from './settlement-way'
import ORGAN_MANAGE from './financial-organ-manage'
import SUPPLIER_MANAGE from './financial-supplier-manage'
import CUSTOMER_MANAGE from './financial-customer-manage'
export default {
	'financial-archives': {
		id: 'financial-archives',
		label: '财务档案',
		code: '0007_0006',
		child: {
			...SETTLEMENT_WAY,
			...ORGAN_MANAGE,
			...SUPPLIER_MANAGE,
			...CUSTOMER_MANAGE
		}
	}
}
