// 项目plugin
import type { App } from 'vue'
import plugin from '@/js/plugin'
import fxEventBus from '@/js/eventBus'
import fxIframePostMessage from '@/js/iframePostMessage'
import fxWeigh from '@/js/weigh'
import fxPrint from '@/js/print'
import fxRedCode from '@/js/redCode'
import fxCameraFloat from '@/js/cameraFloat'
import fxVersionVerification from '@/js/versionVerification'
import fxStyleDynamic from '@/js/styleDynamic'

const install = (app: App): void => {
	app.use(plugin)
		.use(fxEventBus)
		.use(fxIframePostMessage)
		.use(fxWeigh)
		.use(fxPrint)
		.use(fxRedCode)
		.use(fxCameraFloat)
		.use(fxVersionVerification)
		.use(fxStyleDynamic)
}
export default {
	install
}
