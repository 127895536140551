import { get } from '@/js/api/dataSource'
export default {
	// 单据必填设置
	orderRequired: {
		// 查询单据必填项
		getOrderRequired (type: string) {
			return get({ url: `archive/must_fill_set/v1/${type}` })
		}
	}
}
