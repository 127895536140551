// cordova-plugin-scanBluetooth
import windUi from '@/components/wind-ui'
import utils from '@/js/utils'

export interface UsbDevice {
	address: string
	name: string
	deviceType: number
}
const log = (msg: string) => {
	return utils.fxDebug('cordova-api')(msg)
}
// 开启扫描弹窗
const scan = function (): Promise<UsbDevice> {
	return new Promise(resolve => {
		try {
			window.ScanUsbDevice.showScanDialog(
				[''],
				function (json: string) {
					const device = JSON.parse(json)
					resolve(device || {})
				},
				function () {
					windUi.$fxMessage.error('扫描失败')
				}
			)
		} catch (error) {
			log('浏览器环境下无法使用scanUsb.scan功能')
			windUi.$fxMessage.error('扫描失败')
		}
	})
}
export default {
	scan
}
