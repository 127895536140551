import utils from '@/js/utils'
import { getModuleAuthConfigNameMap } from './mutations'
import type { State } from './state'

const getDetailsAuth = function (detailsAuth: State['detailsAuth'], moduleName: string) {
	return detailsAuth[moduleName] || {}
}
const hasAuth = function (state: State['detailsAuth'], moduleName: string, details: string) {
	const detailsAuthMap = getDetailsAuth(state, moduleName)
	return utils.logicJudgmentStr(details, (detail: string) => {
		return !!detailsAuthMap[detail]
	})
}
// 获取详情权限
export const detailsAuthGetter = (state: State) => (authKey: string): boolean => {
	const [moduleName, details] = authKey.split('.')
	return hasAuth(state.detailsAuth, moduleName, details)
}
// 获取模块权限
export const moduleAuthGetter = (state: State) => (moduleName: string): boolean => {
	const authMap = getModuleAuthConfigNameMap()
	return utils.logicJudgmentStr(moduleName, (moduleName: string) => {
		return authMap.has(moduleName) ? !!state.moduleAuth[moduleName] : true
	})
}
export const getAuthDetails = function (state: State): typeof state.detailsAuth {
	return state.detailsAuth
}
