// 单据生成类型依赖字段
export interface BillGenerateField {
	autoType?: number
	billType?: string
	subBillType?: string
	sourceBillType?: string
	billSign?: string
	sourceType?: string
	sendTtjNcFlag?: string
}

export interface BillGenerateTypeItem {
	id?: string | number
	type?: string
	label: string
	rule?: (organDetails: BillGenerateField) => boolean
}

export interface BillGenerateTypeObject {
	[key: string]: BillGenerateTypeItem
}

export const normalizationBillGenerateType = (GenerateTypeMap: BillGenerateTypeObject): BillGenerateTypeItem[] => {
	return Object.keys(GenerateTypeMap).map(key => {
		return {
			id: key,
			type: key,
			...GenerateTypeMap[key]
		}
	})
}
