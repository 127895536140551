<template>
	<div class="wind-calendar">
		<a-calendar
			v-model:value="calendarDate"
			:fullscreen="false"
			mode="month"
			value-format="YYYY-MM-DD"
			class="wind-calendar__date"
			@select="onPanelChange"
		>
		</a-calendar>
		<a-time-picker
			v-model:value="calendarTime"
			value-format="HH:mm"
			format="HH:mm"
			:allowClear="false"
			inputReadOnly
			class="wind-calendar__time"
			@change="onTimeChange"
		/>
	</div>
</template>
<script lang="ts">
/**
 * Calendar by shang 2022/9/21
 * @desc 日历
 *
 * @param {title} [str] - 标题
 * @param {value}  [str]
 * @param {isLink}  [boolean]
 *
 * @event {on-click} 选择时触发
 * @event {on-clear} 点击clear按钮时触发
 */
import { defineComponent, ref } from 'vue'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
export default defineComponent({
	name: 'w-calendar',
	props: {
		value: String
	},
	setup (props, { emit }) {
		const calendarDate = ref<Dayjs>()
		const calendarTime = ref<Dayjs>()
		let dateString = ''
		let timeString = ''
		const initDateTime = () => {
			const dateList = props.value?.split(' ')
			const [date, time] = dateList as string[]
			calendarDate.value = dayjs(date, 'YYYY-MM-DD')
			calendarTime.value = dayjs(time, 'HH:mm')
			dateString = date
			timeString = calendarTime.value.format('HH:mm')
		}
		const onPanelChange = (value: string) => {
			dateString = value
			emtiDate()
		}
		const onTimeChange = (value: string) => {
			timeString = value
			emtiDate()
		}
		const emtiDate = () => {
			const dateValue = `${dateString} ${timeString}:00`
			emit('on-change', dateValue)
			emit('update:value', dateValue)
		}
		initDateTime()
		return {
			calendarDate,
			calendarTime,
			onPanelChange,
			onTimeChange
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.wind-calendar {
    position:relative;
    &__date {
        :deep(.ant-picker-calendar-mode-switch) {
            width: 100px;
                label {
                display:none;
			}
        }
    }
    &__time {
        position: absolute;
        top: 12px;
        right: 7px;
        width: 100px;
        height: 24px;
    }
}
</style>
