interface IconMap { [key: string]: string }
const getImageUrl = (name: string) => {
	const path = `/src/assets/${name.replace(/^\@\/assets\//, '')}`
	// const modulePath = name.replace(/\@\/assets\/images\/icon/, '').replace(/\/[^/]*\.\w+$/, '')
	const modules = import.meta.globEager('/src/assets/images/icon/wind-ui/*')
	return (modules[path] as { default: string }).default
}
const iconList: IconMap = {
	scale: getImageUrl('@/assets/images/icon/wind-ui/icon-scale.png'),
	'scale-weight': getImageUrl('@/assets/images/icon/wind-ui/icon-scale-weight.png'),
	'scale-peel': getImageUrl('@/assets/images/icon/wind-ui/icon-scale-peel.png'),
	'scale-net': getImageUrl('@/assets/images/icon/wind-ui/icon-scale-net.png'),
	print: getImageUrl('@/assets/images/icon/wind-ui/icon-print.png'),
	user: getImageUrl('@/assets/images/icon/wind-ui/icon-user.png'),
	setting: getImageUrl('@/assets/images/icon/wind-ui/icon-setting.png'),
	order: getImageUrl('@/assets/images/icon/wind-ui/icon-order.png'),
	house: getImageUrl('@/assets/images/icon/wind-ui/icon-house.png'),
	'left-arrow': getImageUrl('@/assets/images/icon/wind-ui/icon-left-arrow.png'),
	'left-arrow-active': getImageUrl('@/assets/images/icon/wind-ui/icon-left-arrow-active.png'),
	'right-arrow-active': getImageUrl('@/assets/images/icon/wind-ui/icon-right-arrow-active.png'),
	'left-arrow-gray': getImageUrl('@/assets/images/icon/wind-ui/icon-left-arrow-gray.png'),
	'right-arrow-gray': getImageUrl('@/assets/images/icon/wind-ui/icon-right-arrow-gray.png'),
	search: getImageUrl('@/assets/images/icon/wind-ui/icon-search.png'),
	'signal-state': getImageUrl('@/assets/images/icon/wind-ui/icon-signal-state.png'),
	'battery-level': getImageUrl('@/assets/images/icon/wind-ui/icon-battery-level.png'),
	camera: getImageUrl('@/assets/images/icon/wind-ui/icon-camera.png'),
	'cell-link-arrow': getImageUrl('@/assets/images/icon/wind-ui/icon-cell-link-arrow.png'),
	'select-arrow': getImageUrl('@/assets/images/icon/wind-ui/icon-select-arrow.png'),
	empty: getImageUrl('@/assets/images/icon/wind-ui/icon-empty.png'),
	'right-identifier': getImageUrl('@/assets/images/icon/wind-ui/icon-right-identifier.png'),
	'right-identifier-fillblue': getImageUrl('@/assets/images/icon/wind-ui/icon-right-identifier-fillblue.png'),
	'right-identifier-fillgray': getImageUrl('@/assets/images/icon/wind-ui/icon-right-identifier-fillgray.png'),
	'check-circle-filled': getImageUrl('@/assets/images/icon/wind-ui/icon-check-circle-filled.png'),
	'scroll-top': getImageUrl('@/assets/images/icon/wind-ui/icon-scroll-top.png'),
	'checking-item': getImageUrl('@/assets/images/icon/wind-ui/icon-checking-item.png'),
	'trash-can': getImageUrl('@/assets/images/icon/wind-ui/icon-trash-can.png'),
	'delete-icon': getImageUrl('@/assets/images/icon/wind-ui/icon-delete-tag.png'),
	'purchase-info': getImageUrl('@/assets/images/icon/wind-ui/icon-purchase-info.png'),
	'assign-info': getImageUrl('@/assets/images/icon/wind-ui/icon-assign-info.png'),
	expand: getImageUrl('@/assets/images/icon/wind-ui/icon-expand.png'),
	fold: getImageUrl('@/assets/images/icon/wind-ui/icon-fold.png'),
	clock: getImageUrl('@/assets/images/icon/wind-ui/icon-clock.png'),
	'refresh-icon': getImageUrl('@/assets/images/icon/wind-ui/icon-refresh.png'),
	'weigh-trash-can': getImageUrl('@/assets/images/icon/wind-ui/icon-weight-trash-can.png'),
	'image-icon': getImageUrl('@/assets/images/icon/wind-ui/icon-image.png'),
	'camara-icon': getImageUrl('@/assets/images/icon/wind-ui/icon-camara.png'),
	'print-btn-icon': getImageUrl('@/assets/images/icon/wind-ui/icon-print-btn.png'),
	'right-arrow': getImageUrl('@/assets/images/icon/wind-ui/icon-right-arrow.png'),
	'back-icon': getImageUrl('@/assets/images/icon/wind-ui/icon-back.png')
}
export default iconList
