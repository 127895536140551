export default {
	'receipt-order': {
		id: 'receipt-order',
		label: '收款单',
		code: '0007_0005_0006',
		child: {
			'add-receipt': {
				id: 'add-receipt',
				label: '新建',
				code: '0007_0005_0006_001_001',
				decisionByAllModule: ['financial-manage_capital-manage_receipt-order.edit-receipt']
			},
			'edit-receipt': {
				id: 'edit-receipt',
				label: '编辑',
				code: '0007_0005_0006_002_001',
				controlledByAllModule: 'financial-manage_capital-manage_receipt-order.add-receipt'
			},
			'submit-receipt': {
				id: 'submit-receipt',
				label: '提交',
				code: '0007_0005_0006_009_001'
			},
			'back-receipt': {
				id: 'back-receipt',
				label: '退回',
				code: '0007_0005_0006_010_001'
			},
			'check-receipt': {
				id: 'check-receipt',
				label: '审核',
				code: '0007_0005_0006_011_001'
			},
			'uncheck-receipt': {
				id: 'uncheck-receipt',
				label: '反审',
				code: '0007_0005_0006_012_001'
			},
			'recheck-receipt': {
				id: 'recheck-receipt',
				label: '复审',
				code: '0007_0005_0006_013_001'
			},
			'unrecheck-receipt': {
				id: 'unrecheck-receipt',
				label: '取消复审',
				code: '0007_0005_0006_014_001'
			},
			'delete-receipt': {
				id: 'delete-receipt',
				label: '删除',
				code: '0007_0005_0006_004_001'
			},
			'print-receipt': {
				id: 'print-receipt',
				label: '打印',
				code: '0007_0005_0006_015_001'
			}
		}
	}
}
