import { setStorage } from '../utils'
import fxUtils from '@/js/utils'

export function SET_ORDER_REQUIRED_SETTING (state: Record<string, Record<string, object>>, res: Record<string, string> | Record<string, string>[]): void {
	if (fxUtils.isArray(res)) {
		state.appOrderRequiredConfig = {}
		res.forEach(item => {
			state.appOrderRequiredConfig[item.type] = fxUtils.JSONparse(item.data, 'object')
		})
	} else {
		state.appOrderRequiredConfig[res.type] = fxUtils.JSONparse(res.data, 'object')
	}
	setStorage('appOrderRequiredConfig', state.appOrderRequiredConfig)
}
