import utils from '@/js/utils'
import fxCommon from '@/js/common'
import vuex from '@/store'
import type { WeightItem } from '@/models/weight'
import type { Unit } from '@/models/common'
import type { FxItemQrCode } from '@/models/redCode'
import { getFxSoureQrCodeRemote } from '@/config/env.config'

const SOURCETRACE_URL = 'https://mobile.engine.fxscm.net/scm8/#/sourceTrace'
const SOURCETRACE_URL_TEST = 'https://order.fxscm.net/scm8/#/sourceTrace'
const getItemUnitRatio = <T> (item: T): number => {
	const _item = item as { [key: string]: Partial<Unit> }
	if (_item.unit) {
		return _item.unit?.ratio!
	} else if (_item.itemUnit) {
		return _item.itemUnit.ratio!
	} else if (_item.mitemUnit) {
		return _item.mitemUnit.ratio!
	}
	return 1
}
export const getAmount = function (printUnitCount: number, item: { item?: { canWeigh?: number, kgToMainUnit?: number } }, weighRecord: Partial<WeightItem>, defaultAmount: number) {
	if (utils.isDef(printUnitCount)) {
		return printUnitCount!
	} else if (fxCommon.isDetailCanWeigh(item)) {
		return fxCommon.getAmountByWeight(weighRecord.netWeight!, item.item!.kgToMainUnit!, getItemUnitRatio(item))
	} else {
		return defaultAmount
	}
}

export const formatterWeightRecord = function (weighRecord: Partial<WeightItem>): Partial<WeightItem> {
	if (utils.isEmptyObj(weighRecord)) {
		return weighRecord
	}
	return {
		grossWeight: vuex.getters.getWeightValue(weighRecord.grossWeight),
		netWeight: vuex.getters.getWeightValue(weighRecord.netWeight),
		tareWeight: vuex.getters.getWeightValue(weighRecord.tareWeight),
		unitName: vuex.getters.getWeightUnitLabel
	}
}

export const createFxItemQrCode = function (params: Partial<FxItemQrCode> = {}) {
	const splitFlag = '`~'
	const fieldMap = {
		entCode: vuex.getters.entCodeGetter || '0',
		version: '1.0',
		itemCategory: '0',
		itemCode: params.itemCode || '0',
		itemUnitId: params.itemUnitId || '0',
		itemBatchCode: '0',
		serialNumber: utils.getObjDeepValue(vuex.getters.getQrcodeSetting, 'itemCode.sequenceCode') ? utils.createUUID() : '0',
		itemAmount: utils.getObjDeepValue(vuex.getters.getQrcodeSetting, 'itemCode.amount') ? params.itemAmount || '0' : '0',
		suppilerCode: utils.getObjDeepValue(vuex.getters.getQrcodeSetting, 'itemCode.supplierCode') ? params.suppilerCode || '0' : '0',
		detailId: utils.getObjDeepValue(vuex.getters.getQrcodeSetting, 'itemCode.detail') ? params.detailId : '0',
		reserveField1: '0',
		reserveField2: '0',
		reserveField3: '0'
	}
	return Object.values(fieldMap).join(splitFlag)
}

export const createFxItemSourceQrCode = function (params: Partial<FxItemQrCode> = {}) {
	const url = getFxSoureQrCodeRemote() === 'release' ? SOURCETRACE_URL : SOURCETRACE_URL_TEST
	const billClass = params.billClass
	const shareId = params.shareId
	return url + '?billClass=' + billClass + '&shareId=' + shareId
}

