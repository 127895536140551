import { requestAnimationFrameInit } from './requestAnimationFrame'
const scrollTo = function ({ offsetTop = 0, $el = document.documentElement, distance = 100 }: { offsetTop: number, $el: HTMLElement, distance: number }): void {
	if (!window.requestAnimationFrame) {
		requestAnimationFrameInit()
	}
	let timer = -1
	let scrollTop = $el.scrollTop
	window.cancelAnimationFrame(timer)
	timer = window.requestAnimationFrame(function fn () {
		if (scrollTop < offsetTop) {
			scrollTop += distance
			$el.scrollTop = scrollTop
			timer = window.requestAnimationFrame(fn)
		} else {
			$el.scrollTop = offsetTop
			window.cancelAnimationFrame(timer)
		}
	})
}
const getComputedStyle = document.defaultView?.getComputedStyle.bind(document.defaultView)
const getScrollEventTarget = function (element: HTMLElement | Window, rootParent = window) {
	let node = element
	while (
		node &&
		node.tagName !== 'HTML' &&
		node.tagName !== 'BODY' &&
		node.nodeType === 1 &&
		node !== rootParent
	) {
		if (typeof getComputedStyle === 'function') {
			const { overflowY } = getComputedStyle(node as HTMLElement)
			if (overflowY === 'scroll' || overflowY === 'auto') {
				return node
			}
			node = node.parentNode
		}
	}
	return rootParent
}
const doScrollTop = function (element: HTMLElement, distance = 20, parent?: HTMLElement): void {
	const scrollEventTarget = getScrollEventTarget(element)
	const offsetTop = !parent ? element.offsetTop : getElementTop(element, parent)
	scrollTo({
		$el: scrollEventTarget as HTMLElement,
		offsetTop,
		distance
	})
}
const getElementTop = function (el: HTMLElement, parent: HTMLElement) {
	let actualTop = el.offsetTop
	let current = el.offsetParent as HTMLElement
	while (current !== null && current !== parent) {
		actualTop += current.offsetTop
		current = current.offsetParent as HTMLElement
	}
	return actualTop
}
export default {
	scrollTo,
	doScrollTop
}
