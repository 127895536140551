export default {
	'filter-setting': {
		id: 'filter-setting',
		label: '单据展示设置',
		code: '0002_0006',
		child: {
			'edit-filter': {
				id: 'edit-filter',
				label: '编辑单据展示设置',
				code: '0002_0006_002_001'
			}
		}
	}
}
