<template>
	<div class="fx-order-list-block">
		<div class="fx-order-list-block__top-cell">
			<a-checkbox
				v-show="showCheckbox"
				:checked="checked"
				class="top-cell__checkbox"
				@click="onCheckBoxClick"
				@change="onBlockCheckedChange"
			></a-checkbox>
			<slot name="top"></slot>
		</div>
		<slot></slot>
	</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
	name: 'fx-order-list-block',
	props: {
		checked: Boolean,
		showCheckbox: {
			type: Boolean,
			default: true
		}
	},
	emits: ['update:checked'],
	setup (props, { emit }) {
		const onCheckBoxClick = (event:Event) => {
			event.stopPropagation()
		}
		const onBlockCheckedChange = () => {
			emit('update:checked', !props.checked)
		}
		return {
			onCheckBoxClick,
			onBlockCheckedChange
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.fx-order-list-block {
	position:relative;
	margin: 10px 0;
    margin-left: 14px;
    padding-top: 5px;
    width: 390px;
    font-size: 15px;
    border-radius: 5px;
    background-color: $fxWhite;
	&__top-cell {
		display: flex;
		align-items: center;
		margin-right: 10px;
		padding-left: 10px;
		&__checkbox {
			margin-right: 10px;
		}
	}
}
</style>
