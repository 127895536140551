<template>
	<a-input
		v-model:value="value"
		:placeholder="placeholder"
		allowClear
		class="wind-search-bar"
		@change="onChangeHandler"
		@pressEnter="onPressEnterHandler"
	>
		<template #prefix>
			<w-icon type="search" class="search-icon"></w-icon>
		</template>
	</a-input>
</template>
<script lang="ts">
/**
 * SearchBar
 * @desc SearchBar 搜索框
 */
import { defineComponent, ref, watch } from 'vue'
import { getFxInstance } from '@/js/instance'
export default defineComponent({
	name: 'w-search-bar',
	props: {
		placeholder: {
			type: String,
			default: '请输入'
		},
		searchKey: {
			type: String,
			default: ''
		}
	},
	emits: ['search', 'update:searchKey', 'pressEnter'],
	setup (props, { emit }) {
		const fxInstance = getFxInstance()
		const value = ref(props.searchKey)
		watch(() => props.searchKey, (newValue) => {
			value.value = newValue
		})
		const onChangeHandler = () => {
			emit('update:searchKey', value.value)
			fxInstance.$fxUtils.fxDebounce(200, false, true).then(() => {
				emit('search', value.value)
			})
		}
		const onPressEnterHandler = () => {
			emit('pressEnter', value.value)
		}
		return {
			value,
			onChangeHandler,
			onPressEnterHandler
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.wind-search-bar {
    margin-left: 20px;
    width: 300px;
    border-radius: 24px
}
</style>
