// cordova-plugin-inappbrowser
import utils from '@/js/utils'
const log = (msg: string) => {
	return utils.fxDebug('cordova-api')(msg)
}
// 通过手机默认浏览器打开
const open = function (url: string, target: string, options?: string) {
	try {
		window.cordova.InAppBrowser.open(url, target, options)
	} catch (error) {
		log('浏览器环境下无法使用InAppBrowser.open功能')
	}
}

export default {
	open
}

