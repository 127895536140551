<template>
	<div :class="['wind-select-tag', `wind-select-tag--${type}`]">
		<div
			v-for="item in currentSelectList"
			:key="item.id"
			centered
			:class="['wind-select-tag__item', {
				'is-select': isSelect(item),
				'wind-select-tag__item-badge': showBadge,
				'is-disabled': disabled || item.disabled
			}, selectTagClass]"
			@click="onSelectClick(item)"
		>
			<div class="wind-select-tag__item-text">{{(item)[optionProps.name as keyof SelectItem]}}</div>
			<div v-if="showBadge" class="wind-select-tag__badge">{{badgeCount(item)}}</div>
		</div>
		<div
			v-if="showMoreBtn"
			class="wind-select-tag__item"
			@click="onMoreClick"
		>{{moreBtnMessage}}</div>
	</div>
</template>
<script lang="ts">
/**
 * SelectTag by shang 2022/8/25
 * @desc SelectTag 标签选择器
 */
import { defineComponent, ref, computed } from 'vue'
import type { PropType } from 'vue'
interface SelectItem {
	id: string
	name:string
	count: number
	disabled?: boolean
}
export default defineComponent({
	name: 'wind-select-tag',
	props: {
		selected: {
			type: [Array, String, Number] as PropType<string[]|String|Number>,
			default: () => {
				return []
			}
		},
		selectList: {
			type: Array as PropType<SelectItem[]>,
			default: () => {
				return []
			}
		},
		optionProps: {
			type: Object as PropType<{name:string, count:string}>,
			default: () => {
				return {
					name: 'label',
					count: 'count'
				}
			}
		},
		mulity: Boolean,
		showCount: {
			type: Number,
			default: 4
		},
		showBadge: Boolean,
		selectTagClass: String,
		disabled: Boolean,
		maxBadgeCount: {
			type: Number,
			default: 99
		},
		type: {
			type: String,
			default: 'normal'
		}
	},
	emits: ['update:selected', 'change'],
	setup (props, { emit }) {
		const isSelect = (selectItem: SelectItem) => {
			if (props.mulity && Array.isArray(props.selected)) {
				return props.selected.includes(selectItem.id)
			} else {
				return props.selected === selectItem.id
			}
		}
		const onSelectClick = (selectItem: SelectItem) => {
			if (props.disabled || selectItem.disabled) {
				return false
			}
			let selected = props.selected
			if (isSelect(selectItem)) {
				if (props.mulity && Array.isArray(selected)) {
					selected = selected.filter((item: string) => {
						return item !== selectItem.id
					})
				} else {
					return false
				}
			} else {
				if (props.mulity && Array.isArray(selected)) {
					selected.push(selectItem.id)
				} else {
					selected = selectItem.id
				}
			}
			emit('update:selected', selected)
			emit('change', selected, selectItem)
		}
		// {{{ showMore
		const showMoreFlag = ref(false)
		const moreBtnMessage = computed(() => {
			return showMoreFlag.value ? '隐藏' : '显示更多'
		})
		const showMoreBtn = computed(() => {
			return props.selectList.length > props.showCount
		})
		const onMoreClick = () => {
			showMoreFlag.value = !showMoreFlag.value
		}
		// }}}
		const currentSelectList = computed(() => {
			if (props.selectList.length <= props.showCount) {
				return props.selectList
			}
			if (showMoreFlag.value) {
				return props.selectList
			}
			return props.selectList.slice(0, props.showCount)
		})
		const badgeCount = computed(() => {
			const countKey = props.optionProps.count as keyof SelectItem
			return (item:SelectItem) => {
				return item[countKey] ? Number(item[countKey]) > props.maxBadgeCount ? `${props.maxBadgeCount}+` : item[countKey] : 0
			}
		})
		return {
			isSelect,
			onSelectClick,
			onMoreClick,
			moreBtnMessage,
			showMoreBtn,
			currentSelectList,
			badgeCount
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.wind-select-tag {
    display: flex;
    overflow: auto;
    min-height: 40px;
    flex: 1;
    flex-flow: row wrap;
    align-content: flex-start;
    &__item {
        position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
        margin-right: 10px;
        margin-bottom: 10px;
        width: 115px;
        height: 35px;
        font-size: 12px;
        border-radius: 20px;
        text-align: center;
        background-color: #eeeeee;
        &.is-select {
            color: $fxWhite;
            background-color: $fxBlue;
            .wind-select-tag__badge {
                background-color: #fc4e49;
            }
        }
		&.is-disabled {
			opacity: 0.7;
		}
        &-badge {
            margin-top: 12px;
            margin-right: 30px;
            margin-bottom: 8px;
            width: 80px;
            height: 30px;
            font-size: 14px;
            line-height: 30px;
        }
        &-text {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    &__badge {
        position: absolute;
        top: -8px;
        left: 88%;
		display: flex;
		justify-content: center;
		align-items: center;
        padding: 0 6px;
        width: max-content;
        min-width: 28px;
        height: 20px;
        font-size: 14px;
        border-radius: 10px;
        color: #ffffff;
        background-color: #f57676;
        line-height: 20px;
        border-bottom-left-radius: 0;
    }
}
.wind-select-tag--mini {
	min-height: 20px;
	.wind-select-tag__item {
		width: 55px;
		height: 20px;
		.wind-select-tag__badge {
			width: 20px;
			min-width: 0;
			height: 10px;
			font-size: 10px;
		}
	}
	.wind-select-tag__item-badge {
		margin-top: 0;
	}
}
.wind-select-tag--small {
	min-height: 30px;
	.wind-select-tag__item {
		width: 65px;
		height: 30px;
		.wind-select-tag__badge {
			width: 20px;
			min-width: 0;
			height: 10px;
			font-size: 10px;
		}
	}
	.wind-select-tag__item-badge {
		margin-top: 0;
	}
}
</style>
