import type { RequestConfig } from '@/js/api/dataSource'
import { post, put, get, del } from '@/js/api/dataSource'
import fxCommon from '@/js/common'
const API_MODULE = 'purchasePlanOrder'
export default {
	// 采购计划单
	purchasePlanOrder: {
		// {{{ list页面筛选条件
		// 获取入库方tab下拉选择数据
		getOrganSelectTabList (): RequestConfig {
			// 入库方——‘机构’、‘配送中心’
			const params = { organTypeList: ['2', '1'] }
			return post({ url: 'archive/organ/v1/manage/simple/tab', data: params })
		},
		// 获取出库方tab下拉选择数据
		getOtherOrganSelectTabList (): RequestConfig {
			// 出库方——‘机构’、‘配送中心’、‘供货商’、‘客户’
			const params = { organTypeList: ['2', '1', '5', '6'] }
			return post({ url: 'archive/organ/v1/manage/simple/tab', data: params })
		},
		// 获取单据类型下拉选择数据
		getBillTypeSelectList (): RequestConfig {
			return get({ url: 'bill/pad/stock_plans/v1/types' })
		},
		// }}}
		// {{{ 采购计划单list页
		// 获取采购计划单list数据
		getBillList (): RequestConfig {
			return post({
				url: 'bill/pad/stock_plans/v1/page?del_flag=false',
				success: res => {
					res || (res = { result: [], total: 0 })
					return res
				}
			})
		},
		// 批量删除
		delMulity (): RequestConfig {
			return put({ url: 'bill/pad/stock_plans/v1/delete', successToast: true })
		},
		// 批量提交
		submitMulity (): RequestConfig {
			return put({ url: 'bill/pad/stock_plans/v1/submit', successToast: true })
		},
		// 批量退回
		returnMulity (): RequestConfig {
			return put({ url: 'bill/pad/stock_plans/v1/back', successToast: true })
		},
		// 批量审核
		checkMulity (): RequestConfig {
			return put({ url: 'bill/pad/stock_plans/v1/check', successToast: true })
		},
		// 批量反审
		uncheckMulity (): RequestConfig {
			return put({ url: 'bill/pad/stock_plans/v1/uncheck', successToast: true })
		},
		// }}}
		// {{{ 新增采购计划单
		// 获取买方机构列表
		getOrganList (): RequestConfig {
			return post({
				url: 'archive/bill/employee_organ/v1/buyer_organ_list_new',
				data: {
					scmPadFlag: 'pad'
				}
			})
		},
		// 获取仓库列表
		getStoreList (organId: string): RequestConfig {
			return post({ url: `archive/bill/house/v1/organ/${organId}/houseList` })
		},
		// 获取经办人列表
		getAgentList (): RequestConfig {
			return post({ url: 'archive/bill/employee/v1/operator_list' })
		},
		// 详情-根据单据种类获取可使用单据业务类型
		getBillDetailBizTypeSelect (type: string): RequestConfig {
			return post({ url: `archive/bill/bill_biz_type/v1/bill_type/200/${type}`, loading: false })
		},
		// 获取采购方案列表
		getStockPlanList (): RequestConfig {
			return post({ url: 'plan/stock/v1/stock_plan_list' })
		},
		// 新增采购计划单接口
		addBill (): RequestConfig {
			return post({
				url: 'bill/pad/stock_plans/v1',
				successToast: true,
				success: res => fxCommon.normalizationBillUpdateVersion(API_MODULE, res)
			})
		},
		// }}}
		// {{{ 添加品项
		// 添加品项-查询品项列表--分未添加、已添加
		getItemList (billId: string): RequestConfig {
			return post({
				url: `bill/pad/stock_plans/v1/${billId}/weigh`,
				success: res => {
					res.stockPlan || (res.stockPlan = { id: '' })
					return fxCommon.normalizationBillUpdateVersion(API_MODULE, res)
				}
			})
		},
		// 新增品项明细
		addDetail (billId: string): RequestConfig {
			return post({
				url: `bill/pad/stock_plans/${billId}/detail/v1`,
				data: { ...fxCommon.getBillDetailUpdateVersion(API_MODULE, billId), checkAccept: 1 },
				success: res => fxCommon.normalizationBillDetailUpdateVersion(API_MODULE, res, billId),
				successToast: true
			})
		},
		// 保存修改品项明细
		saveDetail (billId: string): RequestConfig {
			return put({
				url: `bill/pad/stock_plans/${billId}/detail/v1`,
				data: { ...fxCommon.getBillDetailUpdateVersion(API_MODULE, billId), checkAccept: 1 },
				success: res => fxCommon.normalizationBillDetailUpdateVersion(API_MODULE, res, billId),
				successToast: true
			})
		},
		// 删除单条明细
		delDetail (billId: string): RequestConfig {
			return put({ url: `bill/pad/stock_plans/${billId}/detail/v1/batch/del`, successToast: true })
		},
		// 根据品项查询单位列表
		getUnitList (itemId: string, billId: string): RequestConfig {
			return post({ url: `bill/pad/stock_plans/${billId}/detail/v1/unitList/${itemId}` })
		},
		// }}}
		// {{{ 采购计划单单据详情
		// 获取单据详情
		getBillDetail (billId: string): RequestConfig {
			return get({
				url: `bill/pad/stock_plans/v1/${billId}`,
				success: res => {
					res.stockPlan || (res.stockPlan = { id: '' })
					res.inHouse || (res.inHouse = { id: '' })
					res.appendixList || (res.appendixList = [])
					return fxCommon.normalizationBillUpdateVersion(API_MODULE, res)
				}
			})
		},
		// 明细清空
		clearDetails (billId: string): RequestConfig {
			return put({ url: `bill/pad/stock_plans/${billId}/detail/v1/clear` })
		},
		// 明细查询库存
		stockAmount (billId: string): RequestConfig {
			return post({ url: `bill/pad/stock_plans/${billId}/detail/v1/batch_update/sell/price`, loading: false })
		},
		// 保存单据
		saveBill (billId: string): RequestConfig {
			return put({
				url: `bill/pad/stock_plans/v1/${billId}`,
				successToast: true,
				data: {
					...fxCommon.getBillUpdateVersion(API_MODULE, billId),
					...fxCommon.getTenantIdParam()
				},
				success: res => fxCommon.normalizationBillUpdateVersion(API_MODULE, res)
			})
		},
		// 提交单据
		submitBill (billId: string): RequestConfig {
			return put({ url: `bill/pad/stock_plans/v1/${billId}/submit`, successToast: true })
		},
		// 退回单据
		returnBill (billId: string): RequestConfig {
			return put({ url: `bill/pad/stock_plans/v1/back/${billId}`, successToast: true })
		},
		// 审核单据
		checkBill (billId: string): RequestConfig {
			return post({ url: `bill/pad/stock_plans/v1/${billId}/check`, successToast: true })
		},
		// 反审单据
		uncheckBill (billId: string): RequestConfig {
			return put({ url: `bill/pad/stock_plans/v1/uncheck/${billId}`, successToast: true })
		},
		// 删除单据
		deleteBill (billId: string): RequestConfig {
			return del({ url: `bill/pad/stock_plans/v1/${billId}/delete`, successToast: true })
		},
		// }}},
		// {{{ 异常警告
		// （必订）获取可选仓库列表
		getMustItemStore (): RequestConfig {
			return post({ url: 'bill/mobile/stock_plans/v1/house' })
		},
		// 提交/审核前的异常警告接口
		checkMistake (billId: string): RequestConfig {
			return post({
				url: `bill/pad/stock_plans/v1/mistake_proofing/${billId}`,
				success: (res) => {
					res.errorDetailList = res.errorDetailList || res.mustItemList
					fxCommon.syncBillVersionByMistakeDetails(API_MODULE, billId, res)
					return res
				}
			})
		},
		// 查询必定品项
		getMust (billId: string): RequestConfig {
			return post({
				url: `bill/pad/stock_plans/v1/mistake_proofing/${billId}?error_check=false`,
				data: ['Must'],
				loading: false,
				success: (res) => {
					res.errorDetailList = res.errorDetailList || res.mustItemList
					fxCommon.syncBillVersionByMistakeDetails(API_MODULE, billId, res)
					return res
				}
			})
		},
		// 查询重复品项的明细
		getRepeat (billId: string): RequestConfig {
			return post({
				url: `bill/pad/stock_plans/v1/mistake_proofing/${billId}?error_check=false`,
				data: ['Repeat'],
				loading: false,
				success: (res) => {
					fxCommon.syncBillVersionByMistakeDetails(API_MODULE, billId, res)
					return res
				}
			})
		},
		// 查询存在数量为0的明细
		getZeroAmount (billId: string): RequestConfig {
			return post({
				url: `bill/pad/stock_plans/v1/mistake_proofing/${billId}?error_check=false`,
				data: ['ZeroAmount'],
				loading: false,
				success: (res) => {
					fxCommon.syncBillVersionByMistakeDetails(API_MODULE, billId, res)
					return res
				}
			})
		},
		// 查询未关联品项
		getRel (billId: string): RequestConfig {
			return post({
				url: `bill/pad/stock_plans/v1/mistake_proofing/${billId}?error_check=false`,
				data: ['ItemHouseRelation'],
				loading: false,
				success: (res) => {
					fxCommon.syncBillVersionByMistakeDetails(API_MODULE, billId, res)
					return res
				}
			})
		},
		// 查询关联必定品项
		getMustOrder (billId: string): RequestConfig {
			return post({
				url: `bill/pad/stock_plans/v1/mistake_proofing/${billId}?error_check=false`,
				data: ['MustOrder'],
				loading: false,
				success: (res) => {
					res.errorDetailList = res.errorDetailList || res.mustItemList
					fxCommon.syncBillVersionByMistakeDetails(API_MODULE, billId, res)
					return res
				}
			})
		},
		// 查询卖方参考库存不足品项
		getInsufficientInventory (billId: string): RequestConfig {
			return post({
				url: `bill/pad/stock_plans/v1/mistake_proofing/${billId}?error_check=false`,
				data: ['InsufficientInventory'],
				loading: false,
				success: (res) => {
					fxCommon.syncBillVersionByMistakeDetails(API_MODULE, billId, res)
					return res
				}
			})
		},
		// }}}
		// 根据机构查询订货分类
		searchClassByOrgan (): RequestConfig {
			return post({ url: 'archive/store_order_class/v1/searchByOrgan' })
		}
	}
}
