import { fxApi } from '@/js/api'
import type { AuthConfig, AuthConfigListItem } from '@/config/auth_config'
import { AUTH_CONFIG } from '@/config/auth_config'
import { normalizationAuthCode } from './mutations'
import utils from '@/js/utils'


function authName2Code (moduleName: string) {
	const moduleNameSplitList = moduleName.split('_')
	const moduleItem = moduleNameSplitList.reduce((prev: AuthConfig, cur: string, index: number, list: string[]) => {
		if (index === list.length - 1) {
			return (prev![cur]) as unknown as AuthConfig
		}
		return (prev![cur].child) as AuthConfig
	}, AUTH_CONFIG)
	return normalizationAuthCode(moduleItem.code as unknown as string)
}
function getDetailsAuthConfigMap (moduleName: string) {
	const authMap = new Map()
	const moduleNameSplitList = moduleName.split('_')
	const detailsAuth = moduleNameSplitList.reduce((prev: AuthConfig, cur: string) => {
		return (prev![cur].child) as AuthConfig
	}, AUTH_CONFIG)
	Object.values(detailsAuth).forEach(item => {
		authMap.set(normalizationAuthCode(item.code), item.id)
	})
	return authMap
}

function normalizationTreePrivileges (data: AuthConfigListItem[]) {
	const authObj: { [key: string]: AuthConfigListItem[] } = {}
	data.forEach(item => {
		if (item.children) {
			authObj[item.code] = item.children
		}
	})
	return authObj
}

function normalizationDetailsAuthData (data: AuthConfigListItem[], moduleName: string) {
	const authMap = getDetailsAuthConfigMap(moduleName)
	const authObj: { [key: string]: string } = {}
	data.forEach(item => {
		if (authMap.has(item.code)) {
			const authName = authMap.get(item.code)
			authObj[authName] = item.code
		} else {
			utils.fxDebug(`${item.name}-${item.code}不在当前详情权限列表, 请添加后再操作`)
		}
	})
	return authObj
}

export function syncModuleAuth<T extends { haveModulars: string }> ({ commit }: { commit: Function }): Promise<T> {
	return new Promise(resolve => {
		return fxApi('common.getCurrentAuth').then((res: T) => {
			commit('SET_AUTH_MODULE', res.haveModulars)
			resolve(res)
		})
	})
}

export function syncDetailsAuth ({ commit }: { commit: Function }, { moduleName, loading = true }: { moduleName: string[], loading: boolean }) {
	const moduleList = moduleName.map(moduleName => {
		return {
			moduleName,
			code: authName2Code(moduleName)
		}
	})
	const fatherCodeList = moduleList.map(item => item.code)
	return fxApi('common.getCurrentAuth', fatherCodeList, loading).then(res => {
		const privilegesMap = normalizationTreePrivileges(res.treeHavePrivilegesData)
		const moduleDetaislAuthMap: { [key: string]: ReturnType<typeof normalizationDetailsAuthData> } = {}
		moduleList.forEach(item => {
			const privilege = privilegesMap[item.code]
			moduleDetaislAuthMap[item.moduleName] = privilege ? normalizationDetailsAuthData(privilege, item.moduleName) : {}
		})
		commit('SET_AUTH_DETAILS_MULITY', { moduleDetaislAuthMap })
		return Promise.resolve()
	})
}
