import type { App } from 'vue'

export class FxEventBus {
	events: { [key: string]: Function[] }
	constructor () {
		this.events = {}
	}

	emit<T> (eventName: string, data: T): void {
		if (this.events[eventName]) {
			this.events[eventName].forEach(function (fn) {
				fn(data)
			})
		}
	}

	on (eventName: string, fn: Function): void {
		this.events[eventName] = this.events[eventName] || []
		this.events[eventName].push(fn)
	}

	off (eventName: string, fn: Function): void {
		if (this.events[eventName]) {
			for (let i = 0; i < this.events[eventName].length; i++) {
				if (this.events[eventName][i] === fn) {
					this.events[eventName].splice(i, 1)
					break
				}
			}
		}
	}
}

export const fxEventBus = new FxEventBus()

const install = (app: App): void => {
	app.config.globalProperties.$fxEventBus = fxEventBus
}

export default {
	install
}

