// cordova-plugin-scanBluetooth
import windUi from '@/components/wind-ui'
import utils from '@/js/utils'

interface BlueDevice {
	address: string
	name: string
	deviceType: number
}
interface BlueStateInfo {
	operateType: number
	bluetoothInfo: {
		stateCode: number
	}
	deviceInfo: {
		address: string
		name: string
		deviceType: number
	}
}
const log = (msg: string) => {
	return utils.fxDebug('cordova-api')(msg)
}
export interface ScanBluetoothInstance {
	scan: () => Promise<BlueDevice>
	registerBluetoothReceiver: () => Promise<BlueStateInfo>
}

// 开启扫描弹窗
const scan = function (): Promise<BlueDevice> {
	return new Promise(resolve => {
		try {
			window.ScanBluetooth.showScanDialog(
				[''],
				function (json: string) {
					const device = JSON.parse(json)
					resolve(device || {})
				},
				function () {
					windUi.$fxMessage.error('扫描失败')
				}
			)
		} catch (error) {
			log('浏览器环境下无法使用scanBluetooth.scan功能')
			windUi.$fxMessage.error('扫描失败')
		}
	})
}
// 注册蓝牙监听
const registerBluetoothReceiver = function (cb: (blueStateInfo: BlueStateInfo) => void): void {
	try {
		window.ScanBluetooth.registerBluetoothReceiver(
			[''],
			function (json: string) {
				const blueStateInfo = JSON.parse(json)
				cb(blueStateInfo || {})
			},
			function () {
			}
		)
	} catch (error) {
		log('浏览器环境下无法使用scanBluetooth.registerBluetoothReceiver功能')
	}
}
export default {
	scan,
	registerBluetoothReceiver
}
