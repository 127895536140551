<template>
	<div class="item-list__category-list">
		<div
			v-for="itemCategory in categoryList"
			:key="itemCategory.id"
			class="item-list__category-item fx-ellipsis"
			:class="{'is-active':value===itemCategory.id}"
			@click="onItemCategoryClick(itemCategory)"
		>{{itemCategory.name}}</div>
	</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
export default defineComponent({
	name: 'categoryList',
	props: {
		value: String,
		categoryList: {
			type: Array as PropType<{id: string, name: string}[]>,
			default: () => {
				return []
			}
		}
	},
	emits: ['update:value', 'on-category-click'],
	setup (props, { emit }) {
		const onItemCategoryClick = (item:{id: string, name: string}) => {
			emit('update:value', item.id)
			emit('on-category-click', item.id)
		}
		return {
			onItemCategoryClick
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.item-list__category-list {
	overflow: auto;
	width: 100px;
	background-color: $fxGray20;
	.item-list__category-item {
		position: relative;
		padding-left: 5px;
		height: 60px;
		text-align: center;
		line-height: 60px;
		&.is-active::before {
			position: absolute;
			top: 0;
			left: 0;
			width: 5px;
			height: 60px;
			background-color: $fxDefaultColor;
			content: "";
		}
	}
}
</style>
