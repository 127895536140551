import { AUTH_CONFIG } from '@/config/auth_config'
import { setStorage } from '../utils'
import utils from '@/js/utils'
import type { State } from './state'

export function normalizationAuthCode (code: string) {
	return /^(?!scm_)/.test(code) ? `scm_${code}` : code
}

export function getModuleAuthConfigMap () {
	const authMap = new Map()
	const setAuthMap = function (authConfig: typeof AUTH_CONFIG, isRoot: boolean, mapKey = '') {
		Object.values(authConfig).forEach(item => {
			const _mapKey = isRoot ? item.id : `${mapKey}_${item.id}`
			authMap.set(normalizationAuthCode(item.code), _mapKey)
			if (item.child) {
				setAuthMap(item.child, false, _mapKey)
			}
		})
	}
	setAuthMap(AUTH_CONFIG, true)
	return authMap
}

export function getModuleAuthConfigNameMap () {
	const authMap = new Map()
	const setAuthMap = function (authConfig: typeof AUTH_CONFIG, isRoot: boolean, mapKey = '') {
		Object.values(authConfig).forEach(item => {
			const _mapKey = isRoot ? item.id : `${mapKey}_${item.id}`
			authMap.set(_mapKey, normalizationAuthCode(item.code))
			if (item.child) {
				setAuthMap(item.child, false, _mapKey)
			}
		})
	}
	setAuthMap(AUTH_CONFIG, true)
	return authMap
}
function normalizationModuleAuthData (data: { code: string, name: string }[]) {
	const authMap = getModuleAuthConfigMap()
	const authObj: { [key: string]: string } = {}
	data.forEach(item => {
		if (authMap.has(item.code)) {
			const authName = authMap.get(item.code)
			authObj[authName] = item.code
		} else {
			utils.fxDebug(`${item.name}-${item.code}不在当前菜单权限列表, 请添加后再操作`)
		}
	})
	return authObj
}


export function SET_AUTH_MODULE<T extends Partial<State>> (state: T, auth: { code: string, name: string }[]) {
	const moduleAuth = normalizationModuleAuthData(auth)
	state.moduleAuth = moduleAuth
	setStorage('moduleAuth', state.moduleAuth)
}

export function SET_AUTH_DETAILS_MULITY<T extends Partial<State>> (state: T, { moduleDetaislAuthMap }: { moduleDetaislAuthMap: State['detailsAuth'] }) {
	const detailsAuth: State['detailsAuth'] = {}
	Object.keys(moduleDetaislAuthMap).forEach(moduleAuth => {
		detailsAuth[moduleAuth] = moduleDetaislAuthMap[moduleAuth]
	})
	state.detailsAuth = detailsAuth
	setStorage('detailsAuth', state.detailsAuth)
}

