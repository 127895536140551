<template>
	<div class="wind-header">
		<w-icon v-show="showBackBtn" type="left-arrow" class="wind-header__back" @click="onBackClick"></w-icon>
		<div class="wind-header__title">{{title}}</div>
		<div class="wind-header__center" :class="centerSlotClass">
			<slot name="header-center"></slot>
		</div>
		<div v-if="centerSlotAlign==='center'" class="wind-header__center"></div>
		<div v-show="$slots['header-right']" class="wind-header__right">
			<slot name="header-right"></slot>
		</div>
	</div>
</template>
<script lang="ts">
/**
 * Header by shang 2022/8/11
 * @desc Header 顶部标题栏
 */
import { defineComponent, computed } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
	name: 'wind-header',
	props: {
		title: String,
		showBackBtn: {
			type: Boolean,
			default: true
		},
		customerBackHandler: Function,
		centerSlotAlign: String
	},
	setup (props) {
		const router = useRouter()
		const onBackClick = () => {
			if (typeof props.customerBackHandler === 'function') {
				props.customerBackHandler()
				return false
			}
			router.back()
		}
		const centerSlotClass = computed(() => {
			if (props.centerSlotAlign === 'center') {
				return 'is-center'
			}
			return null
		})
		return {
			centerSlotClass,
			onBackClick
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.wind-header {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 44px;
    color:$fxWhite;
    background-color: $fxDefaultColor;
    &__back {
        margin-left: 20px;
        width: 35px !important;
        height: 35px !important;
    }
    &__title {
        margin-left: 20px;
        font-size: 18px;
        font-weight: bold;
    }
    &__center {
        flex: 1;
        display: flex;
        &.is-center {
            position:absolute;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    &__right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}
</style>
