<template>
	<div class="wind-app-container" :class="['wind-app-container', ...classes]">
		<slot></slot>
	</div>
</template>
<script lang="ts">
/**
 * AppContaner by shang 2022/8/11
 * @desc AppContaner app页面内容容器
 * @param {String} direction 布局 column, row 垂直/水平
 */
import { defineComponent, computed } from 'vue'
export default defineComponent({
	name: 'wind-app-container',
	props: {
		direction: {
			type: String,
			default: 'column'
		}
	},
	setup (props) {
		const classes = computed(() => {
			return [`wind-app-container__direction-${props.direction}`]
		})
		return {
			classes
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.wind-app-container {
    display:flex;
    overflow: auto;
    text-align: left;
    background-color: $wind-app-container-background-color;
    flex: 1;
    &__direction-column {
        flex-direction: column;
    }
    &__direction-row {
        flex-direction: row;
    }
}
</style>
