export default {
	'financial-in-store': {
		id: 'financial-in-store',
		label: '财务入库单',
		code: '0007_0004_0001',
		child: {
			'edit-instore': {
				id: 'edit-instore',
				label: '编辑',
				code: '0007_0004_0001_002_001'
			},
			'submit-instore': {
				id: 'submit-instore',
				label: '提交',
				code: '0007_0004_0001_009_001'
			},
			'back-instore': {
				id: 'back-instore',
				label: '退回',
				code: '0007_0004_0001_010_001'
			},
			'check-instore': {
				id: 'check-instore',
				label: '审核',
				code: '0007_0004_0001_011_001'
			},
			'uncheck-instore': {
				id: 'uncheck-instore',
				label: '反审',
				code: '0007_0004_0001_012_001'
			},
			'recheck-instore': {
				id: 'recheck-instore',
				label: '复审',
				code: '0007_0004_0001_013_001'
			},
			'unrecheck-instore': {
				id: 'unrecheck-instore',
				label: '取消复审',
				code: '0007_0004_0001_014_001'
			},
			'delete-instore': {
				id: 'delete-instore',
				label: '删除',
				code: '0007_0004_0001_004_001'
			},
			'pullDown-instore': {
				id: 'pullDown-instore',
				label: '下推',
				code: '0007_0004_0001_242_001',
				decisionByAllModule: ['financial-manage_capital-manage_payable-order.edit-payable']
			},
			'splitBill-instore': {
				id: 'splitBill-instore',
				label: '拆单',
				code: '0007_0004_0001_243_001'
			}
		}
	}
}
