import PAYABLE_ORDER from './payable-order'
import PAYMENT_ORDER from './payment-order'
import ADVANCED_ORDER from './advanced-order'
import VERIFICATION_ORDER from './verification-order'
import RECEIVABLE_ORDER from './receivable-order'
import RECEIPT_ORDER from './receipt-order'
import COLLECTION_ORDER from './collection-order'
import RECEIVABLEWRITEOFF_ORDER from './receivable-write-off-order'
export default {
	'capital-manage': {
		id: 'capital-manage',
		label: '资金管理',
		code: '0007_0005',
		child: {
			...PAYABLE_ORDER,
			...PAYMENT_ORDER,
			...ADVANCED_ORDER,
			...VERIFICATION_ORDER,
			...RECEIVABLE_ORDER,
			...RECEIPT_ORDER,
			...COLLECTION_ORDER,
			...RECEIVABLEWRITEOFF_ORDER
		}
	}
}
