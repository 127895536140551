export default {
	'advanced-order': {
		id: 'advanced-order',
		label: '预付单',
		code: '0007_0005_0003',
		child: {
			'add-advanced': {
				id: 'add-advanced',
				label: '新建',
				code: '0007_0005_0003_001_001',
				decisionByAllModule: ['financial-manage_capital-manage_advanced-order.edit-advanced']
			},
			'edit-advanced': {
				id: 'edit-advanced',
				label: '编辑',
				code: '0007_0005_0003_002_001',
				controlledByAllModule: 'financial-manage_capital-manage_advanced-order.add-advanced'
			},
			'submit-advanced': {
				id: 'submit-advanced',
				label: '提交',
				code: '0007_0005_0003_009_001'
			},
			'back-advanced': {
				id: 'back-advanced',
				label: '退回',
				code: '0007_0005_0003_010_001'
			},
			'check-advanced': {
				id: 'check-advanced',
				label: '审核',
				code: '0007_0005_0003_011_001'
			},
			'uncheck-advanced': {
				id: 'uncheck-advanced',
				label: '反审',
				code: '0007_0005_0003_012_001'
			},
			'recheck-advanced': {
				id: 'recheck-advanced',
				label: '复审',
				code: '0007_0005_0003_013_001'
			},
			'unrecheck-advanced': {
				id: 'unrecheck-advanced',
				label: '取消复审',
				code: '0007_0005_0003_014_001'
			},
			'delete-advanced': {
				id: 'delete-advanced',
				label: '删除',
				code: '0007_0005_0003_004_001'
			}
		}
	}
}
