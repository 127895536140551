import FINANCIAL_IN_STORE from './financial-in-store'
import FINANCIAL_OUT_STORE from './financial-out-store'
export default {
	'inventory-accounting': {
		id: 'inventory-accounting',
		label: '存货核算',
		code: '0007_0004',
		child: {
			...FINANCIAL_IN_STORE,
			...FINANCIAL_OUT_STORE
		}
	}
}
