<template>
	<div ref="refModel">
		<a-modal
			v-model:visible="visible"
			title="常用筐皮设置"
			centered
			width="50vw"
			class="item-detail__dialog"
			:getContainer="() => $refs.refModel"
		>
			<template #footer>
				<div class="footer-btn">
					<a-button type="primary" ghost @click="onCancelClick">取消</a-button>
					<a-button type="primary" @click="onConfirmClick">确定</a-button>
				</div>
			</template>
			<div class="form-box">
				<div class="form-item">
					<div class="form-item-title">当前总筐皮（kg）:</div>
					<span>{{ sumBasketTare }}</span>
				</div>
				<div class="form-item">
					<div class="form-item-title">自定义筐皮（kg）:</div>
					<w-input-number
						v-model:value="customBasketTare"
						allowClear
						:min="0"
						:max="999.99"
						:pointSize="2"
						:intLength="3"
						placeholder="0.01～999.99"
						@blur="onBlur"
						class="form-input"
					/>
				</div>
				<div class="form-item-common">
					<div class="form-item-title">常用筐皮:</div>
					<div class="common-bashet-list">
						<div
							class="common-bashet-item"
							v-for="item in commonBasketTare"
							:key="item.id"
							:class="{'is-active': isActive(item.id)}"
							@click="onBasketTareClick(item)"
						>{{ item.weight }}kg</div>
					</div>
				</div>
				<div class="form-item-current-use">
					<div class="form-item-title">当前使用筐皮:<span class="current-use-amount-tip">（最多添加10个）</span></div>
					<div class="current-use-bashet-list">
						<div class="current-use-bashet-item" v-for="(item,index) in currentUseBasketTare" :key="item.id">
							<CloseCircleOutlined class="delete-btn" @click="onUseBashetDel(index)"/>
							{{ item.weight }}kg
						</div>
					</div>
				</div>
			</div>
		</a-modal>
	</div>
</template>
<script lang="ts" setup>
import { ref, watch, computed } from 'vue'
import { useStore } from '@/store'
import { getFxInstance } from '@/js/instance'
import type { BasketTareItem } from '@/models/weight'
import { CloseCircleOutlined } from '@ant-design/icons-vue'
const props = defineProps({
	value: {
		type: Boolean,
		default: false
	}
})
const emit = defineEmits<{(e: 'update:value', value: boolean): void}>()
const store = useStore()
const fxInstance = getFxInstance()
const visible = ref(false)
const customBasketTare = ref<string | number>(0)
const gOfRatio = computed(() => {
	return fxInstance.$fxTypeMiddleware.getCommonTypeObjectById('weightUnitList')('3').ratio
})
const sumBasketTare = computed(() => {
	if (customBasketTare.value) {
		return customBasketTare.value
	} else {
		const sumWeight = (currentUseBasketTare.value as BasketTareItem[]).map(item => item.weight).reduce((pre, cur) => {
			pre += cur
			return pre
		}, 0)
		return fxInstance.$fxUtils.toFixedParseNumber(sumWeight, 2)
	}
})
const commonBasketTare = computed(() => store.getters.getBasketTareList)
const isActive = computed(() => {
	return (id: string) => {
		return (currentUseBasketTare.value as BasketTareItem[]).map(item => item.id).includes(id)
	}
})
const currentUseBasketTare = ref<BasketTareItem[]>([])
watch(() => props.value, (val:boolean) => {
	visible.value = val
	if (val) {
		clear()
	}
}, {
	immediate: true
})
watch(() => visible.value, (val:boolean) => {
	emit('update:value', val)
})
const onBasketTareClick = (item: BasketTareItem) => {
	if (currentUseBasketTare.value.length >= 10) {
		return false
	}
	currentUseBasketTare.value.push(item)
}
const clear = () => {
	customBasketTare.value = ''
	currentUseBasketTare.value = []
}
const onBlur = () => {
	if (customBasketTare.value === 0) {
		customBasketTare.value = ''
	}
}
const onUseBashetDel = (index: number) => {
	currentUseBasketTare.value.splice(index, 1)
}
const onCancelClick = () => {
	visible.value = false
}
const onConfirmClick = () => {
	if (!sumBasketTare.value && !customBasketTare.value) {
		visible.value = false
		return false
	}
	const basketTare = Number(sumBasketTare.value) * gOfRatio.value
	fxInstance.$fxWeigh.setBoxTare(basketTare)
	visible.value = false
}
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
:deep(.ant-modal-header) {
	border-bottom: 1px solid $fxGray25!important;
}
:deep(.ant-modal-footer) {
	border-top: 1px solid $fxGray25!important;
}
.item-detail__dialog {
	.footer-btn {
		display: flex;
		.ant-btn-background-ghost.ant-btn-primary {
			border-color: #979797;
			color: $fxBlack1;
		}
	}
	.form-box {
		overflow: auto;
		height: 60vh;
		.form-item-common {
			margin-bottom: 20px;
		}
		.form-item {
			display: flex;
			margin-bottom: 20px;
			height: 40px;
			line-height: 40px;
			&-title {
				width: 150px;
				font-size: 15px;
				font-weight: 400;
				color: $fxBlack1;
				.current-use-amount-tip {
					color: $fxRed;
				}
			}
			.form-input {
				padding: 0 10px;
				width: 270px;
				height: 40px;
				border: 1px solid #B4B4B4;
				border-radius: 2px;
			}
			:deep(.ant-input-number-input) {
				height: 38px;
			}
		}
		.form-item-current-use {
			.form-item-title {
				width: 100%;
			}
		}
		.common-bashet-list,.current-use-bashet-list {
			display: flex;
			flex-wrap: wrap;
			padding-top: 10px;
			.common-bashet-item,.current-use-bashet-item {
				margin: 0 5px 5px;
				margin-bottom: 5px;
				width: 95px;
				height: 40px;
				border: 1px solid $fxBlack2;
				border-radius: 5px;
				text-align: center;
				line-height: 40px;
			}
			.is-active {
				border-color: $fxBlue20;
				color: $fxBlue20;
				background: rgb(59 124 255 / 8%);
			}
			.current-use-bashet-item {
				position: relative;;
				.delete-btn {
					position: absolute;
					top: -8px;
					right: -8px;
					font-size: 16px;
					color: $fxBlack2;
					background: #ffffff;
				}
			}
		}
	}
}
</style>
