<template>
	<div
		class="fx-order-list-footer-btn"
		@click="onAddOrderClick"
	>{{btnText}}</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
	name: 'fx-order-list-footer-btn',
	props: {
		btnText: String
	},
	setup (props, { emit }) {
		const onAddOrderClick = () => {
			emit('on-add-click')
		}
		return {
			onAddOrderClick
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.fx-order-list-footer-btn {
	position: absolute;
	right: 20px;
	bottom: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 10px;
	width: 60px;
	height: 60px;
	border: 1px solid #cccccc;
	border-radius: 30px;
	text-align: center;
	color: $fxWhite;
	background-color: $fxDefaultColor;
}
</style>
