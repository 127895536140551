import { ref } from 'vue'
import type { Ref } from 'vue'

const connected = ref(false)
const isIntegratedPrint = ref(false)
export const usePrint = (): { [key: string]: Ref } => {
	return {
		connected,
		isIntegratedPrint
	}
}
