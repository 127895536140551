<template>
	<a-button
		:type="type"
		shape="round"
		ghost
		:danger="danger"
		class="list-btn"
		@click="onBtnClick"
	>
		<slot></slot>
	</a-button>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
	name: 'fx-order-list-top',
	props: {
		type: {
			type: String,
			default: 'primary'
		},
		danger: Boolean
	},
	emits: ['click'],
	setup (props, { emit }) {
		const onBtnClick = () => {
			emit('click')
		}
		return {
			onBtnClick
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
@import "$assets/stylus/handler";
.list-btn {
	margin-right: 17px;
	background-color: $fxWhite;

	@include fxDynamicFontSize(13px);
}
</style>
