import type { App } from 'vue'
import fxAuth from './fx-auth'
const directives = {
	'fx-auth': fxAuth
}
type DirectivesKey = keyof typeof directives

const install = (app: App): void => {
	Object.keys(directives).forEach((key) => {
		app.directive(key, directives[key as DirectivesKey])
	})
}

export default {
	install
}
