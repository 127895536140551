import type { App } from 'vue'
import bill from './bill'
import weight from './weight'

const fxCommon = {
	...bill,
	...weight
}
const install = (app: App): void => {
	app.config.globalProperties.$fxCommon = fxCommon
}

export default {
	install,
	...fxCommon
}
