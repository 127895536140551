export default {
	// 工序任务单——标准时间单位
	timeUnitList: [
		{
			id: 1,
			label: '小时'
		}, {
			id: 2,
			label: '分钟'
		}, {
			id: 3,
			label: '天'
		}
	],
	// 工序任务单——汇总状态
	taskType: [{
		id: 1,
		type: 'unsummary',
		label: '普通'
	}, {
		id: 2,
		type: 'summary',
		label: '汇总'
	}]
}
