<template>
	<div class="fx-order-list-footer">
		<div class="fx-order-list-footer__title">
			<a-checkbox
				v-show="showCheckbox"
				:checked="currentSelectAll"
				class="select-all-check-box"
				@change="onSelectAllCheckedChange"
			>全选</a-checkbox>
			{{listTitle}}：<span class="fx-weight-border-font">{{billListTotal}}</span>
		</div>
		<div
			v-if="showBtn"
			class="add-btn"
			@click="onAddOrderClick"
		>{{btnText}}</div>
	</div>
</template>
<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
export default defineComponent({
	name: 'fx-order-list-footer',
	props: {
		selectAll: Boolean,
		listTitle: String,
		billListTotal: String,
		btnText: String,
		showBtn: {
			type: Boolean,
			default: true
		},
		showCheckbox: {
			type: Boolean,
			default: true
		}
	},
	setup (props, { emit }) {
		const currentSelectAll = ref(false)
		const onSelectAllCheckedChange = () => {
			currentSelectAll.value = !currentSelectAll.value
			emit('update:selectAll', currentSelectAll.value)
			emit('on-select-change', currentSelectAll.value)
		}
		watch(() => props.selectAll, (val) => {
			currentSelectAll.value = val
		})
		const onAddOrderClick = () => {
			emit('on-add-click')
		}
		return {
			currentSelectAll,
			onSelectAllCheckedChange,
			onAddOrderClick
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.fx-order-list-footer {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 14px;
    height: 50px;
    background-color: $fxWhite;
    box-shadow: $fx-box-shadow-bottom;
    &__title {
        display: flex;
        align-items: center;
        margin-left: 20px;
        width: 200px;
        .select-all-check-box {
            margin-right: 20px;
        }
    }
	.add-btn {
		position: absolute;
        right: 20px;
        bottom: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 10px;
        width: 60px;
        height: 60px;
		border: 1px solid #cccccc;
		border-radius: 30px;
		text-align: center;
		color: $fxWhite;
		background-color: $fxDefaultColor;
	}
}
</style>
