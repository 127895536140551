// 入库单生成类型
import type { BillTypeField } from './index'
export enum InStoreFieldEnum {
	// 主表 {{{
	// 系统单号
	sysBillCode,
	// 手工单号
	userBillCode,
	// 单据类型
	billType,
	// 业务类型
	billBizTypeId,
	// 入库时间
	businessDate,
	// 入库方
	inOrgan,
	// 入库方式
	inOrganMode,
	// 入库仓库
	inHouse,
	// 出库方
	otherOrgan,
	// 整单运费
	carriage,
	// 分摊明细
	allotDetails,
	// 经办人
	operateManm,
	// 备注
	memo,
	// 附件上传
	attachment,
	// }}}
	// 明细表 {{{
	// 品项编号
	itemCode,
	// 品项名称
	itemName,
	// 规格
	spec,
	// 单位
	unit,
	// 入库数量
	amount,
	// 入库成本单价
	price,
	// 入库成本金额
	money,
	// 含税单价
	inTaxPrice,
	// 含税金额
	inTaxMoney,
	// 税率
	taxRate,
	// 非税单价
	inPrice,
	// 非税金额
	inMoney,
	// 税额
	inTaxRateMoney,
	// 辅助单位
	assistUnit,
	// 辅助数量
	assistUnitAmount,
	// 验收标记
	receiveFlag,
	// 收货货位
	houseItemLocation,
	// 期望到货时间
	expectArrivalDate,
	// }}}
	// 折叠信息 {{{
	// 生产日期
	productDate,
	// 赠品
	gift,
	// 明细备注
	itemMemo
	// }}}
}
/**
 * @name inStoreBillField
 * @desc 入库单编辑字段
 * @author shw
 * @date 2023/2/6
 */
const billTypeField: BillTypeField = {
	// 手工新建采购入库单
	'stockOrder-sub-hand': {
		[InStoreFieldEnum.sysBillCode]: false,
		[InStoreFieldEnum.userBillCode]: true,
		[InStoreFieldEnum.billType]: true,
		[InStoreFieldEnum.billBizTypeId]: true,
		[InStoreFieldEnum.businessDate]: true,
		[InStoreFieldEnum.inOrgan]: true,
		[InStoreFieldEnum.inOrganMode]: true,
		[InStoreFieldEnum.inHouse]: true,
		[InStoreFieldEnum.otherOrgan]: true,
		[InStoreFieldEnum.carriage]: true,
		[InStoreFieldEnum.allotDetails]: true,
		[InStoreFieldEnum.operateManm]: true,
		[InStoreFieldEnum.memo]: true,
		[InStoreFieldEnum.attachment]: true,
		[InStoreFieldEnum.amount]: true,
		[InStoreFieldEnum.assistUnitAmount]: true,
		[InStoreFieldEnum.inTaxPrice]: true,
		[InStoreFieldEnum.inTaxMoney]: true,
		[InStoreFieldEnum.taxRate]: true,
		[InStoreFieldEnum.inMoney]: true,
		[InStoreFieldEnum.inPrice]: true,
		[InStoreFieldEnum.productDate]: true,
		[InStoreFieldEnum.itemMemo]: true
	},
	// 手工新建其他入库单
	'other-sub-hand': {
		[InStoreFieldEnum.sysBillCode]: false,
		[InStoreFieldEnum.userBillCode]: true,
		[InStoreFieldEnum.billType]: true,
		[InStoreFieldEnum.billBizTypeId]: true,
		[InStoreFieldEnum.businessDate]: true,
		[InStoreFieldEnum.inOrgan]: true,
		[InStoreFieldEnum.inOrganMode]: true,
		[InStoreFieldEnum.inHouse]: true,
		[InStoreFieldEnum.otherOrgan]: true,
		[InStoreFieldEnum.carriage]: true,
		[InStoreFieldEnum.allotDetails]: true,
		[InStoreFieldEnum.operateManm]: true,
		[InStoreFieldEnum.memo]: true,
		[InStoreFieldEnum.attachment]: true,
		[InStoreFieldEnum.amount]: true,
		[InStoreFieldEnum.assistUnitAmount]: true,
		[InStoreFieldEnum.inTaxPrice]: true,
		[InStoreFieldEnum.inTaxMoney]: true,
		[InStoreFieldEnum.taxRate]: true,
		[InStoreFieldEnum.inMoney]: true,
		[InStoreFieldEnum.inPrice]: true,
		[InStoreFieldEnum.productDate]: true,
		[InStoreFieldEnum.itemMemo]: true
	},
	// 直发分派入库单(销售订单入库分派生成入库单/销售订单分派生成正常入库单)
	'assignStraight-sub': {
		[InStoreFieldEnum.sysBillCode]: false,
		[InStoreFieldEnum.userBillCode]: true,
		[InStoreFieldEnum.billType]: false,
		[InStoreFieldEnum.billBizTypeId]: true,
		[InStoreFieldEnum.businessDate]: true,
		[InStoreFieldEnum.inOrgan]: false,
		[InStoreFieldEnum.inOrganMode]: false,
		[InStoreFieldEnum.inHouse]: false,
		[InStoreFieldEnum.otherOrgan]: false,
		[InStoreFieldEnum.carriage]: true,
		[InStoreFieldEnum.allotDetails]: true,
		[InStoreFieldEnum.operateManm]: true,
		[InStoreFieldEnum.memo]: true,
		[InStoreFieldEnum.attachment]: true,
		[InStoreFieldEnum.amount]: true,
		[InStoreFieldEnum.assistUnitAmount]: true,
		[InStoreFieldEnum.inTaxPrice]: true,
		[InStoreFieldEnum.inTaxMoney]: true,
		[InStoreFieldEnum.taxRate]: true,
		[InStoreFieldEnum.inMoney]: true,
		[InStoreFieldEnum.inPrice]: true,
		[InStoreFieldEnum.productDate]: true,
		[InStoreFieldEnum.itemMemo]: true
	},
	// 销售订单出库分派生成出库单审核→生成入库方入库单
	'saleOrder-sub-outOrder': {
		[InStoreFieldEnum.sysBillCode]: false,
		[InStoreFieldEnum.userBillCode]: true,
		[InStoreFieldEnum.billType]: false,
		[InStoreFieldEnum.billBizTypeId]: true,
		[InStoreFieldEnum.businessDate]: true,
		[InStoreFieldEnum.inOrgan]: false,
		[InStoreFieldEnum.inOrganMode]: false,
		[InStoreFieldEnum.inHouse]: false,
		[InStoreFieldEnum.otherOrgan]: false,
		[InStoreFieldEnum.carriage]: false,
		[InStoreFieldEnum.allotDetails]: true,
		[InStoreFieldEnum.operateManm]: true,
		[InStoreFieldEnum.memo]: true,
		[InStoreFieldEnum.attachment]: true,
		[InStoreFieldEnum.amount]: true,
		[InStoreFieldEnum.assistUnitAmount]: true,
		[InStoreFieldEnum.inTaxPrice]: true,
		[InStoreFieldEnum.inTaxMoney]: true,
		[InStoreFieldEnum.taxRate]: true,
		[InStoreFieldEnum.inMoney]: true,
		[InStoreFieldEnum.inPrice]: true,
		[InStoreFieldEnum.productDate]: false,
		[InStoreFieldEnum.itemMemo]: true
	},
	// 越库分派入库单（卖方有仓库）
	'assignCross-sub': {
		[InStoreFieldEnum.sysBillCode]: false,
		[InStoreFieldEnum.userBillCode]: true,
		[InStoreFieldEnum.billType]: false,
		[InStoreFieldEnum.billBizTypeId]: true,
		[InStoreFieldEnum.businessDate]: true,
		[InStoreFieldEnum.inOrgan]: false,
		[InStoreFieldEnum.inOrganMode]: false,
		[InStoreFieldEnum.inHouse]: false,
		[InStoreFieldEnum.otherOrgan]: false,
		[InStoreFieldEnum.carriage]: true,
		[InStoreFieldEnum.allotDetails]: true,
		[InStoreFieldEnum.operateManm]: true,
		[InStoreFieldEnum.memo]: true,
		[InStoreFieldEnum.attachment]: true,
		[InStoreFieldEnum.amount]: true,
		[InStoreFieldEnum.assistUnitAmount]: true,
		[InStoreFieldEnum.inTaxPrice]: true,
		[InStoreFieldEnum.inTaxMoney]: true,
		[InStoreFieldEnum.taxRate]: true,
		[InStoreFieldEnum.inMoney]: true,
		[InStoreFieldEnum.inPrice]: true,
		[InStoreFieldEnum.productDate]: true,
		[InStoreFieldEnum.itemMemo]: true
	},
	// 越库分派入库单（卖方无仓库）
	'assignCross-sub-noHouse': {
		[InStoreFieldEnum.sysBillCode]: false,
		[InStoreFieldEnum.userBillCode]: true,
		[InStoreFieldEnum.billType]: false,
		[InStoreFieldEnum.billBizTypeId]: true,
		[InStoreFieldEnum.businessDate]: true,
		[InStoreFieldEnum.inOrgan]: false,
		[InStoreFieldEnum.inOrganMode]: false,
		[InStoreFieldEnum.inHouse]: false,
		[InStoreFieldEnum.otherOrgan]: false,
		[InStoreFieldEnum.carriage]: false,
		[InStoreFieldEnum.allotDetails]: true,
		[InStoreFieldEnum.operateManm]: true,
		[InStoreFieldEnum.memo]: true,
		[InStoreFieldEnum.attachment]: true,
		[InStoreFieldEnum.amount]: true,
		[InStoreFieldEnum.assistUnitAmount]: true,
		[InStoreFieldEnum.inTaxPrice]: true,
		[InStoreFieldEnum.inTaxMoney]: true,
		[InStoreFieldEnum.taxRate]: true,
		[InStoreFieldEnum.inMoney]: true,
		[InStoreFieldEnum.inPrice]: true,
		[InStoreFieldEnum.productDate]: false,
		[InStoreFieldEnum.itemMemo]: true
	},
	// 出库单审核生成(手工出库单审核→入库单)
	'outStore-sub': {
		[InStoreFieldEnum.sysBillCode]: false,
		[InStoreFieldEnum.userBillCode]: true,
		[InStoreFieldEnum.billType]: false,
		[InStoreFieldEnum.billBizTypeId]: true,
		[InStoreFieldEnum.businessDate]: true,
		[InStoreFieldEnum.inOrgan]: false,
		[InStoreFieldEnum.inOrganMode]: false,
		[InStoreFieldEnum.inHouse]: false,
		[InStoreFieldEnum.otherOrgan]: false,
		[InStoreFieldEnum.carriage]: true,
		[InStoreFieldEnum.allotDetails]: true,
		[InStoreFieldEnum.operateManm]: true,
		[InStoreFieldEnum.memo]: true,
		[InStoreFieldEnum.attachment]: true,
		[InStoreFieldEnum.amount]: true,
		[InStoreFieldEnum.assistUnitAmount]: true,
		[InStoreFieldEnum.inTaxPrice]: false,
		[InStoreFieldEnum.inTaxMoney]: false,
		[InStoreFieldEnum.taxRate]: false,
		[InStoreFieldEnum.inMoney]: false,
		[InStoreFieldEnum.inPrice]: false,
		[InStoreFieldEnum.productDate]: true,
		[InStoreFieldEnum.itemMemo]: true
	},
	// 采购订单的出库单直接生成
	'stockOrder-sub-outOrder': {
		// [InStoreFieldEnum.sysBillCode]: false,
		// [InStoreFieldEnum.userBillCode]: true,
		// [InStoreFieldEnum.billType]: true,
		// [InStoreFieldEnum.billBizTypeId]: true,
		// [InStoreFieldEnum.businessDate]: true,
		// [InStoreFieldEnum.inOrgan]: true,
		// [InStoreFieldEnum.inOrganMode]: true,
		// [InStoreFieldEnum.inHouse]: true,
		// [InStoreFieldEnum.otherOrgan]: true,
		// [InStoreFieldEnum.carriage]: true,
		// [InStoreFieldEnum.allotDetails]: true,
		// [InStoreFieldEnum.operateManm]: true,
		// [InStoreFieldEnum.memo]: true,
		[InStoreFieldEnum.attachment]: true
		// [InStoreFieldEnum.amount]: true,
		// [InStoreFieldEnum.assistUnitAmount]: true,
		// [InStoreFieldEnum.inTaxPrice]: true,
		// [InStoreFieldEnum.inTaxMoney]: true,
		// [InStoreFieldEnum.taxRate]: true,
		// [InStoreFieldEnum.inMoney]: true,
		// [InStoreFieldEnum.inPrice]: true,
		// [InStoreFieldEnum.productDate]: true,
		// [InStoreFieldEnum.itemMemo]: true
	}
}

export default billTypeField
