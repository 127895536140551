<template>
	<div v-show="showScale" class="weigh-connect">
	</div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import { getFxInstance } from '@/js/instance'
import { useWeight } from '@/js/hooks'
import { isCordova } from '@/config/env.config'
export default defineComponent({
	name: 'fx-scales-weigh-connect',
	setup () {
		const fxInstance = getFxInstance()
		const { connected } = useWeight()
		const showScale = ref(isCordova())
		const onScaleScanClick = () => {
			if (connected.value) {
				fxInstance.$fxWeigh.disConnectScale()
				fxInstance.$fxWeigh.setBluetoothDisConnectState()
				return
			}
			setDeviceInfo().then(() => {
				fxInstance.$fxWeigh.scan().then(device => {
					if (device.deviceType === 1) {
						fxInstance.$fxWeigh.connect(device.address, device.name).then(() => {
							fxInstance.$fxMessage.success('电子秤连接成功')
							fxInstance.$fxWeigh.emitState()
							fxInstance.$fxWeigh.emitBatteryState()
						})
					}
				})
			})
		}
		const setDeviceInfo = () => {
			return fxInstance.$fxWeigh.initWeighDevice()
		}
		return {
			onScaleScanClick,
			connected,
			showScale
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.weigh-connect {
		position: relative;
    display: flex;
}
.conected-icon {
    position: absolute;
    top: 12px;
    left: 15px;
    width: 10px;
    height: 10px;
}
</style>
