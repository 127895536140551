import type { App } from 'vue'
import cordova from '@/js/cordova'
import windUi from '@/components/wind-ui'
import { usePrint } from '@/js/hooks'
import LABEL_PRINT_CONFIG from '@/config/label_print_config'
import type { LabelPrintConfig, GetLabelPrintConfig } from '@/config/label_print_config'
import type { UsbDevice } from '@/js/cordova/src/scanUsb'
import utils from '@/js/utils'

const log = utils.fxDebug('fxPrint')
const { connected } = usePrint()

class FxPrint {
	scan () {
		return cordova.scanBluetooth.scan()
	}

	usbScan () {
		return cordova.scanUsb.scan()
	}

	connect (address: string, name: string) {
		return cordova.print.connectBlueToothPrinter(address, name).then(result => {
			connected.value = true
			return Promise.resolve(result)
		})
	}

	usbConnect (deviceInfo: UsbDevice) {
		return cordova.print.connectUsbPrinter(deviceInfo).then(result => {
			connected.value = true
			return Promise.resolve(result)
		})
	}

	zoneLabelPrinter () {
		return cordova.print.connectZoneLabelPrinter().then(result => {
			connected.value = true
			return Promise.resolve(result)
		})
	}

	printLabel (labelStyleXml: string, labelData: string, labelWidth: number, labelHeight: number, printNum: number) {
		if (!connected.value) {
			const error = '未连接打印机!'
			windUi.$fxMessage.warning('未连接打印机')
			return Promise.reject(error)
		}
		return cordova.print.printLabel(labelStyleXml, labelData, labelWidth, labelHeight, printNum)
	}

	getLabelPrintData<T extends keyof LabelPrintConfig> (billTypeKey: T): GetLabelPrintConfig<T> {
		return this.getLabelPrintConfig(billTypeKey) as GetLabelPrintConfig<T>
	}

	getLabelPrintConfig (key: string) {
		const [mainKey, subKey] = key.split('_')
		const configItem = LABEL_PRINT_CONFIG[mainKey as keyof typeof LABEL_PRINT_CONFIG]
		return configItem[subKey as keyof typeof configItem]
	}

	printLabelContinuous (labelStyleXml: string, labelData: string, labelWidth: number, labelHeight: number, printNum: number, index: number, total: number) {
		return this.printLabel(labelStyleXml, labelData, labelWidth, labelHeight, printNum).then(() => {
			windUi.$fxLoading.show(`正在打印${index + 1}/${total}`)
			return new Promise(resolve => {
				setTimeout(() => {
					if (index + 1 === total) {
						windUi.$fxLoading.close()
					}
					resolve(true)
				}, 500)
			})
		}).catch(error => {
			log(error)
		})
	}
}

export const fxPrint = new FxPrint()

const install = (app: App): void => {
	app.config.globalProperties.$fxPrint = fxPrint
}

export default {
	install,
	FxPrintInstance: fxPrint
}
