export default {
	'receivable-order': {
		id: 'receivable-order',
		label: '应收单',
		code: '0007_0005_0005',
		child: {
			'add-receivable': {
				id: 'add-receivable',
				label: '新建',
				code: '0007_0005_0005_001_001',
				decisionByAllModule: ['financial-manage_capital-manage_receivable-order.edit-receivable']
			},
			'edit-receivable': {
				id: 'edit-receivable',
				label: '编辑',
				code: '0007_0005_0005_002_001',
				controlledByAllModule: 'financial-manage_capital-manage_receivable-order.add-receivable'
			},
			'submit-receivable': {
				id: 'submit-receivable',
				label: '提交',
				code: '0007_0005_0005_009_001'
			},
			'back-receivable': {
				id: 'back-receivable',
				label: '退回',
				code: '0007_0005_0005_010_001'
			},
			'check-receivable': {
				id: 'check-receivable',
				label: '审核',
				code: '0007_0005_0005_011_001'
			},
			'uncheck-receivable': {
				id: 'uncheck-receivable',
				label: '反审',
				code: '0007_0005_0005_012_001'
			},
			'recheck-receivable': {
				id: 'recheck-receivable',
				label: '复审',
				code: '0007_0005_0005_013_001'
			},
			'unrecheck-receivable': {
				id: 'unrecheck-receivable',
				label: '取消复审',
				code: '0007_0005_0005_014_001'
			},
			'delete-receivable': {
				id: 'delete-receivable',
				label: '删除',
				code: '0007_0005_0005_004_001'
			},
			'pushDown-receivable': {
				id: 'pushDown-receivable',
				label: '下推',
				code: '0007_0005_0005_242_001',
				decisionByAllModule: ['financial-manage_capital-manage_receipt-order.edit-receipt']
			}
		}
	}
}
