
import type { App, defineComponent } from 'vue'
import tag from './SearchBar.vue'

tag.install = (app: App): void => {
	app.component(tag.name, tag)
}

type IWithInstall = ReturnType<typeof defineComponent> & { install(app: App): void }

const fxComponent: IWithInstall = tag
export default fxComponent
