import { message } from 'ant-design-vue'
import type { MessageType } from 'ant-design-vue/lib/message'

const success = function (msg: string):MessageType {
	return message.success(msg, 1)
}
const warning = function (msg: string):MessageType {
	return message.warning(msg, 1)
}
const error = function (msg: string):MessageType {
	return message.error(msg, 1)
}

export default {
	success,
	warning,
	error
}
