export default {
	'sorting-task': {
		id: 'sorting-task',
		label: '分拣任务管理',
		code: '0011_0001',
		child: {
			'add-order': {
				id: 'add-order',
				label: '新建分拣任务单',
				code: '0011_0001_001_001',
				decision: ['edit-order']
			},
			'delete-order': {
				id: 'delete-order',
				label: '删除分拣任务单',
				code: '0011_0001_004_001'
			},
			'designate-order': {
				id: 'designate-order',
				label: '指派分拣任务单',
				code: '0011_0001_270_001'
			},
			'returnClaim-order': {
				id: 'returnClaim-order',
				label: '退回分拣任务单',
				code: '0011_0001_204_001'
			},
			'export-order': {
				id: 'export-order',
				label: '导出分拣任务单',
				code: '0011_0001_040_001'
			},
			'submitPad-order': {
				id: 'submitPad-order',
				label: '（平板端）提交分拣任务单',
				code: '0011_0001_271_001'
			},
			'confirmAllPad-order': {
				id: 'confirmAllPad-order',
				label: '（平板端）全部确认',
				code: '0011_0001_272_001'
			},
			'resetPad-order': {
				id: 'resetPad-order',
				label: '（平板端）重置',
				code: '0011_0001_274_001'
			},
			'stockoutPad-order': {
				id: 'stockoutPad-order',
				label: '（平板端）缺货',
				code: '0011_0001_275_001'
			},
			'withdrawPad-order': {
				id: 'withdrawPad-order',
				label: '（平板端）撤回',
				code: '0011_0001_276_001'
			}
		}
	}
}
