import SORTING_TASK from './sorting-task'
export default {
	'sortation-manage': {
		id: 'sortation-manage',
		label: '分拣管理',
		code: '0011',
		child: {
			...SORTING_TASK
		}
	}
}
