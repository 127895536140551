<template>
	<div class="wind-select">
		<w-cell
			v-if="cellType==='cell'"
			:title="cellLabel"
			:value="customCellText || currentCellText"
			:disabled="disabled"
			:required="required"
			:class="{'empty-cell-text': isUnSelected}"
			isLink
			@on-click="showModal"
		></w-cell>
		<template v-else>
			<div :class="['wind-select__cell', {
				'has-border': !readonly && border
			}]" @click="showModal">
				<span class="cell-text" :class="{'empty-cell-text': isUnSelected}">{{customCellText || currentCellText}}</span>
				<w-icon-svg v-if="!disabled&&!readonly" type="icon-cell-link-arrow" class="select-arrow-icon"></w-icon-svg>
			</div>
		</template>
		<a-modal
			v-model:visible="visible"
			:title="title"
			:bodyStyle="{
				width: '45vw',
				height: '35vw',
				'padding': '1vw 1vw 2vw 1vw'
			}"
			centered
			width="45vw"
			class="wind-select__modal wind-select-mulity__modal"
		>
			<div v-show="filterable" class="list-item-seachbar-container">
				<w-search-bar
					v-model:searchKey="searchKey"
					placeholder="请输入搜索关键词"
					class="list-item-seachbar"
				></w-search-bar>
			</div>
			<template #footer>
				<div class="btn-box">
					<a-button key="reset" type="plain" class="confirm-btn" @click="handleReset">重置</a-button>
					<a-button key="submit" type="primary" class="confirm-btn" @click="handleOk">确定</a-button>
				</div>
			</template>
			<div v-if="allVisibleOptionList.length > 0" class="wind-select__modal-container">
				<div class="list-item-container">
					<div
						v-for="listItem in allVisibleOptionList"
						:key="listItem.id"
						class="list-item"
						:class="{'is-select': currentSelectItemIdsMap[listItem.id]}"
						@click="onListItemClick(listItem)"
					>
						<w-icon class="right-identifier-icon"
							:type="currentSelectItemIdsMap[listItem.id] ? 'right-identifier-fillblue' : 'right-identifier-fillgray'">
						</w-icon>
						<span class="list-item__text">{{(listItem as unknown as Record<string, string>)[optionProps.name]}}</span>
					</div>
				</div>
			</div>
			<div v-else class="wind-select__empty-container">
				<w-icon type="empty" class="empty-icon"></w-icon>
			</div>
		</a-modal>
	</div>
</template>
<script lang="ts">
/**
 * Select-mulity by shang 2023/05/18
 * @desc Select-mulity 多选选择器
 */
import { computed, defineComponent, ref, watch } from 'vue'
import type { PropType } from 'vue'
import { getFxInstance } from '@/js/instance'
interface ListItem {
	id: string|number
	visibleFlag?: boolean
}
export default defineComponent({
	name: 'wind-select-mulity',
	props: {
		value: {
			type: Array as PropType<Array<string|number>>,
			default: () => {
				return []
			}
		},
		optionlist: {
			type: Array as PropType<ListItem[]>,
			default: () => {
				return []
			}
		},
		optionProps: {
			type: Object as PropType<{name:string}>,
			default: () => {
				return {
					name: 'name'
				}
			}
		},
		title: {
			type: String,
			default: '选择选项'
		},
		cellType: String,
		cellLabel: {
			type: String,
			default: ''
		},
		customCellText: String,
		disabled: Boolean,
		readonly: Boolean,
		required: Boolean,
		placeholder: {
			type: String,
			default: '请选择'
		},
		fpSelectShow: Function,
		border: {
			type: Boolean,
			default: true
		},
		filterable: {
			type: Boolean,
			default: false
		}
	},
	emits: ['change'],
	setup (props, { emit }) {
		const fxInstance = getFxInstance()
		const visible = ref<boolean>(false)
		const currentSelectItemList = ref<ListItem[]>([])
		const currentSelectItemIds = computed(() => currentSelectItemList.value.map(item => item.id))
		const currentSelectItemIdsMap = computed(() => {
			const idsMap: {[key:string|number]: boolean} = {}
			currentSelectItemIds.value.forEach(id => {
				idsMap[id] = true
			})
			return idsMap
		})
		const currentSelectItemNames = computed(() => currentSelectItemList.value.map(item => (item as unknown as Record<string, string>)[props.optionProps.name]).join(','))
		const isUnSelected = computed(() => currentSelectItemList.value.length === 0)
		const currentCellText = computed(() => {
			if (props.readonly) {
				return ''
			} else {
				return isUnSelected.value ? props.placeholder : currentSelectItemNames.value
			}
		})
		const showModal = () => {
			searchKey.value = ''
			if (props.disabled) {
				return
			}
			setActiveItem()
			if (typeof props.fpSelectShow === 'function') {
				props.fpSelectShow().then(() => {
					visible.value = true
				})
			} else {
				visible.value = true
			}
		}

		const setActiveItem = () => {
			const idsMap: {[key:string|number]: boolean} = {}
			props.value.forEach(id => {
				idsMap[id] = true
			})
			currentSelectItemList.value = props.optionlist.filter(item => {
				return idsMap[item.id]
			})
		}
		watch(() => props.value, () => {
			setActiveItem()
		}, {
			immediate: true
		})
		const onListItemClick = (item:ListItem) => {
			const index = currentSelectItemList.value.findIndex(_item => _item.id === item.id)
			if (~index) {
				currentSelectItemList.value.splice(index, 1)
			} else {
				currentSelectItemList.value.push(item)
			}
		}
		const handleReset = () => {
			currentSelectItemList.value = []
		}
		const handleOk = () => {
			visible.value = false
			emit('change', currentSelectItemIds.value, currentSelectItemList.value)
		}
		// {{{ searchItem
		const searchKey = ref('')
		const searchProps = ['name', 'code', 'pinYin']
		const allVisibleOptionList = computed(() => {
			const listData = props.optionlist.filter((item:ListItem) => {
				return item.visibleFlag !== false && fxInstance.$fxUtils.fuzzyQueryObj(item, searchKey.value, searchProps)
			})
			return listData
		})
		return {
			visible,
			isUnSelected,
			currentCellText,
			searchKey,
			showModal,
			handleOk,
			handleReset,
			allVisibleOptionList,
			currentSelectItemIds,
			currentSelectItemIdsMap,
			onListItemClick,
			currentSelectItemNames
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.wind-select {
	&__cell {
		display: flex;
		align-items: center;
		width: 280px;
		height: 36px;
		line-height: 36px;
		box-sizing: border-box;
		&.has-border {
			padding-right: 10px;
			padding-left: 15px;
			border: 1px solid #cccccc;
			border-radius: 20px;
		}
		.cell-text {
			flex: 1;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		.empty-cell-text {
			color: #cccccc;
		}
		.select-arrow-icon {
			width: 12px;
			height: 12px;
			color: #a7a8ab;
			transform: rotate(90deg);
		}
	}
	.empty-cell-text {
		:deep(.wind-cell__value) {
			color: #cccccc;
		}
	}
	&__modal-container {
		overflow: auto;
		height: 96%;
		.list-item-container {
			display: flex;
			overflow: auto;
			height: 100%;
			flex-direction: column;
			.list-item {
				display: flex;
				align-items: center;
				padding: 0 10px;
				height: 40px;
				line-height: 40px;
				box-sizing: border-box;
				&.is-select {
					color: $fxDefaultColor;
					background-color: #5c92fe14;
				}
				.list-item__text {
					flex: 1;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				.right-identifier-icon {
					margin-right: 8px;
				}
			}
		}
	}
	&__empty-container {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		.empty-icon {
			width: 200px;
			height: 200px;
		}
	}
}
.wind-select__modal {
	.btn-box {
		display: flex;
		justify-content: center;
		width: 100%;
		.confirm-btn {
			width: 50%;
		}
	}
	.list-item-seachbar {
		margin: 0;
		width: 100%;
	}
}
</style>
<style lang="scss">
.wind-select-mulity {
	&__modal {
		.ant-modal-body {
			border-bottom: 1px solid#f0f0f0;
		}
	}
}
</style>
