<template>
	<div class="fx-user">
		<template v-if="logoutVisible">
			<a-popover
				v-model:visible="visible"
				:title="userName"
				arrowPointAtCenter
				overlayClassName="fx-user__popover"
				placement="bottomRight"
				trigger="click"
				class="fx-user__name"
			>
				<template v-if="logoutVisible" #content>
					<div style="margin-bottom: 20px">
						<a @click="handleFullscreen">{{ fullscreen ? '退出全屏' : '全屏' }}</a>
					</div>
					<div>
						<a @click="onLogoutClick">退出登录</a>
					</div>
				</template>
				<w-icon type="user" class="fx-user-icon"></w-icon>
				<span class="fx-ellipsis">{{ userName }}</span>
			</a-popover>
		</template>

		<template v-else>
			<a-popover
				placement="bottomRight"
				arrowPointAtCenter
				overlayClassName="fx-user__popover"
				trigger="click"
				class="fx-user__name"
			>
				<template #content>
					<div class="fx-user__content">
						{{ userName }}
					</div>
				</template>
				<w-icon type="user" class="fx-user-icon"></w-icon>
				<span class="fx-ellipsis">{{ userName }}</span>
			</a-popover>
		</template>
	</div>
</template>
<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import { useStore } from '@/store'
import { getFxInstance } from '@/js/instance'
import { routeLoginPage } from '@/js/hooks'
import { logout } from '@/js/api/bar'
// import { isWeb } from '@/config/env.config'
export default defineComponent({
	name: 'fx-user',
	setup () {
		const fxInstance = getFxInstance()
		const store = useStore()
		const userName = computed(() => store.getters.getEmployeeName)
		const visible = ref(false)
		const logoutVisible = ref(true)
		const onLogoutClick = () => {
			fxInstance.$fxMessageBox.confirm('是否退出登录？').then(() => {
				logout().then(() => {
					store.commit('LOGIN_OUT')
					routeLoginPage()
				}).catch(()=>{
				})
			})
		}
		const fullscreen = ref(false)

		const handleFullscreen = () => {
			fullscreen.value = !fullscreen.value
			if (fullscreen.value) {
				document.documentElement.requestFullscreen()
			} else {
				document.exitFullscreen()
			}


		}
		return {
			visible,
			userName,
			logoutVisible,
			onLogoutClick,
			fullscreen,
			handleFullscreen
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";

.fx-user {
	display: flex;
}
</style>
<style lang="scss">
@import "$assets/stylus/varsty";

.fx-user__popover {
	.fx-user__content {
		align-items: center;
		min-width: 80px;
	}

	.ant-popover-title {
		padding: 5px 16px;
	}

	.ant-popover-inner-content {
		padding: 5px 16px;
		font-weight: 900;
	}
}

.fx-user__name {
	display: flex;
	align-items: center;
	max-width: 10vw;
	height: 44px;
	line-height: 44px;

	.fx-user-icon {
		margin-right: 2px;
		flex-shrink: 0;
	}
}
</style>
