// vue 全局注册
import type { App } from 'vue'
import directives from '@/js/directives'
import router from '@/router'
import store from '@/store'


const install = (app: App): void => {
	app.use(directives)
		.use(store)
		.use(router)
}
export default {
	install
}
