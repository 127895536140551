import REPORT_CURRENT_STORE from './report-current-store'
import REPORT_PURCHASE_SELL_STOCK_SUMMARY from './report-purchase-sell-stock-summary'
import REPORT_PURCHASE_SELL_STOCK_STANDING_BOOK from './report-purchase-sell-stock-standing-book'
import REPORT_STORE_GROSS_PROFIT from './report-shop-gross-profit'
import REPORT_DISH_COST from './report-dish-cost'
import REPORT_DISH_GROSS_PROFIT from './report-dish-gross-profit'
import REPORT_RAW_MATERIAL_DIFFERENCE from './report-raw-material-difference'
import REPORT_WARNING_DULL_PRODUCTS from './report-warning-dull-products'
export default {
	report: {
		id: 'report',
		label: '报表中心',
		code: '0008',
		child: {
			...REPORT_CURRENT_STORE,
			...REPORT_PURCHASE_SELL_STOCK_SUMMARY,
			...REPORT_PURCHASE_SELL_STOCK_STANDING_BOOK,
			...REPORT_STORE_GROSS_PROFIT,
			...REPORT_DISH_COST,
			...REPORT_DISH_GROSS_PROFIT,
			...REPORT_RAW_MATERIAL_DIFFERENCE,
			...REPORT_WARNING_DULL_PRODUCTS
		}
	}
}
