export default {
	'payable-order': {
		id: 'payable-order',
		label: '应付单',
		code: '0007_0005_0001',
		child: {
			'add-payable': {
				id: 'add-payable',
				label: '新建',
				code: '0007_0005_0001_001_001',
				decisionByAllModule: ['financial-manage_capital-manage_payable-order.edit-payable']
			},
			'edit-payable': {
				id: 'edit-payable',
				label: '编辑',
				code: '0007_0005_0001_002_001',
				controlledByAllModule: 'financial-manage_capital-manage_payable-order.add-payable'
			},
			'submit-payable': {
				id: 'submit-payable',
				label: '提交',
				code: '0007_0005_0001_009_001'
			},
			'back-payable': {
				id: 'back-payable',
				label: '退回',
				code: '0007_0005_0001_010_001'
			},
			'check-payable': {
				id: 'check-payable',
				label: '审核',
				code: '0007_0005_0001_011_001'
			},
			'uncheck-payable': {
				id: 'uncheck-payable',
				label: '反审',
				code: '0007_0005_0001_012_001'
			},
			'recheck-payable': {
				id: 'recheck-payable',
				label: '复审',
				code: '0007_0005_0001_013_001'
			},
			'unrecheck-payable': {
				id: 'unrecheck-payable',
				label: '取消复审',
				code: '0007_0005_0001_014_001'
			},
			'delete-payable': {
				id: 'delete-payable',
				label: '删除',
				code: '0007_0005_0001_004_001'
			},
			'pushDown-payable': {
				id: 'pushDown-payable',
				label: '下推',
				code: '0007_0005_0001_242_001',
				decisionByAllModule: ['financial-manage_capital-manage_payment-order.edit-payment']
			}
		}
	}
}
