export const enum fxEventBusKey {
	// 称重器状态变化
	WEIGHT_STATE_CHANGE = 'weightStateChange',
	// 称重器电池状态变化
	WEIGHT_BATTERY_CHANGE = 'weightBatteryChange',
	// 红外扫码
	RED_CODE_EMIT = 'redCodeEmit',
	// 红外扫二维码
	RED_QR_CODE_EMIT = 'redQrCodeEmit',
	// 虚拟键盘事件
	VIRTUAL_INPUT_EMIT = 'virtualInputEmit'
}
