import STOCK_PLAN from './stock-plan'
import STOCK_PLAN_ORDER from './stock-plan-order'
import STOCK_OFFER_ORDER from './stock-offer-order'
import STOCK_ORDER from './stock-order'
import SALE_ORDER from './sale-order'
import ASSIGN_MANAGE from './assign-manage'
import ASSIGN_SUMMARY from './assign-summary-sheet'
import STOCK_INQUIRY_ORDER from './stock-inquiry-order'
export default {
	'stock-sale-manage': {
		id: 'stock-sale-manage',
		label: '采销管理',
		code: '0004',
		child: {
			...STOCK_PLAN,
			...STOCK_PLAN_ORDER,
			...STOCK_OFFER_ORDER,
			...STOCK_ORDER,
			...SALE_ORDER,
			...STOCK_INQUIRY_ORDER,
			...ASSIGN_MANAGE,
			...ASSIGN_SUMMARY
		}
	}
}
