<template>
	<a-modal
		v-model:visible="visible"
		:footer="null"
		:bodyStyle="{
			width: '70vw',
			height: '41vw',
			'padding': '1vw 1vw 1vw 1vw',
			display: 'flex'
		}"
		:title="computedTitle"
		width="70vw"
		centered
		class="bill-error__dialog"
	>
		<div class="bill-error__container">
			<div class="bill-error__container-tips fx-warn-font">{{computedTip}}</div>
			<div ref="tableRef" class="bill-error__table-container">
				<div class="wind-table">
					<table class="wind-table__table">
						<thead class="wind-table__table-thead">
							<tr class="wind-table__table-thead-tr">
								<th
									v-for="(columnItem) in tableColumn"
									:key="columnItem.prop"
									:class="['wind-table__table-thead-th', `wind-table-column__${columnItem.prop}`]"
									:style="{
										width: columnItem.width
									}"
								>{{columnItem.title}}</th>
							</tr>
						</thead>
						<tbody class="wind-table__table-tbody">
							<tr v-for="(item, index) in billErrorList" :key="(item as Record<string, string>).id" class="wind-table__table-tbody-tr">
								<td
									v-for="(columnItem) in tableColumn"
									:key="columnItem.prop"
									:class="['wind-table__table-tbody-td', `wind-table-column__${columnItem.prop}`]"
									:style="{
										width: columnItem.width
									}"
								>
									<w-render-dom v-if="columnItem.render" :render="columnItem.render" :backValue="(item as Record<string, number>)"></w-render-dom>
									<div v-else>
										{{columnItem.formatter ? columnItem.formatter(item, index) : (item as Record<string, string>)[columnItem.prop]}}
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</a-modal>
</template>
<script lang="tsx">
import { defineComponent, ref, computed, nextTick } from 'vue'
import { getFxInstance } from '@/js/instance'
import { jumpToBill } from '@/js/hooks'
import type { BillType } from '@/config/app_type'
export default defineComponent({
	name: 'billError',
	setup () {
		const fxInstance = getFxInstance()
		const visible = ref(false)
		const tableRef = ref<HTMLElement | null>(null)
		const billErrorList = ref<unknown[]>([])
		const errorCode = ref(0)
		const billType = ref<string>('')
		const tableColumn = ref([{
			prop: 'index',
			title: '序号',
			align: 'center',
			width: '40px',
			formatter: (item:unknown, index:number) => index + 1
		}, {
			prop: 'sysBillCode',
			title: '系统单号',
			width: '150px',
			render: (record: {id:string, billClass:string, sysBillCode:string}) => {
				return (
					<w-link
						underline={true}
						class="item-detail-link"
						onclick={() => {
							onBillClick(record)
						}}
					>
						{record.sysBillCode}
					</w-link>
				)
			}
		}, {
			prop: 'businessDate',
			title: '业务时间',
			width: '150px'
		}, {
			prop: 'billState',
			title: '审核状态',
			width: '80px',
			render: ({ billState }: { billState: string }) => {
				return (
					<w-tag
						label={getBillState(billState).label}
						color={getBillState(billState).stateIconColor}
						class="bill-state-tag"
					></w-tag>
				)
			}
		}])
		const computedTitle = computed(() => {
			switch (billType.value) {
				case 'CheckBill':
					switch (errorCode.value) {
						case 2:
							return '未审核单据'
						case 3:
							return '已审核盘点单'
						default:
							return ''
					}
				case 'InStore':
					return '已审核盘点单'
				case 'OutStore':
					return '已审核盘点单'
				case 'MoveOrder':
					return '已审核盘点单'
				case 'BusinessOrder':
					return '已审核盘点单'
				case 'Pick':
					return '已审核盘点单'
				case 'MachineCompose':
					return '已审核盘点单'
				default:
					return ''
			}
		})
		const computedTip = computed(() => {
			switch (billType.value) {
				case 'CheckBill':
					switch (errorCode.value) {
						case 2:
							return '注：盘点时间前存在未审核的出入库单据'
						case 3:
							return '注：业务日期后存在已审核的盘点单据'
						default:
							return ''
					}
				case 'InStore':
					return '注：业务日期后存在已审核的盘点单据'
				case 'OutStore':
					return '注：业务日期后存在已审核的盘点单据'
				case 'MoveOrder':
					return '注：该单据审核后生成的下级单据，业务日期后存在已审核的盘点单'
				case 'BusinessOrder':
					return '注：该单据审核后生成的下级单据，业务日期后存在已审核的盘点单'
				case 'Pick':
					return '注：该单据审核后生成的下级单据，业务日期后存在已审核的盘点单'
				case 'MachineCompose':
					return '注：业务日期后存在已审核的盘点单据'
				default:
					return ''
			}
		})
		const getBillState = (billState: string) => {
			return fxInstance.$fxStateMiddleware.getOrderStateById(billState) || {}
		}
		const onBillClick = (item:{id: string, billClass:string}) => {
			jumpToBill(item.billClass, {
				id: item.id
			}, 'detailPath')
		}
		const doTableScrollTop = () => {
			nextTick(() => {
				const tbody = tableRef.value?.querySelector('.wind-table__table-tbody')
				if (tbody) {
					tbody!.scrollTop = 0
				}
			})
		}
		const open = (_billType: BillType, list: unknown[], code: number) => {
			billType.value = _billType
			errorCode.value = code
			billErrorList.value = list
			visible.value = true
			doTableScrollTop()
		}
		return {
			visible,
			tableRef,
			tableColumn,
			billErrorList,
			computedTitle,
			computedTip,
			getBillState,
			onBillClick,
			open
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.bill-error__container {
    flex: 1;
    display: flex;
    flex-direction: column;
    &-tips {
        padding-left: 20px;
        height: 40px;
    }
    .bill-error__table-container {
        flex: 1;
        .bill-error__table {
            height: 100%;
            &:deep(.table-striped) td {
                background-color: #fafafa;
            }
        }
    }
}
.wind-table {
    overflow: hidden;
    height: 100%;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    &__table {
        display: table;
        width: 100%;
        height: 100%;
        border-radius: 5px 5px 0 0;
        text-align: left;
        table-layout: fixed;
        border-collapse: separate;
        border-spacing: 0;
        &-thead {
            display: table;
            table-layout: fixed;
            width: 100%;
            vertical-align: middle;
            border-color: inherit;
            &-tr {
                height: 40px;
            }
            &-th {
                position: relative;
                overflow:hidden;
                padding: 0 10px;
                font-weight: 500;
                border-bottom: 1px solid #f0f0f0;
                text-align: center;
                text-overflow : ellipsis;
                white-space:nowrap;
                color: rgb(0 0 0 / 85%);
                background: #fafafa;
                background-color: #fafafa;
                &::before {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    width: 1px;
                    height: 1.6em;
                    background-color: rgb(0 0 0 / 6%);
                    transform: translateY(-50%);
                    content: "";
                }
            }
        }
        &-tbody {
            display: block;
            overflow: auto;
            height: calc( 100% - 40px );
            &-tr {
                display: table;
                table-layout: fixed;
                width: 100%;
                height: 40px;
                &:nth-child(2n) {
                    background-color: #fafafa;
                }
            }
            &-td {
                position: relative;
                overflow:hidden;
                padding: 0 10px;
                font-weight: 500;
                border-bottom: 1px solid #f0f0f0;
                text-align: center;
                text-overflow : ellipsis;
                white-space:nowrap;
                color: rgb(0 0 0 / 85%);
            }
        }
    }
}
</style>
