import { createStore, useStore as baseUseStore } from 'vuex'
import loginer from './src/loginer'
import system from './src/system'
import orderMistakeCheck from './src/orderMistakeCheck'
import orderRequired from './src/orderRequired'
import stylusDynamic from './src/stylusDynamic'
import auth from './src/auth'
import type { GetStates, GetGetters, GetDispatchs, GetCommits } from './src/utils/storeType'


const modules = {
	loginer,
	system,
	orderMistakeCheck,
	auth,
	orderRequired,
	stylusDynamic
}
interface UseStore {
	state: GetStates<typeof modules>
	getters: GetGetters<typeof modules>
	dispatch: GetDispatchs<typeof modules>
	commit: GetCommits<typeof modules>
}

const useStore = (): UseStore => {
	const { state, getters, dispatch, commit } = baseUseStore<GetStates<typeof modules>>()
	return { state, getters, dispatch, commit }
}

export { useStore }

export default createStore({
	modules
})
