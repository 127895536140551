import { getStorageJson } from '../utils'
const state = {
	// 模块权限
	moduleAuth: getStorageJson<{ [key: string]: string }>('moduleAuth', {}),
	// 按钮权限
	detailsAuth: getStorageJson<{ [key: string]: { [key: string]: string } }>('detailsAuth', {})
}

export default state
export type State = typeof state
