import utils from '@/js/utils'
import type { BillDetail, ItemDetail } from '@/models/out-store'
import type { WeightItem } from '@/models/weight'
import { getAmount, formatterWeightRecord, createFxItemQrCode, createFxItemSourceQrCode } from '../../utils'
export default function getLabelPrintSourceData<T extends BillDetail, K extends Partial<ItemDetail>> (bill: T, item: K, weighRecord: Partial<WeightItem>, printUnitCount?: number, shareId?: string, isPrintSourceQrCode?: boolean) {
	const amount = getAmount(printUnitCount!, item, weighRecord, item.amount!)
	const accurateWeighRecord = formatterWeightRecord(weighRecord)
	const amountQrCode = createFxItemQrCode({
		itemCode: item.item?.code,
		itemUnitId: item.itemUnit?.id,
		itemAmount: amount,
		detailId: item.id,
		suppilerCode: utils.getObjDeepValue(bill, 'organ.id', '')
	})
	const sourceQrCode = (isPrintSourceQrCode === true) ? createFxItemSourceQrCode({
		billClass: bill.billClass,
		shareId: shareId ?? ''
	}) : null
	return {
		baseInfo: [
			{
				printTime: utils.setDate(),
				sysBillCode: bill.sysBillCode,
				userBillCode: bill.userBillCode,
				billTypeName: bill.billTypeName,
				businessDate: utils.setDate(bill.businessDate),
				otherOrgan: bill.organ.name,
				inOrgan: (bill.otherOrgan && bill.otherOrgan.name) || '',
				carriageFee: bill.carriageFee,
				operateMan: bill.operateMan.name,
				expressDeliveryCompanyName: bill.expressDeliveryCompanyName,
				expressDeliveryNumber: bill.expressDeliveryNumber
			}
		],
		detailInfo: [
			{
				itemCode: item!.item!.code,
				itemName: item!.item!.name,
				itemTypeRefName: item!.item!.itemTypeRef?.name,
				itemSpec: item!.item!.spec,
				itemUnitName: item!.itemUnit!.name,
				amount,
				productDate: utils.setDate(item.productDate),
				shelfLife: item!.item!.shelfLife,
				price: item.price,
				money: item.money,
				inTaxPrice: item.inTaxPrice,
				inTaxMoney: item.inTaxMoney,
				salesTaxPrice: item.salesTaxPrice,
				salesTaxMoney: item.salesTaxMoney,
				taxRate: item.salesTaxRate,
				assistUnit: item.item?.assistUnit || '',
				inStoreAssistUnitAmount: item.assistUnitAmount,
				batchCode: item.batchCode,
				inHouse: item.inHouse?.name,
				outHouse: item.outHouse?.name,
				storagePlace: '',
				weightValue: accurateWeighRecord.netWeight,
				peerValue: accurateWeighRecord.tareWeight,
				roughWeight: accurateWeighRecord.grossWeight,
				weightUnit: accurateWeighRecord.unitName,
				addCount: weighRecord.num,
				outReason: item.outReason?.name,
				expectArrivalDate: utils.setDate(item.expectArrivalDate),
				grossProfitMoney: 0,
				grossProfitRate: 0
			}
		],
		itemCode: [{
			itemBarcode: item!.itemUnit!.barcode || '',
			batchQrCode: item!.batchCode || '',
			amountQrCode,
			sourceQrCode
		}]
	}
}
