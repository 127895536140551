<template>
	<div class="fx-order-detail-contaner">
        <slot name="alter"></slot>
		<div :class="['fx-order-detail-contaner__context', {
            'is-right-container': rightContainer
        }]">
			<slot name="context">
			</slot>
		</div>
		<div class="fx-order-detail-contaner__title">
			<slot name="title">
			</slot>
		</div>
		<div :class="['fx-order-detail-contaner__bottom', {
            'is-right-container': rightContainer
        }]">
            <div class="bottom-left">
                <slot name="bottom-left"></slot>
            </div>
            <div class="bottom-right">
                <slot name="bottom"></slot>
            </div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
	name: 'fx-order-detail-contaner',
	props: {
		rightContainer: {
			type: Boolean,
			default: true
		}
	}
})
</script>

<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.fx-order-detail-contaner {
    position: relative;
    display: flex;
    overflow: auto;
    padding-top: 60px;
    padding-bottom: 52px;
    width: 100%;
    height:100%;
    flex: 1;
    flex-direction: column;
    background-color: $fxGray14;
    &__title {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        display: flex;
        align-items: center;
        height: 60px;
        font-size: 15px;
        background-color: $fxWhite;
        box-shadow: $fx-box-shadow-top;
        &.is-right-container {
            margin-left: 10px;
        }
    }
    &__context {
        display: flex;
        overflow: auto;
        height: 100%;
        flex: 1;
        flex-direction: row;
    }
    &__bottom {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 52px;
        background-color: $fxWhite;
        box-shadow: $fx-box-shadow-bottom;
        &.is-right-container {
            margin-left: 10px;
        }
        .bottom-left {
            display: flex;
            margin-left: 10px;
        }
        .bottom-right {
            display: flex;
            justify-content: flex-end;
        }
    }
}
</style>
