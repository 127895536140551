<template>
	<i
		:class="['wind-icon', ...classes]"
		:style="iconStyle"
		@click="onIconClick"
	></i>
</template>
<script lang="ts">
/**
 * Icon by shw
 * @desc Icon 图标
 * @params [String] type 内置图标类型
 * @params [String] imgUrl 自定义图标url
 * @params [String] width 图标宽
 * @params [String] height 图标高
 * @params [String] size 图标尺寸(normal, mini)
 * @params [Boolean] stop 阻止点击冒泡
 * @params [Boolean] stop 阻止冒泡
 * @params [Boolean] pointer 鼠标样式
 * @params [Boolean] verticalAlign 垂直居中
 * @params [Boolean] disabled 禁用
 */
import { defineComponent, computed } from 'vue'
import ICON_URL from '../../ts/config/icon_url'
import { getFxInstance } from '@/js/instance'
export default defineComponent({
	name: 'w-icon',
	props: {
		type: String,
		imgUrl: String,
		width: String,
		height: String,
		size: {
			type: String,
			default: 'normal'
		},
		stop: Boolean,
		pointer: Boolean,
		verticalAlign: Boolean,
		disabled: Boolean
	},
	emits: ['click'],
	setup (props, { emit }) {
		const fxInstance = getFxInstance()
		const onIconClick = function (event: Event) {
			if (props.stop) {
				event.stopPropagation()
			}
			if (props.disabled) {
				return false
			}
			if ((event as Event).type === 'click') {
				emit('click')
			}
		}
		const iconStyle = computed(() => {
			const imgUrl = fxInstance.$fxUtils.isDef(props.type) ? ICON_URL[props.type as string] : props.imgUrl
			return {
				width: props.width,
				height: props.height,
				backgroundImage: `url(${imgUrl})`
			}
		})
		const classes = computed(() => {
			return [`wind-icon__size-${props.size}`, {
				'is-pointer': props.pointer,
				'is-vertical-align': props.verticalAlign,
				'is-disabled': props.disabled
			}]
		})
		return {
			onIconClick,
			classes,
			iconStyle
		}
	}
})
</script>
<style lang="scss" scoped>
.wind-icon {
    display:inline-block;
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    &__size-mini {
        width: 12px;
        height: 12px;
    }
    &.is-pointer {
        cursor: pointer;
    }
    &.is-vertical-align {
        vertical-align: middle;
    }
    &.is-disabled {
        filter: grayscale(100%);
        cursor: not-allowed;
    }
}
</style>
