import utils from '@/js/utils'
import type { BillDetail, SortingLineOrgan } from '@/models/sortation-task'
import type { WeightItem } from '@/models/weight'
import fxTypeMiddleware from '@/js/typeMiddleware'
import { getAmount, formatterWeightRecord } from '../../utils'
export default function getLabelPrintSourceData<T extends Partial<BillDetail>, K extends Partial<SortingLineOrgan>> (bill: T, item: K, weighRecord: Partial<WeightItem>, printUnitCount?: number) {
	const alreadySortTotal = getAmount(printUnitCount!, item, weighRecord, item.alreadySortTotal!)
	const diffAmount = utils.toFixedParseNumber(utils.toNumber(alreadySortTotal) - utils.toNumber(item.shouldSortTotal), 4, false)
	const accurateWeighRecord = formatterWeightRecord(weighRecord)
	const sortingMethod = fxTypeMiddleware.getCommonTypeObjectById('sortingMethod')(`${bill.sortMethod}`).label ?? ''
	const sortingMode = fxTypeMiddleware.getCommonTypeObjectById('sortingMode')(`${bill.sortMode}`).label ?? ''
	const sortingStrategy = fxTypeMiddleware.getCommonTypeObjectById('sortingStrategy')(`${bill.sortStrategy}`).label ?? ''
	const allocationType = fxTypeMiddleware.getCommonTypeObjectById('allocationType')(`${bill.allocationType}`).label ?? ''
	const storageMethod = fxTypeMiddleware.getCommonTypeObjectById('storageMethod')(`${item!.item!.storageMethod}`).label ?? ''
	return {
		baseInfo: [
			{
				printTime: utils.setDate(),
				sysBillCode: bill.sysBillCode,
				sortingMethod,
				sortingWay: sortingMode,
				sortingStrategy,
				generatedDate: utils.setDate(bill.createDate),
				deliveryDate: utils.setDate(bill.expectedDeliveryDate),
				receivingDate: utils.setDate(bill.latestDeliveryDate),
				outOrgan: bill.outOrgan!.name,
				inOrgan: item.inOrgan!.name,
				assignmentMode: allocationType,
				createdMan: bill.createManName,
				sortingMan: bill.workMan?.name
			}
		],
		detailInfo: [
			{
				itemCode: item.item!.code,
				itemName: item.item!.name,
				itemTypeName: item.itemType!.name,
				spec: item.item!.spec,
				itemUnitName: item.unit?.name,
				inspectAmount: item.shouldSortTotal,
				inspectedAmount: alreadySortTotal,
				diffAmount,
				originBillCode: item.sourceCodes,
				shelfLife: item.item!.shelfLife,
				outHouse: (item.outHouse && item.outHouse.name) || '',
				inHouse: (item.inHouse && item.inHouse.name) || '',
				storageMethod,
				weightValue: accurateWeighRecord.netWeight,
				peerValue: accurateWeighRecord.tareWeight,
				roughWeight: accurateWeighRecord.grossWeight,
				weightUnit: accurateWeighRecord.unitName,
				addCount: weighRecord.num
			}
		],
		itemCode: [{
			itemBarcode: item!.unit!.barcode || ''
		}]
	}
}
