export default {
	getDeviceType (arg0 = '', success: Function, error: Function): void {
		window.cordova.exec(success, error, 'ScalePlugin', 'getDeviceType', [arg0])
	},
	connectBluetoothScale (arg0 = '', arg1 = '', success: Function, error: Function): void {
		window.cordova.exec(success, error, 'ScalePlugin', 'connectBluetoothScale', [arg0, arg1])
	},
	connectIntegratedScale (arg0 = '', success: Function, error: Function): void {
		window.cordova.exec(success, error, 'ScalePlugin', 'connectIntegratedScale', [arg0])
	},
	connectUsbScale (arg0 = '', success: Function, error: Function): void {
		window.cordova.exec(success, error, 'ScalePlugin', 'connectUsbScale', [arg0])
	},
	addScaleChangeListener (arg0 = '', success: Function, error: Function): void {
		window.cordova.exec(success, error, 'ScalePlugin', 'addScaleChangeListener', [arg0])
	},
	addScaleBatteryListener (arg0 = '', success: Function, error: Function): void {
		window.cordova.exec(success, error, 'ScalePlugin', 'addScaleBatteryListener', [arg0])
	},
	setTare (arg0 = '', success: Function, error: Function): void {
		window.cordova.exec(success, error, 'ScalePlugin', 'setTare', [arg0])
	},
	setZero (arg0 = '', success: Function, error: Function): void {
		window.cordova.exec(success, error, 'ScalePlugin', 'setZero', [arg0])
	},
	setBoxTare (arg0 = '', success: Function, error: Function): void {
		window.cordova.exec(success, error, 'ScalePlugin', 'setBoxTare', [arg0])
	},
	disConnectScale (arg0 = '', arg1 = '', success: Function, error: Function): void {
		window.cordova.exec(success, error, 'ScalePlugin', 'disConnectScale', [arg0, arg1])
	}
}
