<template>
	<div class="wind-select-tab">
		<w-cell
			v-if="cellType==='cell'"
			:title="cellLabel"
			:value="cellText"
			:disabled="disabled"
			:required="required"
			isLink
			@on-click="showModal"
		></w-cell>
		<div v-else class="wind-select-tab__cell" @click="showModal">
			<span class="cell-text">{{cellText}}</span>
			<w-icon type="select-arrow" class="select-arrow-icon"></w-icon>
		</div>
		<a-modal
			v-model:visible="visible"
			:title="title"
			:bodyStyle="{
				width: '65vw',
				height: '35vw',
				'padding': '1vw 1vw 2vw 1vw'
			}"
			centered
			width="65vw"
			class="wind-select-tab-cascade__modal"
		>
			<div class="wind-select-tab__modal-container-left">
				<div v-if="allVisibleOptionList.length > 0" class="wind-select-tab__modal-container">
					<van-tabs v-model:active="tabActive">
						<van-tab v-for="item in allVisibleOptionList" :key="item.id" :name="item.id" :title="item.name">
							<div class="list-item-container">
								<div
									v-for="listItem in item.listData"
									:key="listItem.id"
									class="list-item"
									:class="{'is-select': currentSelectItem.id === listItem.id}"
									@click="onListItemClick(listItem)"
								>
								<span class="list-item__text">{{(listItem as unknown as Record<string, string>)[optionProps.name]}}</span>
								<w-icon v-show="currentSelectItem.id === listItem.id" type="right-identifier" clas="right-identifier-icon"></w-icon>
								</div>
							</div>
						</van-tab>
					</van-tabs>
				</div>
				<div v-else class="wind-select-tab__empty-container">
					<w-icon type="empty" class="empty-icon"></w-icon>
				</div>
			</div>
			<div class="wind-select-tab__modal-container-right">
				<div class="affiliated-title">{{affiliatedTitle}}</div>
				<div v-if="affiliatedList.length > 0" class="wind-select-tab__modal-container">
					<div class="list-item-container">
						<div
							v-for="listItem in affiliatedList"
							:key="listItem.id"
							class="list-item"
							:class="{'is-select': currentAffiliatedSelectItem.id === listItem.id}"
							@click="onAffiliatedItemClick(listItem)"
						>
							<span class="list-item__text">{{(listItem as unknown as Record<string, string>)[optionProps.name]}}</span>
							<w-icon v-show="currentAffiliatedSelectItem.id === listItem.id" type="right-identifier" class="right-identifier-icon"></w-icon>
						</div>
					</div>
				</div>
				<div v-else class="wind-select-tab__empty-container">
					<w-icon type="empty" class="empty-icon"></w-icon>
				</div>
			</div>
			<template #footer>
				<a-button key="submit" type="primary" class="confirm-btn" @click="handleOk">确定</a-button>
			</template>
		</a-modal>
	</div>
</template>
<script lang="ts">
/**
 * SelectTab by shang 2022/8/25
 * @desc SelectTab 分页选择器
 */
import { defineComponent, ref, watch } from 'vue'
import type { PropType } from 'vue'
interface StoreItem {
	id: string
	visibleFlag?: boolean
}
interface ListItem extends StoreItem {
	store: StoreItem[]
}
interface TabItem {
	id: string
	name: string
	listData: ListItem[]
}
export default defineComponent({
	name: 'wind-select-tab-cascade',
	props: {
		value: String,
		affiliatedValue: String,
		optionlist: {
			type: Array as PropType<TabItem[]>,
			default: () => {
				return []
			}
		},
		optionProps: {
			type: Object as PropType<{name:string}>,
			default: () => {
				return {
					name: 'name'
				}
			}
		},
		title: {
			type: String,
			default: '选择选项'
		},
		affiliatedTitle: {
			type: String,
			default: '选择选项'
		},
		cellType: String,
		cellLabel: {
			type: String,
			default: ''
		},
		cellText: String,
		emptyText: {
			type: String,
			default: '暂无数据'
		},
		disabled: Boolean,
		required: Boolean,
		beforeConfirm: {
			type: Function,
			default: () => {
				return Promise.resolve()
			}
		}
	},
	setup (props, { emit }) {
		const visible = ref<boolean>(false)
		const showModal = () => {
			setActiveTab()
			visible.value = true
		}
		const handleOk = () => {
			props.beforeConfirm(currentSelectItem.value.id, currentAffiliatedSelectItem.value.id).then(() => {
				if (currentSelectItem.value.id !== props.value || currentAffiliatedSelectItem.value.id !== props.affiliatedValue) {
					emit('update:value', currentSelectItem.value.id)
					emit('update:value', currentAffiliatedSelectItem.value.id)
					emit('change', {
						value: currentSelectItem.value,
						affiliatedValue: currentAffiliatedSelectItem.value,
						affiliatedList: affiliatedList.value
					})
				}
				visible.value = false
			})
		}
		const tabActive = ref('')
		const currentSelectItem = ref<ListItem>({
			id: '',
			[props.optionProps.name]: '',
			store: []
		})
		const currentAffiliatedSelectItem = ref<StoreItem>({
			id: '',
			[props.optionProps.name]: ''
		})
		const affiliatedList = ref<StoreItem[]>([])
		const setActiveTab = () => {
			currentSelectItem.value = {
				id: '',
				[props.optionProps.name]: '',
				store: []
			}
			tabActive.value = allVisibleOptionList.value[0].id
			if (props.value) {
				let listItemIndex = -1
				const tab = allVisibleOptionList.value.find(item => {
					const index = item.listData.findIndex(listItem => {
						return listItem.id === props.value
					})
					if (~index) {
						listItemIndex = index
					}
					return ~index
				})
				if (tab) {
					tabActive.value = tab.id
					currentSelectItem.value = tab.listData[listItemIndex]
					affiliatedList.value = filterAffiliatedList(currentSelectItem.value.store) || []
				} else {
					affiliatedList.value = []
					console.warn(`wind-select-tab-cascade组件未找到匹配id:${props.value}`)
				}
			}
			if (props.affiliatedValue) {
				const select = affiliatedList.value.find((item:StoreItem) => {
					return item.id === props.affiliatedValue
				})
				if (select) {
					currentAffiliatedSelectItem.value = select
				} else {
					console.warn(`wind-select-tab-cascade组件未找到匹配affiliatedId:${props.affiliatedValue}`)
				}
			}
		}
		const onListItemClick = (item:ListItem) => {
			currentSelectItem.value = item
			clearAffiliatedItem()
			affiliatedList.value = filterAffiliatedList(currentSelectItem.value.store) || []
		}
		const filterAffiliatedList = (list:StoreItem[]) => {
			return list.filter(item => {
				return item.visibleFlag !== false
			})
		}
		const onAffiliatedItemClick = (item:StoreItem) => {
			currentAffiliatedSelectItem.value = item
		}
		const clearAffiliatedItem = () => {
			currentAffiliatedSelectItem.value = {
				id: '',
				[props.optionProps.name]: ''
			}
		}
		const allVisibleOptionList = ref<TabItem[]>([])
		watch(() => props.optionlist, (val:TabItem[]) => {
			allVisibleOptionList.value = val.map((item:TabItem) => {
				const listData = item.listData.filter(_item => {
					return _item.visibleFlag !== false
				})
				return {
					...item,
					listData
				}
			})
		}, {
			immediate: true
		})
		return {
			visible,
			showModal,
			handleOk,
			tabActive,
			allVisibleOptionList,
			currentSelectItem,
			currentAffiliatedSelectItem,
			onListItemClick,
			onAffiliatedItemClick,
			affiliatedList
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.wind-select-tab {
    &__cell {
        display: flex;
        align-items: center;
        padding-right: 10px;
        padding-left: 15px;
        width: 280px;
        height: 36px;
        border: 1px solid #cccccc;
        border-radius: 20px;
        line-height: 36px;
        box-sizing: border-box;
        .cell-text {
            flex: 1;
            overflow:hidden;
            text-overflow : ellipsis;
            white-space:nowrap;
        }
        .select-arrow-icon {
            width: 10px;
            height: 10px;
        }
    }
    &__modal-container {
        overflow: auto;
        flex: 1;
        .van-tabs {
            display: flex;
            overflow: auto;
            height: 100%;
            flex-direction: column;
            :deep(.van-tabs__wrap) {
                margin-bottom:5px;
                height: 40px;
            }
            :deep(.van-tabs__content) {
                flex: 1;
                overflow: auto;
            }
        }
        .list-item {
            display: flex;
            align-items: center;
            padding: 0 10px;
            height: 40px;
            line-height: 40px;
            box-sizing: border-box;
            &.is-select {
                color: $fxDefaultColor;
            }
            .list-item__text {
                flex: 1;
                overflow:hidden;
                text-overflow : ellipsis;
                white-space:nowrap;
            }
            .right-identifier-icon {
                margin-left: 20px;
            }
        }
    }
    &__empty-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        .empty-icon {
            width: 200px;
            height: 200px;
        }
    }
}
.wind-select-tab-cascade__modal {
    .wind-select-tab__modal-container-left {
        display:flex;
        overflow: auto;
        flex: 1;
    }
    .wind-select-tab__modal-container-right {
        display:flex;
        overflow: auto;
        flex: 1;
        flex-direction: column;
    }
    .affiliated-title {
        padding: 0 10px;
        height: 40px;
        font-weight: 900;
        line-height: 40px;
        box-sizing: border-box;
    }
    .confirm-btn {
        width: 100%;
    }
}
</style>
<style lang="scss">
.wind-select-tab-cascade__modal {
    .ant-modal-body {
        display:flex
    }
}
</style>
