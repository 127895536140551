// cordova-plugin-floatcamera
import windUi from '@/components/wind-ui'
import utils from '@/js/utils'

export interface CameraFloatInstance {
	open: () => Promise<boolean>
	close: () => Promise<boolean>
	onTakePictureListener: (fn: (file: File) => void) => Promise<void>
	takePicture: (params: { amount?: string, money?: string }) => Promise<File | null>
	onCameraStateListener: (fn: (state: number) => void) => Promise<void>
}
let takePictureResolve: null | Function = null
let openResolve: null | Function = null
let closeResolve: null | Function = null
const log = (msg: string) => {
	return utils.fxDebug('cordova-api')(msg)
}
const open = function (): Promise<boolean> {
	const params = JSON.stringify({
		amount: '',
		money: ''
	})
	return new Promise(resolve => {
		try {
			openResolve = resolve
			window.FloatCamera.openCamera(
				params,
				function () { },
				function () {
					windUi.$fxMessage.error('打开摄像头失败')
				}
			)
		} catch (error) {
			log('浏览器环境下无法使用floatcamera.open功能')
			windUi.$fxMessage.error('打开摄像头失败')
		}
	})
}
const close = function (): Promise<boolean> {
	return new Promise(resolve => {
		try {
			closeResolve = resolve
			window.FloatCamera.closeCamera(
				[''],
				function () {
					resolve(true)
				},
				function () {
					windUi.$fxMessage.error('关闭摄像头失败')
				}
			)
		} catch (error) {
			log('浏览器环境下无法使用floatcamera.close功能')
			windUi.$fxMessage.error('关闭摄像头失败')
		}
	})
}
const onTakePictureListener = function (cb: Function): void {
	try {
		window.FloatCamera.addTakePictureListener(
			[''],
			function (json: string) {
				const blob = new Blob([json], { type: 'image/png' })
				const file = new window.File([blob], `camerofloat${new Date().getTime()}.png`, { type: 'image/png' })
				if (takePictureResolve) {
					takePictureResolve(file)
					takePictureResolve = null
				} else {
					cb(file)
				}
			},
			function () {
				log('拍摄失败')
			}
		)
	} catch (error) {
		log('浏览器环境下无法使用floatcamera.onTakePictureListener功能')
	}
}
const takePicture = function (cameraData: { amount?: string, money?: string } = { amount: '', money: '' }): Promise<File | null> {
	const params = JSON.stringify(cameraData)
	return new Promise((resolve) => {
		try {
			takePictureResolve = resolve
			window.FloatCamera.takePicture(
				params,
				function () {
				},
				function () {
					log('拍摄失败')
				}
			)
		} catch (error) {
			takePictureResolve = null
			resolve(null)
			log('浏览器环境下无法使用floatcamera.takePicture功能')
		}
	})
}
const onCameraStateListener = function (cb: Function): void {
	try {
		window.FloatCamera.addCameraStateListener(
			[''],
			function (state: number) {
				if (state && openResolve) {
					openResolve(true)
					openResolve = null
				}
				if (!state && closeResolve) {
					closeResolve(true)
					closeResolve = null
				}
				cb(state)
			},
			function () {
				log('拍摄失败')
			}
		)
	} catch (error) {
		log('浏览器环境下无法使用floatcamera.onCameraStateListener功能')
	}
}
export default {
	open,
	close,
	onTakePictureListener,
	takePicture,
	onCameraStateListener
}
