<template>
	<div class="fx-print-mulity-tools">
		<a-button
			:disabled="disabled"
			class="print-mulity-btn"
			@click="showModal"
		>批量打印</a-button>
		<a-modal
			v-model:visible="visible"
			:title="title"
			:bodyStyle="{
				width: '100%',
				height: '26vw',
				display: 'flex'
			}"
			:maskClosable="false"
			centered
			width="50vw"
			class="fx-print-mulity-tools__modal"
		>
			<div class="fx-print-mulity-tools__modal-container">
				<div class="container-cell">
					<w-stepper
						v-model:value="currentPrintCount"
						:max="max"
						:min="min"
						:intLength="intLength"
						integer
					></w-stepper>
					<span class="cell-label">份标签</span>
				</div>
				<div class="container-cell">
					<w-stepper
						v-model:value="unitCount"
						:max="max"
						:min="min"
						:pointSize="pointSize"
						:intLength="intLength"
					></w-stepper>
					<span class="cell-label">{{ printUnitName }}</span>
				</div>
			</div>
			<template #footer>
				<a-button class="confirm-btn" @click="onCancelClick">取消</a-button>
				<a-button type="primary" class="confirm-btn" @click="onPrintClick">打印</a-button>
			</template>
		</a-modal>
	</div>
</template>
<script lang="ts">
/**
 * fxPrintMulityTools by shang 2022/8/25
 * @desc fxPrintMulityTools 批量打印工具栏
 */
import { defineComponent, ref } from 'vue'
export default defineComponent({
	name: 'fx-print-mulity-tools',
	props: {
		title: {
			type: String,
			default: '打印'
		},
		disabled: Boolean,
		min: {
			type: Number,
			default: 0
		},
		max: {
			type: Number,
			default: Infinity
		},
		pointSize: {
			type: [String, Number],
			default: 4
		},
		intLength: {
			type: [String, Number],
			default: 8
		},
		printUnitName: String,
		printUnitCount: Number
	},
	emits: ['on-confirm'],
	setup (props, { emit }) {
		const currentPrintCount = ref(1)
		const unitCount = ref(1)
		// {{{ 打开窗口
		const visible = ref(false)
		const showModal = () => {
			if (props.disabled) {
				return false
			}
			currentPrintCount.value = 1
			unitCount.value = Number(props.printUnitCount)
			visible.value = true
		}
		const onPrintClick = () => {
			emit('on-confirm', currentPrintCount.value, unitCount.value)
			visible.value = false
		}
		const onCancelClick = () => {
			visible.value = false
		}
		// }}}

		return {
			visible,
			showModal,
			onPrintClick,
			onCancelClick,
			currentPrintCount,
			unitCount
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
@import "$assets/stylus/handler";
.fx-print-mulity-tools {
	.print-mulity-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 10px;
		height: 36px;
		flex: 1;

		@include fxDynamicFontSize(13px);
	}
}
.fx-print-mulity-tools__modal {
	&-container {
        overflow: auto;
        flex:1;
        box-sizing:border-box;
		.container-cell {
			display: flex;
			align-items: center;
			margin-bottom: 10px;
			padding-left: 50px;
			.cell-label {
				margin-left: 20px;
			}
		}
	}
}
</style>
