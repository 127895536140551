<template>
	<div class="item-can-weigh">
		非
	</div>
</template>

<script>
export default {
	name: 'itemWeighIcon',
	setup () {
		return {}
	}
}
</script>

<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.item-can-weigh {
	margin-right: 5px;
	width: 18px;
	height: 18px;
	font-size: 13px;
	font-weight: bold;
	border-radius: 3px;
	text-align: center;
	color: $fxWhite;
	background-color: $fxDefaultColor;
	line-height: 18px;
}
</style>
