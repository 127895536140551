import INVOICE_MANAGE from './invoice-manage'
import ACCOUNT_PERIOD from './account-period'
import FINANCIAL_ARCHIVES from './financial-archives'
import CARRY_FORWARD from './carry-forward'
import INVENTORY_ACCOUNTING from './inventory-accounting'
import CAPITAL_MANAGE from './capital-manage'
export default {
	'financial-manage': {
		id: 'financial-manage',
		label: '财务管理',
		code: '0007',
		child: {
			...INVOICE_MANAGE,
			...ACCOUNT_PERIOD,
			...FINANCIAL_ARCHIVES,
			...CARRY_FORWARD,
			...INVENTORY_ACCOUNTING,
			...CAPITAL_MANAGE
		}
	}
}
