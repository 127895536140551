<template>
  <div></div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from '@/store'
import { getFxInstance } from '@/js/instance'
const tenNumber = /^\d{10}$/
export default defineComponent({
	setup () {
		interface Employee {
			name: string
		}
		interface ChangeLogin {
			domain: string,
			token: string,
			isSameUser: boolean,
			employee: Employee
		}
		const fxInstance = getFxInstance()
		const store = useStore()
		const router = useRouter()
		const route = useRoute()
		onMounted(() => {
			// 监听键盘按键按下事件
			const key = ref('')
			setTimeout(() => {
				document.addEventListener('keydown', (event) => {
					if (event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement) {
						// 如果事件发生在输入框内，不处理
						return
					}
					if (event.key !== 'Enter') {
						key.value += event.key
						// console.log(key.value)
						if (tenNumber.test(key.value)) {
							loginHandler(key.value)
								.then(loginFinishHandler)
								.then(setDeviceInfo)
								.then(() => {
									if (route.name === 'home') {
										location.reload()
									} else {
										router.push('home')
									}
								})
						}
					} else {
						key.value = ''
					}
				})
			}, 100)
		})
		const loginHandler = (employeeCard: string) => {
			const token = store.state.loginer.token
			if (!token) {
				const param = {
					host: store.state.loginer.hostId,
					employeeCardId: employeeCard
				}
				return fxInstance.$fxApi('common.loginDomainEmployeeCard')({ data: param }).then((res: ChangeLogin) => {
					store.commit('SET_LOGINER', {
						domain: res.domain,
						loginSilentFlag: 1,
						token: res.token
					})
					return Promise.resolve()
				})
			} else {
				const params = {
					host: store.state.loginer.hostId,
					token,
					employeeCardId: employeeCard
				}
				return fxInstance.$fxApi('common.loginEmployeeCard')({ data: params }).then((res: ChangeLogin) => {
					if (res.isSameUser) {
						const userName = store.getters.getEmployeeName
						fxInstance.$fxMessage.warning(`员工【${userName}】已登录`)
						return Promise.reject(new Error(`员工【${userName}】已登录`))
					} else {
						return fxInstance.$fxMessageBox.confirm(`确定切换为员工【${res.employee?.name}】登录？`).then(() => {
							store.commit('SET_LOGINER', {
								token: ''
							})
							store.commit('SET_LOGINER', {
								domain: res.domain,
								loginSilentFlag: 1,
								token: res.token
							})
							return Promise.resolve()
						})
					}
				})
			}
		}
		const getCurrentUser = () => {
			return store.dispatch('syncLoginer')
		}
		const getModuleAuth = () => {
			return store.dispatch('syncModuleAuth')
		}
		const loginFinishHandler = () => {
			return Promise.all([getCurrentUser(), getModuleAuth()])
		}
		const setDeviceInfo = () => {
			return fxInstance.$fxWeigh.initWeighDevice()
		}
		return {

		}
	}
})
</script>

<style lang="scss">
</style>
