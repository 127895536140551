import utils from '@/js/utils'
import type { WeightItem } from '@/models/weight'
import type { BillDetail, ItemDetail, DosingDetail, AdditionProductDetail } from '@/views/bill_machining-order/OrderDetail.vue'
import fxTypeMiddleware from '@/js/typeMiddleware'
import { getAmount, formatterWeightRecord, createFxItemQrCode } from '../../utils'
const getDosingNames = (item: Partial<ItemDetail>): string => {
	if (!item.dosingDetails) {
		return ''
	}
	return (item.dosingDetails as unknown as DosingDetail[]).map(dosingItem => {
		return dosingItem.item.name
	}).join(',')
}
const getAdditionNames = (item: Partial<ItemDetail>): string => {
	if (!item.additionProductList) {
		return ''
	}
	return (item.additionProductList as unknown as AdditionProductDetail[]).map(additionProductItem => {
		return additionProductItem.item.name
	}).join(',')
}
export default function getLabelPrintSourceData<T extends BillDetail, K extends Partial<ItemDetail>> (bill: T, item: K, weighRecord: Partial<WeightItem>, otherChargeTypeNames: string, printUnitCount?: number) {
	const actualTotal = getAmount(printUnitCount!, item, weighRecord, item.actualTotal!)
	const accurateWeighRecord = formatterWeightRecord(weighRecord)
	const dosingNames = getDosingNames(item)
	const additionNames = getAdditionNames(item)
	const additionFlagLabel = fxTypeMiddleware.getCommonTypeObjectById('additionType')(`${item.additionFlag}`).label ?? ''
	const amountQrCode = createFxItemQrCode({
		itemCode: item.item?.code,
		itemUnitId: item.unit?.id,
		itemAmount: actualTotal,
		detailId: item.id,
		suppilerCode: utils.getObjDeepValue(bill, 'organ.id', '')
	})
	const printData = {
		baseInfo: [
			{
				printTime: utils.setDate(),
				sysBillCode: bill.sysBillCode,
				userBillCode: bill.userBillCode,
				billTypeName: bill.billTypeName,
				businessDate: utils.setDate(bill.businessDate),
				organName: bill.organ.name,
				operateMan: bill.operateMan.name,
				memo: bill.memo
			}
		],
		detailInfo: [
			{
				storeCode: (item.house && item.house.code) || '',
				storeName: (item.house && item.house.name) || '',
				itemCode: item!.item!.code,
				itemName: item!.item!.name,
				shelfLifeDays: item!.item!.shelfLife,
				composeBomName: (item.composeBom && item.composeBom.name) || '',
				dosingNames,
				additionNames,
				actualTotal,
				planTotal: item.planTotal,
				itemUnitName: item.unit?.name,
				materialTheoreticalCostAmount: item.dosingTheoryCostMoney,
				materialCostCharge: item.materialCostCharge,
				planMakeCharge: item.planMakeCharge,
				makeCharge: item.makeCharge,
				manualCharge: item.manualCharge,
				otherChargeTypeNames,
				otherCharge: item.otherCharge,
				productionCostPrice: item.productionCostPrice,
				productionCost: item.productionCost,
				planProductionCostPrice: item.planProductionCostPrice,
				planProductionCost: item.planProductionCost,
				taxRate: item.taxRate,
				productionDate: item.productDate,
				additionFlagLabel,
				detailMemo: item.memo,
				weightValue: accurateWeighRecord.netWeight,
				peerValue: accurateWeighRecord.tareWeight,
				roughWeight: accurateWeighRecord.grossWeight,
				weightUnit: accurateWeighRecord.unitName,
				addCount: weighRecord.num
			}
		],
		itemCode: [{
			itemBarcode: item!.unit!.barcode || '',
			batchQrCode: item!.batchCode || '',
			amountQrCode
		}]
	}
	return printData
}
