import type { RequestConfig } from '@/js/api/dataSource'
import { get } from '@/js/api/dataSource'
export default {
	// 参数接口
	paramConfig: {
		// 获取所有系统参数
		getParamsAll (): RequestConfig {
			return get({ url: 'param/config/v1/all', loading: false })
		}
	}
}
