import request from '@/utils/request'
import { params } from 'postcss-html'

export function login (data) {
	return request({
		url: '/login',
		headers: {
			isToken: false,
			repeatSubmit: false
		},
		method: 'post',
		data
	})
}

// 退出方法
export function logout () {
	return request({
		url: '/logout',
		method: 'post'
	})
}

// 获取用户详细信息
export function getInfo () {
	return request({
		url: '/getInfo',
		method: 'get'
	})
}

export function getTableList (query) {
	return request({
		url: '/orderCounter/detailList',
		method: 'get',
		params: query
	})
}

export function listCounterOrderFlowDetail (data) {
	return request({
		url: 'counterOrder/listCounterOrderFlowDetail',
		method: 'post',
		data
	})
}

export function getProductGift () {
	return request({
		url: '/productGift/listForPad',
		method: 'get'
	})
}

export function addCounterOrderFlowDetail (data) {
	return request({
		url: '/counterOrder/addCounterOrderFlowDetail',
		method: 'post',
		data
	})
}

// 单品类型
export function getProductTypeList () {
	return request({
		url: '/productType/allList',
		method: 'get'
	})
}

export function getProductList (query) {
	return request({
		url: '/product/listForPad',
		method: 'get',
		params: query
	})
}

export function getPackageList (query) {
	return request({
		url: '/productPackage/listForPad',
		method: 'get',
		query
	})
}

export function clearShoppingCar (data) {
	return request({
		url: 'counterOrder/clearShoppingCar',
		method: 'post',
		data
	})
}

export function completePayment (data) {
	return request({
		url: 'counterOrder/completePayment',
		method: 'post',
		data
	})
}

export function virtualAccountCompletePayment (data) {
	return request({
		url: 'counterOrder/virtualAccountCompletePayment',
		method: 'POST',
		data
	})
}

// 开台
export function openStage (data) {
	return request({
		// url: `/orderCounter/openStage`,
		url: `/orderCounter/openStageForPad`,
		method: 'post',
		data
	})
}

export function applyOpenStage (data) {
	return request({
		url: '/orderCounter/applyOpenStage',
		method: 'post',
		data
	})
}

export function giveUpStage (query) {
	return request({
		url: '/counterOrder/release',
		method: 'get',
		params: query
	})
}

// 获取所有客户
export function getAllCustomerList (query) {
	return request({
		url: '/sysCustomer/allList',
		method: 'get',
		params: query
	})
}

export function fuzzyMobileList (mobile) {
	return request({
		url: '/sysCustomer/fuzzyMobileList',
		method: 'GET',
		params: {
			mobile
		}
	})
}

//获取存酒情况
export function stockListByCustomer (params) {
	return request({
		url: '/pssStorageWine/stockListByCustomer',
		method: 'GET',
		params
	})
}

//发起存酒
export function storageWines (data) {
	return request({
		url: '/pssStorageWine/storageWines',
		method: 'POST',
		data
	})
}

export function packageDetail (id) {
	return request({
		url: '/productPackage/findDetailList',
		method: 'GET',
		params: { id }
	})
}

export function findPaymentQrCodePic (counterNo) {
	return request({
		url: '/orderCounter/findPaymentQrCodePic',
		method: 'GET',
		params: {
			counterNo
		}
	})
}

export function sendVerifyCodeSms (mobile, t) {
	let type = 0
	if (t === 'cun') {
		type = 1
	} else if (t === 'qu') {
		type = 2
	} else if (t === 'quyz') {
		type = 3
	} else if (t === 'cunyz') {
		type = 4
	}
	return request({
		url: '/pssStorageWine/sendVerifyCodeSms',
		method: 'GET',
		params: {
			mobile,
			type
		}
	})
}

export function verifiedStorageWines (data) {
	// return request({
	// 	url: '/pssStorageWine/verifiedStorageWines',
	// 	method: 'POST',
	// 	data
	// })
	return request({
		url: '/pssStorageWine/verifiedStorageWines',
		method: 'POST'
	})
}

export function sakeWines (data) {
	return request({
		url: '/pssStorageWine/sakeWines',
		method: 'POST',
		data
	})
}

export function verifiedRetrievalWine () {
	return request({
		url: '/pssRetrievalWine/verifiedRetrievalWine',
		method: 'GET'
	})
}

export function verifyCodeSms (data) {
	return request({
		url: '/pssRetrievalWine/verifyCodeSms',
		method: 'POST',
		data
	})
}

export function stockWineDetail (storageWineNo) {
	return request({
		url: '/pssStorageWine/stockWineDetail',
		method: 'GET',
		params: {
			storageWineNo
		}
	})
}

export function findDetailByCounterDetailId (counterDetailId) {
	return request({
		url: '/orderCounter/findDetailByCounterDetailId',
		method: 'GET',
		params: {
			counterDetailId
		}
	})
}

export function verifiedStorageWinesProductInfo (data) {
	return request({
		url: '/pssStorageWine/verifiedStorageWinesProductInfo',
		method: 'POST',
		data
	})
}

export function callManager (params) {
	return request({
		url: '/callManager',
		method: 'GET',
		params
	})
}

export function cartList (data) {
	return request({
		url: '/counterOrder/cart/list ',
		method: 'POST',
		data
	})
}

export function queryTradPay (data) {
	return request({
		url: '/counterOrder/queryTradePay',
		method: 'POST',
		data
	})
}

export function cartToPay (data) {
	return request({
		url: '/counterOrder/cart/toPay',
		method: 'POST',
		data
	})
}


export function cartGetUnPaid (data) {
	return request({
		url: '/counterOrder/cart/getUnPaid',
		method: 'POST',
		data
	})
}

export function cartCancelPay (data) {
	return request({
		url: '/counterOrder/cart/cancelPay',
		method: 'POST',
		data
	})
}

export function findBalancePrice (params) {
	return request({
		url: '/sysCustomerAccount/findBalancePrice',
		method: 'GET',
		params
	})
}

export function findStockDrinkFreeList (data) {
	return request({
		// url: '/sysCustomerAccount/findStockDrinkFreeList',
		url: '/orderCounterOrderDrinkFree/findStockDrinkFreeList',
		method: 'POST',
		data
	})
}


