<template>
	<div class="scales-weigh-panel">
		<div v-show="!simple" class="scales-panel__info">
			<div class="scales-cell">
				<div class="scales-cell__label weight-unit-label">净重({{ weightUnitLabel }})：</div>
				<div class="scales-cell__value weight-unit-weight">{{weightUnitWeight}}</div>
			</div>
			<div class="scales-system-info">
				<div class="signal-state">
					<div class="signal-state-doc" :class="{
						'is-active': isStable
					}"></div>
					<div>稳定</div>
					<div class="signal-state-doc" :class="{
						'is-active': isTared
					}"></div>
					<div>去皮</div>
					<w-icon type="battery-level" class="battery-level-icon"></w-icon>
					<div>{{voltagePercent}}%</div>
				</div>
			</div>
			<div class="scales-cell scales-cell-bottom">
				<div class="weigh-cell">
					<div class="scales-cell__label">皮：</div>
					<div class="scales-cell__value">{{weightUnitPeelWeight}}</div>
				</div>
				<div class="weigh-cell">
					<div class="scales-cell__label">毛：</div>
					<div class="scales-cell__value">{{weightUnitGrossWeight}}</div>
				</div>
				<div class="weigh-cell">
					<template v-if="data.item && data.item.assistUnit">
						<div class="scales-cell__label">辅({{data.item && data.item.assistUnit}})：</div>
						<div class="scales-cell__value">{{assistUnitAmountComputed}}</div>
					</template>
					<template v-else>
						<div class="scales-cell__label">辅/无：</div>
						<div class="scales-cell__value">-</div>
					</template>
				</div>
			</div>
		</div>
		<div v-show="simple" class="scales-panel__info scales-panel__info-simple">
			<div class="scales-cell">
				<div class="scales-cell__label">净重({{ weightUnitLabel }})：</div>
				<div class="scales-cell__value">{{weightUnitWeight}}</div>
				<a-button
					v-show="!disabled"
					shape="round"
					class="scales-btn-simple"
					ghost
					:disabled="disabled"
					@click="onTareClick"
				>去皮</a-button>
				<a-button
					v-show="!disabled"
					shape="round"
					class="scales-btn-simple"
					ghost
					:disabled="disabled"
					@click="onAccClick"
				>累加</a-button>
				<a-button
					type="primary"
					shape="round"
					class="scales-btn-simple"
					:disabled="disabled"
					@click="onScalesSaveClick"
				>保存</a-button>
				<w-icon
					class="expand-btn"
					type="expand"
					@click="onSwitchScalesSimpleClick"
				></w-icon>
			</div>
		</div>
		<div v-if="showMoney" v-show="!simple" class="scales-panel__money">
			<slot name="money">
				<div class="money-label">金额</div>
				<div class="money-value">¥{{moneySum}}</div>
			</slot>
		</div>
		<div v-show="!simple" class="scales-panel__btn-tools">
			<div
				v-for="item in scaleBtnListFirst"
				v-show="item.isShow"
				class="scales-btn"
				:class="{
					'is-active':item.isActive,
					'is-disabled':disabled || item.isDisabled
				}"
				:style="btnWidthStyle"
				:key="item.id"
				@click="onScaleBtnClick(item.handler)"
			>
				<span>{{item.name}}</span>
				<w-icon :imgUrl="stateIcons[item.icon]" class="scales-btn-icon"></w-icon>
			</div>
		</div>
		<div v-show="!simple" class="scales-panel__btn-tools">
			<div
				v-for="item in scaleBtnListSecond"
				class="scales-btn"
				:class="{
					'is-active':item.isActive,
					'is-disabled':disabled || item.isDisabled
				}"
				:key="item.id"
				@click="onScaleBtnClick(item.handler)"
			>
				<span>{{item.name}}</span>
				<w-icon :imgUrl="stateIcons[item.icon]" class="scales-btn-icon"></w-icon>
			</div>
		</div>
		<div v-show="!simple" class="scales-panel__weight-total">
			<div class="weight-total-label">总重</div>
			<div class="weight-total-value">
				<w-icon type="scale-net" class="scales-btn-icon" size="mini"></w-icon>
				{{totalWeight.netWeight}}{{totalWeight.unitName}}
			</div>
			<div class="weight-total-value">
				<w-icon type="scale-peel" class="scales-btn-icon" size="mini"></w-icon>
				{{totalWeight.tareWeight}}{{totalWeight.unitName}}
			</div>
			<div class="weight-total-value">
				<div v-if="$slots['stock']" v-show="!simple" class="scales-panel__stock">
					<slot name="stock"></slot>
				</div>
			</div>
		</div>
		<div v-show="!simple" class="scales-panel__weight-list">
			<div
				v-for="(item, index) in data.weighRecords"
				:key="index"
				:class="['scales-panel__weight-block', {
					'is-active': weighRecordActive===index
				}]"
				@click="onWeighRecordItemClick(index)"
			>
				<div class="weight-block__title">称重{{index+1}}</div>
				<div class="weight-block__weight">净重：{{store.getters.getWeightValue(item.netWeight)}}{{ weightUnitLabel }}</div>
				<div class="weight-block__peelWeight">皮重：{{store.getters.getWeightValue(item.tareWeight)}}{{ weightUnitLabel }}</div>
				<w-icon v-show="!disabled &&  weighRecordActive===index " type="trash-can" class="delete-weight-btn" @click="onWeightDeleteClick(index)"></w-icon>
				<w-icon v-show="!disabled &&  weighRecordActive!==index " type="weigh-trash-can" class="delete-weight-btn" @click="onWeightDeleteClick(index)"></w-icon>
			</div>
			<a-button type="dashed" class="setting-manually__btn" @click="onManuallyClick">
				<template #icon><PlusOutlined class="add-btn"/></template>
			</a-button>
		</div>
		<manuallyWeightDialog
			v-model:value="manuallyWeightDialogVisible"
			:pointSize="sysPointSize"
			@on-confirm="onManuallyWeightConfirm"
		></manuallyWeightDialog>
		<BasketTareDialog v-model:value="basketTareDialogVisible"></BasketTareDialog>
		<w-textarea-dialog
			v-model:value="itemMemoDialogVisible"
			:inputValue="itemMemo"
			:maxlength="70"
			title="备注"
			@on-confirm="onItemMemoDialogConfirm"
		></w-textarea-dialog>
	</div>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue'
import { getFxInstance } from '@/js/instance'
import { useAppIcon, useWeight } from '@/js/hooks'
import { useStore } from '@/store'
import type { WeightItem } from '@/models/weight'
import ManuallyWeightDialog from './ManuallyWeightDialog.vue'
import BasketTareDialog from './BasketTareDialog.vue'
import { PlusOutlined } from '@ant-design/icons-vue'
import windUi from '@/components/wind-ui'
export default defineComponent({
	name: 'fx-scales-weigh',
	components: {
		manuallyWeightDialog: ManuallyWeightDialog,
		BasketTareDialog,
		PlusOutlined
	},
	props: {
		data: {
			type: Object,
			default: () => {
				return {
				}
			}
		},
		showMoney: {
			type: Boolean,
			default: true
		},
		simple: Boolean,
		disabled: Boolean,
		disabledMoney: {
			type: Boolean,
			default: false
		},
		disabledAmount: {
			type: Boolean,
			default: false
		},
		enterManually: {
			type: Boolean,
			default: true
		}
	},
	emits: [
		'update:simple',
		'on-weight-records-change',
		'on-weight-stable',
		'on-weight-print-auto',
		'on-memo-change',
		'on-simple-save-click',
		'on-simple-acc-click'
	],
	setup (props, { emit }) {
		onMounted(() => {
			fxInstance.$fxWeigh.connectAndListenerWeighSilent()
		})
		const fxInstance = getFxInstance()
		const store = useStore()
		const {
			connected,
			resetWeightState,
			weight,
			roundDownWeight,
			roundUpWeight,
			peelWeight,
			weightUnitWeight,
			weightUnitPeelWeight,
			weightUnitGrossWeight,
			isTared,
			isStable,
			isWeightLock,
			weightLockToggle,
			isRoundDown,
			isRoundUp
		} = useWeight()
		const weightUnitLabel = computed(() => store.getters.getWeightUnitLabel)
		const voltagePercent = ref('100')
		const btnWidthStyle = computed(() => {
			const btnWidth = 100 / scaleBtnListFirst.value.filter(item => item.isShow).length
			return `width: ${btnWidth}%;`
		})
		const scaleBtnListFirst = computed(() => {
			return [{
				id: 'zero',
				name: '归零',
				icon: 'scaleActionZero',
				isActive: false,
				isDisabled: false,
				isShow: true,
				handler: () => {
					fxInstance.$fxWeigh.setZero()
				}
			}, {
				id: 'manually',
				name: '手工录入',
				icon: 'scaleActionManually',
				isActive: false,
				isDisabled: props.disabledAmount,
				isShow: props.enterManually && false,
				handler: () => {
					if (props.data.weighRecords.length >= fxInstance.$fxCommon.getMaxWeightCount()) {
						return false
					}
					manuallyWeightDialogVisible.value = true
				}
			}, {
				id: 'basketTare',
				name: '筐皮',
				icon: 'scaleActionManually',
				isActive: false,
				isDisabled: props.disabledAmount,
				isShow: true,
				handler: () => {
					if (!connected.value) {
						return windUi.$fxMessage.warning('请连接电子秤!')
					}
					if (props.data.weighRecords.length >= fxInstance.$fxCommon.getMaxWeightCount()) {
						return false
					}
					basketTareDialogVisible.value = true
				}
			}, {
				id: 'lock',
				name: '锁定',
				icon: 'scaleActionLock',
				isActive: isWeightLock.value,
				isDisabled: false,
				isShow: true,
				handler: weightLockToggle
			}, {
				id: 'clear',
				name: '清空',
				icon: 'scaleActionClear',
				isActive: false,
				isDisabled: false,
				isShow: true,
				handler: () => {
					const data = props.data
					if (data.weighRecords.length > 0) {
						fxInstance.$fxMessageBox.confirm('是否清空当前称重数据？').then(() => {
							data.weighRecords = []
							emit('on-weight-records-change')
						})
					}
				}
			}]
		})
		const scaleBtnListSecond = computed(() => {
			return [{
				id: 'tare',
				name: '去皮',
				icon: 'scaleActionTare',
				isActive: false,
				isDisabled: false,
				handler: () => {
					fxInstance.$fxWeigh.setTare()
				}
			}, {
				id: 'roundDown',
				name: '抹零',
				icon: 'scaleActionRoundDown',
				isActive: isRoundDown.value,
				isDisabled: props.disabledAmount,
				handler: () => {
					accWeight(roundDownWeight.value)
				}
			}, {
				id: 'roundUp',
				name: '取整',
				icon: 'scaleActionRoundUp',
				isActive: isRoundUp.value,
				isDisabled: props.disabledAmount,
				handler: () => {
					accWeight(roundUpWeight.value)
				}
			}, {
				id: 'memo',
				name: '备注',
				icon: 'scaleActionMemo',
				isActive: false,
				isDisabled: false,
				handler: () => {
					itemMemo.value = props.data.memo
					itemMemoDialogVisible.value = true
				}
			}]
		})
		const onScaleBtnClick = (handler: Function) => {
			if (props.disabled) {
				return false
			}
			handler()
		}
		const stateList = [...scaleBtnListFirst.value.map(item => item.icon), ...scaleBtnListSecond.value.map(item => item.icon)]
		const stateIcons = useAppIcon(stateList)
		onMounted(() => {
			resetWeightState()
			fxInstance.$fxWeigh.onListenerState(state => {
				if (state.isStable && weight.value) {
					emit('on-weight-stable', {
						weight: weight.value,
						peelWeight: peelWeight.value,
						isTared: isTared.value
					})
				}
			})
			fxInstance.$fxWeigh.onListenerBatteryState(state => {
				voltagePercent.value = state.voltagePercent
			})
		})
		const isItemCanWeigh = computed(() => {
			return !!(props.data.item && props.data.item.canWeigh)
		})
		// tareWeight
		const totalWeight = computed(() => {
			if (props.data.weighRecords) {
				return getSumWeightRecord()
			} else {
				return {}
			}
		})
		const accWeight = (_weight:number = weight.value, _peelWeight:number = peelWeight.value) => {
			if (props.data.weighRecords.length >= fxInstance.$fxCommon.getMaxWeightCount()) {
				return false
			}
			if (_weight <= 0) {
				fxInstance.$fxMessage.warning('请先称重!')
				return false
			}
			const weighRecords = props.data.weighRecords
			const money = fxInstance.$fxUtils.toFixed(props.data.item.kgToMainUnit * weight.value * props.data.mainUnitPrice, store.getters.getSysMoneyPointSize)
			const grossWeight = fxInstance.$fxUtils.toFixedParseNumber(_weight + _peelWeight, store.getters.getSysPointSize)
			weighRecords.push({
				id: fxInstance.$fxUtils.createUUID(),
				unitName: 'Kg',
				netWeight: _weight,
				tareWeight: _peelWeight,
				grossWeight,
				money,
				num: 0
			})
			weighRecords.forEach((item:{num:number}, index:number) => {
				item.num = index + 1
			})
			weighRecordActive.value = weighRecords.length - 1
			emit('on-weight-records-change')
			if (store.getters.getWeightPrintAuto) {
				emit('on-weight-print-auto')
			}
		}
		const doSaveWeight = () => {
			return new Promise(resolve => {
				if (isItemCanWeigh.value && props.data.weighRecords.length === 0 && weight.value > 0) {
					accWeight()
					resolve(true)
				} else if (isItemCanWeigh.value && props.data.weighRecords.length === 0 && weight.value === 0) {
					fxInstance.$fxMessage.warning('请先称重!')
					resolve(false)
				} else {
					resolve(true)
				}
			})
		}
		const weighRecordActive = ref(-1)
		const onWeighRecordItemClick = (index:number) => {
			weighRecordActive.value = index
		}
		const clearWeightRecordActive = () => {
			weighRecordActive.value = -1
		}
		const onWeightDeleteClick = (index:number) => {
			const weighRecords = props.data.weighRecords
			weighRecords.splice(index, 1)
			weighRecordActive.value = -1
			emit('on-weight-records-change')
		}
		const getActiveWeighRecord = ():Promise<Partial<WeightItem>> => {
			return new Promise(resolve => {
				if (props.data.weighRecords.length === 0) {
					fxInstance.$fxMessage.warning('请先称重!')
				} else if (!~weighRecordActive.value) {
					resolve(getSumWeightRecord())
				} else {
					resolve(props.data.weighRecords[weighRecordActive.value])
				}
			})
		}
		const getSumWeightRecord = (): Partial<WeightItem> => {
			const weightData = (props.data.weighRecords as WeightItem[])?.reduce((prev, cur) => {
				prev.netWeight += cur.netWeight
				prev.grossWeight += cur.grossWeight
				prev.tareWeight += cur.tareWeight
				prev.unitName = weightUnitLabel.value
				return prev
			}, {
				netWeight: 0,
				grossWeight: 0,
				tareWeight: 0,
				unitName: ''
			})
			weightData.netWeight = fxInstance.$fxUtils.toFixedParseNumber(weightData.netWeight, store.getters.getSysPointSize)
			weightData.grossWeight = fxInstance.$fxUtils.toFixedParseNumber(weightData.grossWeight, store.getters.getSysPointSize)
			weightData.tareWeight = fxInstance.$fxUtils.toFixedParseNumber(weightData.tareWeight, store.getters.getSysPointSize)
			return weightData
		}
		const weightSum = computed(() => {
			if (!props.data.weighRecords || props.data.weighRecords.length === 0) {
				return 0
			}
			const sum = fxInstance.$fxUtils.arrSum(props.data.weighRecords, (item:WeightItem) => item.netWeight || 0, store.getters.getSysPointSize)
			return fxInstance.$fxUtils.toFixedParseNumber(sum, store.getters.getSysPointSize)
		})
		const moneySum = computed(() => {
			if (!props.data.item) {
				return 0
			}
			const sum = fxInstance.$fxUtils.arrSum(props.data.weighRecords, (item:WeightItem) => item.money || 0, store.getters.getSysMoneyPointSize)
			return fxInstance.$fxUtils.toFixed(sum, store.getters.getSysMoneyPointSize)
		})
		const basketTareDialogVisible = ref(false)
		const manuallyWeightDialogVisible = ref(false)
		const sysPointSize = computed(() => store.getters.getWieghtUnitDecimalPlaceValue)
		const onManuallyWeightConfirm = (weighData: {weight:number, peer:number}) => {
			accWeight(weighData.weight, weighData.peer)
		}
		const onManuallyClick = () => {
			if (props.data.weighRecords.length >= fxInstance.$fxCommon.getMaxWeightCount()) {
				return false
			}
			manuallyWeightDialogVisible.value = true
		}
		const getWeightSum = () => {
			return weightSum.value
		}
		const assistUnitAmountComputed = computed(() => {
			if (!props.data.item.assistUnit || !weight.value) {
				return 0
			}
			return fxInstance.$fxUtils.toFixed(props.data.item.kgToMainUnit * weight.value / props.data.item.assistUnitRatio, store.getters.getSysPointSize)
		})
		// {{{ itemMemoDialog
		const itemMemoDialogVisible = ref(false)
		const itemMemo = ref('')
		const onItemMemoDialogConfirm = (value:string) => {
			itemMemo.value = value
			emit('on-memo-change', value)
		}
		const getMemo = () => {
			return itemMemo.value
		}
		// }}}
		const onTareClick = () => {
			fxInstance.$fxWeigh.setTare()
		}
		const onAccClick = () => {
			emit('on-simple-acc-click')
		}
		const onScalesSaveClick = () => {
			emit('on-simple-save-click')
		}
		const onSwitchScalesSimpleClick = () => {
			emit('update:simple', false)
		}
		return {
			store,
			weightUnitLabel,
			weightUnitWeight,
			weight,
			peelWeight,
			weightUnitPeelWeight,
			weightUnitGrossWeight,
			totalWeight,
			voltagePercent,
			isStable,
			isTared,
			moneySum,
			stateIcons,
			btnWidthStyle,
			scaleBtnListFirst,
			scaleBtnListSecond,
			onWeightDeleteClick,
			onScaleBtnClick,
			weighRecordActive,
			onWeighRecordItemClick,
			clearWeightRecordActive,
			getActiveWeighRecord,
			basketTareDialogVisible,
			manuallyWeightDialogVisible,
			sysPointSize,
			onManuallyWeightConfirm,
			onManuallyClick,
			assistUnitAmountComputed,
			itemMemoDialogVisible,
			itemMemo,
			onItemMemoDialogConfirm,
			accWeight,
			doSaveWeight,
			getWeightSum,
			getMemo,
			onTareClick,
			onAccClick,
			onScalesSaveClick,
			onSwitchScalesSimpleClick
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.scales-weigh-panel {
	.scales-panel__info {
		position: relative;
		display: flex;
		overflow: hidden;
		height: 120px;
		color: #ffffff;
		background-color: #252e34;
		flex-direction: column;
		&-simple {
			height: 50px;
			line-height: 50px;
			.scales-cell {
				align-items: center;
			}
			.scales-cell__label {
				font-size: 18px;
				flex: none !important;
			}
			.scales-btn-simple {
				margin-right: 5px;
				padding: 4px 10px;
			}
			.expand-btn {
				margin-right: 5px;
				margin-left: 5px;
				width: 23px;
				height: 23px;
			}
		}
		.scales-system-info {
			position: absolute;
			top: 30px;
			display: flex;
			align-items: center;
			width: 100%;
			height: 23px;
			box-sizing: border-box;
			.signal-state {
				flex: 1;
				display: flex;
				align-items: center;
				font-size: 14px;
				.signal-state-doc {
					margin-right: 10px;
					margin-left: 10px;
					width: 10px;
					height: 10px;
					border-radius: 5px;
					background-color: #eeeeee;
					&.is-active {
						background-color: #61ef8b;
					}
				}
			}
			.battery-level-icon {
				margin-right: 10px;
				margin-left: 10px;
			}
		}
		.scales-cell-bottom {
			display: flex;
			flex: 1;
			width: 100%;
			font-size: 1.563vw;
			border-top: 1px solid #DBDBDB;
			.scales-cell__value {
				position: absolute;
				right: 9px;
				bottom: 10px;
				font-size: 20px;
			}
		}
		.scales-cell {
			display: flex;
			width: 100%;
			font-size: 15px;
			.weigh-cell {
				position: relative;
				display: flex;
				border-right: 1px solid #DBDBDB;
				flex: 1;
			}
			.scales-cell__label {
				margin-left: 10px;
			}
			.weight-unit-label {
				height: 18px;
				font-size: 18px;
				font-weight: 500;
				color: #FFFFFF;
			}
			.scales-cell__value {
				margin-right: 10px;
				text-align: right;
				flex: 1;
			}
			.weight-unit-weight {
				font-size: 35px;
			}
		}
	}
	.scales-panel__money {
		display: flex;
		align-items: center;
		padding: 6px;
		height: 48px;
		font-size: 18px;
		font-weight: bold;
		border-bottom: 1px solid #979797;
		border-radius: 5px;
		.money-label {
			flex: 1;
			margin-left: 10px;
		}
		.money-value {
			margin-right: 10px;
			text-align: right;
			flex: 1;
		}
	}
	.scales-panel__stock {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: 0 10px;
		width: 100%;
		border: none;
	}
	.scales-panel__btn-tools {
		display: flex;
		height: 42px;
		font-size: 16px;
		.scales-btn {
			width: 92px;
			height: 42px;
			border-right: 1px solid #979797;
			border-bottom: 1px solid #979797;
			text-align: center;
			background: #F7F7F7;
			line-height: 42px;
			&-icon {
				position: absolute;
				right: 0;
				bottom: 0;
			}
			&.is-active {
				color: $fxWhite;
				background-color: $fxDefaultColor;
			}
			&.is-disabled {
				pointer-events: none;
				background-color: $fxGray21;
			}
		}
	}
	.scales-panel__weight-total {
		font-size: 13px;
		color: #666666;
		.weight-total-label {
			display: inline-block;
			margin: 2px 10px 0 6px;
		}
		.weight-total-value {
			display: inline-block;
			margin-right: 16px;
			font-size: 12px;
		}
		.scales-btn-icon {
			margin-bottom: 3px;
			vertical-align: middle;
		}
	}
	.scales-panel__weight-list {
		display: flex;
		overflow: auto;
		margin-top: 2px;
		.scales-panel__weight-block {
			position: relative;
			margin: 0 5px;
			padding: 5px;
			width: 153px;
			height: 85px;
			font-size: 13px;
			border: 1px solid #C7C7C7;
			border-radius: 5px;
			color: #666666;
			background: #FFFFFF;
			box-sizing: border-box;
			flex-shrink: 0;
			.weight-block__weight {
				font-size: 15px;
			}
			&.is-active {
				border-color: $fxDefaultColor;
				color: $fxDefaultColor;
			}
			.delete-weight-btn {
				position: absolute;
				top: 5px;
				right: 5px;
				width: 15px;
				height: 15px;
				color: #333333;
			}
		}
		.setting-manually__btn {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 5px;
			width: 85px;
			height: 85px;
			border-color: $fxBlue20;
			border-radius: 5px;
			color: $fxBlue20;
			flex-shrink: 0;
			.add-btn {
				font-size: 30px;
			}
		}
	}
}
</style>
