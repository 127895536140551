
export default {
	// 品项管理——存储方式
	storageMethod: [{
		id: '0',
		label: '常温'
	}, {
		id: '1',
		label: '冷藏'
	}, {
		id: '2',
		label: '冷冻'
	}],
	// 品项管理——品项是否参与称重
	itemWeighType: [{
		id: 1,
		name: '参与称重'
	}, {
		id: 0,
		name: '不参与称重'
	}]
}
