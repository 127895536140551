import type { RequestConfig } from '@/js/api/dataSource'
import { get, post, put, del } from '@/js/api/dataSource'
import fxCommon from '@/js/common'
const API_MODULE = 'takeStockOrder'
export default {
	// 盘点单
	takeStockOrder: {
		// {{{ 盘点单主表
		// 获取盘点单主表
		getBillList (): RequestConfig {
			return post({ url: 'bill/pad/check/v1/page' })
		},
		// 批量删除
		delMulity (): RequestConfig {
			return put({ url: 'bill/pad/check/v1/delete', successToast: true })
		},
		// 批量提交
		submitMulity (): RequestConfig {
			return put({ url: 'bill/pad/check/v1/submit', successToast: true, errorToast: false })
		},
		// 批量退回
		returnMulity (): RequestConfig {
			return put({ url: 'bill/pad/check/v1/back', successToast: true })
		},
		// 批量审核
		checkMulity (): RequestConfig {
			return put({ url: 'bill/pad/check/v1/check', successToast: true, errorToast: false })
		},
		// 批量反审
		uncheckMulity (): RequestConfig {
			return put({ url: 'bill/pad/check/v1/uncheck', successToast: true, errorToast: false })
		},
		// 批量复审
		recheckMulity (): RequestConfig {
			return put({ url: 'bill/pad/check/v1/recheck', successToast: true })
		},
		// 批量取消复审
		reuncheckMulity (): RequestConfig {
			return put({ url: 'bill/pad/check/v1/un_recheck', successToast: true })
		},
		// }}}
		// {{{ 新增盘点单
		// 获取机构列表
		getOrganList (): RequestConfig {
			return post({
				url: 'archive/mobile/organ/v1/in_store/organ_list',
				data: {
					flag: 'pad'
				}
			})
		},
		// 获取仓库列表
		getStoreList (organId: string): RequestConfig {
			return post({ url: `archive/bill/house/v1/organ/${organId}/houseList` })
		},
		// 获取盘点分类列表
		getCheckClassList (): RequestConfig {
			return post({ url: 'bill/check/class/v1/list' })
		},
		// 获取经办人列表
		getAgentList (): RequestConfig {
			return post({ url: 'archive/bill/employee/v1/operator_list' })
		},
		// 新增盘点单
		addBill (): RequestConfig {
			return post({
				url: 'bill/pad/check/v1',
				success: res => fxCommon.normalizationBillUpdateVersion(API_MODULE, res)
			})
		},
		// }}}
		// {{{ 盘点中
		// 获取盘点品项
		getCheckDetail (billId: string): RequestConfig {
			return get({
				url: `bill/pad/check/v1/${billId}`,
				success: res => fxCommon.normalizationBillUpdateVersion(API_MODULE, res)
			})
		},
		// 查询品项的账存、账存金额、最小单位单价
		getItemStock (): RequestConfig {
			return post({ url: 'bill/check/v1/item_stock_price' })
		},
		// 新增盘点单明细
		addDetail (billId: string): RequestConfig {
			return post({
				url: `bill/pad/check/${billId}/detail/v1`,
				successToast: true,
				data: { ...fxCommon.getBillDetailUpdateVersion(API_MODULE, billId) },
				success: res => fxCommon.normalizationBillDetailUpdateVersion(API_MODULE, res, billId)
			})
		},
		// 保存盘点单明细
		saveDetail (billId: string, detailId: string): RequestConfig {
			return put({
				url: `bill/pad/check/${billId}/detail/v1/${detailId}`,
				successToast: true,
				data: { ...fxCommon.getBillDetailUpdateVersion(API_MODULE, billId) },
				success: res => fxCommon.normalizationBillDetailUpdateVersion(API_MODULE, res, billId)
			})
		},
		// 删除盘点明细
		deleteDetails (billId: string): RequestConfig {
			return del({
				url: `bill/pad/check/${billId}/detail/v1/delete`,
				successToast: true
			})
		},
		// }}}
		// {{{ 盘点单详情
		// 获取业务类型
		getBusinessTypeList (billType: string): RequestConfig {
			return post({ url: `archive/bill/bill_biz_type/v1/bill_type/102/${billType}` })
		},
		// 单据详情
		getBillDetail (billId: string): RequestConfig {
			return get({
				url: `bill/pad/check/v1/${billId}`,
				success: res => fxCommon.normalizationBillUpdateVersion(API_MODULE, res)
			})
		},
		// 保存单据
		saveBill (billId: string): RequestConfig {
			return put({
				url: `bill/pad/check/v1/${billId}`,
				successToast: true,
				data: {
					...fxCommon.getBillUpdateVersion(API_MODULE, billId),
					...fxCommon.getTenantIdParam()
				},
				success: res => fxCommon.normalizationBillUpdateVersion(API_MODULE, res)
			})
		},
		// 删除单据
		deleteBill (billId: string): RequestConfig {
			return del({ url: `bill/pad/check/v1/${billId}/delete`, successToast: true })
		},
		// 提交单据
		submitBill (billId: string): RequestConfig {
			return put({ url: `bill/pad/check/v1/${billId}/submit`, successToast: true, errorToast: false })
		},
		// 退回单据
		returnBill (billId: string): RequestConfig {
			return put({ url: `bill/pad/check/v1/${billId}/back`, successToast: true })
		},
		// 审核单据
		checkBill (billId: string): RequestConfig {
			return put({ url: `bill/pad/check/v1/${billId}/check`, successToast: true, errorToast: false })
		},
		// 反审单据
		uncheckBill (billId: string): RequestConfig {
			return put({ url: `bill/pad/check/v1/${billId}/uncheck`, successToast: true, errorToast: false })
		},
		// 复审单据
		recheckBill (billId: string): RequestConfig {
			return put({ url: `bill/pad/check/v1/${billId}/recheck`, successToast: true })
		},
		// 取消复审单据
		unrecheckBill (billId: string): RequestConfig {
			return put({ url: `bill/pad/check/v1/${billId}/un_recheck`, successToast: true })
		},
		// 获取盘盈原因list
		getCheckCauseList (): RequestConfig {
			return get({ url: 'bill/check/cause/v1/list' })
		},
		// }}}
		// {{{
		// 防错校验
		// 提交前的防错校验接口
		checkMistake (billId: string): RequestConfig {
			return post({
				url: `bill/pad/check/v1/mistake_proofing/${billId}`,
				success: (res) => {
					fxCommon.syncBillVersionByMistakeDetails(API_MODULE, billId, res)
					return res
				}
			})
		},
		// 获取必盘品项
		getMustItem (billId: string): RequestConfig {
			return post({
				url: `bill/pad/check/v1/mistake_proofing/${billId}`,
				data: ['MustItem'],
				loading: false,
				success: (res) => {
					fxCommon.syncBillVersionByMistakeDetails(API_MODULE, billId, res)
					return res
				}
			})
		},
		// 获取重复品项
		getRepeat (billId: string): RequestConfig {
			return post({
				url: `bill/pad/check/v1/mistake_proofing/${billId}?error_check=false`,
				data: ['Repeat'],
				loading: false,
				success: (res) => {
					fxCommon.syncBillVersionByMistakeDetails(API_MODULE, billId, res)
					return res
				}
			})
		},
		// 仓库未关联品项查询
		getRel (billId: string): RequestConfig {
			return post({
				url: `bill/pad/check/v1/mistake_proofing/${billId}?error_check=false`,
				data: ['ItemHouse'],
				loading: false,
				success: (res) => {
					fxCommon.syncBillVersionByMistakeDetails(API_MODULE, billId, res)
					return res
				}
			})
		},
		// 批量更新生产日期
		updateProductDate (billId: string): RequestConfig {
			return put({ url: `bill/mobile/check/${billId}/detail/v1/update_product_date`, successToast: true })
		}
		// }}}
	}
}
