// 收货类型枚举
export enum ReceivingTypeEnum {
	// 按单据收货
	receivingByBill = 1,
	// 按出库方收货
	receivingByOtherOrgan = 2
}
// 收货类型
const receivingType = [{
	id: ReceivingTypeEnum.receivingByBill,
	label: '按单据收货'
}, {
	id: ReceivingTypeEnum.receivingByOtherOrgan,
	label: '按出库方收货'
}]
// 单据验收状态
const acceptanceState = [{
	id: 0,
	label: '待验收',
	type: 'unacceptance',
	stateIconColor: 'orange'
}, {
	id: 1,
	label: '已验收',
	type: 'acceptance',
	stateIconColor: 'green'
}]
export default {
	// 收货类型
	receivingType,
	// 单据验收状态
	acceptanceState
}
