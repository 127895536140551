import routerConfig from '@/router/index'
import type { Router, RouteRecordRaw } from 'vue-router'

type RouteType = RouteRecordRaw &{
  meta?: {
    keepAlive?: boolean
  }
}

const flatten = function (arr: RouteType[], keepAlives: string[]): void {
	arr.forEach(item => {
		if (item.meta && item.meta.keepAlive) {
			keepAlives.push(item.name as string)
		}
		if (Array.isArray(item.children)) {
			flatten(item.children as RouteType[], keepAlives)
		}
	})
}

const flattenRouterConfig = function (routerConfig: Router): string[] {
	const keepAlives: string[] = []
	flatten(routerConfig.getRoutes() as RouteType[], keepAlives)
	return keepAlives
}

const keepAlives: string[] = flattenRouterConfig(routerConfig)

export default keepAlives
