
export default {
	// 盘点方式 pad端只有合批的数据, 因此没有分批的选项
	checkWay: [{
		type: 'blending',
		id: 1,
		label: '合批',
		pinYin: 'hp'
	}],
	// 盘点单单据类型
	takeStockBillType: [{
		type: 'takeStock',
		id: 4001,
		label: '盘点',
		pinYin: 'pd'
	}],
	// 未盘品项处理方式
	notCheckType: [{
		type: 'zero',
		id: 0,
		label: '实盘结0',
		pinYin: 'spjl'
	}, {
		type: 'keep',
		id: 1,
		label: '实盘不变',
		pinYin: 'spbb'
	}]
}
