import type { ComponentInternalInstance } from 'vue'
import { getCurrentInstance } from 'vue'
import type { AxiosInstance } from 'axios'
import type { utils } from '@/js/utils'
import type { StateMiddleware } from '@/js/stateMiddleware'
import type { TypeMiddleware } from '@/js/typeMiddleware'
import type { DataSourceReturnFunc } from '@/js/api/dataSource'
import type { CordovaInstance } from '@/js/cordova'
import type { fxEventBus } from '@/js/eventBus'
import type { fxWeigh } from '@/js/weigh'
import type { fxPrint } from '@/js/print'
import type { fxCameraFloat } from '@/js/cameraFloat'
import type { fxRedCode } from '@/js/redCode'
import type { FxCalculation } from '@/js/calculation'
import type { iframePostMessage } from '@/js/iframePostMessage'
import type { fxVersionVerification } from '@/js/versionVerification'
import type { styleDynamic } from '@/js/styleDynamic'
import type { FxMessage, FxMessageBox, FxLoading } from '@/components/wind-ui'
import type fxCommon from '@/js/common'

export interface FxInstance {
	$fxMessage: FxMessage
	$fxMessageBox: FxMessageBox
	$fxLoading: FxLoading
	$fxRequestGet: AxiosInstance
	$fxRequestPut: AxiosInstance
	$fxRequestPost: AxiosInstance
	$fxRequestDel: AxiosInstance
	$fxRequestFile: AxiosInstance
	$fxUtils: typeof utils
	$fxStateMiddleware: StateMiddleware
	$fxTypeMiddleware: TypeMiddleware
	$fxCalculation: FxCalculation
	$fxApi: (apiName: string, ...arg: unknown[]) => DataSourceReturnFunc
	$fxCordova: CordovaInstance
	$fxEventBus: typeof fxEventBus
	$fxWeigh: typeof fxWeigh
	$fxPrint: typeof fxPrint
	$fxIframePostMessage: typeof iframePostMessage
	$fxCameraFloat: typeof fxCameraFloat
	$fxRedCode: typeof fxRedCode
	$fxVersionVerification: typeof fxVersionVerification
	$fxStyleDynamic: typeof styleDynamic
	$fxCommon: typeof fxCommon
}

export const getFxInstance = function (): FxInstance {
	const { appContext } = getCurrentInstance() as ComponentInternalInstance


	return appContext.config.globalProperties as unknown as FxInstance
}
