export default {
	'import-manage': {
		id: 'import-manage',
		label: '基础数据导入',
		code: '0002_0004',
		child: {
			'import-basic': {
				id: 'import-basic',
				label: '基础数据导入',
				code: '0002_0004_030_003'
			}
		}
	}
}
