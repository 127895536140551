<template>
	<a :href="(hrefCoumpted as string)" :class="classes" class="wind-link" @click="onLinkClick" :target='target'>
		<w-icon v-if="icon" :type="icon" :class="[iconClass]" class="wind-link-icon"></w-icon>
		<span class="wind-link__content">
			<slot></slot>
		</span>
		<w-icon-svg
			v-if="afterIconSvg"
			:type="afterIconSvg"
			:class="['wind-link-icon-after', afterIconClass]"
		></w-icon-svg>
	</a>
</template>
<script lang="ts">
/**
 * link by shang 2022/4/27
 * @desc link
 * @params [String] href 跳转地址
 * @params [String] type 类型 primary, danger, default
 * @params [Boolean] stop 阻止冒泡
 * @params [Boolean] disabled 禁用
 * @params [String] target 跳转方式
 */
import { defineComponent, computed } from 'vue'
export default defineComponent({
	name: 'wind-link',
	props: {
		href: String,
		type: {
			type: String,
			default: 'primary'
		},
		stop: Boolean,
		disabled: Boolean,
		underline: Boolean,
		icon: String,
		afterIconSvg: String,
		inline: {
			type: Boolean,
			default: true
		},
		iconClass: String,
		afterIconClass: String,
		target: {
			type: String,
			default: '_self'
		}
	},
	emits: ['click'],
	setup (props, { emit }) {
		const hrefCoumpted = computed(() => {
			return props.href ? props.href : null
		})
		const classes = computed(() => {
			return [`wind-link-${props.type}`, {
				'is-block': !props.inline,
				'is-disabled': props.disabled,
				'is-underline': props.underline
			}]
		})
		const onLinkClick = function () {
			if (props.disabled) {
				return false
			}
			if (props.stop) {
				(event as Event).stopPropagation()
			}
			emit('click')
		}
		return {
			hrefCoumpted,
			classes,
			onLinkClick
		}
	}
})
</script>
<style lang="scss" scoped>
@import "../../style/varsty";
.wind-link {
	display: flex;
	align-items: center;
    padding: 0 5px;
    font-size: 12px;
    line-height: 20px;
    &.is-block {
        display: block;
    }
    &.is-disabled {
        cursor: not-allowed;
    }
    &.is-underline {
        text-decoration: underline;
    }
    .wind-link-icon {
        margin-right: 4px;
        width: 15px;
        height: 15px;
    }
	.wind-link-icon-after {
        margin-left: 4px;
		width: 15px;
		height: 15px;
	}
    &-default {
        &:hover {
            color:$wind-link-color-primary;
        }
    }
    &-gray {
        color: $fxBlack1;
        &:hover {
            opacity: 0.7;
        }
    }
    &-primary {
        color:$wind-link-color-primary;
        &:hover {
            color:$wind-link-color-primary;
            opacity: 0.7;
        }
    }
    &-danger {
        color:$wind-link-color-danger;
        &:hover {
            color:$wind-link-color-danger;
        }
    }
    :deep(.el-switch) {
        vertical-align: bottom;
    }
}
</style>
