// 品项二维码默认设置
export default {
	itemCode: {
		// 数量
		amount: false,
		// 明细
		detail: false,
		// 序列码
		sequenceCode: false,
		// 供货商编码
		supplierCode: false
	}
}
