// 项目ui
import type { App } from 'vue'
import VCalendar from 'v-calendar'
import Antd from 'ant-design-vue'
import commonUi from '@/components/common-ui'
import windUi from '@/components/wind-ui'
import { Tabs, Tab } from 'vant'

import 'ant-design-vue/dist/reset.css'
import 'vant/lib/index.css'
import '@/assets/stylus/main.scss'
import 'virtual:svg-icons-register'
import 'v-calendar/style.css'


const install = (app: App): void => {
	app.use(Antd)
		.use(VCalendar, {})
		.use(commonUi)
		.use(windUi)
		.use(Tabs).use(Tab)
}
export default {
	install
}
