import type { State } from './state'
import { getInfo } from '@/js/api/bar'

interface CurrentUser {
	tenantId: number
	name: string
	username: string
	id: number
	organName: string
	systemFlag: number
	haveMoneyPower: number
	haveCostPower: number
}

interface CurrentOrgan {
	name: string
	id: number

}

interface CurrentTenant {
	entCode: String
	slyFlag: number
}

export function syncLoginer<T extends {
	currentUser: CurrentUser,
	currentTenant: CurrentTenant,
	currentOrgan: CurrentOrgan
}> ({ commit }: { commit: Function, state: State }): Promise<T> {
	return new Promise(resolve => {
		getInfo().then((res) => {
			console.log(res)
			const userData = {
				employeeName: res.user.userName,
				username: res.user.userName,
				roles: JSON.stringify(res.user.roles.map((item: any) => (item.roleName)))
			}
			commit('SET_LOGINER', userData)
			resolve(res)
		})
	})
}
