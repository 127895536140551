// 项目常用函数
import type { App } from 'vue'
import utils from '@/js/utils'
import stateMiddleware from '@/js/stateMiddleware'
import typeMiddleware from '@/js/typeMiddleware'
import fxCalculation from '@/js/calculation'
import fxCommon from '@/js/common'
import fxApi from '@/js/api'

const install = (app: App): void => {
	app.use(utils)
		.use(stateMiddleware)
		.use(typeMiddleware)
		.use(fxCalculation)
		.use(fxCommon)
		.use(fxApi)
}
export default {
	install
}
