export default {
	'financial-out-store': {
		id: 'financial-out-store',
		label: '财务出库单',
		code: '0007_0004_0002',
		child: {
			'edit-outstore': {
				id: 'edit-outstore',
				label: '编辑',
				code: '0007_0004_0002_002_001'
			},
			'submit-outstore': {
				id: 'submit-outstore',
				label: '提交',
				code: '0007_0004_0002_009_001'
			},
			'back-outstore': {
				id: 'back-outstore',
				label: '退回',
				code: '0007_0004_0002_010_001'
			},
			'check-outstore': {
				id: 'check-outstore',
				label: '审核',
				code: '0007_0004_0002_011_001'
			},
			'uncheck-outstore': {
				id: 'uncheck-outstore',
				label: '反审',
				code: '0007_0004_0002_012_001'
			},
			'recheck-outstore': {
				id: 'recheck-outstore',
				label: '复审',
				code: '0007_0004_0002_013_001'
			},
			'unrecheck-outstore': {
				id: 'unrecheck-outstore',
				label: '取消复审',
				code: '0007_0004_0002_014_001'
			},
			'delete-outstore': {
				id: 'delete-outstore',
				label: '删除',
				code: '0007_0004_0002_004_001'
			},
			'pullDown-outstore': {
				id: 'pullDown-outstore',
				label: '下推',
				code: '0007_0004_0002_242_001',
				decisionByAllModule: ['financial-manage_capital-manage_receivable-order.edit-receivable']
			},
			'splitBill-outstore': {
				id: 'splitBill-outstore',
				label: '拆单',
				code: '0007_0004_0002_243_001'
			}
		}
	}
}
