<template>
	<div class="fx-weigh-image" :class="{
		'is-disabled': disabled
	}">
		<w-icon-svg type="icon-camera" class="camera-icon" @click="onCameraClick"></w-icon-svg>
		<div class="btn-text" @click="showModal">查看照片</div>
		<a-modal
			v-model:visible="visible"
			:title="title"
			:bodyStyle="{
				width: '60vw',
				height: '41vw',
				'padding': '1vw 1vw 1vw 1vw',
				display: 'flex'
			}"
			centered
			width="60vw"
			class="fx-weigh-image__modal"
		>
			<div v-if="fileList.length>0" class="fx-weigh-image__images-ul">
				<div v-for="(item, index) in fileList" :key="index" class="file-list__block">
					<w-image :src="item" class="block-image" @click="onPreviewClick(item)"></w-image>
					<a-popconfirm
						title="确认后，称重图片将被删除，确认是否删除？"
						@confirm="onFileDelClick(item, index)"
					>
						<w-icon
							v-if="!disabled"
							type="delete-icon"
							size="mini"
							pointer
							class="delete-btn"
						></w-icon>
					</a-popconfirm>
				</div>
			</div>
			<w-empty v-else></w-empty>
			<template #footer>
				<a-button key="submit" type="primary" class="confirm-btn" @click="handleOk">确定</a-button>
			</template>
		</a-modal>
		<w-image-preview v-model:previewVisible="previewVisible" :previewUrl="previewUrl"></w-image-preview>
	</div>
</template>
<script lang="ts">
/**
 * fxWeighImage by shang 2022/8/25
 * @desc fxWeighImage 拍照按钮
 */
import { defineComponent, ref } from 'vue'
import type { PropType } from 'vue'
import { getFxInstance } from '@/js/instance'
interface WeighImageItem {
	imageUrl: string
}
export default defineComponent({
	name: 'fx-weigh-image',
	props: {
		value: {
			type: Array as PropType<WeighImageItem[]>,
			default: () => {
				return []
			}
		},
		title: {
			type: String,
			default: '称重图片'
		},
		maxCount: {
			type: Number,
			default: 6
		},
		emptyText: {
			type: String,
			default: '暂无数据'
		},
		disabled: Boolean
	},
	setup (props, { emit }) {
		const fxInstance = getFxInstance()
		const visible = ref(false)
		const previewVisible = ref(false)
		const previewUrl = ref('')
		const fileList = ref<string[]>([])
		let fileString = ''
		const onCameraClick = () => {
			if (props.disabled) {
				return false
			}
			if (props.value.length >= props.maxCount) {
				fxInstance.$fxMessage.warning('已达到最大照片数量')
				return false
			}
			fxInstance.$fxCordova.camera.getPicture().then(uploadFn).then(res => {
				const imageList = props.value
				imageList.push({
					imageUrl: res as unknown as string
				})
				emit('change', imageList)
			})
		}
		const showModal = () => {
			if (props.disabled) {
				return false
			}
			fileList.value = props.value.map(item => item.imageUrl)
			fileString = fileList.value.join(',')
			visible.value = true
		}
		const handleOk = () => {
			visible.value = false
			const currentFileString = fileList.value.join(',')
			if (currentFileString !== fileString) {
				const list = fileList.value.map(item => {
					return {
						imageUrl: item
					}
				})
				emit('update:value', list)
				emit('change', list)
			}
		}
		const uploadFn = (file:File) => {
			const params = {
				file
			}
			return fxInstance.$fxApi('common.upload')({ data: params })
		}
		const onPreviewClick = (url:string) => {
			previewUrl.value = url
			previewVisible.value = true
		}
		const onFileDelClick = (item: string, index:number) => {
			fileList.value.splice(index, 1)
		}
		return {
			visible,
			fileList,
			previewVisible,
			previewUrl,
			onCameraClick,
			showModal,
			handleOk,
			uploadFn,
			onFileDelClick,
			onPreviewClick
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.fx-weigh-image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    margin-left: 6px;
    width: 166px;
    height: 36px;
    border: 1px solid $fxDefaultColor;
    border-radius: 20px;
    text-align:center;
    color: $fxDefaultColor;
    line-height: 36px;
    .camera-icon {
        margin-right: 35px;
        width: 20px;
        height: 20px;
        color: $fxDefaultColor;
    }
    .btn-text {
        position: relative;
        &::before {
            position: absolute;
            top: 5px;
            left: -20px;
            width: 1px;
            height: 26px;
            background-color: $fxDefaultColor;
            content: "";
        }
    }
    &.is-disabled {
        border-color: #d9d9d9;
        color: rgb(0 0 0 / 25%);
        background-color: #f5f5f5;
        .camera-icon {
            color: #d9d9d9;
        }
        .btn-text {
            &::before {
                background-color: #d9d9d9;
            }
        }
    }
}
.fx-weigh-image__modal {
    .fx-weigh-image__images-ul {
        display: flex;
        overflow: auto;
        padding-top:10px;
        padding-left: 20px;
        flex:1;
        flex-flow: row wrap;
        box-sizing:border-box;
        .file-list__block {
            position: relative;
            display: flex;
            align-items: center;
            margin-right: 20px;
            margin-bottom: 20px;
            width: 150px;
            height: 150px;
            border-radius: 6px;
            background-color: #f2f3f5;
            flex-direction: row;
            box-sizing: border-box;
            .block-image {
                width: 100%;
                height: 100%;
                border-radius: 6px;
            }
            .delete-btn {
                position: absolute;
                top:-10px;
                right: -10px;
                width: 20px;
                height: 20px;
            }
        }
    }
    .confirm-btn {
        width: 100%;
    }
}
</style>
