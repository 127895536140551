import { setStorage } from '../utils'
import fxStyleDynamic from '@/js/styleDynamic'
import type { State } from './state'

export function SET_STYLUS_DYNAMIC_FONT_SIZE_TYPE<T extends Partial<State>> (state: T, fontSizeType: string): void {
	state.fontSizeType = fontSizeType
	setStorage('fontSizeType', fontSizeType)
	fxStyleDynamic.setStyle('fontSizeType', fontSizeType)
}

