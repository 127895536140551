<template>
	<w-app-container direction="row" class="item-list">
		<w-scroll-load-list
			ref="itemListContainerRef"
			v-model:loading="loading"
			:finish="finish"
			:isEmpty="!viewItemDetailsList.length"
			:immediateCheck="false"
			class="item-list__list"
			@load="onListLoad"
		>
			<div
				v-for="(item, index) in viewItemDetailsList"
				:key="item.id"
				:ref="(el) => setItemRefs(el as ComponentPublicInstance, item.id)"
				:style="listItemStyle"
				class="item-list__item"
			>
				<slot :itemData="item" :index="index"></slot>
			</div>
		</w-scroll-load-list>
		<w-alpha-index v-if="showAlphaIndex" v-model:value="currentAlphaIndex"></w-alpha-index>
	</w-app-container>
</template>
<script lang="ts">
import { defineComponent, ref, watch, computed } from 'vue'
import type { PropType, ComponentPublicInstance } from 'vue'
import { getFxInstance } from '@/js/instance'
export default defineComponent({
	name: 'categoryList',
	props: {
		itemDetailsList: {
			type: Array as PropType<{id: string, name: string}[]>,
			default: () => {
				return []
			}
		},
		showAlphaIndex: Boolean,
		alphaIndex: {
			type: String,
			default: ''
		},
		rowCount: Number
	},
	emits: ['update:alphaIndex'],
	setup (props, { emit }) {
		const fxInstance = getFxInstance()
		const currentAlphaIndex = ref('')
		watch(() => currentAlphaIndex.value, value => {
			emit('update:alphaIndex', value)
		})
		// {{{ scroll-load-list
		const viewItemDetailsList = ref<typeof props.itemDetailsList>([])
		const start = 0
		const pageCount = 30
		let end = start + pageCount
		const loading = ref(false)
		const finish = ref(false)
		const onListLoad = () => {
			viewItemDetailsList.value = props.itemDetailsList.slice(start, end)
			loading.value = false
			if (viewItemDetailsList.value.length >= props.itemDetailsList.length) {
				finish.value = true
			}
			end += pageCount
		}
		const itemListContainerRef = ref<ComponentPublicInstance>()
		const refreshList = () => {
			fxInstance.$fxUtils.doScrollTop(itemListContainerRef.value?.$el, 200)
			end = start + pageCount
			loading.value = true
			finish.value = false
			onListLoad()
		}
		// }}}
		const itemBlockRefMap: {[key:string]:ComponentPublicInstance} = {}
		const setItemRefs = (el: ComponentPublicInstance, id:string) => {
			if (el) {
				itemBlockRefMap[id] = el
			}
		}
		const scrollInView = (key:string) => {
			if (itemBlockRefMap[key]) {
				fxInstance.$fxUtils.doScrollTop(itemBlockRefMap[key].$el, 200)
			}
		}
		watch(() => props.itemDetailsList, () => {
			viewItemDetailsList.value = props.itemDetailsList.slice(start, end)
			if (viewItemDetailsList.value.length >= props.itemDetailsList.length) {
				finish.value = true
			}
		})
		const listItemStyle = computed(() => {
			if (props.rowCount) {
				return { 'flex-basis': (1 / props.rowCount) * 100 + '%' }
			} else {
				return ''
			}
		})
		return {
			currentAlphaIndex,
			loading,
			finish,
			onListLoad,
			viewItemDetailsList,
			refreshList,
			setItemRefs,
			scrollInView,
			itemListContainerRef,
			listItemStyle
		}
	}
})
</script>
<style lang="scss" scoped>
@import "$assets/stylus/varsty";
.item-list {
	position: relative;
	display: flex;
	overflow: auto;
	flex: 1;
	box-sizing: border-box;
	&__list {
		padding-top: 10px;
		padding-left: 10px;
	}
	&__item {
		overflow: hidden;
	}
}
</style>
